import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideSnackbar } from 'src/utils/snackbar/SnackbarSlice';
import { IRootState } from 'src/store/types';
import { Alert, AlertTitle, alpha, Box, Button, CircularProgress, Snackbar, Typography } from '@mui/material';
import { themeV5 } from 'src/app/theme/ThemeV5';
import { useIntl } from 'src/app/i18n/TypedIntl';

export const SnackbarPopUp = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { isVisible, title, text, type, duration, positionType, isAlert, isLoader } = useSelector(
    (state: IRootState) => state.snackbar,
  );

  const handleCloseSnackBar = () => {
    dispatch(hideSnackbar());
  };

  if (!isVisible) return null;

  return (
    <Snackbar
      open
      anchorOrigin={{
        vertical: positionType === 'top' ? 'top' : 'bottom',
        horizontal: 'center',
      }}
      autoHideDuration={duration}
      onClose={() => handleCloseSnackBar()}
    >
      <Alert
        severity={type}
        variant="outlined"
        onClose={() => handleCloseSnackBar()}
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'center',
          gap: '8px',
          borderRadius: '4px',
          padding: '6px 16px',
          minWidth: '323px',
          maxWidth: '398px',

          '.MuiAlert-icon': {
            ...(isLoader && {
              display: 'none',
            }),
          },

          '.MuiAlert-action': {
            ...(isLoader && {
              display: 'none',
            }),
          },

          ...(isLoader && {
            background: themeV5.palette.grey[300],
            border: themeV5.palette.grey[300],
            color: themeV5.palette.text.primary,
            alignItems: 'center',
            borderRadius: '48px',
            padding: '8px 22px',
            minWidth: 'auto',
            maxWidth: 'auto',

            '.MuiAlert-message': {
              padding: 0,
            },
          }),
        }}
      >
        <Box sx={{ display: 'flex', gap: '16px' }}>
          <Box
            sx={{
              display: 'flex',
              ...(isAlert && {
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '4px',
                flex: '1 0 0',
              }),
            }}
          >
            <AlertTitle
              sx={{
                display: 'flex',
                alignItems: 'center',
                ...(isLoader && {
                  margin: '0',
                }),
              }}
            >
              {isLoader && <CircularProgress size={24} />}
              {title}
            </AlertTitle>
            {title !== 'loading' && <Typography>{text}</Typography>}
          </Box>
          <Box>
            {type === 'error' && (
              <Button
                sx={{
                  backgroundColor: alpha('#D32F2F', 0.16),
                  color: '#D32F2F',
                  padding: '4px 10px',
                }}
                onClick={() => handleCloseSnackBar()}
              >
                {intl.formatMessage({ id: 'user_management.add.status.error.button' })}
              </Button>
            )}
          </Box>
        </Box>
      </Alert>
    </Snackbar>
  );
};
