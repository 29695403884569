import { Box } from '@mui/material';
import React from 'react';
import { UserNameField } from 'src/enterprise/configuration-replication/pages/UserManagementPage/Form/InputFields/UserName';
import { UserRoleField } from 'src/enterprise/configuration-replication/pages/UserManagementPage/Form/InputFields/UserRole';
import { UserEmailField } from 'src/enterprise/configuration-replication/pages/UserManagementPage/Form/InputFields/UserEmail';
import { UserPhoneNumberField } from 'src/enterprise/configuration-replication/pages/UserManagementPage/Form/InputFields/UserPhoneNumber';
import { UserLocationAccessField } from 'src/enterprise/configuration-replication/pages/UserManagementPage/Form/InputFields/UserLocationAccess';
import { useFormContext } from 'react-hook-form';

interface EditUserFormProps {
  row: { id: string; name: string; role: string; email: string; phoneNumber: string; locationAccess: string[] };
}

export const EditUserForm = ({ row }: EditUserFormProps) => {
  const { register } = useFormContext();

  if (!row) return null;

  return (
    <Box sx={{ alignItems: 'baseline', alignSelf: 'stretch', display: 'flex', gap: '16px', flexGrow: 1 }}>
      <input type="hidden" {...register(`users.${row.id}.id`, { value: row.id })} defaultValue={row.id} />
      <UserNameField row={row} />
      <UserRoleField row={row} />
      <UserEmailField row={row} />
      <UserPhoneNumberField row={row} />
      <UserLocationAccessField row={row} />
    </Box>
  );
};
