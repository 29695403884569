import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { StaffHttpPort } from 'src/tips/infrastructure/StaffHttpPort';
import { QUERYKEY_BUSINESS_STAFF } from 'src/tips/queries/getBusinessStaffQuery';

export const useUpdateStaffRateMutation = (businessId: string) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const staffPort = new StaffHttpPort(configuration.bookkeepingApiBaseUrl);
  return useMutation({
    mutationFn: (updateRateDto: UpdateStaffRateDto) =>
      staffPort.updateRate(businessId, updateRateDto.staffId, updateRateDto.rate),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_BUSINESS_STAFF, businessId] });
    },
  });
};

export interface UpdateStaffRateDto {
  staffId: string;
  rate: number;
}
