import { TimeRange } from '../browse/domain/TimeRange';
import { Instant, UnitOfTime } from '../../Instant';

export class DateRange {
  private constructor(private start: Instant, private end: Instant) {
  }

  /**
   * @Deprecated we should not use js Date anymore
   * we need to adapt the code using this
   * see ADR : https://www.notion.so/sundayapp/Date-handling-in-merchant-dashboard-1744779213d345fcb283f8a9930a13cc
   */
  static fromDates(start: Date, end: Date) {
    return new DateRange(Instant.fromJsDate(start), Instant.fromJsDate(end));
  }

  static fromInstants(start: Instant, end: Instant) {
    return new DateRange(start, end);
  }

  public allDays(): Instant[] {
    const allDays: Instant[] = [];
    for (let i = 0; i < this.numberOfDays(); i += 1) {
      allDays.push(this.start.add(i, 'd'));
    }
    return allDays;
  }

  public startDateI() {
    return this.start;
  }

  public endDateI() {
    return this.end;
  }

  /**
   * @Deprecated we should not use js Date anymore
   * we need to adapt the code using this
   * see ADR : https://www.notion.so/sundayapp/Date-handling-in-merchant-dashboard-1744779213d345fcb283f8a9930a13cc
   */
  public startDate() {
    return this.start.toDate();
  }

  /**
   * @Deprecated we should not use js Date anymore
   * we need to adapt the code using this
   * see ADR : https://www.notion.so/sundayapp/Date-handling-in-merchant-dashboard-1744779213d345fcb283f8a9930a13cc
   */
  public endDate() {
    return this.end.toDate();
  }

  static lastSevenDays(timezone?: string) {
    return new DateRange(
      Instant.now(timezone)
        .subtract(6, 'd')
        .startOf('day'),
      Instant.now(timezone)
        .endOfDay(),
    );
  }

  static previousSevenDays(timezone?: string) {
    return new DateRange(
      Instant.now(timezone)
        .subtract(7, 'd')
        .startOf('day'),
      Instant.now(timezone)
        .startOf('day'),
    );
  }

  static lastTwoWeeks(timezone?: string) {
    return new DateRange(
      Instant.now(timezone)
        .subtract(13, 'd')
        .startOf('day'),
      Instant.now(timezone)
        .endOfDay(),
    );
  }

  static lastThirtyDays(timezone?: string) {
    return new DateRange(
      Instant.now(timezone)
        .subtract(29, 'd')
        .startOf('day'),
      Instant.now(timezone)
        .endOfDay(),
    );
  }

  static lastYear(timezone?: string) {
    return new DateRange(
      Instant.now(timezone)
        .subtract(1, 'y')
        .startOf('day'),
      Instant.now(timezone)
        .endOfDay(),
    );
  }

  static last11Months(now: Instant = Instant.now()) {
    const start = now
      .subtract(11, 'M')
      .startOf('day')
      .startOf('M');
    return new DateRange(start, now);
  }

  static today(timezone?: string) {
    return new DateRange(this.startOfToday(timezone), this.endOfToday(timezone));
  }

  static yesterday(timezone?: string) {
    return new DateRange(this.startOfYesterday(timezone), this.endOfYesterday(timezone));
  }

  static startOfToday(timezone?: string) {
    return Instant.now(timezone)
      .startOf('day');
  }

  static endOfToday(timezone?: string) {
    return Instant.now(timezone)
      .endOfDay();
  }

  static startOfYesterday(timezone?: string) {
    return Instant.now(timezone)
      .subtract(1, 'day')
      .startOf('day');
  }

  static endOfYesterday(timezone?: string) {
    return Instant.now(timezone)
      .subtract(1, 'day')
      .endOfDay();
  }

  shortLabel(locale: string) {
    if (locale === ('en-US')) {
      return `${this.start.format('MM/DD')} - ${this.end.format('MM/DD')}`;
    }

    return `${this.start.format('DD/MM')} - ${this.end.format('DD/MM')}`;
  }

  toTimeRange(): TimeRange {
    return {
      startDate: this.start.toDate(),
      endDate: this.end.toDate(),
    };
  }

  contains(creationDate: Instant): boolean {
    return creationDate.isBefore(this.end) && this.start.isBefore(creationDate);
  }

  numberOfDays(): number {
    return this.end.deltaInDays(this.start);
  }

  isYesterday(timezone?: string): boolean {
    const yesterday = DateRange.yesterday(timezone);
    return this.start.equals(yesterday.start) && this.end.equals(yesterday.end);
  }

  isLastSevenDays(timezone?: string): boolean {
    const last7days = DateRange.lastSevenDays(timezone);
    return this.start.equals(last7days.start) && this.end.equals(last7days.end);
  }

  isLastThirtyDays(timezone?: string): boolean {
    const last30days = DateRange.lastThirtyDays(timezone);
    return this.start.equals(last30days.start) && this.end.equals(last30days.end);
  }

  isCustom(): boolean {
    return !this.isYesterday() && !this.isLastSevenDays() && !this.isLastThirtyDays();
  }

  add(n: number, unitOfTime: UnitOfTime): DateRange {
    return new DateRange(this.start.add(n, unitOfTime), this.end.add(n, unitOfTime));
  }

  subtract(n: number, unitOfTime: UnitOfTime): DateRange {
    return new DateRange(this.start.subtract(n, unitOfTime), this.end.subtract(n, unitOfTime));
  }

  mapStart(mapper: (i: Instant) => Instant): DateRange {
    return new DateRange(mapper(this.start), this.end);
  }

  mapEnd(mapper: (i: Instant) => Instant): DateRange {
    return new DateRange(this.start, mapper(this.end));
  }
}
