interface SpaceUsagesOptions {
  none: string;
  xs: string;
  small: string;
  mediumSmall: string;
  medium: string;
  mediumLarge: string;
  mediumXLarge: string;
  largeSmall: string;
  largeMedium: string;
  large: string;
  xLarge: string;
}

export const spaceUsages: SpaceUsagesOptions = {
  none: '0',
  xs: '2px',
  small: '4px',
  mediumSmall: '8px',
  medium: '12px',
  mediumLarge: '16px',
  mediumXLarge: '20px',
  largeSmall: '24px',
  largeMedium: '32px',
  large: '48px',
  xLarge: '64px',
};
