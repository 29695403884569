import { InputDatePicker, Panel, Tooltip } from '@sundayapp/b2b-react-component-library';
import * as React from 'react';
import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Box, CircularProgress, IconButton, Menu, Select, SelectChangeEvent, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import MenuItem from '@mui/material/MenuItem';
import { useListReconciliationReportsByStatus } from '../queries/listReports';
import { useReportSettings } from '../queries/ReportSettings';
import ReconciliationTable from '../component/ReconciliationTable';
import { CashupStatus } from '../domain/ReconciliationReport';
import { downloadBlob, toYyyyMmDdFormat } from 'src/accounting/payout/pages/utils';
import { ReconciliationRepositoryHttp } from '../infrastructure/ReconciliationRepositoryHttp';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { LocalisationKey } from 'src/lang/en';

const MainPanel = styled(Panel)`
  flex-direction: column;
  padding: 0;
`;
const ALL = 'ALL';

const cashupStatusFromString = (valueStr: string): CashupStatus | undefined => {
  const result = valueStr as CashupStatus;
  const isValid = Object.values(CashupStatus).includes(result);
  if (isValid) {
    return result;
  }
  return undefined;
};

type DownloadButtonProps = {
  isRangeSelected: boolean;
  exportByShift: () => void;
  exportByPaymentMethod: () => void;
};

const DownloadButton = ({ isRangeSelected, exportByShift, exportByPaymentMethod }: DownloadButtonProps) => {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleExportByShift = () => {
    setAnchorEl(null);
    exportByShift();
  };

  const handleExportByPaymentMethod = () => {
    setAnchorEl(null);
    exportByPaymentMethod();
  };

  if (!isRangeSelected) {
    return (
      <Tooltip
        direction="left"
        title={intl.formatMessage({ id: 'reconciliation.report.export_with_no_range_selected' })}
      >
        <IconButton className="custom-large-iconButton" size="large" disableRipple disabled>
          <DownloadIcon sx={{ width: '22px', height: '22px' }} />
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <>
      <IconButton className={'custom-large-iconButton'} size="large" disableRipple onClick={handleClick}>
        <DownloadIcon sx={{ width: '22px', height: '22px' }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleExportByShift}>
          {intl.formatMessage({ id: 'reconciliation.report.export_by_shift' })}
        </MenuItem>
        <MenuItem onClick={handleExportByPaymentMethod}>
          {intl.formatMessage({ id: 'reconciliation.report.export_by_payment_method' })}
        </MenuItem>
      </Menu>
    </>
  );
};

const cashupStatusLabel: Record<CashupStatus, LocalisationKey> = {
  [CashupStatus.Open]: 'reconciliation.report.status.open',
  [CashupStatus.Closed]: 'reconciliation.report.status.closed',
};

const HistoricalReportsTab = () => {
  const intl = useIntl();
  const [reportStatusStr, setReportStatusStr] = useState<string>(CashupStatus.Closed);
  const [rangeDate, setRange] = useState<[Date, Date]>();
  const business = useCurrentBusinessOrThrow();
  const reportStatus = useMemo(() => cashupStatusFromString(reportStatusStr), [reportStatusStr]);
  const { data: reports, isLoading } = useListReconciliationReportsByStatus(business.id, reportStatus, rangeDate);
  const reconciliationRepository = new ReconciliationRepositoryHttp();
  useReportSettings();
  const { data: reportSettings } = useReportSettings();

  if (isLoading || !reports || !reportSettings) {
    return <CircularProgress />;
  }

  const onRangeChange = async (startDate: Date, endDate: Date) => {
    setRange([startDate, endDate]);
  };

  const downloadShiftExport = () => {
    const [start, end] = rangeDate as [Date, Date];
    reconciliationRepository.exportByShift(business.id, start, end, reportStatus).then((blob) => {
      const startStr = toYyyyMmDdFormat(start);
      const endStr = toYyyyMmDdFormat(end);
      const fileName = `reconciliation-by-shift-${business.name}-${startStr}-${endStr}.csv`;
      downloadBlob(blob, fileName);
    });
  };
  const downloadPaymentMethodExport = () => {
    const [start, end] = rangeDate as [Date, Date];
    reconciliationRepository.exportByPaymentMethod(business.id, start, end, reportStatus).then((blob) => {
      const startStr = toYyyyMmDdFormat(start);
      const endStr = toYyyyMmDdFormat(end);
      const fileName = `reconciliation-by-payment-method-${business.name}-${startStr}-${endStr}.csv`;
      downloadBlob(blob, fileName);
    });
  };

  const handleChange = (event: SelectChangeEvent) => setReportStatusStr(event.target.value);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Panel
        style={{
          justifyContent: 'space-between',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'stretch',
        }}
      >
        <Box display="flex" flexDirection="row" gap="10px" alignItems="stretch">
          <InputDatePicker
            style={{
              width: 'fit-content',
              background: 'white',
              borderRadius: '8px',
              height: 'unset',
            }}
            type="range"
            value={rangeDate}
            placeholder={intl.formatMessage({ id: 'datepicker.select_date_range' })}
            onChange={onRangeChange}
          />
          <Select
            value={reportStatusStr}
            label="Report Status"
            onChange={handleChange}
            style={{ width: 'fit-content' }}
          >
            {Object.values(CashupStatus).map((status) => (
              <MenuItem key={status} value={status}>
                <FormattedMessage id={cashupStatusLabel[status]} />
              </MenuItem>
            ))}
            <MenuItem key={ALL} value={ALL}>
              <FormattedMessage id="reconciliation.report.status.all" />
            </MenuItem>
          </Select>
        </Box>

        <DownloadButton
          isRangeSelected={rangeDate !== undefined}
          exportByShift={() => downloadShiftExport()}
          exportByPaymentMethod={downloadPaymentMethodExport}
        />
      </Panel>

      {reports.length === 0 && (
        <Typography variant="body1">
          <FormattedMessage id="reconciliation.report.history.empty" />
        </Typography>
      )}
      {reports.length > 0 && (
        <MainPanel>
          <ReconciliationTable reports={reports} reportSettings={reportSettings} />
        </MainPanel>
      )}
    </Box>
  );
};

export default HistoricalReportsTab;
