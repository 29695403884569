import { alpha, Box, Button, styled, Typography } from '@mui/material';
import { BannerCard } from 'src/home/components/header/HomeBanner/BannerCard';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { ampli } from 'src/ampli';
import { Business } from 'src/business/domain/Business';

const BannerWrapper = styled(Box)(() => ({
  borderRadius: '32px',
  background: 'linear-gradient(106deg, #240525 31.41%, #FF17E9 96.82%)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '32px',
  marginBottom: '32px',
  zIndex: 3,

  ['@media (max-width: 500px)']: {
    padding: '40px 24px',
    margin: '32px -32px',
  },

  ['@media (max-width: 1024px)']: {
    flexDirection: 'column-reverse',
    gap: '24px',
  },
}));

const BannerTitleWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',

  ['@media (max-width: 1024px)']: {
    textAlign: 'center',
  },

  ['@media (max-width: 1025px)']: {
    alignItems: 'center',
  },
}));

const BannerGreyTitle = styled(Typography)(({ theme }) => ({
  color: alpha(theme.palette.background.paper, 0.58),
  fontSize: '50px',
  fontWeight: 500,
  lineHeight: '64.8px',
  letterSpacing: '-3.6px',

  ['@media (max-width: 767px)']: {
    lineHeight: '50.4px',
    letterSpacing: '-2.8px',
  },

  ['@media only screen and (min-width: 350px) and (max-width: 1024px)']: {
    fontSize: '56px',
  },

  ['@media (min-width: 1025px)']: {
    width: '541px',
    fontSize: '72px',
  },
}));

const BannerTitleSpan = styled(Typography)(({ theme }) => ({
  color: theme.palette.background.paper,
  fontSize: '50px',
  lineHeight: '64.8px',
  letterSpacing: '-3.6px',

  ['@media only screen and (min-width: 350px) and (max-width: 1024px)']: {
    fontSize: '56px',
  },

  ['@media (min-width: 1025px)']: {
    fontSize: '72px',
  },
})) as typeof Typography;

const BannerSubTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.background.paper,

  ['@media (max-width: 500px)']: {
    padding: '0px 26px',
  },
}));

const BannerButtonsWrapper = styled(Box)(() => ({
  display: 'flex',
  fontWeight: 500,

  ['@media (max-width: 500px)']: {
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '8px',
    width: '100%',
  },
}));

const BannerButtonText = styled(Typography)(() => ({
  fontWeight: 500,
  ['@media (max-width: 500px)']: {
    width: '100%',
  },
}));

interface HomeBannerProps {
  adoptionRate?: number | undefined;
  totalRevenue?: string;
  billViewRate?: number;
  business?: Business;
}

export const HomeBanner = ({ adoptionRate, totalRevenue, billViewRate, business }: HomeBannerProps) => {
  const intl = useIntl();

  const eventData = {
    venueId: business?.id,
    venueName: business?.name,
  };

  return (
    <BannerWrapper>
      <BannerTitleWrapper>
        <BannerGreyTitle>
          {intl.formatMessage({ id: 'venue.home.banner.title' })}{' '}
          <BannerTitleSpan component="span">
            {intl.formatMessage({ id: 'venue.home.banner.titleSpan' })}
          </BannerTitleSpan>
        </BannerGreyTitle>
        <BannerSubTitle variant="h6">{intl.formatMessage({ id: 'venue.home.banner.subTitle' })}</BannerSubTitle>
        <BannerButtonsWrapper>
          <Button
            variant="outlined"
            sx={{
              borderColor: 'background.paper',
              '&:hover': { backgroundColor: 'background.paper', color: 'text.primary' },

              ['@media (max-width: 500px)']: {
                width: '100%',
              },
            }}
            component={'a'}
            href={'https://intercom.help/sundayapp-help/en/articles/10722442-digital-bill'}
            onClick={() => {
              ampli.howToIncreaseAdoptionClicked(eventData);
            }}
            target={'_blank'}
            rel={'noreferrer'}
          >
            <BannerButtonText>{intl.formatMessage({ id: 'venue.home.banner.button.howToIncrease' })}</BannerButtonText>
          </Button>
          <Button
            sx={(theme) => ({
              color: theme.palette.background.paper,
              ['@media (max-width: 500px)']: {
                width: '100%',
              },
            })}
            component={'a'}
            href={'https://intercom.help/sundayapp-help/en/articles/10722493-adoption-rate'}
            target={'_blank'}
            rel={'noreferrer'}
            onClick={() => {
              ampli.howIsAdoptionCalculatedClicked(eventData);
            }}
          >
            <BannerButtonText>
              {intl.formatMessage({ id: 'venue.home.banner.button.howIsCalculated' })}
            </BannerButtonText>
          </Button>
        </BannerButtonsWrapper>
      </BannerTitleWrapper>
      <BannerCard adoptionRate={adoptionRate} totalRevenue={totalRevenue} billViewRate={billViewRate} />
    </BannerWrapper>
  );
};
