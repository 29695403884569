import React from 'react';
import { useNavigate } from 'react-router';
// eslint-disable-next-line @typescript-eslint/no-redeclare
import { Title } from '@sundayapp/b2b-react-component-library';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { datadogLogs } from '@datadog/browser-logs';
import { RouteDefinitions } from 'src/app/RouteDefinitions';
import { ErrorPageWrapper, RobotImg } from '../pages/ErrorPage/ErrorPage.style';
import robotImg from '../../app/assets/robot.png';
import { spaceUsages } from 'src/app/theme/SpaceUsagesTheme';
import { SundayText } from 'src/app/component/Text/Text';
import { Button } from '@mui/material';

// Used only because some accounts were created before in firebase and redirect to this page
const FirebaseLegacyAuthenticationInterceptor = () => {
  const navigate = useNavigate();

  const redirectToHomePage = () => {
    navigate(RouteDefinitions.SignIn.path);
  };

  datadogLogs.logger.log('Firebase auth link used');

  return (
    <ErrorPageWrapper>
      <RobotImg src={robotImg} alt="error page" />
      <Title size="title1" marginBottom={spaceUsages.mediumXLarge}>
        <FormattedMessage id="authentication_error.title" />
      </Title>
      <SundayText size="medium" textAlign="center">
        <FormattedMessage id="authentication_error.subtitle" />
      </SundayText>
      <Button
        color="primary"
        size="large"
        onClick={redirectToHomePage}
        sx={{
          marginTop: spaceUsages.large,
          marginBottom: spaceUsages.medium,
          textTransform: 'lowercase',
        }}
      >
        <FormattedMessage id="authentication_error.goto" />
      </Button>
    </ErrorPageWrapper>
  );
};

export default FirebaseLegacyAuthenticationInterceptor;
