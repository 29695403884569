import React from 'react';
import styled from 'styled-components';
import { useSortable } from '@dnd-kit/sortable';
// eslint-disable-next-line @typescript-eslint/no-redeclare
import { CSS } from '@dnd-kit/utilities';
import { ChevronRight, DragIndicator, VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { useNavigate } from 'react-router';
import MenuItemName from './MenuItemName';
import MenuItemLastUpdated from './MenuItemLastUpdated';
import MenuItemSchedule from './MenuItemSchedule';
import { MenuSummary } from '../../../domain/MenuSummary';
import { venueDynamicMenuPath } from 'src/app/navigation/pathHelpers';
import { CopyMenu } from './CopyMenu';
import { BusinessId } from 'src/business/domain/Business';
import { MenuAssetSourceIcon } from 'src/menu/dynamic-menu/components/MenuAssetSourceIcon';
import { useMenuAssetsSources } from 'src/menu/dynamic-menu/pages/MenusPage/DigitalMenusContext';
import { MenuImage } from 'src/menu/dynamic-menu/pages/MenusPage/components/MenuImage';
import { spaceUsages } from 'src/app/theme/SpaceUsagesTheme';

const MenuItemLineContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
  border-radius: ${spaceUsages.largeSmall};
  padding: 8px;
  height: 125px;
  background-color: white;
  gap: 16px;
`;

const MenuItemToolColumn = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  padding: 16px;
  gap: 12px;
  align-items: center;
  justify-content: flex-end;
`;

type MenuItemProps = {
  businessId: BusinessId;
  menu: MenuSummary;
};

const MenuItem = ({ businessId, menu }: MenuItemProps) => {
  const navigateTo = useNavigate();
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: menu.id });
  const menuAssetsSources = useMenuAssetsSources();

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const onMenuRoute = () => {
    navigateTo(venueDynamicMenuPath(businessId, menu.id));
  };

  return (
    <MenuItemLineContainer ref={setNodeRef} {...attributes} style={style}>
      <Stack
        direction="row"
        alignItems="center"
        onClick={onMenuRoute}
        sx={{ cursor: 'pointer' }}
        width="50%"
        spacing={3}
      >
        <DragIndicator fontSize="large" {...listeners} style={{ cursor: 'grab', opacity: '0.5' }} />
        <MenuImage url={menu.imageUrl} />
        <Stack direction={'row'} spacing={1} width="auto" alignItems={'center'}>
          <MenuItemName name={menu.name} displayName={menu.displayName} active={menu.enabled} />
          <MenuAssetSourceIcon
            isSunday={menu.sundayMenu}
            menuAssetsSources={menuAssetsSources}
            posConnection={menu.posConnection}
          />
        </Stack>
        <ChevronRight fontSize="large" style={{ marginLeft: 1, opacity: menu.enabled ? 1 : 0.3 }} />
      </Stack>
      <MenuItemSchedule schedules={menu.schedules} active={menu.enabled} />

      <MenuItemLastUpdated lastUpdated={menu.lastModifiedAt} active={menu.enabled} />
      <MenuItemToolColumn>
        {menu.enabled!! ? (
          <VisibilityOutlined fontSize="large" />
        ) : (
          <VisibilityOffOutlined fontSize="large" style={{ opacity: 0.3 }} />
        )}
        <CopyMenu businessId={businessId} menu={menu} />
      </MenuItemToolColumn>
    </MenuItemLineContainer>
  );
};

export default MenuItem;
