import { datadogLogs } from '@datadog/browser-logs';
import {
  deleteObject,
  FirebaseStorage,
  getDownloadURL,
  getMetadata,
  getStorage,
  ref,
  StorageReference,
  uploadBytes,
  uploadString,
} from 'firebase/storage';
import { useMemo } from 'react';
import { CoverImageSizes, DocumentRepository } from 'src/domain/document/DocumentRepository';

async function getUpdatedDateFromFile(fileReference: StorageReference) {
  const initialMetadata = await getMetadata(fileReference);
  if (!initialMetadata || !initialMetadata.updated) {
    return Date.now();
  }
  return new Date(initialMetadata.updated as string).getTime();
}

export class DocumentRepositoryFirebaseStorage implements DocumentRepository {
  constructor(private storage: FirebaseStorage) {}

  async saveImage(venueId: string, path: string, fileName: string, base64Image: string): Promise<string> {
    const fileReference = ref(this.storage, [venueId, path, fileName].join('/'));
    await uploadString(fileReference, base64Image, 'data_url', {
      contentType: 'image/jpg',
    });
    return getDownloadURL(fileReference);
  }

  async saveVenueCoverImage(venueId: string, base64Image: string) {
    const fileReference = ref(this.storage, [venueId, 'cover', 'cover.jpeg'].join('/'));
    await uploadString(fileReference, base64Image, 'data_url', {
      contentType: 'image/jpeg',
    });
    const initialUpdatedDate = await getUpdatedDateFromFile(fileReference);
    const { s, m, l, xl } = await this.getDownloadUrlsOfResizedCoverImages(venueId, initialUpdatedDate);
    const raw = await getDownloadURL(fileReference);
    return {
      raw,
      s,
      m,
      l,
      xl,
    };
  }

  async getDownloadUrlsOfResizedCoverImages(venueId: string, initialUpdatedDate: number): Promise<CoverImageSizes> {
    const [s, m, l, xl] = await Promise.all(
      ['cover_100x56.jpeg', 'cover_250x141.jpeg', 'cover_500x282.jpeg', 'cover_800x452.jpeg'].map(
        (fileName) =>
          new Promise<string>((resolve, reject) => {
            const retry = async (count: number) => {
              if (count <= 0) return reject();
              try {
                const fileRef = ref(this.storage, [venueId, 'cover', fileName].join('/'));
                const resizedUpdatedDate = await getUpdatedDateFromFile(fileRef);
                if (resizedUpdatedDate < initialUpdatedDate) {
                  throw new Error(
                    'it is probably an outdated version of the file, or the file could not be resized in time (image resolution too big)',
                  );
                }
                const downloadUrl = await getDownloadURL(fileRef);
                resolve(downloadUrl);
              } catch (e) {
                console.log(`could not get file ${fileName} because of error ${e}, retrying ${count} times`);
                setTimeout(() => retry(count - 1), 1000);
              }
            };
            retry(8);
          }),
      ),
    );
    return { s, m, l, xl };
  }

  async saveVenueImageAsPNG(venueId: string, base64Image: string): Promise<Record<string, string>> {
    const fileReference = ref(this.storage, [venueId, 'logo', 'rounded.png'].join('/'));
    await uploadString(fileReference, base64Image, 'data_url', {
      contentType: 'image/png',
    });
    const initialUpdatedDate = await getUpdatedDateFromFile(fileReference);
    const [fileReference48, fileReference80, fileReference300] = await Promise.all(
      ['rounded_48x48.png', 'rounded_80x80.png', 'rounded_300x300.png'].map(
        (fileName) =>
          new Promise<string>((resolve, reject) => {
            const retry = async (count: number) => {
              if (count <= 0) return reject();
              try {
                const fileRef = ref(this.storage, [venueId, 'logo', fileName].join('/'));
                const resizedUpdatedDate = await getUpdatedDateFromFile(fileRef);
                if (resizedUpdatedDate < initialUpdatedDate) {
                  throw new Error(
                    'it is probably an outdated version of the file, or the file could not be resized in time (image resolution too big)',
                  );
                }
                const downloadUrl = await getDownloadURL(fileRef);
                resolve(downloadUrl);
              } catch (e) {
                console.log(`could not get file ${fileName} because of error ${e}, retrying ${count} times`);
                setTimeout(() => retry(count - 1), 1000);
              }
            };
            retry(8);
          }),
      ),
    );
    return {
      no_resize: await getDownloadURL(fileReference),
      '48x48': fileReference48,
      '80x80': fileReference80,
      '300x300': fileReference300,
    };
  }

  async saveFile(venueId: string, path: string, file: File) {
    const fileReference = ref(this.storage, [venueId, path].join('/'));
    await uploadBytes(fileReference, file!);
    return getDownloadURL(fileReference);
  }

  async deleteRefFromUrl(url: string | undefined) {
    if (url === undefined) return;
    try {
      const fileReference = ref(this.storage, url);
      await deleteObject(fileReference);
    } catch (error) {
      datadogLogs.logger.error('Could not delete file in storage from url', {
        error,
        url,
      });
    }
  }
}

export const useDocumentRepositoryFirebaseStorage = () => {
  const storage = getStorage();
  return useMemo(() => new DocumentRepositoryFirebaseStorage(storage), [storage]);
};
