import {
  Box,
  CardMedia,
  Chip,
  CircularProgress,
  circularProgressClasses,
  LinearProgress,
  linearProgressClasses,
  styled,
  Typography,
} from '@mui/material';
import qrCodeMobileImg from '../../../assets/banner-qrcode-hand.png';
import { useIntl } from 'src/app/i18n/TypedIntl';

interface BannerCardProps {
  adoptionRate?: number | undefined;
  totalRevenue?: string | undefined;
  billViewRate?: number | undefined;
}

const OrangeCircle = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
    <circle cx="5" cy="5" r="5" fill="#FF963A" />
  </svg>
);

const ChevronRight = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      /* eslint-disable-next-line max-len */
      d="M8.29289 5.29289C8.68342 4.90237 9.31658 4.90237 9.70711 5.29289L15.7071 11.2929C16.0976 11.6834 16.0976 12.3166 15.7071 12.7071L9.70711 18.7071C9.31658 19.0976 8.68342 19.0976 8.29289 18.7071C7.90237 18.3166 7.90237 17.6834 8.29289 17.2929L13.5858 12L8.29289 6.70711C7.90237 6.31658 7.90237 5.68342 8.29289 5.29289Z"
      fill="black"
    />
  </svg>
);

const CardImage = styled(CardMedia)(() => ({
  width: '107px',
  height: '107px',
  position: 'absolute',
  top: '-35px',
  left: '-3px',
  ['@media (max-width: 321px)']: { top: '16px', width: '98px', height: '98px' },
}));

interface CustomCircularProgressProps {
  billViewRate?: number | undefined;
}

const CustomCircularProgress = ({ billViewRate }: CustomCircularProgressProps) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <CircularProgress
        variant="determinate"
        sx={(theme) => ({
          color: '#E2E2E2',
          ...theme.applyStyles('dark', {
            color: '#E2E2E2',
          }),
          padding: '0',
          margin: '0',
        })}
        size={25}
        thickness={8}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        value={billViewRate}
        sx={(theme) => ({
          color: '#FF963A',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'butt',
          },
          ...theme.applyStyles('dark', {
            color: '#FF963A',
          }),
          padding: '0',
          margin: '0',
        })}
        size={25}
        thickness={8}
      ></CircularProgress>
    </Box>
  );
};

const BannerCardWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '16px',
  backdropFilter: 'blur(45px)',
  padding: '24px',
  gap: '18px',

  ['@media (max-width: 1024px)']: {
    width: '100%',
  },
}));

const CardImageWrapper = styled(Box)(() => ({
  display: 'flex',
  border: '1px solid rgba(14, 7, 29, 0.14)',
  borderRadius: '16px',
  position: 'relative',
  padding: '8px 10px 12px 88px',
  marginTop: '10px',
}));

export const BannerCard = ({ adoptionRate, totalRevenue, billViewRate }: BannerCardProps) => {
  const intl = useIntl();

  return (
    <BannerCardWrapper>
      <Box>
        <Typography sx={{ fontSize: '18px', lineHeight: '20px', letterSpacing: '-0.54px', fontWeight: 500 }}>
          {intl.formatMessage({ id: 'venue.home.banner.card.qrCodeAdoption' })}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <Box>
          <Typography sx={{ fontSize: '40px', fontWeight: 500, lineHeight: '40px', letterSpacing: '-2px' }}>
            {adoptionRate || 0}%
          </Typography>
        </Box>
        <Box>
          <Chip label={'Low'} color={'warning'} />
        </Box>
      </Box>
      <Box>
        <Box
          sx={{
            marginLeft: '-9px',
            ['@media (max-width: 500px)']: {
              marginLeft: '0',
            },
          }}
        >
          <LinearProgress
            variant={'determinate'}
            value={adoptionRate || 0}
            sx={{
              height: 8,
              borderRadius: 8,
              background: '#DDDDE4',

              [`& .${linearProgressClasses.bar}`]: {
                borderRadius: 8,
                backgroundColor: '#FF8B10',
              },
            }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            marginTop: '10px',
            gap: '8px',
            ['@media (max-width: 500px)']: { justifyContent: 'space-between' },
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <OrangeCircle />
            <Typography sx={{ fontSize: '14px', fontWeight: 500, lineHeight: '16px', letterSpacing: '-0.42px' }}>
              {intl.formatMessage({ id: 'venue.home.banner.card.qrCodeRevenue' })}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '16px',
                letterSpacing: '-0.42px',
                color: 'text.secondary',
              }}
            >
              {totalRevenue}
            </Typography>
          </Box>
        </Box>
      </Box>
      <CardImageWrapper>
        <Box>
          <CardImage image={qrCodeMobileImg} />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flex: 1 }}>
          <Box>
            <Box>
              <Typography sx={{ fontSize: '14px' }}>
                {intl.formatMessage({ id: 'venue.home.banner.card.digitalBill' })}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <CustomCircularProgress billViewRate={billViewRate} />
              <Typography sx={{ fontSize: '21px', fontWeight: 500, lineHeight: '23.1px', letterSpacing: '-0.63px' }}>
                {billViewRate}%
              </Typography>
            </Box>
          </Box>
          {false && (
            <Box sx={{ display: 'flex', cursor: 'pointer' }}>
              <ChevronRight />
            </Box>
          )}
        </Box>
      </CardImageWrapper>
    </BannerCardWrapper>
  );
};
