import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from 'src/app/queries/utils';
import { HttpBusinessRelationRepository } from 'src/infrastructure/venue/HttpBusinessRelationRepository';
import { BusinessId } from 'src/business/domain/Business';

export const useListBusinessUserRelations = (businessId: BusinessId) => {
  const repository = new HttpBusinessRelationRepository(ConfigurationLoader.load().merchantAccountsBaseUrl);

  return useQuery({
    queryKey: [queryKeys.relations.LIST_BUSINESS_USER_RELATIONS, { businessId }],
    queryFn: () => repository.listBusinessUserRelations(businessId),
  });
};
