import { useMutation, useQueryClient } from '@tanstack/react-query';

import { CurrencyCode } from '@sundayapp/web-money';
import { HttpPaymentTerminalRepository } from '../infrastructure/HttpPaymentTerminalRepository';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import {
  PaymentTerminalConfiguration,
  tipsKind,
  TipsLevelConfiguration,
  User,
} from '../domain/PaymentTerminalConfiguration';
import { PaymentTerminalConfigurationQueryKeys } from '../queries/paymentTerminalConfigurationQuery';
import { ConfigurationUpdateRequestDto, UserEnabledDto } from '../domain/PaymentTerminalRepository';
import { EnrollmentId } from 'src/business/domain/Enrollment';


function mapToTipsLevelDto(currency: string, tipsLevel: TipsLevelConfiguration) {
  const valuesConfig = tipsLevel.kind === tipsKind.AMOUNT
    ? {
      amount1: { amount: Math.round(tipsLevel.value1 * 100000), currency },
      amount2: { amount: Math.round(tipsLevel.value2 * 100000), currency },
      amount3: { amount: Math.round(tipsLevel.value3 * 100000), currency },
      amount4: { amount: Math.round(tipsLevel.value4 * 100000), currency },
    }
    : {
      rate1: Math.round(tipsLevel.value1 * 100),
      rate2: Math.round(tipsLevel.value2 * 100),
      rate3: Math.round(tipsLevel.value3 * 100),
      rate4: Math.round(tipsLevel.value4 * 100),
    };

  return {
    enabled: tipsLevel.enabled,
    kind: tipsLevel.kind,
    ...valuesConfig,
  };
}

function toUser(user: User): UserEnabledDto {
  return {
    id: user.id,
    enabled: user.enabled,
  };
}

export const useUpdatePaymentTerminalConfigurationMutation = (currency: CurrencyCode, enrollmentId: EnrollmentId) => {
  const queryClient = useQueryClient();
  const configuration = ConfigurationLoader.load();
  const repository = new HttpPaymentTerminalRepository(configuration.paymentTerminalBaseUrl);

  return useMutation({
    mutationFn: (config: PaymentTerminalConfiguration) => {
      const { lowAmountThreshold, highAmountThreshold } = config.general;
      const configDto: ConfigurationUpdateRequestDto = {
        ...config.general,
        lowAmountThreshold: {
          ...lowAmountThreshold,
          amount: Math.round(lowAmountThreshold.amount * 100000),
        },
        highAmountThreshold: {
          ...highAmountThreshold,
          amount: Math.round(highAmountThreshold.amount * 100000),
        },
        waiterListEnabled: config.waiter.enabled && config.waiter.waiters.length > 0,
        waiterList: config.waiter.waiters.map((w) => w.name),
        waiters: config.waiter.waiters,
        userExperienceEnabled: config.tips.userExperienceEnabled,
        tipsEnabled: config.tips.tipsEnabled,
        roundedTipsEnabled: config.tips.roundedTipsEnabled,
        tipsLevels: {
          level1: mapToTipsLevelDto(currency, config.tips.tipsLevels.level1),
          level2: mapToTipsLevelDto(currency, config.tips.tipsLevels.level2),
          level3: mapToTipsLevelDto(currency, config.tips.tipsLevels.level3),
        },
        posConnectionEnabled: config.psoConnection.enabled,
        scanEnabled: config.scan.enabled,
        fastPaymentEnabled: config.fastPayment.enabled,
        users: config.users.users.map((user) => toUser(user)),
        userGuestEnabled: config.users.userGuestEnabled,
        userListEnabled: config.users.enabled,
        concertProtocolEnabled: config.concertConfiguration.enabled,

      };
      if (config.id) {
        return repository.updateConfiguration(enrollmentId, config.id!, configDto);
      } else {
        return repository.createConfiguration(enrollmentId, configDto);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: PaymentTerminalConfigurationQueryKeys.allConfigurations() });
    },
  });
};
