import React, { useMemo, useState } from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { CircularProgress, styled } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { toViewModel } from './StaffPerformanceViewModel';
import { useStaffPerformance } from './useStaffPerformance';
import { Header } from '../component/DataGridHeader';
import { buildColumns } from './buildColumns';
import { NoDataOverlay } from '../component/NoDataOverlay';
import { buildGroupColumns } from './buildGroupColumns';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { StaffPerformanceRangeType } from 'src/staff-performance/domain/StaffPerformance';
import { useGetSinglePosConnectionOptions } from 'src/pos/queries/getPosConnectionOptions';
import { Instant } from 'src/sundayplus/Instant';
import { useCanManageTips } from 'src/tips/hooks/useCanManageTips';
import { PerformersKeyMetrics } from 'src/staff-performance/component/PerformersKeyMetrics';
import { Page } from '@templates/PageTemplate';

const StaffDataGrid = styled(DataGrid)(() => ({
  '.MuiDataGrid-columnHeader--filledGroup': {
    '.MuiDataGrid-columnHeaderTitleContainer': {
      borderBottom: 'none !important',
    },
  },

  '.MuiDataGrid-overlayWrapper': {
    zIndex: 1,
  },
}));
const defaultRange: StaffPerformanceRangeType = '7_DAYS';

export const StaffPerformance = () => {
  const intl = useIntl();
  const business = useCurrentBusinessOrThrow();
  const [startDate, updateStartDate] = useState<Instant>(Instant.now().startOf('day'));
  const [endDate, updateEndDate] = useState<Instant>(Instant.now().endOfDay());
  const [range, setRange] = useState<StaffPerformanceRangeType>(defaultRange);

  const { isLoading: isStaffPerformanceLoading, data } = useStaffPerformance({
    range,
    businessId: business.id,
    timezone: business.timezone,
    startDate: startDate.toDate(),
    endDate: endDate.toDate(),
  });
  const { isLoading: isPosConnectionLoading, data: posConnectionOptions } = useGetSinglePosConnectionOptions(
    business.id,
  );
  const shouldCollectAdoptionRate = posConnectionOptions?.collectAdoptionRate ?? false;

  const isLoading = isStaffPerformanceLoading || isPosConnectionLoading;
  const canManageTips = useCanManageTips();

  const onCustomRangeChanged = (currentStartDate: Date, currentEndDate: Date) => {
    updateStartDate(Instant.fromJsDate(currentStartDate));
    updateEndDate(Instant.fromJsDate(currentEndDate));
  };

  const dataForMetrics = useMemo(() => {
    return data;
  }, [data]);

  return (
    <Page
      hasHeadTitle={{ preLogoText: [{ id: 'menu.staff_performance' }], variant: 'h4', showTitle: false }}
      noBanner={true}
    >
      <div style={{ overflowX: 'hidden' }}>
        <Header
          range={range}
          setRange={setRange}
          startDate={startDate.toDate()}
          endDate={endDate.toDate()}
          setCustomRangeDate={onCustomRangeChanged}
        />
        {!isLoading && (
          <>
            <PerformersKeyMetrics data={dataForMetrics} />
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
              <div style={{ width: '100%', height: data?.length ? 550 : 'auto' }}>
                <StaffDataGrid
                  rows={toViewModel(data ?? [], intl.locale, business.currency, shouldCollectAdoptionRate)}
                  columns={buildColumns(business, canManageTips)}
                  disableRowSelectionOnClick
                  hideFooter
                  columnHeaderHeight={28}
                  slots={{
                    noRowsOverlay: NoDataOverlay,
                  }}
                  columnGroupingModel={buildGroupColumns(canManageTips)}
                  initialState={{ sorting: { sortModel: [{ field: 'billViewRate', sort: 'desc' }] } }}
                />
              </div>
            </div>
          </>
        )}
        {isLoading && <CircularProgress style={{ alignSelf: 'center' }} />}
      </div>
    </Page>
  );
};
