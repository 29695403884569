import { useIntl } from 'src/app/i18n/TypedIntl';
import { HomeAction } from './HomeAction';
import { themeV5 } from 'src/app/theme/ThemeV5';
import RefreshSvg from '../../../app/component/icons/RefreshSvg';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { Business } from 'src/business/domain/Business';

type HomeActionOrderQrCodeProps = {
  business: Business;
};

export const HomeActionOrderQrCode = ({ business }: HomeActionOrderQrCodeProps) => {
  const intl = useIntl();

  const reorderQRCodePath = (): string => {
    const configuration = ConfigurationLoader.load();
    const reorderQRCodesFRUrl = `${configuration.reorderQRCodesFRUrl}`;
    const reorderQRCodesUSUrl = `${configuration.reorderQRCodesUSUrl}`;
    const reorderQRCodesUKUrl = `${configuration.reorderQRCodesUKUrl}`;
    let reorderQRCodesPath = reorderQRCodesFRUrl;
    const countryCode = business.address.countryCode;
    if (countryCode === 'US') {
      reorderQRCodesPath = reorderQRCodesUSUrl;
    }
    if (countryCode === 'GB') {
      reorderQRCodesPath = reorderQRCodesUKUrl;
    }
    const businessId = business.id;

    if (countryCode === 'FR') {
      const accountName = business.name;
      return reorderQRCodesPath.replace('{businessId}', businessId).replace('{accountName}', accountName);
    }
    if (reorderQRCodesPath.endsWith('=')) {
      return `${reorderQRCodesPath}${businessId}`;
    } else {
      return reorderQRCodesPath.replace('{businessId}', businessId);
    }
  };

  return (
    <HomeAction
      icon={<RefreshSvg size={20} color={themeV5.palette.text.secondary} />}
      title={intl.formatMessage({ id: 'venue.home.actions.reorder_qr_code' })}
      onClick={() => {
        window.open(reorderQRCodePath(), '_blank');
      }}
    />
  );
};
