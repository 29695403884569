import React, { useState } from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { useCoverUseCase } from './useCoverUseCase';
import PictureContainer from './PictureContainer';
import { CropPictureData } from 'src/domain/venue/CropperService';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import {
  CoverColorCustomizerWrapper,
} from 'src/pages/Settings/Customization/components/ColorCustomizers/CoverColorCustomizer';
import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import { MAX_SIZE } from '../Cropping/CropTool';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

interface CoverHandlerProps {
  venueId: string;
}

export const CoverHandler: React.FC<CoverHandlerProps> = ({ venueId }: CoverHandlerProps) => {
  const {
    deleteCovers,
    uploadCover,
    tempCoverPicture,
    removeTempCoverPicture,
    saveCroppedPicture,
    editCoverPicture,
    coverUrl,
    loading,
  } = useCoverUseCase(venueId as EnrollmentId);

  const business = useCurrentBusinessOrThrow();
  const [showBillPageCoverGuide, setShowBillPageCoverGuide] = useState(false);

  const aspect = 16 / 9;

  const wholeCoverPreviewHeightPx = MAX_SIZE / aspect;
  const targetWidthPxInPAT = 500;
  const realToPreviewRatio = MAX_SIZE / targetWidthPxInPAT;


  const billSmallCoverRealHeightPx = targetWidthPxInPAT / aspect / 2;
  const billPageSmallCoverPreviewHeightPx = billSmallCoverRealHeightPx * realToPreviewRatio;

  const billPageCropGuideTop100px = {
    top: '50%',
    transform: `translateY(-${wholeCoverPreviewHeightPx / 2}px)`,
    height: `${billPageSmallCoverPreviewHeightPx}px`,
  };

  const billPageCropGuide50Percent = {
    top: '50%',
    transform: `translateY(-${billPageSmallCoverPreviewHeightPx / 2}px)`,
    height: `${billPageSmallCoverPreviewHeightPx}px`,
  };

  const billPageCropGuideCss = business.address.countryCode === 'US' ? billPageCropGuideTop100px : billPageCropGuide50Percent;

  return (
    <Stack spacing={2}>
      <PictureContainer
        inputId="cover"
        title={<FormattedMessage id="settings.app_settings.cover.cover" />}
        url={coverUrl}
        onCloseCropTool={removeTempCoverPicture}
        tempPicture={tempCoverPicture}
        uploadFile={(file: File) => uploadCover(file)}
        deletePicture={() => deleteCovers()}
        saveCroppedPicture={(crop: CropPictureData) => saveCroppedPicture(crop)}
        editPicture={editCoverPicture}
        aspect={aspect}
        colorSelector={false}
        cropShape="rect"
        loading={loading}
        downloadFileName={'cover.jpg'}
        additionalGuides={{
          guidesComponent: showBillPageCoverGuide ? (
            <div style={{
              position: 'absolute',
              width: '100%',
              boxShadow: '0 0 100px rgba(255, 255, 255, 1)',
              border: '1px solid white',
              ...billPageCropGuideCss,
            }} />
          ) : <></>,
          guideSettings: <FormControlLabel control={<Checkbox defaultChecked />}
                                           label={<FormattedMessage id={'settings.app_settings.cover.highlight_bill_page_region'} />}
                                           checked={showBillPageCoverGuide}
                                           onChange={(__, checked) => setShowBillPageCoverGuide(checked)} />,
        }}

      />
      <CoverColorCustomizerWrapper enrollmentId={venueId as EnrollmentId} />
    </Stack>
  );
};
