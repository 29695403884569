import { Button, InputText } from '@sundayapp/b2b-react-component-library';
import { useIntl } from 'src/app/i18n/TypedIntl';
import React, { useState } from 'react';
import { money, MoneyView, renderMoney, useRenderMoney } from '@sundayapp/web-money';
import {
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  Slider,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { Payment } from '../../domain/Payment';
import { RefundType, useRefund } from './useRefund';
import { ProviderIcon } from '../ProviderIcon';
import RefundsHistory from './RefundsHistory';
import { usePaymentAdapter } from '../../page/usePaymentAdapter';
import { RefundConfirmation } from './RefundConfirmation';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { useListPaymentTerminals } from 'src/payment-terminal/queries/listPaymentTerminalsQuery';
import { themeV5 } from 'src/app/theme/ThemeV5';

type RefundComponentProps = {
  onClose: () => void;
  payment: Payment;
  handleRefreshPayment: () => Promise<void>;
};

// Allows to round a number to two decimal after the comma
function roundToCents(amount: number) {
  return Math.round(amount * 100) / 100;
}

export const RefundComponent = ({ onClose, payment, handleRefreshPayment }: RefundComponentProps) => {
  const business = useCurrentBusinessOrThrow();
  const paymentAdapter = usePaymentAdapter();
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const urMoney = useRenderMoney();
  const terminals = useListPaymentTerminals(business.pdqEnrollment?.id).data;

  const {
    refundType,
    setRefundType,
    refundErrorMessage,
    refundAmount,
    setRefundAmount,
    refundComment,
    setRefundComment,
    refundTerminalId,
    setRefundTerminalId,
    isRefundInProgress,
    refundPayment,
    partiallyRefundable,
    isValid,
    // @ts-ignore
  } = useRefund(payment, onClose, paymentAdapter, business);

  const { formatMessage, locale } = useIntl();

  const computeRefundTypeLabel = (type: RefundType) => {
    let label: string;
    switch (type) {
      case RefundType.FULL: {
        label = formatMessage({ id: 'payments.refund_dialog.full', defaultMessage: 'full refund' });
        break;
      }
      case RefundType.PARTIAL: {
        label = formatMessage({ id: 'payments.refund_dialog.partial', defaultMessage: 'custom amount' });
        break;
      }
      default:
        label = '';
        break;
    }
    return label;
  };

  function onRefundClick() {
    setConfirmation(true);
  }

  async function onConfirm() {
    setConfirmation(false);
    await refundPayment();
    await handleRefreshPayment();
  }

  async function onCancel() {
    setConfirmation(false);
  }

  function getRefundAmount() {
    if (refundType === RefundType.FULL) {
      return payment.refundableAmount;
    }
    return { amount: refundAmount * 100000, currency: business.currency };
  }

  function displayRefundAmount() {
    return urMoney(getRefundAmount());
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRefundTerminalId((event.target as HTMLInputElement).value);
  };

  const marks = [
    {
      value: 0,
      label: urMoney({ amount: 0, currency: business.currency }),
    },
    {
      value: payment.refundableAmount.amount / 100000,
      label: urMoney(payment.refundableAmount),
    },
  ];

  return (
    <div>
      {partiallyRefundable && (
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <ToggleButtonGroup
            value={refundType}
            exclusive
            onChange={(_event: unknown, value: RefundType) => setRefundType(value)}
            fullWidth
          >
            <ToggleButton value={RefundType.FULL}>{computeRefundTypeLabel(RefundType.FULL)}</ToggleButton>
            <ToggleButton value={RefundType.PARTIAL}>{computeRefundTypeLabel(RefundType.PARTIAL)}</ToggleButton>
          </ToggleButtonGroup>
        </div>
      )}

      {payment.paymentProvider !== 'DNA' && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            marginTop: '24px',
            marginBottom: '24px',
          }}
        >
          {payment.paymentMethodRef && <div>•••• •••• •••• {payment.paymentMethodRef}</div>}
          <div style={{ marginLeft: 'auto' }} />
          {payment.paymentMethodType && (
            <div style={{ marginLeft: '8px' }}>
              <ProviderIcon methodType={payment.paymentMethodType} brand={payment.cardBrand} />
            </div>
          )}
        </div>
      )}

      {partiallyRefundable && refundType === RefundType.PARTIAL && (
        <>
          <InputText
            value={refundAmount}
            style={{ marginTop: '24px' }}
            type="number"
            error={!isValid}
            subCaption={
              !isValid
                ? formatMessage(
                  {
                    id: 'payments.refund_dialog.input_error',
                  },
                  {
                    lowAmount: renderMoney(money(0, business.currency), locale),
                    highAmount: renderMoney(payment.refundableAmount, locale),
                  },
                )
                : undefined
            }
            onChange={(event: { target: { value: string } }) => {
              const amount = event.target.value as unknown as number;
              setRefundAmount(roundToCents(amount));
            }}
            id="amount"
            label={formatMessage({ id: 'payments.refund_dialog.amount', defaultMessage: 'refund' })}
          />
          <div style={{ padding: '0 16px 0 16px' }}>
            <Slider
              value={refundAmount}
              aria-label="Default"
              valueLabelDisplay="auto"
              min={0}
              max={payment.refundableAmount.amount / 100000}
              step={0.01}
              style={{ marginBottom: '24px' }}
              onChange={(_, value) => {
                const amount = value as number;
                setRefundAmount(roundToCents(amount));
              }}
              marks={marks}
            />
          </div>
        </>
      )}
      {refundType === RefundType.FULL && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: '24px',
            marginBottom: '24px',
            fontSize: '24px',
          }}
        >
          <div>{formatMessage({ id: 'payments.refund_dialog.total', defaultMessage: 'total' })}</div>
          <MoneyView value={payment.refundableAmount} />
        </div>
      )}
      {payment.paymentProvider === 'DNA' && terminals !== undefined && terminals.length > 0 && (
        <div>
          <div
            style={{
              marginTop: '24px',
              fontSize: '24px',
            }}
          >
            {formatMessage({
              id: 'payments.refund_dialog.select_terminal',
              defaultMessage: 'Select a terminal',
            })}
          </div>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={refundTerminalId}
            value={refundTerminalId}
            onChange={handleChange}
            name="radio-buttons-group"
          >
            {terminals.map((terminal) => (
              <FormControlLabel
                key={terminal.id}
                value={terminal.id}
                control={<Radio />}
                label={terminal.name + ' ' + terminal.serialNumber}
              />
            ))}
          </RadioGroup>
        </div>
      )}

      <RefundsHistory payment={payment} />
      <InputText
        placeholder={formatMessage({ id: 'payments.refund_dialog.refund_notes', defaultMessage: 'add a note' })}
        value={refundComment}
        style={{ marginTop: '24px', marginBottom: '24px' }}
        onChange={(event: { target: { value: string } }) => setRefundComment(event.target.value)}
        id="notes"
        label={formatMessage({ id: 'payments.refund_dialog.notes', defaultMessage: 'notes' })}
        disabled={payment.refundableAmount.amount === 0}
      />
      {!isRefundInProgress && (
        <>
          <div style={{ paddingBottom: '16px' }}>
            <Button disabled={!isValid} variant="primary" size="medium" fullWidth onClick={onRefundClick}>
              {formatMessage({ id: 'payments.refund_dialog.refund', defaultMessage: 'refund' })}
              &nbsp;
              {displayRefundAmount()}
            </Button>
          </div>
          <div>
            <Button variant="secondary" size="medium" fullWidth onClick={onClose}>
              {formatMessage({ id: 'payments.refund_dialog.cancel', defaultMessage: 'cancel refund' })}
            </Button>
          </div>
        </>
      )}
      {isRefundInProgress && (
        <Button variant="primary" size="medium" fullWidth onClick={() => {}}>
          <CircularProgress />
        </Button>
      )}
      {refundErrorMessage && <div style={{ color: themeV5.palette.error.main }}>{refundErrorMessage}</div>}
      <RefundConfirmation
        show={confirmation}
        onConfirm={onConfirm}
        onCancel={onCancel}
        refundAmount={getRefundAmount()}
        refundType={refundType}
      />
    </div>
  );
};
