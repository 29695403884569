import axios from 'axios';
import { UserRole } from 'src/domain/user/UserRole';
import { ListBusinessUsersResponse } from 'src/infrastructure/user/ListBusinessUsersResponse';
import { BusinessUser } from 'src/domain/user/BusinessUser';
import { User } from 'src/pages/Settings/UserPermissions/mutation/useBusinessUsersQuery';
import { UserInvitation } from 'src/app-for-restaurants/domain/UserInvitation';

export const usersApi = {
  listUsers: async (baseUrl: string, businessId: string): Promise<BusinessUser[]> => {
    const response = await axios.get<ListBusinessUsersResponse>(`${baseUrl}/businesses/${businessId}/users`);

    return response.data.users
      .map((user) => ({
        userId: user.user_id,
        email: user.email,
        posUserName: user.pos_user_name,
        role: user.role as UserRole,
        firstName: user.first_name,
        lastName: user.last_name,
        phoneNumber: user.phone_number,
        authenticationProviderIds: user.authenticationProviderIds,
      }))
      .sort((a, b) => a.email.localeCompare(b.email));
  },
  showUser: async (businessId: string, userId: string, baseUrl: string) => {
    const response = await axios.get(`${baseUrl}/businesses/${businessId}/users/${userId}`);
    return response.data;
  },
  storeUsers: async (userData: User, baseUrl: string, businessId: string) => {
    const dataToStore = { ...userData, phone_number: userData.phoneNumber };
    const response = await axios.put(`${baseUrl}/businesses/${businessId}/users`, dataToStore);
    return response.data;
  },
  updateUsers: async (businessId: string, userId: string, userData: User, baseUrl: string) => {
    const response = await axios.put(`${baseUrl}/businesses/${businessId}/users/${userId}`, userData);
    return response.data;
  },
  deleteUsers: async (businessId: string, userId: string, baseUrl: string) => {
    const response = await axios.delete(`${baseUrl}/businesses/${businessId}/users/${userId}`);
    return response.data;
  },
  inviteUsers: async (businessId: string, email: string, baseUrl: string) => {
    const response = await axios.post(`${baseUrl}/businesses/${businessId}/users/reSendSignUpLink`, { email });
    return response.data;
  },
  inviteUserWithRole: async (invitations: UserInvitation[], businessId: string, baseUrl: string) => {
    return axios.put(`${baseUrl}/businesses-registration/${businessId}/invite`, { invitations });
  },
};
