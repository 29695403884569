import { CurrencyCode, Money } from '@sundayapp/web-money';
import React, { useEffect, useState } from 'react';
import { SundayText } from 'src/app/component/Text/Text';

export type MoneyDisplayProps = {
  money: Money;
};

export const MoneyDisplay = ({ money }: MoneyDisplayProps) => {
  const [value, setValue] = useState<string>('');

  const toValue = (moneyToMap: Money): string => {
    const amount = moneyToMap.amount / 100000;

    if (moneyToMap.currency === CurrencyCode.EUR) {
      return `${amount}€`;
    }

    if (moneyToMap.currency === CurrencyCode.GBP) {
      return `£${amount}`;
    }

    if (moneyToMap.currency === CurrencyCode.USD) {
      return `$${amount}`;
    }

    return amount.toString();
  };

  useEffect(() => {
    setValue(toValue(money));
  }, [money]);

  return <SundayText size="medium">{value}</SundayText>;
};
