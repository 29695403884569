import styled from 'styled-components';
// eslint-disable-next-line @typescript-eslint/no-redeclare
import { breakpoints, Stats } from '@sundayapp/b2b-react-component-library';
import { Money } from '@sundayapp/web-money';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import React from 'react';
import { CustomMoneyView } from './CustomMoneyView';
import { getSpacing } from 'src/ordering/stylesheet';
import { spaceUsages } from 'src/app/theme/SpaceUsagesTheme';
import { SundayText } from 'src/app/component/Text/Text';

const RevenueAndTipsWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: ${spaceUsages.mediumLarge};

  @media (max-width: ${breakpoints.small}) {
    flex-direction: column;
  }
`;

const AmountWidgetContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const DetailsRevenues = styled.div`
  margin: ${getSpacing(1)};
`;

export interface RevenueDetails {
  label: string;
  revenue: Money;
}

export interface RevenueAndTipsProps {
  name: string;
  revenue: Money;
  revenueDescription?: string;
  tips: Money;
  tipsDescription?: string;
  inconsistency?: Money;
  revenueDetailsBreakdown?: RevenueDetails[];
}

const toDataTestId = (unformattedId: string) => unformattedId.toLowerCase().replace(' ', '-');

const getAmountWidget = (revenue: Money, revenueDetailsBreakdown: RevenueDetails[]) => {
  const nonEmptyDetailsRevenues = revenueDetailsBreakdown.filter((r) => r.revenue.amount > 0);
  return (
    <AmountWidgetContainer>
      <CustomMoneyView value={revenue} />
      <SundayText size="medium">
        {nonEmptyDetailsRevenues && nonEmptyDetailsRevenues.length > 0 && (
          <DetailsRevenues>
            <FormattedMessage id="finance.detailsRevenues" defaultMessage="dont: " />
          </DetailsRevenues>
        )}
        {nonEmptyDetailsRevenues.map((r) => (
          <DetailsRevenues key={r.label}>
            {r.label}
            :
            <CustomMoneyView value={r.revenue} />
          </DetailsRevenues>
        ))}
      </SundayText>
    </AmountWidgetContainer>
  );
};

export const RevenueAndTips = ({
  name,
  revenue,
  tips,
  revenueDescription,
  tipsDescription,
  revenueDetailsBreakdown,
}: RevenueAndTipsProps) => {
  const { formatMessage } = useIntl();
  return (
    <RevenueAndTipsWrapper>
      <Stats
        dataLabel={name}
        dataProvider={revenueDescription}
        amount={
          revenueDetailsBreakdown ? (
            getAmountWidget(revenue, revenueDetailsBreakdown)
          ) : (
            <CustomMoneyView value={revenue} />
          )
        }
        padding={spaceUsages.mediumLarge}
        data-testid={`${toDataTestId(name)}-revenue`}
      ></Stats>
      <Stats
        dataLabel={`${name} ${formatMessage({ id: 'accounting.tips' })}`}
        dataProvider={tipsDescription}
        amount={<CustomMoneyView value={tips} />}
        padding={spaceUsages.mediumLarge}
        data-testid={`${toDataTestId(name)}-tips`}
      />
    </RevenueAndTipsWrapper>
  );
};
