import { useMutation } from '@tanstack/react-query';
import { useDependencies } from 'src/tips/dispatch/useDependencies';
import { BusinessId } from 'src/business/domain/Business';
import { TipsAllocations } from 'src/tips/dispatch/model/TipsAllocation';

export const useDispatchTips = () => {
  const dependencies = useDependencies();
  const mutation = useMutation({
    mutationFn: (params: { businessId: BusinessId; tipsDispatchId: string; allocations: TipsAllocations }) =>
      dependencies.sundayPoolingRepository.dispatchTips(params.businessId, params.tipsDispatchId, params.allocations),
  });
  return {
    mutation,
    dispatchTips: (businessId: BusinessId, tipsDispatchId: string, allocations: TipsAllocations) =>
      mutation.mutateAsync({ businessId, tipsDispatchId, allocations }),
  };
};
