import { IconButton, Menu } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import * as React from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { User } from 'src/payment-terminal/domain/PaymentTerminalConfiguration';
import { useState } from 'react';
import { PTUserPassCodeModal } from 'src/payment-terminal/components/settings/PTUserPassCodeModal';
import { PencilSvg } from '@sundayapp/b2b-react-component-library';
import { EnrollmentId } from 'src/business/domain/Enrollment';


interface Props {
  user: User;
  enrollmentId: EnrollmentId;
}

export function PTUserMenu({ user, enrollmentId }: Props) {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleCloseMenu = () => setAnchorEl(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCloseModal = () => {    setIsModalOpen(false);  };
  const handleUpdateUser = () => {
    setAnchorEl(null);
    setIsModalOpen(true);
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>

      <Menu id="payment-terminal-menu" anchorEl={anchorEl} open={open} onClose={handleCloseMenu}>
        <MenuItem onClick={handleUpdateUser}>
          <ListItemIcon>
            <PencilSvg fontSize="small" />
          </ListItemIcon>
          {intl.formatMessage({ id: 'payment.terminal.manage.menu.modify' })}
        </MenuItem>
      </Menu>

      <PTUserPassCodeModal
        user={user}
        enrollmentId={enrollmentId}
        isOpen={isModalOpen}
        onClose={() => handleCloseModal()}
      />
    </div>
  );
}
