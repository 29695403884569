import { z } from 'zod';
import { CurrencyCode } from '@sundayapp/web-money';
import { Enrollment, EnrollmentId } from './Enrollment';
import { CountryCode } from 'src/domain/CountryCode';
import { ServiceType } from 'src/domain/venue/ServiceType';
import { EnrollmentStatus } from 'src/business/domain/Enrollment';

/*eslint @typescript-eslint/no-redeclare: 'off' */

export const BusinessIdSchema = z.string().brand<'BusinessId'>();
export type BusinessId = z.infer<typeof BusinessIdSchema>;

export const Address = z.object({
  streetAddress: z.string().optional(),
  zipCode: z.string().optional(),
  city: z.string().optional(),
  state: z.string().optional(),
  countryCode: z.string().transform((name) => name as keyof typeof CountryCode),
});
export type Address = z.infer<typeof Address>;

export const TimezoneSchema = z.string().brand<'Timezone'>();
export type Timezone = z.infer<typeof TimezoneSchema>;

export type Business = {
  id: BusinessId;
  name: string;
  legalName: string;
  address: Address;
  timezone: Timezone;
  currency: CurrencyCode;
  posReference: string | undefined;
  tags: string[];
  metadata: Record<string, string>;
  status: string;
  isInitialSetupCompleted: boolean;
  enrollments: Enrollment[];
  patEnrollment: Enrollment | undefined;
  oapEnrollment: Enrollment | undefined;
  pdqEnrollment: Enrollment | undefined;
  smbEnrollment: Enrollment | undefined;
  createdAt: string;
};

export function isActiveServiceType(business: Business, service: ServiceType) {
  return business.enrollments
    .some((enrollment) => enrollment.product === service && enrollment.status === EnrollmentStatus.ACTIVE);
}


export function invalidBusinessIdToRefactor(id: string) {
  return id as BusinessId;
}

export const invalidConversionOfBusinessIdToEnrollmentId = (businessId: BusinessId | string): EnrollmentId => {
  return EnrollmentId.parse(businessId);
};

export const isPaymentTerminalAvailable = (business: Business) => {
  return !!business.pdqEnrollment;
};

export const isPDQOnly = (business: Business) => {
  return business && business.enrollments.length === 1 && business.enrollments[0].product === ServiceType.PAYMENT_TERMINAL;
};

export const getFirstEnrollmentNotPDQ = (business: Business | undefined) => {
  return (business && business.enrollments.length > 0 && !isPDQOnly(business)) ? business.enrollments[0] : undefined;
};

export const isSuspended = (business: Business) => {
  return business.enrollments.every((e) => e.status === 'SUSPENDED');
};
