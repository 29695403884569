import { AxiosInstance } from 'axios';
import { SourceFilterValue, TipsBreakdown } from '../domain/Tips';
import { StaffTippingSummaryResult, TipsRepository } from '../domain/TipsRepository';
import { emphasisSpaceInWaiterName, TipsSummaryByWaiter } from '../domain/TipsSummaryByWaiter';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { BusinessId } from 'src/business/domain/Business';
import { TippingConfiguration, TippingConfigurationSchema } from 'src/tips/domain/TippingConfiguration';
import { StaffTippingSummary, StaffTippingSummarySchema } from 'src/tips/domain/StaffTippingSummary';
import { PayoutMethodDetails, PayoutMethodDetailsSchema } from 'src/tips/domain/PayoutMethodDetails';
import { CurrencyCode, differenceMoneys, money, sumMoneys } from '@sundayapp/web-money';
import { TipPoolDistributionFlow } from 'src/tips/domain/TipPoolDistributionFlows';

export class HttpTipsRepository implements TipsRepository {
  constructor(
    private axios: AxiosInstance,
    private baseUrl: string,
  ) {}

  async updateTipPoolDistributionFlow(
    businessId: string,
    flow: TipPoolDistributionFlow,
  ): Promise<TippingConfiguration> {
    const url = new URL(`${this.baseUrl}/sunday-tipping/businesses/${businessId}`);
    return this.axios.put(`${url}/tip-pool-distribution-flow`, { flow });
  }

  async isSundayTippingEnabled(businessId: string): Promise<boolean> {
    const url = new URL(`${this.baseUrl}/sunday-tipping/${businessId}`);
    try {
      const response = await this.axios.get<{ hasSundayTippingEnabled: boolean }>(url.toString());
      return response.data.hasSundayTippingEnabled;
    } catch (error) {
      return false;
    }
  }

  async getStaffTippingSummary(userId: string, lastNth: number): Promise<StaffTippingSummaryResult> {
    const params = new URLSearchParams();
    params.set('lastNth', lastNth.toString());

    const url = new URL(`${this.baseUrl}/tips/${userId}/tipping-summary`);
    url.search = params.toString();

    try {
      const response = await this.axios.get<StaffTippingSummary>(url.toString());
      return { type: 'SUCCESS', data: StaffTippingSummarySchema.parse(response.data) };
    } catch (error) {
      return { type: 'FAILURE' };
    }
  }

  async getStaffPayoutMethodDetails(userId: string): Promise<PayoutMethodDetails | undefined> {
    const url = new URL(`${this.baseUrl}/tips/${userId}/payout-method-details`);
    try {
      const response = await this.axios.get<PayoutMethodDetails>(url.toString());
      return PayoutMethodDetailsSchema.parse(response.data);
    } catch (error) {
      return undefined;
    }
  }

  async getBusinessTipsBreakdown(
    businessId: string,
    currency: CurrencyCode,
    source: SourceFilterValue,
    startDate: Date,
    endDate: Date,
  ): Promise<TipsBreakdown> {
    const params = new URLSearchParams();
    params.set('from', startDate.toISOString());
    params.set('to', endDate.toISOString());
    if (source !== 'ALL') {
      params.set('source', source);
    }

    const url = new URL(`${this.baseUrl}/tips/${businessId}`);
    url.search = params.toString();
    const response = await this.axios.get<TipsSummaryByWaiter[]>(url.toString());

    return response.data.reduce(
      (acc, { directTipsAmount, posPooledAmount, sundayPooledTipsAmount, directTipsFee, sundayPooledTipsFee }) => {
        const pooledTipsAmount = sumMoneys(posPooledAmount, sundayPooledTipsAmount);
        const tipsAmount = sumMoneys(pooledTipsAmount, directTipsAmount);
        acc.totalGrossTips = sumMoneys(acc.totalGrossTips, tipsAmount);
        acc.totalGrossDirectTips = sumMoneys(acc.totalGrossDirectTips, directTipsAmount);
        acc.totalGrossPooledTips = sumMoneys(acc.totalGrossPooledTips, pooledTipsAmount);

        const netTipsAmount = differenceMoneys(tipsAmount, directTipsFee, sundayPooledTipsFee);
        const netDirectTipsAmount = differenceMoneys(directTipsAmount, directTipsFee);
        const netPooledTipsAmount = differenceMoneys(pooledTipsAmount, sundayPooledTipsFee);
        acc.totalNetTips = sumMoneys(acc.totalNetTips, netTipsAmount);
        acc.totalNetDirectTips = sumMoneys(acc.totalNetDirectTips, netDirectTipsAmount);
        acc.totalNetPooledTips = sumMoneys(acc.totalNetPooledTips, netPooledTipsAmount);

        return acc;
      },
      {
        totalGrossTips: money(0, currency),
        totalNetTips: money(0, currency),
        totalGrossDirectTips: money(0, currency),
        totalNetDirectTips: money(0, currency),
        totalGrossPooledTips: money(0, currency),
        totalNetPooledTips: money(0, currency),
        tips: response.data.map(
          (tip): TipsSummaryByWaiter => ({
            ...tip,
            waiterName: emphasisSpaceInWaiterName(tip),
          }),
        ),
      },
    );
  }

  async getDirectTippingConfiguration(businessId: string): Promise<Record<EnrollmentId, boolean>> {
    const response = await this.axios.get<DirectTippingConfigurationApiResponse>(
      `${this.baseUrl}/direct-tipping/${businessId}`,
    );

    return response.data.directTippingByEnrollment;
  }

  async getTippingConfiguration(businessId: BusinessId): Promise<TippingConfiguration> {
    const res = await this.axios.get(`${this.baseUrl}/tips/${businessId}/tipping-configuration`);
    return TippingConfigurationSchema.parse(res.data);
  }
}

type DirectTippingConfigurationApiResponse = {
  directTippingByEnrollment: Record<EnrollmentId, boolean>;
};
