export const SatisfiedFaceIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_5036_34889)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          /* eslint-disable-next-line max-len */
          d="M9.99992 2.49999C5.85778 2.49999 2.49992 5.85786 2.49992 9.99999C2.49992 14.1421 5.85778 17.5 9.99992 17.5C14.1421 17.5 17.4999 14.1421 17.4999 9.99999C17.4999 5.85786 14.1421 2.49999 9.99992 2.49999ZM0.833252 9.99999C0.833252 4.93738 4.93731 0.833328 9.99992 0.833328C15.0625 0.833328 19.1666 4.93738 19.1666 9.99999C19.1666 15.0626 15.0625 19.1667 9.99992 19.1667C4.93731 19.1667 0.833252 15.0626 0.833252 9.99999ZM6.24992 7.49999C6.24992 6.80964 6.80956 6.24999 7.49992 6.24999C8.19027 6.24999 8.74992 6.80964 8.74992 7.49999C8.74992 8.19035 8.19027 8.74999 7.49992 8.74999C6.80956 8.74999 6.24992 8.19035 6.24992 7.49999ZM11.2499 7.49999C11.2499 6.80964 11.8096 6.24999 12.4999 6.24999C13.1903 6.24999 13.7499 6.80964 13.7499 7.49999C13.7499 8.19035 13.1903 8.74999 12.4999 8.74999C11.8096 8.74999 11.2499 8.19035 11.2499 7.49999Z"
          fill="black"
        />
        <path
          /* eslint-disable-next-line max-len */
          d="M12.9379 13.174C12.273 13.6576 11.2711 14.1667 9.99987 14.1667C8.72859 14.1667 7.72676 13.6576 7.06181 13.174C6.72773 12.931 6.46947 12.6881 6.29274 12.5037C6.20404 12.4111 6.13487 12.3323 6.086 12.2741C6.06154 12.2449 6.04208 12.2208 6.02771 12.2027L6.00996 12.18L6.00396 12.1721L6.00167 12.1691L6.00071 12.1678C6.00071 12.1678 7.1128 11.332 5.99987 12.1667C5.72373 11.7985 5.79835 11.2762 6.16654 11C6.53357 10.7248 7.05377 10.7981 7.33058 11.1632L7.33445 11.1682C7.33941 11.1744 7.34884 11.1862 7.36263 11.2026C7.39025 11.2355 7.43503 11.2869 7.49606 11.3505C7.61881 11.4786 7.80326 11.6524 8.0421 11.8261C8.52298 12.1758 9.18781 12.5 9.99987 12.5C10.8119 12.5 11.4768 12.1758 11.9576 11.8261C12.1965 11.6524 12.3809 11.4786 12.5037 11.3505C12.5647 11.2869 12.6095 11.2355 12.6371 11.2026C12.6509 11.1862 12.6603 11.1744 12.6653 11.1682L12.6688 11.1637C12.9456 10.7985 13.4662 10.7248 13.8332 11C14.2014 11.2762 14.276 11.7985 13.9999 12.1667L13.9981 12.1691L13.9958 12.1721L13.9898 12.18L13.972 12.2027C13.9577 12.2208 13.9382 12.2449 13.9137 12.2741C13.8649 12.3323 13.7957 12.4111 13.707 12.5037C13.5303 12.6881 13.272 12.931 12.9379 13.174Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_5036_34889">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
