import { DateRange } from 'src/sundayplus/reviews/domain/DateRange';
import { DateTimePicker } from '@sundayapp/b2b-react-component-library';
import React from 'react';
import { Instant } from 'src/sundayplus/Instant';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { Business } from 'src/business/domain/Business';

function adjustInputDate(businessTimezone: string): (date: Instant) => Instant {
  const userTimezone = Intl.DateTimeFormat()
    .resolvedOptions().timeZone;
  return (date: Instant) => date.subtract(date.offset(businessTimezone), 'm')
    .add(date.offset(userTimezone), 'm');
}

function adjustOutputDate(businessTimezone: string): (date: Instant) => Instant {
  const userTimezone = Intl.DateTimeFormat()
    .resolvedOptions().timeZone;
  return (date: Instant) => date.add(date.offset(businessTimezone), 'm')
    .subtract(date.offset(userTimezone), 'm');
}

function toRangeForDatePicker(selectedDateRange: DateRange, businessTimezone: string) {

  const newSelectedDateRange = selectedDateRange
    .mapStart(adjustInputDate(businessTimezone))
    .mapEnd(adjustInputDate(businessTimezone));

  return [
    newSelectedDateRange.startDateI()
      .toDate(),
    newSelectedDateRange.endDateI()
      .toDate()];
}

export function DateTimePickerAdapter({
  business,
  onApply,
  onClose,
  selectedDateRange,
}: {
  onApply: (range: DateRange) => void,
  onClose: () => void,
  selectedDateRange: DateRange,
  business: Business,
}) {

  const intl = useIntl();
  return <DateTimePicker
    onApply={(range: Date[]) => {
      if (range.length === 2) {
        const startDate = Instant.fromJsDate(range[0]);
        const endDate = Instant.fromJsDate(range[1]);
        const dateRange = DateRange.fromInstants(startDate, endDate)
          .mapStart(adjustOutputDate(business.timezone))
          .mapEnd(adjustOutputDate(business.timezone));
        onApply(dateRange);
      }
    }}
    onClose={onClose}
    value={toRangeForDatePicker(selectedDateRange, business.timezone)}
    applyButtonLabel={intl.formatMessage({ id: 'datepicker.apply' })}
    cancelButtonLabel={intl.formatMessage({ id: 'datepicker.cancel' })}
    startTimeInputLabel={intl.formatMessage({ id: 'datepicker.start-time' })}
    endTimeInputLabel={intl.formatMessage({ id: 'datepicker.end-time' })}
  />;
}
