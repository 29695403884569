import { Box, LinearProgress, linearProgressClasses, LinearProgressProps, styled, Typography } from '@mui/material';
import React from 'react';
import { themeV5 } from 'src/app/theme/ThemeV5';

interface RateBarProps {
  percentage: number;
  label?: string;
  hasFixedWidth?: boolean;
}

interface BorderLinearProgressProps extends LinearProgressProps {
  $colorBar?: string;
}

const BorderLinearProgress = styled(LinearProgress)<BorderLinearProgressProps>(({ $colorBar }) => ({
  height: 6,
  borderRadius: 3,
  backgroundColor: themeV5.palette.grey[300],

  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: $colorBar,
  },
}));

export const RateProgressBar = ({ percentage, label, hasFixedWidth = true }: RateBarProps) => {
  const getColorBar = () => {
    if (percentage <= 20) return '#D11023';
    if (percentage < 60) return '#E0821E';
    return '#0BAE37';
  };

  if (percentage === 0 || isNaN(percentage)) {
    percentage = 0.1;
    label = '0%';
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', ...(hasFixedWidth && { width: '100%', minWidth: 80 }) }}>
      <Box sx={{ display: 'flex', minWidth: 50 }}>
        <BorderLinearProgress
          sx={{ marginRight: '8px', width: '80px' }}
          variant="determinate"
          value={percentage}
          $colorBar={getColorBar()}
        />
      </Box>

      {label && (
        <Box sx={{ display: 'flex', minWidth: 30 }}>
          <Typography align="center" variant="body2" sx={{ color: getColorBar(), fontWeight: 400 }}>
            {label}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
