import { Controller, useFormContext } from 'react-hook-form';
import { TipsSuggestion } from 'src/pages/Settings/AppSettings/tips/domain/TipsSuggestion';
import { Card, CardContent, Stack, Switch, TextField, Typography } from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import Divider from '@mui/material/Divider';
import { SettingLine } from 'src/pages/Settings/AppSettings/components/TipsGeneralConfiguration';
import React from 'react';

export const PatFastCheckoutConfiguration = () => {
  const {
    watch,
    control,
  } = useFormContext<TipsSuggestion>();

  return (<Card>
    <CardContent>
      <Stack spacing={3}>
        <Typography variant={'h6'}>
          <FormattedMessage id={'settings.app_settings.tips.fast_checkout.title'} />
        </Typography>
        <Divider />
        <SettingLine labelsKeys={{
          mainLabel: 'settings.app_settings.tips.fast_checkout.main_label',
        }}>
          <Controller
            render={({ field }) =>
              <Switch checked={field.value} {...field} />}
            name={'fastCheckoutEnabled'}
            control={control}
          />
        </SettingLine>

        {watch('fastCheckoutEnabled') && <SettingLine labelsKeys={{
          mainLabel: 'settings.app_settings.tips.fast_checkout_tipping_percentage.main_label',
        }}>
          <Controller
            name={'fastCheckoutTippingPercentage'}
            control={control}
            render={({ field }) => <TextField
              {...field}
              sx={{ width: 80 }}
              type={'number'}
              label={<Typography><FormattedMessage
                id={'settings.app_settings.tips.fast_checkout_tipping_percentage.form_label'} /> (%)</Typography>}
            />} />
        </SettingLine>}
      </Stack>
    </CardContent>
  </Card>);
};
