import {
  ArrowIcon,
  Button,
  CheckSvg,
  colorUsage,
  IconWrapper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Title,
} from '@sundayapp/b2b-react-component-library';
import React from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { useNavigate } from 'react-router';
import { Background, RoleDescriptionContainer, RolesContainer, TitleContainer } from './FAQ.styles';
import { Stack, Typography } from '@mui/material';
import { spaceUsages } from 'src/app/theme/SpaceUsagesTheme';
import { SundayText } from 'src/app/component/Text/Text';

function FAQ() {
  const navigate = useNavigate();

  return (
    <Background>
      <Button
        size="medium"
        variant="secondary"
        marginTop={spaceUsages.medium}
        onClick={() => navigate('/settings/users')}
      >
        <IconWrapper size="small" cursor="pointer" color={colorUsage.secondaryText}>
          <ArrowIcon direction="left" />
        </IconWrapper>
        <SundayText size="medium" sx={{ marginLeft: spaceUsages.medium }}>
          <FormattedMessage id="settings.user_permissions.back_to_dashboard" />
        </SundayText>
      </Button>
      <TitleContainer>
        <Title size="title2">
          <FormattedMessage id="settings.user_permissions" />
        </Title>
        <SundayText size="small" color={colorUsage.tertiaryText}>
          <FormattedMessage id="settings.user_permissions.roles_description" />
        </SundayText>
      </TitleContainer>
      <RolesContainer />

      <Table size="small" width="100%">
        <TableHead>
          <TableRow>
            <TableHeader>
              <SundayText size="small">
                <FormattedMessage id="settings.user_permissions.roles.access_settings" />
              </SundayText>
            </TableHeader>
            <TableHeader>
              <RoleDescriptionContainer>
                <SundayText size="medium" textAlign="center">
                  <FormattedMessage id="settings.user_permissions.roles.waiter" />
                </SundayText>
                <SundayText size="xs" textAlign="justify" sx={{ marginTop: '15px' }} color={colorUsage.tertiaryText}>
                  <FormattedMessage id="settings.user_permissions.roles.waiter.description" />
                </SundayText>
              </RoleDescriptionContainer>
            </TableHeader>
            <TableHeader>
              <RoleDescriptionContainer>
                <SundayText size="medium" textAlign="center">
                  <FormattedMessage id="settings.user_permissions.roles.manager" />
                </SundayText>
                <SundayText size="xs" textAlign="justify" sx={{ marginTop: '15px' }} color={colorUsage.tertiaryText}>
                  <FormattedMessage id="settings.user_permissions.roles.manager.description" />
                </SundayText>
              </RoleDescriptionContainer>
            </TableHeader>
            <TableHeader>
              <RoleDescriptionContainer>
                <SundayText size="medium" textAlign="center">
                  <FormattedMessage id="settings.user_permissions.roles.general_manager" />
                </SundayText>
                <SundayText size="xs" textAlign="justify" sx={{ marginTop: '15px' }} color={colorUsage.tertiaryText}>
                  <FormattedMessage id="settings.user_permissions.roles.general_manager.description" />
                </SundayText>
              </RoleDescriptionContainer>
            </TableHeader>
            <TableHeader>
              <RoleDescriptionContainer>
                <SundayText size="medium" textAlign="center">
                  <FormattedMessage id="settings.user_permissions.roles.admin" />
                </SundayText>
                <SundayText size="xs" textAlign="justify" sx={{ marginTop: '15px' }} color={colorUsage.tertiaryText}>
                  <FormattedMessage id="settings.user_permissions.roles.admin.description" />
                </SundayText>
              </RoleDescriptionContainer>
            </TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <FormattedMessage id="settings.user_permissions.roles.orders_page" />
            </TableCell>
            <TableCell>
              <Stack direction="row" spacing={1}>
                <IconWrapper color={colorUsage.sundayPrimary}>
                  <CheckSvg />
                </IconWrapper>
                <Typography variant="body2">
                  <FormattedMessage id="settings.user_permissions.via_sfs_app_mention" />
                </Typography>
              </Stack>
            </TableCell>
            <TableCell>
              <IconWrapper color={colorUsage.sundayPrimary}>
                <CheckSvg />
              </IconWrapper>
            </TableCell>
            <TableCell>
              <IconWrapper color={colorUsage.sundayPrimary}>
                <CheckSvg />
              </IconWrapper>
            </TableCell>
            <TableCell>
              <IconWrapper color={colorUsage.sundayPrimary}>
                <CheckSvg />
              </IconWrapper>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <FormattedMessage id="settings.user_permissions.roles.payments_page" />
            </TableCell>
            <TableCell>
              <Stack direction="row" spacing={1}>
                <IconWrapper color={colorUsage.sundayPrimary}>
                  <CheckSvg />
                </IconWrapper>
                <Typography variant="body2">
                  <FormattedMessage id="settings.user_permissions.via_sfs_app_mention" />
                </Typography>
              </Stack>
            </TableCell>
            <TableCell>
              <IconWrapper color={colorUsage.sundayPrimary}>
                <CheckSvg />
              </IconWrapper>
            </TableCell>
            <TableCell>
              <IconWrapper color={colorUsage.sundayPrimary}>
                <CheckSvg />
              </IconWrapper>
            </TableCell>
            <TableCell>
              <IconWrapper color={colorUsage.sundayPrimary}>
                <CheckSvg />
              </IconWrapper>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <FormattedMessage id="settings.user_permissions.roles.accounting_page" />
            </TableCell>
            <TableCell />
            <TableCell>
              <IconWrapper color={colorUsage.sundayPrimary}>
                <CheckSvg />
              </IconWrapper>
            </TableCell>
            <TableCell>
              <IconWrapper color={colorUsage.sundayPrimary}>
                <CheckSvg />
              </IconWrapper>
            </TableCell>
            <TableCell>
              <IconWrapper color={colorUsage.sundayPrimary}>
                <CheckSvg />
              </IconWrapper>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <FormattedMessage id="settings.user_permissions.roles.reviews_and_insights" />
            </TableCell>
            <TableCell />
            <TableCell>
              <IconWrapper color={colorUsage.sundayPrimary}>
                <CheckSvg />
              </IconWrapper>
            </TableCell>
            <TableCell>
              <IconWrapper color={colorUsage.sundayPrimary}>
                <CheckSvg />
              </IconWrapper>
            </TableCell>
            <TableCell>
              <IconWrapper color={colorUsage.sundayPrimary}>
                <CheckSvg />
              </IconWrapper>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <FormattedMessage id="settings.user_permissions.roles.reply_a_review" />
            </TableCell>
            <TableCell />
            <TableCell>
              <IconWrapper color={colorUsage.sundayPrimary}>
                <CheckSvg />
              </IconWrapper>
            </TableCell>
            <TableCell>
              <IconWrapper color={colorUsage.sundayPrimary}>
                <CheckSvg />
              </IconWrapper>
            </TableCell>
            <TableCell>
              <IconWrapper color={colorUsage.sundayPrimary}>
                <CheckSvg />
              </IconWrapper>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <FormattedMessage id="settings.user_permissions.roles.loyalty" />
            </TableCell>
            <TableCell />
            <TableCell>
              <IconWrapper color={colorUsage.sundayPrimary}>
                <CheckSvg />
              </IconWrapper>
            </TableCell>
            <TableCell>
              <IconWrapper color={colorUsage.sundayPrimary}>
                <CheckSvg />
              </IconWrapper>
            </TableCell>
            <TableCell>
              <IconWrapper color={colorUsage.sundayPrimary}>
                <CheckSvg />
              </IconWrapper>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <FormattedMessage id="settings.user_permissions.roles.customer_data" />
            </TableCell>
            <TableCell />
            <TableCell>
              <IconWrapper color={colorUsage.sundayPrimary}>
                <CheckSvg />
              </IconWrapper>
            </TableCell>
            <TableCell>
              <IconWrapper color={colorUsage.sundayPrimary}>
                <CheckSvg />
              </IconWrapper>
            </TableCell>
            <TableCell>
              <IconWrapper color={colorUsage.sundayPrimary}>
                <CheckSvg />
              </IconWrapper>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <FormattedMessage id="settings.user_permissions.roles.issue_refunds" />
            </TableCell>
            <TableCell />
            <TableCell />
            <TableCell>
              <IconWrapper color={colorUsage.sundayPrimary}>
                <CheckSvg />
              </IconWrapper>
            </TableCell>
            <TableCell>
              <IconWrapper color={colorUsage.sundayPrimary}>
                <CheckSvg />
              </IconWrapper>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <FormattedMessage id="settings.user_permissions.roles.restaurant_details" />
            </TableCell>
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell>
              <IconWrapper color={colorUsage.sundayPrimary}>
                <CheckSvg />
              </IconWrapper>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <FormattedMessage id="settings.user_permissions.roles.qr_code_tab" />
            </TableCell>
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell>
              <IconWrapper color={colorUsage.sundayPrimary}>
                <CheckSvg />
              </IconWrapper>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <FormattedMessage id="settings.user_permissions.roles.user_management" />
            </TableCell>
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell>
              <IconWrapper color={colorUsage.sundayPrimary}>
                <CheckSvg />
              </IconWrapper>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <FormattedMessage id="settings.user_permissions.roles.receive_daily_email" />
            </TableCell>
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell>
              <IconWrapper color={colorUsage.sundayPrimary}>
                <CheckSvg />
              </IconWrapper>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Background>
  );
}

export default FAQ;
