import styled from 'styled-components';
import { fontSize, getSpacing } from '@sundayapp/b2b-react-component-library';
import { themeV5 } from 'src/app/theme/ThemeV5';

export const MenuTitle = styled.div`
  display: flex;
  justify-content: start;
  width: 100%;
  max-width: 180px;
  font-size: ${fontSize.large};
  padding-top: ${getSpacing(1.5)};
  color: ${themeV5.palette.grey[500]};
`;

type MenuLineProps = {
  isMobile: boolean;
};

export const MenuLine = styled.div<MenuLineProps>`
  display: flex;
  width: 100%;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
`;
