import { useMutation } from '@tanstack/react-query';
import { HttpPaymentTerminalRepository } from '../infrastructure/HttpPaymentTerminalRepository';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { EnrollmentId } from 'src/business/domain/Enrollment';

export const useChangeUserPassCodeMutation = (enrollmentId: EnrollmentId) => {
  const configuration = ConfigurationLoader.load();
  const repository = new HttpPaymentTerminalRepository(configuration.paymentTerminalBaseUrl);
  return useMutation({
    mutationFn: (dto: ChangeUserPassCodeMutationDto) => repository.changeUserPassCode(enrollmentId, dto.userId, dto.code),
    onSuccess: () => {},
  });
};

export type ChangeUserPassCodeMutationDto = {
  userId: string;
  code: string;
};
