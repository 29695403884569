// eslint-disable-next-line @typescript-eslint/no-redeclare
import { colorUsage, IconWrapper, TrashSvg } from '@sundayapp/b2b-react-component-library';
import styled from 'styled-components';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import React, { useState } from 'react';
import TimeSelector from './TimeSelector';
import { TimeRange } from '../../domain/TimeRange';
import { spaceUsages } from 'src/app/theme/SpaceUsagesTheme';
import { SundayText } from 'src/app/component/Text/Text';

type MenuTimeRangeProps = {
  startTime: string;
  endTime: string;
  onDelete: () => void;
  onUpdate: (time: TimeRange) => void;
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${spaceUsages.mediumSmall};
  padding-left: ${spaceUsages.mediumSmall};
  width: 100%;
`;

const TimeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${spaceUsages.mediumSmall};
  width: 100%;
  gap: ${spaceUsages.small};
`;

const TimeRow = styled.div`
  display: flex;
  width: 100%;
`;

const TimeCell = styled.div`
  display: flex;
  width: 50%;
`;

const TrashContainer = styled.div`
  margin-top: 32px;
`;

const MenuTimeRange = ({ startTime, endTime, onUpdate, onDelete }: MenuTimeRangeProps) => {
  const [invalidDateRange, setInvalidDateRange] = useState<boolean>(false);

  const onTimeUpdated = (newStartTime: string, newEndTime: string) => {
    const time = {
      startTime: newStartTime,
      endTime: newEndTime,
    } as TimeRange;

    setInvalidDateRange(newStartTime >= newEndTime);

    onUpdate(time);
  };

  return (
    <Container>
      <TimeContainer>
        <TimeRow>
          <TimeCell>
            <SundayText size="small" color={colorUsage.tertiaryText}>
              <FormattedMessage id="menus.schedule_selector.modal.time_range.from" />
            </SundayText>
          </TimeCell>
          <TimeCell>
            <SundayText size="small" color={colorUsage.tertiaryText}>
              <FormattedMessage id="menus.schedule_selector.modal.time_range.to" />
            </SundayText>
          </TimeCell>
        </TimeRow>
        <TimeRow>
          <TimeCell>
            <TimeSelector
              time={startTime}
              invalidDate={invalidDateRange}
              onUpdate={(newStartTime) => onTimeUpdated(newStartTime, endTime)}
            />
          </TimeCell>
          <TimeCell>
            <TimeSelector
              time={endTime}
              invalidDate={invalidDateRange}
              onUpdate={(newEndTime) => onTimeUpdated(startTime, newEndTime)}
            />
          </TimeCell>
        </TimeRow>
      </TimeContainer>
      <TrashContainer>
        <IconWrapper size="small" color={colorUsage.error} cursor="pointer" onClick={onDelete}>
          <TrashSvg />
        </IconWrapper>
      </TrashContainer>
    </Container>
  );
};

export default MenuTimeRange;
