import styled from 'styled-components';
import { colorUsage } from '@sundayapp/b2b-react-component-library';
import { spaceUsages } from 'src/app/theme/SpaceUsagesTheme';

const MenuItemLineContainer = styled.div`
  display: flex;
  width: 100%;
  border: 1px solid ${colorUsage.quaternaryBorder};
  border-radius: ${spaceUsages.largeSmall};

  padding-top: 12px;
  padding-bottom: 12px;
`;

export default MenuItemLineContainer;
