import { Card, CardContent, CardHeader, Checkbox, Switch, Typography } from '@mui/material';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { User, UserConfiguration } from '../../domain/PaymentTerminalConfiguration';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import { PTUserMenu } from 'src/payment-terminal/components/settings/PTUserMenu';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import WarningAmberOutlined from 'src/app/component/icons/WarningAmberOutlined';
import { alpha, darken, styled } from '@mui/material/styles';

const Warning = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: left;
  padding: 1em 1em;
  align-items: center;
  border-radius: 0.25em;
  color: ${darken('#ED6C02', 0.6)};
  background-color: ${alpha('#ED6C02', 0.1)};
  gap: 0.75em;
`;

const buildColumns = (enrollmentId: EnrollmentId, onEnableChange: (user: User, enabled: boolean) => void): GridColDef[] => [
  {
    field: 'enable',
    disableColumnMenu: true,
    width: 60,
    renderHeader: () => <div></div>,
    renderCell: ({ row: user }: GridCellParams<User>) => {
      const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onEnableChange(user, event.target.checked);
      };

      return (
        <Checkbox
          checked={user.enabled}
          onChange={handleChange}
        />
      );
    },
  },
  {
    field: 'firstName',
    disableColumnMenu: true,
    flex: 1,
    renderHeader: () => <FormattedMessage id="customer_data.firstName" />,
    renderCell: ({ row: user }: GridCellParams<User>) => {
      return (<div> {user.firstName} </div>);
    },
  },
  {
    field: 'lastName',
    disableColumnMenu: true,
    flex: 1,
    renderHeader: () => <FormattedMessage id="customer_data.lastName" />,
    renderCell: ({ row: user }: GridCellParams<User>) => {
      return (<div> {user.lastName} </div>);
    },
  },
  {
    field: 'email',
    disableColumnMenu: true,
    flex: 3,
    renderHeader: () => <FormattedMessage id="customer_data.email" />,
    renderCell: ({ row: user }: GridCellParams<User>) => {
      return (<div> {user.email} </div>);
    },
  },
  {
    field: 'actions',
    disableColumnMenu: true,
    width: 70,
    sortable: false,
    renderHeader: () => <FormattedMessage id="payment.terminal.manage.list.header.actions" />,
    renderCell: ({ row: user }: GridCellParams<User>) => {
      return <PTUserMenu user={user} enrollmentId={enrollmentId} />;
    },
  },
];

export type UserSettingsProps = {
  enrollmentId: EnrollmentId;
  userConfig: UserConfiguration;
  onUserConfigUpdated: (configuration: UserConfiguration) => void;
};

function UsersForm({ enrollmentId, userConfig, onUserConfigUpdated }: UserSettingsProps) {
  const intl = useIntl();

  return (
    <Card sx={{ padding: '16px' }}>
      <CardHeader
        title={intl.formatMessage({ id: 'payment.terminal.settings.users.title' })}
        subheader={
          <Typography paddingTop="8px">
            {intl.formatMessage({ id: 'payment.terminal.settings.users.subtitle' })}
          </Typography>
        }
        action={
          <Switch
            checked={userConfig.enabled}
            onChange={(e) => onUserConfigUpdated({
              ...userConfig,
              enabled: e.target.checked,
            })}
          />
        }
      />

      {userConfig.enabled && (
        <CardContent>
          {/*<Stack direction="row" justifyContent="space-between" spacing="20px" alignItems="center">*/}
          {/*  <Typography>*/}
          {/*    <FormattedMessage id="payment.terminal.settings.users.guest_enabled" />*/}
          {/*  </Typography>*/}
          {/*  <Switch*/}
          {/*    checked={userConfig.userGuestEnabled}*/}
          {/*    onChange={(e) => onUserConfigUpdated({*/}
          {/*      ...userConfig,*/}
          {/*      userGuestEnabled: e.target.checked,*/}
          {/*    })}*/}
          {/*  />*/}
          {/*</Stack>*/}

          {/*<Divider />*/}
          <Typography>
            <FormattedMessage id="payment.terminal.settings.users.enable_users" />
          </Typography>
          <DataGrid
            autoHeight
            rows={userConfig.users}
            rowHeight={35}
            columns={buildColumns(enrollmentId, (user: User, enabled: boolean) => {
              onUserConfigUpdated({
                ...userConfig,
                users: userConfig.users.map(u => u.id === user.id ? { ...u, enabled: enabled } : u),
              });
            })}
            disableRowSelectionOnClick
            hideFooter
          />

          {userConfig.users.length > 0 && !userConfig.users.some(user => user.enabled) && (
            <Warning>
              <WarningAmberOutlined />
              <Typography variant="body2">
                <FormattedMessage id="payment.terminal.settings.users.no_users_enabled" />
              </Typography>
            </Warning>
          )}
        </CardContent>
      )}

    </Card>
  );
}

export default UsersForm;
