import styled from 'styled-components';
import { Button } from '@sundayapp/b2b-react-component-library';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import React from 'react';
import { useReorderStaticMenus } from '../../infrastructure/mutations/useReorderStaticMenus';
import { StaticMenu, StaticMenuIdSchema } from '../../domain/StaticMenus';
import { useBusinessIdOrThrow } from 'src/business/hooks/useBusinessId';
import { spaceUsages } from 'src/app/theme/SpaceUsagesTheme';

export type MenuPriorityPanelProps = {
  orderedMenu: StaticMenu[];
  closePriorityMode: () => void;
};

const MenuPriorityContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
`;

const MenuPriorityButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const MenuPriorityPanel = ({ orderedMenu, closePriorityMode }: MenuPriorityPanelProps) => {
  const businessId = useBusinessIdOrThrow();
  const { reorderStaticMenus } = useReorderStaticMenus();

  const onCancel = () => {
    closePriorityMode();
  };

  const onConfirm = async () => {
    reorderStaticMenus(
      businessId,
      orderedMenu.map((menu) => StaticMenuIdSchema.parse(menu.id)),
    );
    closePriorityMode();
  };

  return (
    <MenuPriorityContainer>
      <MenuPriorityButtonContainer>
        <Button type="submit" variant="primary" size="medium" marginTop={spaceUsages.large} onClick={onConfirm}>
          <FormattedMessage id="menus.priority.button.confirm" />
        </Button>
        <Button type="submit" variant="secondary" size="medium" marginTop={spaceUsages.mediumLarge} onClick={onCancel}>
          <FormattedMessage id="menus.priority.button.cancel" />
        </Button>
      </MenuPriorityButtonContainer>
    </MenuPriorityContainer>
  );
};

export default MenuPriorityPanel;
