import {
  TipPoolDistributionFlow,
  tipPoolDistributionFlows,
  tipPoolDistributionFlowTranslationKeys,
} from 'src/tips/domain/TipPoolDistributionFlows';
import React, { useState } from 'react';
import { Button, Modal, Radio, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { DistributionStrategyView } from 'src/tips/dispatch/components/dispatchSteps/SelectDispatchStrategy';

export const TipPoolDistributionConfigurationModal = ({
  onSubmit,
  onClose,
  value,
}: {
  onSubmit: (selected: TipPoolDistributionFlow) => void;
  onClose: () => void;
  value: TipPoolDistributionFlow;
}) => {
  const [form, setForm] = useState<TipPoolDistributionFlow>(value);

  return (
    <Modal open={true}>
      <Stack
        sx={{
          background: 'white',
          height: '100%',
          overflow: 'auto',
          paddingBottom: '1rem',
        }}
        flexDirection="column"
      >
        <Stack direction="row" justifyContent="space-between" alignItems={'center'} padding="1rem 1.5rem 1rem 1.5rem">
          <Stack sx={{ cursor: 'pointer' }} onClick={onClose}>
            <CloseIcon />
          </Stack>
          <Stack alignItems={'center'}>
            <Typography>
              <FormattedMessage id={'sunday_tipping.tip_policies.tip_pool_distribution_flow.label'} />
            </Typography>
          </Stack>
          <Stack alignItems={'flex-end'}>
            <Button size="small" variant="contained" onClick={() => onSubmit(form)}>
              <FormattedMessage id={'tips.sunday_pooling.dispatch.continue'} />
            </Button>
          </Stack>
        </Stack>

        <Stack
          sx={{
            width: '100%',
            height: '100%',
            overflow: 'auto',
          }}
          padding="3rem 0 3rem 0"
          direction="column"
          justifyContent={'flex-start'}
          alignItems={'center'}
        >
          <Stack width="auto" height="100%" justifyContent={'space-between'} spacing={3}>
            <Stack direction="column" spacing={3}>
              <Typography variant={'h4'}>
                <FormattedMessage id={'sunday_tipping.tip_policies.tip_pool_distribution_flow.tooltip'} />
              </Typography>
              <Stack direction="column" spacing={2}>
                {Object.values(tipPoolDistributionFlows).map((flow) => (
                  <DistributionStrategyView
                    key={flow}
                    icon={<Radio checked={form === flow} />}
                    title={<FormattedMessage id={tipPoolDistributionFlowTranslationKeys[flow]} />}
                    description={
                      <FormattedMessage
                        id={`sunday_tipping.tip_policies.tip_pool_distribution_flow.${flow}.sub_title`}
                      />
                    }
                    onClick={() => setForm(flow)}
                    selected={form === flow}
                  />
                ))}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Modal>
  );
};
