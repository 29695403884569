import { TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import React from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';

interface UserEmailFieldProps {
  row: {
    id: string;
    email: string;
  };
}

export const UserEmailField = ({ row }: UserEmailFieldProps) => {
  const { control, trigger } = useFormContext();
  const intl = useIntl();

  return (
    <Controller
      name={`users.${row.id}.email`}
      control={control}
      rules={{
        required: {
          value: true,
          message: `*${intl.formatMessage({ id: 'user_management.form.error.required' })}`,
        },
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          message: `*${intl.formatMessage({ id: 'user_management.form.error.invalid' })}`,
        },
      }}
      defaultValue={row.email || ''}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          value={field.value || ''}
          label={`${intl.formatMessage({ id: 'user_management.email' })}*`}
          sx={{ flex: 1 }}
          onBlur={() => {
            field.onBlur();
            trigger(`users.${row.id}.email`).then();
          }}
          helperText={error?.message}
          error={!!error}
        />
      )}
    />
  );
};
