import styled from 'styled-components';
import { spaceUsages } from 'src/app/theme/SpaceUsagesTheme';

interface LoaderProps {
  maxSize: number;
}

export const LoaderContainer = styled.div<LoaderProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ maxSize }) => maxSize}px;
  width: ${({ maxSize }) => maxSize}px;
  position: relative;
  background-color: #f0f0f0;
  border-radius: 8px;
  margin-bottom: ${spaceUsages.mediumLarge};
`;
