import React, { ReactElement } from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import {
  AuthenticationContent,
  AuthenticationPage,
  AuthenticationPart,
  IllustrationPart,
  SundayLogo,
} from './Authentication.styles';
import { LocalisationKey } from 'src/lang/en';
import { Stack, Typography } from '@mui/material';
import { SundayLogoSvg } from 'src/auth/assets/SundayLogoSvg';

type AuthenticationProps = {
  children: ReactElement;
  titleKey?: LocalisationKey;
};

const Authentication = ({ children, titleKey = 'welcome.title' }: AuthenticationProps) => (
  <AuthenticationPage>
    <IllustrationPart />
    <AuthenticationPart>
      <AuthenticationContent>
        <Stack direction={'row'} alignItems={'center'}>
          <Typography fontSize={'xxx-large'}>
            <FormattedMessage id={titleKey} />
          </Typography>
          <SundayLogo>
            <SundayLogoSvg />
          </SundayLogo>
        </Stack>
        {children}
      </AuthenticationContent>
    </AuthenticationPart>
  </AuthenticationPage>
);

export default Authentication;
