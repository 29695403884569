import { IntlShape } from 'src/app/i18n/TypedIntl';
import { FulfillmentType } from '../domain/FulfillmentType';

export const fulfillmentMethodMessages = (intl: IntlShape, serviceType: FulfillmentType) : string => {
  if (serviceType === FulfillmentType.EAT_IN_PICK_UP_AT_COUNTER) {
    return intl.formatMessage({ id: 'venue.customMessageForEatInPickUpAtCounter', defaultMessage: 'message for pick up at counter' });
  }
  if (serviceType === FulfillmentType.TAKE_AWAY) {
    return intl.formatMessage({ id: 'venue.customMessageForTakeAway', defaultMessage: 'message for take away' });
  }
  if (serviceType === FulfillmentType.EAT_IN_SERVICE_AT_TABLE) {
    return intl.formatMessage({ id: 'venue.customMessageForEatInServiceAtTable', defaultMessage: 'message for service at table' });
  }
  if (serviceType === FulfillmentType.HYBRID_ORDERING) {
    return intl.formatMessage({ id: 'venue.customMessageForHybridOrderingAtTable', defaultMessage: 'message for hybrid ordering' });
  }
  if (serviceType === FulfillmentType.CLICK_AND_COLLECT) {
    return intl.formatMessage({ id: 'venue.customMessageForClickAndCollect', defaultMessage: 'message for the click and collect' });
  }
  if (serviceType === FulfillmentType.PRE_ORDERING) {
    return intl.formatMessage({ id: 'venue.customMessageForPreOrdering', defaultMessage: 'message for pre ordering' });
  }
  return '';
};
