import React from 'react';
import { TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { useIntl } from 'src/app/i18n/TypedIntl';

export const LegalName = () => {
  const { formState: { errors }, register } = useFormContext<CheckoutOnboardingInformationDraft>();
  const legalNameError = errors.legalName;
  const intl = useIntl();

  return <TextField
    required
    InputLabelProps={{ shrink: true }}
    error={!!legalNameError}
    label={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.legalName' })}
    {...register('legalName', { required: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.legalName.error.required' }) })}
    helperText={!!legalNameError ? legalNameError.message : intl.formatMessage({ id: 'settings.payments.checkout.onboarding.legalName.help' })}
  />;
};
