import styled from 'styled-components';
import { spaceUsages } from 'src/app/theme/SpaceUsagesTheme';

export const InputColor = styled.input`
  display: flex;
  margin-left: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  padding: 1px;
  margin-right: ${spaceUsages.mediumSmall};

  -webkit-appearance: none;
  border: none;
  width: 32px;
  height: 32px;

  &::-webkit-color-swatch-wrapper {
    padding: 0;
  }

  &::-webkit-color-swatch {
    border: none;
    border-radius: 50%;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const CoverBackgroundColorHandlerWrapper = styled.div`
  width: 100%;
`;
