import { Controller, useFormContext } from 'react-hook-form';
import React from 'react';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { useIntl } from 'src/app/i18n/TypedIntl';
import dayjs from 'dayjs';

export const DateOfBirth = ({ legalRepresentativeIndex }: {
  legalRepresentativeIndex: number
}) => {
  const intl = useIntl();
  const dateEighteenYearsAgo = dayjs()
    .subtract(18, 'year');
  const { control } = useFormContext<CheckoutOnboardingInformationDraft>();
  return <Controller
    name={`representatives.${legalRepresentativeIndex}.dateOfBirth`}
    control={control}
    rules={{ required: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.representatives.dateOfBirth.error' }) }}
    render={({
      field: {
        onChange,
        value,
      },
      fieldState: { error },
    }) => (
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={intl.locale}>
        <DatePicker
          shouldDisableDate={date => date.isAfter(dateEighteenYearsAgo)}
          label={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.representatives.dateOfBirth' })}
          value={value}
          format={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.representatives.dateOfBirth.format' })}
          onChange={event => onChange(event)}
          slotProps={{
            textField: {
              error: !!error,
              helperText: error ? error.message : intl.formatMessage({ id: 'settings.payments.checkout.onboarding.representatives.dateOfBirth.helper_text' }),
              InputLabelProps: {
                shrink: true,
                required: true,
              },
            },
          }}
        />
      </LocalizationProvider>
    )}
  />;
};
