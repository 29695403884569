import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { colorUsage, GoToSvg, IconWrapper } from '@sundayapp/b2b-react-component-library';
import { GoToContainer } from './GoToFaqTooltip.styles';
import { Link } from '@mui/material';
import { spaceUsages } from 'src/app/theme/SpaceUsagesTheme';
import { SundayText } from 'src/app/component/Text/Text';

export const goToFaqTooltip = () => (
  <>
    <FormattedMessage id="settings.user_permissions.roles_description" />
    <Link href="/settings/users/faq" target="_blank">
      <GoToContainer>
        <SundayText size="small" color={colorUsage.clearText} sx={{ marginRight: spaceUsages.medium }}>
          <FormattedMessage id="settings.user_permissions.learn_more" />
        </SundayText>
        <IconWrapper color={colorUsage.clearText} size="small" cursor="pointer">
          <GoToSvg />
        </IconWrapper>
      </GoToContainer>
    </Link>
  </>
);
