import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { GridCellParams, GridColDef, GridSortCellParams } from '@mui/x-data-grid';
import React from 'react';
import styled from 'styled-components';
import { ReviewStar } from '../component/ReviewStar';
import { RatingSkeleton } from '../component/RatingSkeleton';
import { NumberOfReviewsSkeleton } from '../component/NumberOfReviewsSkeleton';
import { fromMoneyStringToFloatNumber } from './fromMoneyStringToFloatNumber';
import { SundayPaymentsRate } from './StaffPerformanceViewModel';
import { ReadReviews } from '../component/ReadReviews';
import { Business } from 'src/business/domain/Business';
import { Tooltip, Typography } from '@mui/material';
import { GoogleIcon } from 'src/staff-performance/assets/GoogleIcon';
import { themeV5 } from 'src/app/theme/ThemeV5';
import { RateProgressBar } from 'src/staff-performance/component/DataGrid/RateProgressBar';

const HeaderCell = styled.span`
  color: #b4b4bb;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

const NbReview = styled.span`
  width: 20px;
`;

const determineReviewStarColor = (rating: number) => {
  if (rating <= 2.5) return '#D11023';
  if (rating < 4) return '#E0821E';
  return '#336B42';
};

const sortComparatorForMoneyViewValues =
  (field: string) => (v1: any, v2: any, cellParams1: GridSortCellParams, cellParams2: GridSortCellParams) =>
    fromMoneyStringToFloatNumber(cellParams1.api.getCellValue(cellParams1.id, field)) -
    fromMoneyStringToFloatNumber(cellParams2.api.getCellValue(cellParams2.id, field));

const waiterNameColumn = (): GridColDef => ({
  field: 'waiterName',
  disableColumnMenu: true,
  width: 170,
  flex: 0.7,
  display: 'flex',
  renderHeader: () => <HeaderCell />,
  renderCell: ({ row }: GridCellParams) => (
    <Typography sx={{ textTransform: 'capitalize' }}>{row.waiterName}</Typography>
  ),
});

const ratingOf5Column = (): GridColDef => ({
  field: 'ratingOutOf5',
  disableColumnMenu: true,
  flex: 0.4,
  display: 'flex',
  width: 80,
  renderCell: ({ row }: GridCellParams) => {
    const color = determineReviewStarColor(row.ratingOutOf5);
    return (
      <div style={{ display: 'flex' }}>
        {row.ratingOutOf5 ? (
          <>
            <ReviewStar style={{ color }} />
            {row.ratingOutOf5}
          </>
        ) : (
          <RatingSkeleton />
        )}
      </div>
    );
  },
  renderHeader: () => (
    <HeaderCell>
      <FormattedMessage id="staff_performance.reviews.rating" />
    </HeaderCell>
  ),
});

const nbOfReviewsColumn = (business: Business): GridColDef => ({
  field: 'nbOfReviews',
  flex: 0.4,
  display: 'flex',
  width: 90,
  disableColumnMenu: true,
  renderHeader: () => (
    <HeaderCell>
      <FormattedMessage id="staff_performance.total_reviews" />
    </HeaderCell>
  ),
  renderCell: ({ row }: GridCellParams) => (
    <div style={{ display: 'flex', gap: '0.5rem' }}>
      {row.nbOfReviews ? (
        <>
          <NbReview>{row.nbOfReviews}</NbReview>
          <ReadReviews business={business} serverName={row.waiterName} />
        </>
      ) : (
        <NumberOfReviewsSkeleton />
      )}
    </div>
  ),
});

const n5StarsGoogleReviewColumn = (): GridColDef => ({
  field: 'n5StarsGoogleReview',
  disableColumnMenu: true,
  flex: 0.4,
  display: 'flex',
  width: 80,
  renderCell: ({ row }: GridCellParams) => {
    return (
      <div>
        {row.n5StarsGoogleReview ? <NbReview>{row.n5StarsGoogleReview}</NbReview> : <NumberOfReviewsSkeleton />}
      </div>
    );
  },
  renderHeader: () => (
    <HeaderCell>
      <GoogleIcon />
      <Typography sx={{ fontSize: '12px', color: themeV5.palette.text.primary }}>5★</Typography>
    </HeaderCell>
  ),
});

const totalRevenueColumn = (): GridColDef => ({
  field: 'totalRevenue',
  disableColumnMenu: true,
  display: 'flex',
  flex: 0.4,
  width: 100,
  renderHeader: () => (
    <HeaderCell>
      <FormattedMessage id="staff_performance.total_revenue" />
    </HeaderCell>
  ),
  sortComparator: sortComparatorForMoneyViewValues('totalRevenue'),
});

const basketSizeColumn = (): GridColDef => ({
  field: 'basketSize',
  disableColumnMenu: true,
  width: 100,
  flex: 0.4,
  display: 'flex',
  renderHeader: () => (
    <HeaderCell>
      <FormattedMessage id="staff_performance.basket_size" />
    </HeaderCell>
  ),
  sortComparator: sortComparatorForMoneyViewValues('basketSize'),
});

const sundayPaymentsRateColumn = (): GridColDef => ({
  field: 'sundayPaymentsRate',
  flex: 0.6,
  display: 'flex',
  disableColumnMenu: true,
  width: 170,

  renderHeader: () => (
    <HeaderCell>
      <FormattedMessage id="staff_performance.sunday_usage.adoption" />
    </HeaderCell>
  ),
  renderCell: ({ row }: GridCellParams) => (
    <RateProgressBar
      percentage={parseInt(row.sundayPaymentsRate.rate, 10)}
      label={`${parseInt(row.sundayPaymentsRate.label, 10)}%`}
    />
  ),
  sortComparator: (a: SundayPaymentsRate, b: SundayPaymentsRate) => (a.rate ?? 0) - (b.rate ?? 0),
});

const totalTipsColumn = (): GridColDef => ({
  field: 'totalTips',
  flex: 0.4,
  display: 'flex',
  width: 100,
  disableColumnMenu: true,
  renderHeader: () => (
    <HeaderCell>
      <FormattedMessage id="staff_performance.total_tips" />
    </HeaderCell>
  ),
  sortComparator: sortComparatorForMoneyViewValues('totalTips'),
});

const tipsRateColumn = (): GridColDef => ({
  field: 'tipsRate',
  flex: 0.4,
  display: 'flex',
  width: 100,
  disableColumnMenu: true,
  renderHeader: () => (
    <Tooltip title={<FormattedMessage id="staff_performance.sunday_tips_rate_description" />} placement="right">
      <HeaderCell>
        <FormattedMessage id="staff_performance.sunday_tips_rate" />
      </HeaderCell>
    </Tooltip>
  ),
});

const billViewRateColumn = (): GridColDef => ({
  field: 'billViewRate',
  flex: 0.6,
  display: 'flex',
  width: 150,
  disableColumnMenu: true,
  renderHeader: () => (
    <Tooltip title={<FormattedMessage id="staff_performance.bill_view_rate_description" />} placement="right">
      <HeaderCell>
        <FormattedMessage id="staff_performance.group.bill_view" />
      </HeaderCell>
    </Tooltip>
  ),
  renderCell: ({ row }: GridCellParams) => (
    <RateProgressBar percentage={parseInt(row.billViewRate, 10)} label={`${parseInt(row.billViewRate, 10)}%`} />
  ),
  sortComparator: (a: string, b: string) => (parseInt(a, 10) ?? 0) - (parseInt(b, 10) ?? 0),
});

export const buildColumns = (business: Business, canManageTips: boolean): GridColDef[] => {
  const definedColumns = [
    waiterNameColumn(),
    billViewRateColumn(),
    sundayPaymentsRateColumn(),
    ratingOf5Column(),
    nbOfReviewsColumn(business),
    n5StarsGoogleReviewColumn(),
    totalRevenueColumn(),
    basketSizeColumn(),
  ];

  const tipsColumns = tipsRateColumn();

  if (canManageTips) {
    definedColumns.splice(6, 0, totalTipsColumn());
    definedColumns.splice(6, 0, tipsColumns);
  }

  return definedColumns;
};
