import styled from 'styled-components';
import { Box, Button, CircularProgress, SwipeableDrawer } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { HttpPaymentTerminalRepository } from '../../infrastructure/HttpPaymentTerminalRepository';
import { spaceUsages } from 'src/app/theme/SpaceUsagesTheme';
import { useSortable } from '@dnd-kit/sortable';
// eslint-disable-next-line @typescript-eslint/no-redeclare
import { CSS } from '@dnd-kit/utilities';
import { ChevronRight, DragIndicator } from '@mui/icons-material';
import { ConfigurationItemName } from './ConfigurationItemName';
import ConfigurationItemLastUpdated from './ConfigurationItemLastUpdated';
import { ConfigurationItemTerminalsAttached } from './ConfigurationItemTerminalsAttached';
import MainForm from './MainForm';
import { CurrencyCode } from '@sundayapp/web-money';
import { PlusIcon } from 'src/ordering/common/components/Icons';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';

const ConfigurationsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${spaceUsages.mediumLarge};
`;

const ConfigurationItemLineContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
  border-radius: ${spaceUsages.largeSmall};
  padding: 8px 32px 8px 8px;
  height: 125px;
  background-color: white;
`;


type ConfigurationItemProps = {
  configuration: ConfigurationSummary;
  onClick: (id: string) => void;
};

const ConfigurationItem = ({ configuration, onClick }: ConfigurationItemProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: configuration.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: 'pointer',
  };

  const onConfigurationClick = () => {
    onClick(configuration.id);
  };

  return (
    <ConfigurationItemLineContainer
      ref={setNodeRef} {...attributes}
      style={style}
      onClick={onConfigurationClick}
    >
      <Box
        flexDirection="row"
        display="flex"
        alignItems="center"
        width={350}
      >
        <DragIndicator fontSize="large" {...listeners} style={{ cursor: 'grab', opacity: '0.5', marginRight: 16 }} />
        <ConfigurationItemName name={configuration.name} active={true} />
        <ChevronRight fontSize="large" style={{ marginLeft: 1, opacity: 1 }} />
      </Box>
      <ConfigurationItemTerminalsAttached numberOfTerminals={configuration.numberOfTerminals} active={true} />
      <ConfigurationItemLastUpdated lastUpdated={configuration.lastModification} active={true} />
    </ConfigurationItemLineContainer>
  );
};


interface Props {
  enrollmentId: EnrollmentId,
  currency: CurrencyCode
}

interface ConfigurationSummary {
  id: string;
  name: string;
  lastModification: Date;
  numberOfTerminals: number;
}

export function ListConfigurations({ enrollmentId, currency }: Props) {
  const repository = new HttpPaymentTerminalRepository(ConfigurationLoader.load().paymentTerminalBaseUrl);
  const [ configurations, setConfigurations ] = useState<ConfigurationSummary[]>();
  const [ loading, setLoading ] = useState<boolean>(true);

  const [ configurationDrawerOpen, setConfigurationDrawerOpen ] = React.useState(false);
  const [ configurationId, setConfigurationId ] = React.useState<string | undefined>(undefined);

  const toggleDrawer =
    (open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event &&
          event.type === 'keydown' &&
          ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
          return;
        }

        setConfigurationDrawerOpen(open);
      };

  async function refreshConfigurations() {
    const values = await repository.configurations(enrollmentId);
    const summaries: ConfigurationSummary[] = values.map((configuration) => {
      return {
        id: configuration.id ?? '',
        name: configuration.name,
        lastModification: new Date(configuration.lastModification),
        numberOfTerminals: configuration.numberOfTerminals,
      };
    });
    setConfigurations(summaries);
    setLoading(false);
  }

  useEffect(() => {
    setConfigurationId(undefined);
    refreshConfigurations().catch(console.error);
  }, [ enrollmentId ]);

  const onConfigurationClick = (id: string) => {
    setConfigurationId(id);
    setConfigurationDrawerOpen(true);
  };

  const onOpenNewConfigurationClick = async () => {
    setConfigurationId(undefined);
    setConfigurationDrawerOpen(true);
  };

  if (loading || !configurations) return <CircularProgress />;

  const onCompleted = async () => {
    setConfigurationDrawerOpen(false);
    await refreshConfigurations();
  };


  function closeDrawer() {
    return setConfigurationDrawerOpen(false);
  }

  return (
    <div>
      <Button
        sx={{ marginLeft: 'auto', marginBottom: '16px' }}
        startIcon={<PlusIcon color="white" />}
        variant="contained"
        onClick={onOpenNewConfigurationClick}
      >
        <FormattedMessage id="payment.terminal.settings.create" />
      </Button>

      <ConfigurationsContainer>
        {configurations.map((configuration) => {
          return (
            <ConfigurationItem key={configuration.id} configuration={configuration} onClick={onConfigurationClick} />
          );
        })}

      </ConfigurationsContainer>
      <SwipeableDrawer
        anchor={'right'}
        open={configurationDrawerOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <MainForm enrollmentId={enrollmentId}
                  configurationId={configurationId}
                  currency={currency}
                  numberOfTerminals={configurations.find((configuration) => configuration.id === configurationId)?.numberOfTerminals || 0}
                  isDeletable={configurations.length > 1}
                  onCompleted={onCompleted}
                  onClose={closeDrawer}
        />
      </SwipeableDrawer>

    </div>
  );
}
