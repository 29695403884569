import { Box, Paper, styled, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { GoogleIcon } from 'src/staff-performance/assets/GoogleIcon';
import { Staff, toMoneyView } from 'src/staff-performance/page/StaffPerformanceViewModel';
import { RateProgressBar } from 'src/staff-performance/component/DataGrid/RateProgressBar';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { CurrencyCode } from '@sundayapp/web-money';

const LeftTableHeadCell = styled(TableCell)(() => ({
  border: 'none!important',
  paddingTop: '0!important',
  textAlign: 'left',
  width: '250px',
  whiteSpace: 'nowrap',
}));

const RightTableHeadCell = styled(TableCell)(() => ({
  border: 'none!important',
  paddingTop: '0!important',
  textAlign: 'right',
  width: '100px',
  whiteSpace: 'nowrap',
}));

export const StaffPerformanceTable = ({
  data,
  businessCurrency,
  businessLocale,
  testId,
}: {
  data: Staff[];
  businessCurrency: CurrencyCode;
  businessLocale: string;
  testId: string;
}) => {
  const intl = useIntl();

  if (!data.length || data.length === 0) return;

  return (
    <Paper
      sx={{
        boxShadow: 'none',
        width: '100%',
        overflowX: 'auto',
      }}
    >
      <Table
        sx={{
          boxShadow: 'none',
          minWidth: '100%',
          maxWidth: '100%',
          tableLayout: 'fixed',
          '.MuiTableHead-root': { borderBottom: 'none!important' },
        }}
        data-testId={'staffPerformanceTable'}
      >
        <TableHead>
          <TableRow>
            <LeftTableHeadCell>
              {intl.formatMessage({ id: 'venue.home.section.staffPerformance.table.server' })}
            </LeftTableHeadCell>
            <RightTableHeadCell>
              {intl.formatMessage({ id: 'venue.home.section.staffPerformance.table.adoption' })}
            </RightTableHeadCell>
            <RightTableHeadCell>
              {intl.formatMessage({ id: 'venue.home.section.staffPerformance.table.tips' })}
            </RightTableHeadCell>
            <RightTableHeadCell>
              <Box sx={{ alignItems: 'center', display: 'flex', gap: '8px', justifyContent: 'end' }}>
                <GoogleIcon />
                <Typography sx={{ fontSize: '14px' }}>
                  {intl.formatMessage({ id: 'venue.home.section.staffPerformance.table.google5Stars' })}
                </Typography>
              </Box>
            </RightTableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.map((row: Staff) => (
              <TableRow key={row.waiterName} sx={{ border: 'none!important' }}>
                <TableCell
                  sx={{ textAlign: 'left', border: 'none!important', fontWeight: 500 }}
                  data-testId={`${testId}-waiterNameTableCell`}
                >
                  {row.waiterName}
                </TableCell>
                <TableCell
                  sx={{
                    border: 'none!important',
                    display: 'flex',
                    textAlign: 'right',
                    justifySelf: 'end',
                    fontWeight: 500,
                  }}
                  data-testId={`${testId}-rateProgressBarTableCell`}
                >
                  <RateProgressBar
                    percentage={(row.billViewRate ?? 0) * 100}
                    label={`${((row.billViewRate ?? 0) * 100).toFixed(0)}%`}
                  />
                </TableCell>
                <TableCell
                  sx={{ border: 'none!important', fontWeight: 500, textAlign: 'right' }}
                  data-testId={`${testId}-totalTipsTableCell`}
                >
                  {toMoneyView(row.totalTips ? row.totalTips : 0, businessCurrency, businessLocale, 0)}
                </TableCell>
                <TableCell
                  sx={{ border: 'none!important', fontWeight: 500, textAlign: 'right' }}
                  data-testId={`${testId}-n5StarsGoogleReviewTableCell`}
                >
                  {row.n5StarsGoogleReview}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Paper>
  );
};
