import { useMutation } from '@tanstack/react-query';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { MenuRepositoryHttp } from '../../repositories/MenuRepositoryHttp';
import { BusinessId } from 'src/business/domain/Business';

export const usePublishMenuMutation = (businessId: BusinessId) => {
  const configuration = ConfigurationLoader.load();
  const menuRepository = new MenuRepositoryHttp(configuration.menuApiBaseUrl);
  return useMutation({
    mutationFn: () => menuRepository.publishEnabledMenus(businessId),
  });
};
