import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, TextField } from '@mui/material';
import React from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';

interface UserNameFieldProps {
  row?: { id: string; name: string };
}

export const UserNameField = ({ row }: UserNameFieldProps) => {
  const { control } = useFormContext();
  const intl = useIntl();

  if (!row) return null;

  return (
    <Controller
      name={`users.${row.id}.name`}
      control={control}
      rules={{
        required: {
          value: true,
          message: `*${intl.formatMessage({ id: 'user_management.form.error.required' })}`,
        },
      }}
      defaultValue={row.name || ''}
      render={({ field, fieldState: { error } }) => (
        <FormControl sx={{ flex: 1 }}>
          <TextField
            {...field}
            value={field.value || ''}
            label={`${intl.formatMessage({ id: 'user_management.name' })}*`}
            helperText={error?.message}
            error={!!error}
          />
        </FormControl>
      )}
    />
  );
};
