import { Paper, Stack } from '@mui/material';
import React from 'react';
import { extractEnrollmentIdFromPath } from 'src/app/tracking/tracking';
import { GuestAppConfigurationForm } from 'src/guest-app/GuestAppConfigurationForm';
import { ConfigurationHeader } from 'src/guest-app/ConfigurationHeader';

export const GuestAppConfiguration = () => {
  const enrollmentId = extractEnrollmentIdFromPath(new URL(window.location.href));
  if (!enrollmentId) return;
  return (
    <Paper style={{ borderRadius: '1.5rem', maxWidth: '1000px' }} elevation={0}>
      <Stack margin="24px" gap={3} width="inherit">
        <ConfigurationHeader enrollmentId={enrollmentId} />
        <GuestAppConfigurationForm enrollmentId={enrollmentId} />
      </Stack>
    </Paper>
  );
};
