// eslint-disable-next-line @typescript-eslint/no-redeclare
import { IconWrapper, Panel, PlusSvg } from '@sundayapp/b2b-react-component-library';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import MenuScheduleModal from './MenuScheduleModal';
import { GroupedHourConstraints } from './GroupedHourConstraints';
import { MenuScheduleUtils } from './MenuScheduleUtils';
import GroupedConstraintLine from './GroupedConstraintLine';
import { useUpdateStaticMenu } from '../../infrastructure/mutations/useUpdateStaticMenu';
import { useBusinessIdOrThrow } from 'src/business/hooks/useBusinessId';
import { StaticMenu } from '../../domain/StaticMenus';
import { spaceUsages } from 'src/app/theme/SpaceUsagesTheme';
import { SundayText } from 'src/app/component/Text/Text';

type MenuSchedulePanelProps = {
  menu: StaticMenu;
};

const MenuDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${spaceUsages.mediumLarge};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

const GroupedConstraintsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaceUsages.mediumXLarge};
  margin-top: ${spaceUsages.largeSmall};
  width: 100%;
`;

const MenuSchedulePanel = ({ menu }: MenuSchedulePanelProps) => {
  const businessId = useBusinessIdOrThrow();
  const { updateStaticMenu } = useUpdateStaticMenu();

  const [scheduleSelectorDialogOpened, setScheduleSelectorDialogOpened] = useState<boolean>(false);
  const [groupedHourConstraints, setGroupedHourConstraints] = useState<GroupedHourConstraints[]>([]);

  const DEFAULT_GROUPED_HOUR_CONSTRAINT = MenuScheduleUtils.buildGroupConstraint(
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    {
      available: true,
      hours: [{ start: '00:00', end: '24:00' }],
    },
  );

  const updateMenu = async (constraints: GroupedHourConstraints[]) => {
    const newMenu = {
      ...menu,
      constraints: MenuScheduleUtils.groupedConstraintToMenuConstraint(constraints),
    };
    await updateStaticMenu(businessId, newMenu);
  };

  const refreshGroupedHourConstraintsStates = async (constraints: GroupedHourConstraints[]) => {
    const menuRowConstraints = MenuScheduleUtils.groupedConstraintToMenuConstraint(constraints);
    const freshGroupedConstraint = MenuScheduleUtils.menuToGroupedConstraints(menuRowConstraints);
    const optimizedConstraints = MenuScheduleUtils.optimizeGroupedConstraints(freshGroupedConstraint);
    setGroupedHourConstraints(optimizedConstraints);
    await updateMenu(optimizedConstraints);
  };

  const onNewGroupedHourConstraints = async (newGroupedHourConstraints: GroupedHourConstraints) => {
    const newConstraints = groupedHourConstraints.concat(newGroupedHourConstraints);
    await refreshGroupedHourConstraintsStates(newConstraints);
  };

  const onGroupedConstraintsUpdated = async (updatedConstraints: GroupedHourConstraints, index: number) => {
    const updatedGroupedConstraints = groupedHourConstraints.map((constraints, idx) => {
      if (index === idx) {
        return updatedConstraints;
      }
      return constraints;
    });
    await refreshGroupedHourConstraintsStates(updatedGroupedConstraints);
  };

  const onGroupedConstraintsDeleted = async (index: number) => {
    const updatedGroupedConstraints = groupedHourConstraints.filter((constraints, idx) => index !== idx);
    await refreshGroupedHourConstraintsStates(updatedGroupedConstraints);
  };

  useEffect(() => {
    const constraints = MenuScheduleUtils.menuToGroupedConstraints(menu.constraints!);
    setGroupedHourConstraints(constraints);
  }, []);

  return (
    <Panel paddingLeft={spaceUsages.mediumLarge} paddingRight={spaceUsages.mediumLarge}>
      <MenuDetailContainer>
        <Header>
          <SundayText size="xl">
            <FormattedMessage id="menus.schedule_selector.panel.title" />
          </SundayText>
          <IconWrapper size="small" cursor="pointer" onClick={() => setScheduleSelectorDialogOpened(true)}>
            <PlusSvg />
          </IconWrapper>
        </Header>
      </MenuDetailContainer>

      {groupedHourConstraints.length > 0 && (
        <GroupedConstraintsContainer>
          {groupedHourConstraints.map((constraints, index) => (
            <GroupedConstraintLine
              key={String(index)}
              constraints={constraints}
              onUpdated={(constraint) => onGroupedConstraintsUpdated(constraint, index)}
              onDelete={() => onGroupedConstraintsDeleted(index)}
              deleteAvailable={groupedHourConstraints.length > 1}
            />
          ))}
        </GroupedConstraintsContainer>
      )}

      <MenuScheduleModal
        groupedHourConstraints={DEFAULT_GROUPED_HOUR_CONSTRAINT}
        setGroupedHourConstraints={onNewGroupedHourConstraints}
        isOpened={scheduleSelectorDialogOpened}
        onClose={() => setScheduleSelectorDialogOpened(false)}
      />
    </Panel>
  );
};

export default MenuSchedulePanel;
