import { Checkbox, styled, TableCell as MUITableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { ArrowDropDownIcon } from 'src/enterprise/configuration-replication/components/icons/ArrowDropDownIcon';
import { ChangeEvent, useRef } from 'react';

const TableCell = styled(MUITableCell)`
  padding: 16px !important;
`;

export interface UserManagementData {
  lastName?: string;
  email: string;
  locations?: string[];
}

export type TableOrder = 'asc' | 'desc';

interface UserManagementTableHeadProps {
  order?: 'asc' | 'desc';
  orderBy?: 'lastName' | 'email' | 'locations';
  onRequestSort?: (property: keyof UserManagementData) => void;
  countSelectedRows: number;
  rowCount: number;
  onSelectAll: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

export const UserManagementTableHead = ({
  order,
  orderBy,
  onRequestSort,
  countSelectedRows,
  rowCount,
  onSelectAll,
}: UserManagementTableHeadProps) => {
  const intl = useIntl();
  const tableHeadRef = useRef<HTMLTableSectionElement>(null);

  const handleSortingTable = (property: keyof UserManagementData) => () => {
    if (onRequestSort) {
      onRequestSort(property);
    }
  };

  return (
    <TableHead ref={tableHeadRef}>
      <TableRow>
        <TableCell width={'2%'}>
          <Checkbox
            color="primary"
            indeterminate={countSelectedRows > 0 && countSelectedRows < rowCount}
            checked={rowCount > 0 && countSelectedRows === rowCount}
            onChange={onSelectAll}
            inputProps={{
              'aria-label': 'select all rows',
            }}
            sx={{
              '& .MuiSvgIcon-root': {
                borderRadius: '21px',
              },
            }}
          />
        </TableCell>
        <TableCell width={'20%'}>
          <TableSortLabel
            active={orderBy === 'lastName'}
            direction={orderBy === 'lastName' ? order : 'asc'}
            onClick={handleSortingTable('lastName')}
            IconComponent={() => <ArrowDropDownIcon />}
          >
            {intl.formatMessage({ id: 'user_management.name' })}
          </TableSortLabel>
        </TableCell>
        <TableCell width={'20%'}>
          <TableSortLabel
            active={orderBy === 'email'}
            direction={orderBy === 'email' ? order : 'asc'}
            onClick={handleSortingTable('email')}
            IconComponent={() => <ArrowDropDownIcon />}
          >
            {intl.formatMessage({
              id: 'user_management.email',
              defaultMessage: 'Email',
            })}
          </TableSortLabel>
        </TableCell>
        <TableCell>{intl.formatMessage({ id: 'user_management.phone_number' })}</TableCell>
        <TableCell>{intl.formatMessage({ id: 'user_management.locations' })}</TableCell>
        <TableCell width={'5%'}>
          {intl.formatMessage({
            id: 'common.actions',
            defaultMessage: 'Actions',
          })}
        </TableCell>
      </TableRow>
    </TableHead>
  );
};
