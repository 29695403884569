import { Alert, Button, CircularProgress, Link, Stack, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { useGetGuestAppConfiguration } from 'src/guest-app/queries/useGetGuestAppConfiguration';
import { useSaveGuestAppConfiguration } from 'src/guest-app/queries/useSaveGuestAppConfiguration';
import { SubmitHandler, useForm } from 'react-hook-form';
import { datadogLogs } from '@datadog/browser-logs';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';

type ConfigurationInputs = {
  notificationDelay: number;
};
export const GuestAppConfigurationForm = ({ enrollmentId }: { enrollmentId: string }) => {
  const { formatMessage } = useIntl();
  const { data, isLoading } = useGetGuestAppConfiguration(enrollmentId);

  const saveConfiguration = useSaveGuestAppConfiguration(enrollmentId);

  const [configurationError, setConfigurationError] = useState<string>('');

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm<ConfigurationInputs>({
    mode: 'all',
    criteriaMode: 'all',
  });

  const snackBar = useSnackbar();
  const onSubmit: SubmitHandler<ConfigurationInputs> = async ({ notificationDelay }) => {
    setConfigurationError('');
    try {
      const result = await saveConfiguration.mutateAsync({
        notificationDelay,
      });
      if (result) {
        snackBar.addNotification({
          variant: 'success',
          text: formatMessage({ id: 'guest-app.configuration.success', defaultMessage: 'Configuration saved' }),
        });
      } else {
        snackBar.addNotification({
          variant: 'error',
          text: formatMessage({ id: 'guest-app.configuration.errors.other' }),
        });
        datadogLogs.logger.error(
          `An error occurred while trying to configure guest app for enrollmentId: ${enrollmentId}`,
        );
        setConfigurationError(formatMessage({ id: 'guest-app.configuration.errors.other' }));
      }
    } catch (error: any) {
      snackBar.addNotification({
        variant: 'error',
        text: formatMessage({ id: 'guest-app.configuration.errors.other' }),
      });
      datadogLogs.logger.error(
        `An error occurred while trying to configure guest app for enrollmentId: ${enrollmentId}`,
        { error },
      );
      setConfigurationError(formatMessage({ id: 'guest-app.configuration.errors.other' }));
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap="16px" maxWidth="576px">
        <TextField
          label="Notification delay (in minutes)"
          required
          error={!!errors.notificationDelay}
          id="notificationDelay"
          defaultValue={data?.notificationDelay}
          helperText={errors.notificationDelay?.message}
          type="number"
          {...register('notificationDelay', {
            required: formatMessage({ id: 'guest-app.configuration.errors.field' }, { field: 'Store code' }),
          })}
        />

        {configurationError && <Alert severity="error">{configurationError}</Alert>}

        <Button
          variant="contained"
          disabled={!isValid || isSubmitting}
          onClick={() => {
            handleSubmit(onSubmit)();
          }}
        >
          {formatMessage({ id: 'guest-app.configuration.save', defaultMessage: 'Save' })}
        </Button>

        {data?.notificationDelay && (
          <Typography>
            {formatMessage(
              {
                id: 'guest-app.configuration.connected',
                defaultMessage: 'Guest app configured with {notificationDelay} minutes delay 🎊',
              },
              { notificationDelay: data?.notificationDelay },
            )}
          </Typography>
        )}

        <Typography>
          <FormattedMessage
            id="guest-app.configuration.ops_documentation"
            values={{
              link: (
                <Link
                  href="https://www.notion.so/sundayapp/Set-up-Notifications-for-the-APP-Clip-1ae24569fffe80c88660ddbe33ace6bd"
                  target="_blank"
                >
                  <FormattedMessage id="guest-app.configuration.ops_documentation.link" defaultMessage="link" />
                </Link>
              ),
            }}
          />
        </Typography>
      </Stack>
    </form>
  );
};
