import { Box, styled, Typography } from '@mui/material';
import { breakpoints, themeV5 } from 'src/app/theme/ThemeV5';
import { RateProgressBar } from 'src/staff-performance/component/DataGrid/RateProgressBar';
import { ReactNode } from 'react';
import { DissatisfiedFaceIcon } from 'src/staff-performance/assets/DissatisfiedFaceIcon';
import { SatisfiedFaceIcon } from 'src/staff-performance/assets/SatisfiedFaceIcon';

interface MetricCardProps {
  headerTitle: string;
  headerSubtitle: string | ReactNode;
  upTitle: string;
  upMetric: number | string;
  downTitle: string;
  downMetric: number | string;
  isProgressBar?: boolean;
}

const CardWrapper = styled(Box)`
  background-color: ${themeV5.palette.background.paper};
  border-radius: 16px;
  padding: 16px;
  width: 363px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;

  @media (max-width: ${breakpoints.homeTablet}) {
    width: -webkit-fill-available;
  }
` as typeof Box;

const CardElementWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
}) as typeof Box;

const CardElementName = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
}) as typeof Box;

const CardElementTypo = styled(Typography)({
  fontSize: '16px',
  fontWeight: 500,
  textTransform: 'capitalize',
}) as typeof Typography;

const CardElementHeaderTitle = styled(Typography)({
  fontSize: '21px',
  fontWeight: 500,
}) as typeof Typography;

const CardElementHeaderSubtitle = styled(Typography)({
  fontSize: '14px',
  color: 'rgba(0, 0, 0, 0.50)',
}) as typeof Typography;

export const MetricCard = ({
  headerTitle,
  headerSubtitle,
  upTitle,
  upMetric,
  downTitle,
  downMetric,
  isProgressBar,
}: MetricCardProps) => {
  if (upMetric === undefined || downMetric === undefined || upTitle === undefined || downTitle === undefined) return;

  return (
    <CardWrapper>
      <CardElementWrapper component="span">
        <Box component="span">
          <CardElementHeaderTitle component="span">{headerTitle}</CardElementHeaderTitle>
        </Box>
        <Box>
          <CardElementHeaderSubtitle component="span">{headerSubtitle}</CardElementHeaderSubtitle>
        </Box>
      </CardElementWrapper>
      <CardElementWrapper component="span">
        <CardElementName component="span">
          <SatisfiedFaceIcon />
          <CardElementTypo>{upTitle}</CardElementTypo>
        </CardElementName>
        {isProgressBar ? (
          <RateProgressBar
            percentage={parseInt(upMetric.toString(), 10) === 0 ? 0.5 : parseInt(upMetric.toString(), 10)}
            label={`${parseInt(upMetric.toString(), 10)}%`}
            hasFixedWidth={false}
          />
        ) : (
          <Box component="span">
            <CardElementTypo>{upMetric}</CardElementTypo>
          </Box>
        )}
      </CardElementWrapper>
      <CardElementWrapper component="span">
        <CardElementName component="span">
          <DissatisfiedFaceIcon />
          <CardElementTypo component="span">{downTitle}</CardElementTypo>
        </CardElementName>
        {isProgressBar ? (
          <RateProgressBar
            percentage={parseInt(downMetric.toString(), 10) === 0 ? 0.1 : parseInt(downMetric.toString(), 10)}
            label={`${parseInt(downMetric.toString(), 10)}%`}
            hasFixedWidth={false}
          />
        ) : (
          <Box component="span">
            <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>{downMetric}</Typography>
          </Box>
        )}
      </CardElementWrapper>
    </CardWrapper>
  );
};
