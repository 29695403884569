import { Modal } from '@sundayapp/b2b-react-component-library';
import { Button } from '@mui/material';
import React from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { useDeleteCategoryMutation } from '../../../mutations/menu/useCategoryMutation';
import { useBusinessIdOrThrow } from 'src/business/hooks/useBusinessId';

type CategoryDeletionModalProps = {
  isOpen: boolean;
  onClose: () => void;
  categoryId: string;
  categoryName: string;
  menuId: string;
};

const CategoryDeletionModal = ({ isOpen, onClose, categoryId, categoryName, menuId }: CategoryDeletionModalProps) => {
  const { formatMessage } = useIntl();
  const businessId = useBusinessIdOrThrow();

  const deleteCategory = useDeleteCategoryMutation(businessId, menuId, categoryId);

  const deleteMenu = async () => {
    try {
      await deleteCategory.mutateAsync();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Modal
      title={formatMessage({ id: 'menus.edition.category.delete_title' })}
      cancelButtonMessage={formatMessage({ id: 'menus.modal.edition.cancel' })}
      isOpened={isOpen}
      onClose={onClose}
    >
      <p>{categoryName}</p>
      <Button
        variant="contained"
        color="primary"
        size="large"
        fullWidth
        onClick={deleteMenu}
        sx={{ justifyContent: 'center', textTransform: 'lowercase', width: '100%' }}
        data-tesid="confirm-delete-category"
      >
        <FormattedMessage id="menus.edition.category.delete_button" />
      </Button>
    </Modal>
  );
};

export default CategoryDeletionModal;
