// eslint-disable-next-line @typescript-eslint/no-redeclare
import { Button, InputText, Modal, Panel } from '@sundayapp/b2b-react-component-library';
import React, { useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Box } from '@mui/material';
import { CurrencyCode, MoneyView } from '@sundayapp/web-money';
import { v4 as uuidv4 } from 'uuid';
import { produce } from 'immer';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { CashPayment, totalCashPayment } from '../../domain/CashPayment';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { spaceUsages } from 'src/app/theme/SpaceUsagesTheme';
import { SundayText } from 'src/app/component/Text/Text';

interface CashModalProps {
  isOpened: boolean;
  onClose: () => void;
  save: (model: CashPayment) => void;
  currencyCode: CurrencyCode;
  // eslint-disable-next-line react/require-default-props
  payment?: CashPayment;
}

export const CashModalForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 500px;

  input {
    border: 1px solid black;
    border-radius: 5px;
    height: 50px;
    margin-bottom: 10px;
    margin-top: 5px;
  }
`;

export const emptyCashPayment = () => ({
  reporter: {
    id: uuidv4(),
    name: '',
  },
  cashDenominations: {},
});

function cleanupNanDenomination(payment: CashPayment): CashPayment {
  return produce(payment, (draft) => {
    const denominations = draft.cashDenominations;
    Object.keys(denominations).forEach((key) => {
      if (Number.isNaN(denominations[+key])) {
        denominations[+key] = undefined;
      }
    });
  });
}

export const CashModal = ({ isOpened, onClose, save, currencyCode, payment = emptyCashPayment() }: CashModalProps) => {
  const intl = useIntl();

  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitting },
    watch,
    reset,
  } = useForm<CashPayment>({
    mode: 'all',
    criteriaMode: 'all',
    defaultValues: payment,
  });
  const { currency } = useCurrentBusinessOrThrow();
  const inputValues = watch();

  const totalAmount = useMemo(() => totalCashPayment(inputValues, currencyCode), [inputValues]);

  const resetAndClose = () => {
    reset(emptyCashPayment);
    onClose();
  };

  const onSubmit: SubmitHandler<CashPayment> = async (inputs: CashPayment) => {
    save(cleanupNanDenomination(inputs));
    resetAndClose();
  };

  return (
    <Modal
      title={intl.formatMessage({ id: 'reconciliation.modal.cash.title' })}
      cancelButtonMessage={intl.formatMessage({ id: 'modal.cancel' })}
      isOpened={isOpened}
      onClose={resetAndClose}
    >
      <Panel
        flexDirection="column"
        padding="0px"
        marginBottom={spaceUsages.largeSmall}
        marginTop={spaceUsages.mediumSmall}
      >
        <SundayText size="xxl">
          <FormattedMessage id="reconciliation.modal.cash.total_collected" />
        </SundayText>
        <MoneyView value={totalAmount} />
      </Panel>
      <CashModalForm onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ overflowY: 'scroll' }}>
          <InputText
            label={intl.formatMessage({ id: 'reconciliation.modal.waiter_name' })}
            {...register('reporter.name', {
              required: true,
            })}
          />
          {/* As the denominations are multiplied by their quantity to obtain the total amount, */}
          {/*  here we use a denomination of 1 to hold the coin amount */}
          <InputText
            type="number"
            label={intl.formatMessage({ id: 'reconciliation.modal.coin_total' })}
            {...register('cashDenominations.1' as keyof CashPayment, {
              required: false,
              valueAsNumber: true,
            })}
          />
          {[5, 10, 20, 50, 100, 200, 500].map((denomination) => (
            <InputText
              type="number"
              key={denomination}
              label={`${denomination}${currency}`}
              {...register(`cashDenominations[${denomination}]` as keyof CashPayment, {
                required: false,
                valueAsNumber: true,
              })}
            />
          ))}
        </Box>
        <Button
          type="submit"
          variant="primary"
          size="large"
          fullWidth
          disabled={!isValid || isSubmitting}
          marginTop={spaceUsages.large}
        >
          <FormattedMessage id="modal.confirm" />
        </Button>
      </CashModalForm>
    </Modal>
  );
};
