import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { QUERYKEY_PAYMENT_METHOD_MAPPING } from 'src/pages/Settings/PaymentMethodMapping/usePaymentMethodMapping';
import { PosConnectionId } from 'src/pos/domain/PosConnection';
import { VposVenueRepositoryHttp } from 'src/infrastructure/pos/VposVenueRepositoryHttp';
import { Mapping } from 'src/domain/pos-connection/PaymentMethodMapping';

export const useUpdatePaymentMethodMappingMutation = (posConnectionId: PosConnectionId) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const vposVenueRepository = new VposVenueRepositoryHttp(configuration.vposVenueUrl);
  return useMutation({
    mutationFn: (mappings: Mapping[]) => vposVenueRepository.updatePaymentMethodsMapping(posConnectionId, mappings),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_PAYMENT_METHOD_MAPPING] });
    },
  });
};
