import { Card, CardContent, CardHeader, CircularProgress } from '@mui/material';
import React from 'react';
import { useTipsSuggestion } from 'src/pages/Settings/AppSettings/tips/query/useListBusinessUsersQuery';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { ServiceType } from 'src/domain/venue/ServiceType';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { TipsConfigurationLoaded } from 'src/pages/Settings/AppSettings/components/TipsConfigurationLoaded';
import { Address } from 'src/business/domain/Business';

const TipsConfiguration = ({ enrollmentId, product, countryCode }: {
  enrollmentId: EnrollmentId;
  product: ServiceType,
  countryCode: Address['countryCode'];
}) => {
  const intl = useIntl();
  const { data: tipsConfig } = useTipsSuggestion(enrollmentId);

  if (!tipsConfig) {
    return (
      <Card>
        <CardHeader title={intl.formatMessage({ id: 'settings.app_settings.tips.configuration_title' })} />
        <CardContent>
          <CircularProgress />
        </CardContent>
      </Card>
    );
  }

  return <TipsConfigurationLoaded enrollmentId={enrollmentId}
                                  product={product}
                                  tipsConfig={tipsConfig!}
                                  countryCode={countryCode} />;
};

export const TipsConfigurationForProduct = () => {
  const business = useCurrentBusinessOrThrow();
  if (business.patEnrollment) {
    return <TipsConfiguration enrollmentId={business.patEnrollment.id} product={ServiceType.PAY_AT_TABLE}
                              countryCode={business.address.countryCode} />;
  }
  if (business.oapEnrollment) {
    return <TipsConfiguration enrollmentId={business.oapEnrollment.id} product={ServiceType.ORDER_AND_PAY}
                              countryCode={business.address.countryCode} />;
  }
  return null;
};
