import { colorUsage } from '@sundayapp/b2b-react-component-library';
import React from 'react';
import { ConfigurationItemColumn } from './ConfigurationItemColumn';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { SundayText } from 'src/app/component/Text/Text';

export type ConfigurationItemTerminalsAttachedProps = {
  active: boolean;
  numberOfTerminals: number;
};

export const ConfigurationItemTerminalsAttached = ({
  numberOfTerminals,
  active,
}: ConfigurationItemTerminalsAttachedProps) => {
  const intl = useIntl();

  return (
    <ConfigurationItemColumn>
      <SundayText size="medium" color={active ? colorUsage.secondaryText : colorUsage.disabledText}>
        {numberOfTerminals + ' ' + intl.formatMessage({ id: 'payment.terminal.settings.number_of_terminals' })}
      </SundayText>
    </ConfigurationItemColumn>
  );
};
