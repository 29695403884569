import { z } from 'zod';

export const schemaForType = <T>() =>
  <S extends z.ZodType<T, any, any> &
  z.ZodType<{
    [K in keyof z.infer<S>]: K extends keyof T ? z.infer<S>[K] : never;
  },
  any,
  any>,
  >(
    arg: S,
  ): S => arg;


export function createPaginatedResponseSchema<ItemType extends z.ZodTypeAny>(
  itemSchema: ItemType,
) {
  return z.object({
    totalCount: z.number(),
    items: z.array(itemSchema),
  });
}

export function nativeEnumKeySchema<T extends Record<string,  string | number>>(enumObj: T) {
  const keys = Object.keys(enumObj) as [string, ...string[]];
  return z
    .enum(keys)
    .transform((key) => enumObj[key as keyof T]);
}
