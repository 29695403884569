import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { useGetInvitations } from 'src/app-for-restaurants/mutation/useGetInvitations';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { useUsersInvite } from 'src/app-for-restaurants/mutation/useUsersInvite';
import { UserInvitation } from 'src/app-for-restaurants/domain/UserInvitation';
import dayjs from 'dayjs';
import RefreshIcon from '@mui/icons-material/Refresh';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { CountryCode, isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import { UserRole, userRoles } from 'src/domain/user/UserRole';
import { matchIsValidTel, MuiTelInput } from 'mui-tel-input';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const PhoneNumber = ({
  addIncomingInvitation,
  invitation,
}: {
  invitation: UserInvitationWithIndex;
  addIncomingInvitation: (inv: UserInvitationWithIndex) => void;
}) => {
  const business = useCurrentBusinessOrThrow();
  const [error, setError] = useState<boolean>(false);
  return (
    <Stack direction={'row'} spacing={1} width={'auto'}>
      <MuiTelInput
        defaultCountry={business.address.countryCode as CountryCode}
        onChange={(value) => {
          addIncomingInvitation({
            phoneNumber: value,
            role: invitation.role,
            expirationDate: null,
            index: invitation.index,
          });
          if (value.length > 10) setError(matchIsValidTel(invitation.phoneNumber));
        }}
        value={invitation.phoneNumber}
        onBlur={() => setError(!matchIsValidTel(invitation.phoneNumber))}
        error={error}
        label={<FormattedMessage id={'sunday_for_restaurants.access.menu.invitation.phone_number'} />}
      />
      <FormControl>
        <InputLabel>
          <FormattedMessage id={'sunday_for_restaurants.access.menu.invitation.role'} />
        </InputLabel>
        <Select
          sx={{ minWidth: 200 }}
          value={invitation.role.toString()}
          label="Role"
          onChange={(event) =>
            addIncomingInvitation({
              phoneNumber: invitation.phoneNumber,
              role: event.target.value as UserRole,
              expirationDate: null,
              index: invitation.index,
            })
          }
        >
          {Object.values(userRoles).map((value, index) => (
            <MenuItem value={value} key={index}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};
type UserInvitationWithIndex = UserInvitation & { index: number };
export const UserInvitationComponent = () => {
  const business = useCurrentBusinessOrThrow();
  const { data: invitations, isLoading } = useGetInvitations(business.id);
  const { createInvite } = useUsersInvite(business.id);
  const [incomingInvitations, setIncomingInvitations] = useState<UserInvitationWithIndex[]>([
    {
      phoneNumber: '',
      role: userRoles.WAITER,
      expirationDate: null,
      index: 0,
    },
  ]);
  const intl = useIntl();

  const handleGrantRolesClicked = async () => {
    const request = incomingInvitations.map((i) => ({
      phoneNumber: parsePhoneNumber(i.phoneNumber).number,
      role: i.role,
      expirationDate: i.expirationDate,
    }));
    await createInvite({
      invitations: request,
      business: business.id,
    });
    setIncomingInvitations([
      {
        phoneNumber: '',
        role: userRoles.WAITER,
        expirationDate: null,
        index: 0,
      },
    ]);
  };

  return (
    <Stack spacing={4}>
      <Card>
        <CardHeader
          title={intl.formatMessage({ id: 'sunday_for_restaurants.access.menu.invitation.title' })}
          subheader={intl.formatMessage({ id: 'sunday_for_restaurants.access.menu.invitation.sub_title' })}
        />
        <CardContent>
          <Stack spacing={2}>
            <Stack spacing={2}>
              {incomingInvitations
                .sort((a, b) => a.index - b.index)
                .map((invitation, index) => (
                  <Stack key={index} direction={'row'} alignItems={'center'} spacing={2}>
                    <PhoneNumber
                      invitation={invitation}
                      addIncomingInvitation={(inv: UserInvitationWithIndex) =>
                        setIncomingInvitations([...incomingInvitations.filter((item) => item.index !== inv.index), inv])
                      }
                    />

                    <Box>
                      <Stack direction={'row'} spacing={1}>
                        {incomingInvitations.length > 1 && (
                          <IconButton
                            color={'error'}
                            onClick={() => {
                              setIncomingInvitations((prevInvitations) => {
                                const updatedInvitations = prevInvitations.filter((_, idx) => idx !== index);
                                return updatedInvitations.map((item, idx) => ({
                                  ...item,
                                  index: idx,
                                }));
                              });
                            }}
                          >
                            <RemoveCircleOutlineIcon />
                          </IconButton>
                        )}

                        {incomingInvitations.length === index + 1 && (
                          <IconButton
                            onClick={() => {
                              setIncomingInvitations([
                                ...incomingInvitations,
                                {
                                  phoneNumber: '',
                                  role: incomingInvitations.pop()?.role!,
                                  expirationDate: null,
                                  index: index + 1,
                                },
                              ]);
                            }}
                          >
                            <AddCircleOutlineIcon />
                          </IconButton>
                        )}
                      </Stack>
                    </Box>
                  </Stack>
                ))}
            </Stack>
            <Button
              disabled={incomingInvitations.filter((i) => !isValidPhoneNumber(i.phoneNumber)).length > 0}
              variant="contained"
              onClick={handleGrantRolesClicked}
            >
              <FormattedMessage id={'sunday_for_restaurants.access.menu.invitation.invite'} />
            </Button>
          </Stack>
        </CardContent>
      </Card>
      {!isLoading && invitations && invitations.length > 0 && (
        <Card>
          <CardHeader
            title={intl.formatMessage({
              id: 'sunday_for_restaurants.access.menu.invitation.pending_invitations.title',
            })}
            subheader={intl.formatMessage({
              id: 'sunday_for_restaurants.access.menu.invitation.sunday_for_restaurants.access.menu.invitation.pending_invitations.sub_title',
            })}
          />

          <CardContent>
            <Grid container direction={'row'}>
              <Grid item xs={3}>
                <Typography variant={'subtitle2'} color={'grey'}>
                  <FormattedMessage id={'sunday_for_restaurants.access.menu.invitation.phone_number'} />
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant={'subtitle2'} color={'grey'}>
                  <FormattedMessage id={'sunday_for_restaurants.access.menu.invitation.role'} />
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant={'subtitle2'} color={'grey'}>
                  <FormattedMessage id={'sunday_for_restaurants.access.menu.invitation.expiration_date'} />
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant={'subtitle2'} color={'grey'}>
                  Action
                </Typography>
              </Grid>
            </Grid>

            {invitations.map((invitation) => {
              const expirationDate = dayjs(invitation.expirationDate);
              return (
                <Grid container direction={'row'} key={invitation.phoneNumber}>
                  <Grid item xs={3}>
                    <Typography>{parsePhoneNumber(invitation.phoneNumber).formatInternational()}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>{invitation.role}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Chip
                      color={expirationDate.isBefore(dayjs()) ? 'error' : 'success'}
                      label={expirationDate.format('DD/MM/YYYY')}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      variant={'text'}
                      size={'small'}
                      startIcon={<RefreshIcon />}
                      onClick={() =>
                        createInvite({
                          invitations: [invitation],
                          business: business.id,
                        })
                      }
                    >
                      <Typography fontSize={'small'}>
                        <FormattedMessage id={'sunday_for_restaurants.access.menu.invitation.resend_invitation'} />
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              );
            })}
          </CardContent>
        </Card>
      )}
    </Stack>
  );
};
