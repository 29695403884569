import styled from 'styled-components';
import { colorUsage } from '@sundayapp/b2b-react-component-library';
import { spaceUsages } from 'src/app/theme/SpaceUsagesTheme';

export const UploadInputContainer = styled.label`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 160px;
  align-items: center;
  margin-bottom: ${spaceUsages.mediumLarge};
  margin-top: ${spaceUsages.mediumLarge};
  border: 1px dashed ${colorUsage.quaternaryText};
  border-radius: ${spaceUsages.mediumSmall};
  padding: ${spaceUsages.mediumLarge};
  cursor: pointer;
`;

export const ImagePreview = styled.div<{ url: string }>`
  width: 100%;
  height: 100%;
  background-image: ${({ url }) => `url("${url}")`};
  background-size: cover;
`;

export const ImageViz = styled.div<{ url: string }>`
  width: 140px;
  height: 140px;
  background-image: ${({ url }) => `url("${url}")`};
  background-size: cover;
`;

export const InputFile = styled.input`
  display: none;
`;

export const ExistingImageContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  margin-top: ${spaceUsages.mediumLarge};
  margin-bottom: ${spaceUsages.mediumLarge};
`;

export const ExistingImageActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const VerticalDivider = styled.div`
  width: 2px;
  height: 16px;
  border-right: solid 2px ${colorUsage.quaternaryBorder};
  margin-left: ${spaceUsages.mediumSmall};
  margin-right: ${spaceUsages.mediumSmall};
`;
