import React from 'react';
import { Box, Card, CardContent, Typography, useTheme } from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { CrossIcon } from 'src/ordering/common/components/Icons';
import AccountingBannerSvg from 'src/app/component/icons/AccountingBannerSvg';

export const AccountingBanner = () => {
  const theme = useTheme();
  const [hide, setHide] = React.useState<string>('');

  return (
    <Card sx={{ backgroundColor: 'black', display: hide }}>
      <CardContent data-testid={'enterprise-payouts-preview-card'}>
        <Box
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          width="100%"
          justifyContent={'space-between'}
          sx={{
            position: 'relative',
            ['@media (max-width: 1000px)']: {
              flexDirection: 'column',
              height: '-webkit-fill-available',
            },
          }}
        >
          <Box
            display={'flex'}
            alignItems={'flex-start'}
            sx={{ borderColor: 'red', borderWidth: 10 }}
            flexDirection={'column'}
          >
            <Typography style={{ padding: '5px', color: 'white', fontSize: '28px', width: '100%' }}>
              <FormattedMessage id={'accounting.revenue_tab.banner.title'} />
            </Typography>
            <Typography style={{ color: theme.palette.text.secondary, padding: '5px', fontSize: '18px', width: '80%' }}>
              <FormattedMessage id={'accounting.revenue_tab.banner.message'} />
            </Typography>
          </Box>
          <Box display={'flex'} flexDirection={'row'}>
            <AccountingBannerSvg />
            <Box
              sx={{
                position: 'absolute',
                top: '-12px',
                right: -15,
                ['@media (max-width: 768px)']: { right: '-8px' },
              }}
            >
              <CrossIcon color={'white'} onClick={() => setHide('none')} />
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
