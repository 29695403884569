import { CurrencyCode } from '@sundayapp/web-money';
import type { GridValidRowModel } from '@mui/x-data-grid/models/gridRows';

export interface Staff {
  waiterName: string;
  ratingOutOf5: number | null;
  nbOfReviews: number | null;
  totalRevenue: number | null;
  basketSize: number | null;
  nbSundayPayments: number | null;
  sundayPaymentsRate: number | null;
  totalTips: number | null;
  tipsRate: number | null;
  n5StarsGoogleReview: number | null;
  totalTipsPaymentTerminal: number | null;
  avgServiceRating: number | null;
  nbOfWalkouts: number | null;
  walkoutsSalesAmount: number | null;
  walkoutsTipsAmount: number | null;
  billViewRate: number | null;
}

export type SundayPaymentsRate = { rate: number | null; label: string };

export interface StaffViewModel extends GridValidRowModel {
  id: number;
  waiterName: string;
  ratingOutOf5: string;
  nbOfReviews: number;
  totalRevenue: string;
  basketSize: string;
  nbSundayPayments: number;
  sundayPaymentsRate: SundayPaymentsRate;
  totalTips: string;
  tipsRate: string;
  n5StarsGoogleReview: number;
  totalTipsPaymentTerminal: string;
  avgServiceRating: string | null;
  nbOfWalkouts: number | null;
  walkoutsSalesAmount: string;
  walkoutsTipsAmount: string;
  billViewRate: string;
}

export const toRateView = (rate: number | null, locale: string, numberOfDigits: number): string =>
  (rate ?? 0)
    .toLocaleString(locale, {
      style: 'percent',
      minimumFractionDigits: numberOfDigits,
      maximumFractionDigits: numberOfDigits,
    })
    .replace(/\s/g, '');

export const toMoneyView = (
  value: number | null,
  currency: CurrencyCode,
  locale: string,
  numberOfDigits: number,
): string =>
  (value ?? 0)
    .toLocaleString(locale, {
      style: 'currency',
      currencyDisplay: 'narrowSymbol',
      currency: currency as string,
      minimumFractionDigits: numberOfDigits,
      maximumFractionDigits: numberOfDigits,
    })
    .replace(/\s/g, '');

// eslint-disable-next-line max-len
export const toViewModel = (
  staffData: Staff[],
  locale: string,
  currency: CurrencyCode,
  shouldCollectAdoptionRate: boolean,
): StaffViewModel[] =>
  staffData.map(
    (
      {
        basketSize,
        nbOfReviews,
        n5StarsGoogleReview,
        nbSundayPayments,
        ratingOutOf5,
        sundayPaymentsRate,
        tipsRate,
        totalRevenue,
        totalTips,
        waiterName,
        totalTipsPaymentTerminal,
        avgServiceRating,
        walkoutsSalesAmount,
        walkoutsTipsAmount,
        nbOfWalkouts,
        billViewRate,
      },
      index,
    ) => {
      function toRoundedMoney(amount: number | null, ifZeroOrNull?: string) {
        if ((amount === 0 || amount === null) && ifZeroOrNull) {
          return ifZeroOrNull;
        }
        return toMoneyView(amount, currency, locale, 2);
      }

      return {
        id: index + 1,
        waiterName,
        ratingOutOf5: ratingOutOf5 ? ratingOutOf5.toFixed(2) : '',
        nbOfReviews: nbOfReviews ? nbOfReviews : 0,
        n5StarsGoogleReview: n5StarsGoogleReview ? n5StarsGoogleReview : 0,
        totalRevenue: toRoundedMoney(totalRevenue),
        basketSize: basketSize && basketSize !== 0 ? toMoneyView(basketSize, currency, locale, 1) : '-',
        nbSundayPayments: nbSundayPayments ?? 0,
        sundayPaymentsRate: {
          rate: shouldCollectAdoptionRate && sundayPaymentsRate ? sundayPaymentsRate * 100 : null,
          label: shouldCollectAdoptionRate && sundayPaymentsRate ? toRateView(sundayPaymentsRate, locale, 1) : '-',
        },
        totalTips: toRoundedMoney(totalTips),
        tipsRate: toRateView(tipsRate, locale, 1),
        totalTipsPaymentTerminal: toRoundedMoney(totalTipsPaymentTerminal),
        avgServiceRating: avgServiceRating ? avgServiceRating.toFixed(2) : null,
        nbOfWalkouts: nbOfWalkouts ?? 0,
        walkoutsSalesAmount: toRoundedMoney(walkoutsSalesAmount, '-'),
        walkoutsTipsAmount: toRoundedMoney(walkoutsTipsAmount, '-'),
        billViewRate: toRateView(billViewRate, locale, 1),
      };
    },
  );
