// eslint-disable-next-line @typescript-eslint/no-redeclare
import { colorUsage, IconWrapper, PencilSvg, TrashSvg } from '@sundayapp/b2b-react-component-library';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'src/app/i18n/TypedIntl';
import MenuScheduleModal from './MenuScheduleModal';
import { MenuSchedulesUtils } from '../../utils/menuSchedules/MenuSchedulesUtils';
import { GroupedTimeRanges } from './GroupedTimeRanges';
import { spaceUsages } from 'src/app/theme/SpaceUsagesTheme';
import { SundayText } from 'src/app/component/Text/Text';

type GroupedConstraintLineProps = {
  groupedTimeRanges: GroupedTimeRanges;
  onUpdated: (groupedTimeRanges: GroupedTimeRanges) => void;
  onDelete: () => void;
  deleteAvailable: boolean;
};

const LabelsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const GroupedConstraintsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const GroupedConstraintsIcons = styled.div`
  display: flex;
  gap: ${spaceUsages.medium};
`;

const GroupedConstraintLine = ({
  groupedTimeRanges,
  onUpdated,
  onDelete,
  deleteAvailable,
}: GroupedConstraintLineProps) => {
  const intl = useIntl();
  const labels = MenuSchedulesUtils.groupedTimeToString(groupedTimeRanges, intl);
  const [scheduleSelectorDialogOpened, setScheduleSelectorDialogOpened] = useState<boolean>(false);

  return (
    <GroupedConstraintsRow>
      <LabelsContainer>
        {labels.map((label) => (
          <SundayText size="small" key={label}>
            {label}
          </SundayText>
        ))}
      </LabelsContainer>
      <GroupedConstraintsIcons>
        <IconWrapper size="small" cursor="pointer" onClick={() => setScheduleSelectorDialogOpened(true)}>
          <PencilSvg />
        </IconWrapper>
        {deleteAvailable && (
          <IconWrapper color={colorUsage.error} size="small" cursor="pointer" onClick={() => onDelete()}>
            <TrashSvg />
          </IconWrapper>
        )}
      </GroupedConstraintsIcons>

      <MenuScheduleModal
        groupedTimeRanges={groupedTimeRanges}
        setGroupedTimeRanges={onUpdated}
        isOpened={scheduleSelectorDialogOpened}
        onClose={() => setScheduleSelectorDialogOpened(false)}
      />
    </GroupedConstraintsRow>
  );
};

export default GroupedConstraintLine;
