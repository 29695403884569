import { PaymentMethodIconProps } from 'src/accounting/common/Icons/IconProps';
import React from 'react';


export function RewardIcon({ ...props }: PaymentMethodIconProps) {
  return (
    <svg viewBox="-2 0 28 28"  {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <path
          d="M18 3.91992H6C3.79086 3.91992 2 5.71078 2 7.91992V17.9199C2 20.1291 3.79086 21.9199 6 21.9199H18C20.2091 21.9199 22 20.1291 22 17.9199V7.91992C22 5.71078 20.2091 3.91992 18 3.91992Z"
          stroke="#646464" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
        <path d="M7 17.9199L17 7.91992" stroke="#646464" strokeWidth="1.5" strokeLinecap="round"
              strokeLinejoin="round"></path>
        <path
          d="M8 11.9199C9.10457 11.9199 10 11.0245 10 9.91992C10 8.81535 9.10457 7.91992 8 7.91992C6.89543 7.91992 6 8.81535 6 9.91992C6 11.0245 6.89543 11.9199 8 11.9199Z"
          stroke="#646464" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
        <path
          d="M16 17.9199C17.1046 17.9199 18 17.0245 18 15.9199C18 14.8154 17.1046 13.9199 16 13.9199C14.8954 13.9199 14 14.8154 14 15.9199C14 17.0245 14.8954 17.9199 16 17.9199Z"
          stroke="#646464" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
      </g>
    </svg>
  );
}
