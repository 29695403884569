import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Switch,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { useUpdateWaiterRateMutation } from '../mutations/useUpdateWaiterRateMutation';
import { Waiter } from 'src/tips/domain/AllWaiters';
import { ServiceType } from 'src/domain/venue/ServiceType';
import { Business } from 'src/business/domain/Business';
import { EnrollmentId } from 'src/business/domain/Enrollment';

const defaultRateForQR = '50';
const defaultRateForPDQ = '100';

interface Props {
  business: Business;
  serviceType: ServiceType;
  waiter: Waiter;
  closeDrawer: () => void;
}

const getEnrollmentId = (serviceType: ServiceType, business: Business) =>
  business.enrollments.find((it) => it.product === serviceType)!!.id;
const getDefaultRate = (serviceType: ServiceType) =>
  serviceType === ServiceType.PAY_AT_TABLE ? defaultRateForQR : defaultRateForPDQ;

export const UpdateEnrollmentTippingConfiguration: React.FC<Props> = ({
  waiter,
  serviceType,
  business,
  closeDrawer,
}: Props) => {
  const useSnackBar = useSnackbar();
  const theme = useTheme();
  const intl = useIntl();
  const updateRate = useUpdateWaiterRateMutation(business.id);

  const defaultRate = useMemo<string>(() => getDefaultRate(serviceType), [serviceType]);
  const enrollmentId = useMemo<EnrollmentId>(() => getEnrollmentId(serviceType, business), [serviceType, business]);

  const [rate, setRate] = useState(defaultRate);
  const [isChecked, setIsChecked] = useState(false);
  const [isConfirmOpened, setIsConfirmOpened] = useState<boolean>(false);

  useEffect(() => {
    if (waiter.rates[enrollmentId] === 0) {
      setRate(defaultRate);
      setIsChecked(false);
    } else {
      setRate((waiter.rates[enrollmentId] / 100).toString());
      setIsChecked(true);
    }
  }, [waiter, serviceType, business]);

  const onChangeRate = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setRate(event.target.value as string);
    },
    [setRate],
  );

  const onChangeInstantTipping = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newCheckedValue = event.target.checked;
    setIsChecked(newCheckedValue);
  };

  const getCurrentRate = () => (isChecked ? Number(rate) * 100 : 0);

  const updateRateAndClose = (currentWaiterId: string) => {
    updateRate
      .mutateAsync({
        waiterId: currentWaiterId,
        rate: getCurrentRate(),
        enrollmentId: enrollmentId,
      })
      .then((waiterUpdated) => {
        useSnackBar.addNotification({
          variant: 'success',
          text: intl.formatMessage(
            {
              id: 'direct_tipping.rate_updated',
              defaultMessage: `${waiter.name}'s instant tipping configuration updated`,
            },
            { waiter: waiterUpdated.name },
          ),
        });
        closeDrawer();
      });
  };

  const checkRate = () => {
    if (waiter.rates[enrollmentId] !== 0 && getCurrentRate() === 0) {
      setIsConfirmOpened(true);
    } else {
      updateRateAndClose(waiter.waiterId);
    }
  };

  const confirmDisableInstantTipping = () => {
    setIsConfirmOpened(false);
    updateRateAndClose(waiter.waiterId);
  };

  const inputtedRateIsNotValid = () => {
    return isChecked && (isNaN(getCurrentRate()) || getCurrentRate() < 0 || getCurrentRate() > 10000);
  };

  return (
    <>
      <Box ml={4} mt={2} mr={4}>
        <Box flexDirection="row" display="flex">
          <Box>
            <Typography variant="h5" mb={2} color={theme.palette.text.primary}>
              <FormattedMessage id="direct_tipping.receive_instant_title" defaultMessage="Receive instant tips" />
            </Typography>
            <Typography variant="h6" mb={2} color={theme.palette.text.secondary}>
              <FormattedMessage
                id="direct_tipping.receive_instant_info"
                defaultMessage="Turn on to allow this server to receive their tips"
              />
            </Typography>
          </Box>
          <Box ml={4} mt={2}>
            <Switch onChange={onChangeInstantTipping} defaultChecked={isChecked} checked={isChecked} />
          </Box>
        </Box>
        {isChecked && (
          <>
            <Box borderTop={`1px solid ${theme.palette.text.secondary}`} mt={3} mb={3} />
            <Typography variant="h5" mb={2} color={theme.palette.text.primary}>
              <FormattedMessage id="direct_tipping.rate_title" defaultMessage="Configure the percentage" />
            </Typography>
            <Typography variant="h6" mb={4} color={theme.palette.text.secondary}>
              <FormattedMessage
                id="direct_tipping.rate_info"
                defaultMessage="Select the percentage that the server will receive every time that a customer leaves a tip"
              />
            </Typography>
            <Box>
              <TextField label="%" value={rate} onChange={onChangeRate} fullWidth />
            </Box>
          </>
        )}
        <Box mt={'20px'}>
          <Button variant="contained" fullWidth onClick={() => checkRate()} disabled={inputtedRateIsNotValid()}>
            <FormattedMessage id="direct_tipping.save_changes" defaultMessage="Save changes" />
          </Button>
        </Box>
      </Box>
      <Dialog open={isConfirmOpened} onClose={() => setIsConfirmOpened(false)}>
        <DialogTitle>
          <FormattedMessage
            id="direct_tipping.confirm_tipping_off"
            defaultMessage={`Do you want to stop sending tips to ${waiter.name}?`}
            values={{ waiter: waiter.name }}
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormattedMessage
              id="direct_tipping.confirm_description"
              defaultMessage="Changes will be applied automatically"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsConfirmOpened(false)}>
            <FormattedMessage id="direct_tipping.cancel_confirm" defaultMessage="Cancel" />
          </Button>
          <Button onClick={() => confirmDisableInstantTipping()} color={'error'} variant={'outlined'} autoFocus>
            <FormattedMessage id="direct_tipping.apply_confirm" defaultMessage="Yes, stop sending tips" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
