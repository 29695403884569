import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControl,
  Grid2,
  InputLabel,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';

import { revenuesDatePresets, revenueTabDatePresets } from 'src/accounting/revenues/domain/RevenuesDatePresets';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { Money, MoneyView } from '@sundayapp/web-money';
import { palette } from 'src/organization-report/design/palette';
import { isAllowed, isSourceFilter, sourceFilters, SourceFilterValue } from '../domain/Tips';
import { NoTips } from './NoTips';
import { extractTimeFrameFilterFromURLParams, TimeFrameFilter } from 'src/components/time-frame-filter/TimeFrameFilter';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import MenuItem from '@mui/material/MenuItem';
import { restrictedToTipsManager } from 'src/tips/page/restrictedToTipsManager';
import { useGetBusinessTippingConfiguration } from 'src/tips/queries/useGetBusinessTippingConfiguration';
import { useGetBusinessTipsBreakdown } from 'src/tips/queries/useGetBusinessTipsBreakdown';
import InfoIcon from '@mui/icons-material/Info';
import {
  totalGrossTips,
  totalNetDirectTips,
  totalNetPooledTips,
  totalNetTips,
  totalPooledTips,
} from 'src/tips/domain/TipsSummaryByWaiter';

function extractFilters(search: string): SourceFilterValue | undefined {
  const params = new URLSearchParams(search);
  const value = params.get('payment-method') || undefined;
  return isSourceFilter(value) ? value : undefined;
}

const TipsCollectedCard = (props: { amount: Money; title: React.ReactNode; tooltip?: React.ReactNode }) => {
  const theme = useTheme();

  return (
    <Card sx={{ width: '100%' }}>
      <CardHeader
        title={
          <Box display={'flex'} alignItems={'center'} gap={1}>
            <Typography variant="subtitle1" color={theme.palette.text.secondary}>
              {props.title}
            </Typography>
            {props.tooltip}
          </Box>
        }
      />
      <CardContent sx={{ paddingY: 0 }}>
        <Typography variant="h4">
          <MoneyView value={props.amount} />
        </Typography>
      </CardContent>
    </Card>
  );
};

export const TipsBreakdown = restrictedToTipsManager(() => {
  const navigate = useNavigate();
  const business = useCurrentBusinessOrThrow();
  const location = useLocation();
  const datePresets = revenuesDatePresets();
  const period = useMemo(
    () => extractTimeFrameFilterFromURLParams(location.search, datePresets, revenueTabDatePresets.TODAY),
    [location],
  );
  const getDefaultSourceFilter = (): SourceFilterValue => {
    if (business.enrollments.length > 1) {
      return sourceFilters.ALL.value;
    }

    return business.patEnrollment || business.oapEnrollment ? sourceFilters.QR_CODE.value : sourceFilters.PDQ.value;
  };

  const defaultSourceFilter = getDefaultSourceFilter();

  const sourceFilter: SourceFilterValue = useMemo<SourceFilterValue>(
    () => extractFilters(location.search) ?? defaultSourceFilter,
    [location],
  );
  const { data: businessTippingConfiguration, isLoading } = useGetBusinessTippingConfiguration(business.id);
  const { data: tipsBreakdown, isLoading: isTipsBreakdownLoading } = useGetBusinessTipsBreakdown(
    business.id,
    business.currency,
    {
      sourceFilter,
      from: period.dateRange.startDate.toDate(),
      to: period.dateRange.endDate.toDate(),
    },
  );

  const onSourceFilterChanged = (newPaymentMethodFilter: string) => {
    const urlParams = new URLSearchParams(location.search);
    urlParams.set('payment-method', newPaymentMethodFilter);
    location.search = urlParams.toString();
    return navigate(location);
  };

  if (isTipsBreakdownLoading || isLoading || !tipsBreakdown || !businessTippingConfiguration) {
    return <CircularProgress />;
  }

  const hasOnlyPosPooledTips = tipsBreakdown.tips.every(
    (tips) => tips.posPooledAmount.amount > 0 && tips.directTipsAmount.amount === 0,
  );

  const availableSourceFilters = Object.values(sourceFilters).filter(isAllowed(business));
  const businessIsUsingSundayTipping = businessTippingConfiguration?.type === 'SundayTipping';
  const shouldDisplayNetTipsCollectedAmount = tipsBreakdown.totalGrossTips.amount > 0 && businessIsUsingSundayTipping;

  return (
    <Box display="flex" gap="24px" flexDirection="column">
      <Box display={'flex'} gap={'16px'} flexDirection={'row'} width={'100%'}>
        <TimeFrameFilter datePresets={datePresets} period={period} />
        {availableSourceFilters.length > 1 && (
          <FormControl size="small" sx={{ width: '100%', maxWidth: '200px' }}>
            <InputLabel id="filter-product">
              <FormattedMessage id="tips.summary.filter.by_product" />
            </InputLabel>
            <Select
              labelId="filter-product"
              id="filter-product"
              label={'By product'}
              value={sourceFilter}
              onChange={(e) => {
                onSourceFilterChanged(e.target.value);
              }}
            >
              {availableSourceFilters.map((source) => (
                <MenuItem key={source.value} value={source.value}>
                  <FormattedMessage id={source.translations} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Box>
      <Grid2 container alignItems="center" rowSpacing={3} columnSpacing={3} display="flex">
        <Grid2
          display="flex"
          size={{
            xs: 9,
            md: 3,
          }}
        >
          <TipsCollectedCard
            title={<FormattedMessage id="tips.summary.card.total_collected.gross" />}
            amount={tipsBreakdown.totalGrossTips}
            tooltip={
              <Tooltip title={<FormattedMessage id={'tips.summary.card.tooltip'} />}>
                <InfoIcon />
              </Tooltip>
            }
          />
        </Grid2>
        {shouldDisplayNetTipsCollectedAmount && (
          <Grid2
            display="flex"
            size={{
              xs: 9,
              md: 3,
            }}
          >
            <TipsCollectedCard
              title={<FormattedMessage id="tips.summary.card.total_collected.net" />}
              amount={tipsBreakdown.totalNetTips}
              tooltip={
                <Tooltip title={<FormattedMessage id={'tips.summary.card.tooltip.net'} />}>
                  <InfoIcon />
                </Tooltip>
              }
            />
          </Grid2>
        )}

        {!hasOnlyPosPooledTips && (
          <>
            <Grid2
              display="flex"
              size={{
                xs: 9,
                md: 3,
              }}
            >
              <TipsCollectedCard
                title={<FormattedMessage id="tips.summary.card.total_direct" />}
                amount={
                  businessIsUsingSundayTipping ? tipsBreakdown.totalNetDirectTips : tipsBreakdown.totalGrossDirectTips
                }
                tooltip={
                  <Tooltip title={<FormattedMessage id={'tips.summary.card.tooltip.net'} />}>
                    <InfoIcon />
                  </Tooltip>
                }
              />
            </Grid2>
            <Grid2
              display="flex"
              size={{
                xs: 9,
                md: 3,
              }}
            >
              <TipsCollectedCard
                amount={
                  businessIsUsingSundayTipping
                    ? tipsBreakdown.totalNetPooledTips
                    : tipsBreakdown.totalGrossPooledTips
                }
                title={<FormattedMessage id="tips.summary.card.total_pooled" />}
                tooltip={
                  <Tooltip title={<FormattedMessage id={'tips.summary.card.tooltip.net'} />}>
                    <InfoIcon />
                  </Tooltip>
                }
              />
            </Grid2>
          </>
        )}
      </Grid2>

      {tipsBreakdown.tips.length === 0 && (
        <TableContainer sx={{ borderRadius: '10px', padding: '20px' }} component={Paper}>
          <NoTips />
        </TableContainer>
      )}

      {tipsBreakdown.tips.length > 0 && (
        <Table sx={{ tableLayout: 'fixed', width: '100%' }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ border: 'none' }}>
                <Typography variant="subtitle2" color={palette.neutral.grey500}>
                  <FormattedMessage id="tips.summary.table.waiter.title" />
                </Typography>
              </TableCell>
              <TableCell colSpan={2} sx={{ border: 'none' }}>
                <Typography variant="subtitle2" color={palette.neutral.grey500}>
                  <FormattedMessage id="tips.summary.table.tips.title" />
                </Typography>
              </TableCell>
              {!hasOnlyPosPooledTips && (
                <TableCell colSpan={2} sx={{ border: 'none' }}>
                  <Typography variant="subtitle2" color={palette.neutral.grey500}>
                    <FormattedMessage id="tips.summary.table.tips.allocation.title" />
                  </Typography>
                </TableCell>
              )}
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="caption" color={palette.neutral.grey400}>
                  <FormattedMessage id="tips.summary.table.column.waiter.header" />
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption" color={palette.neutral.grey400}>
                  <FormattedMessage id="tips.summary.table.column.tips.total.header.gross" />
                </Typography>
              </TableCell>
              {businessIsUsingSundayTipping && (
                <TableCell>
                  <Typography variant="caption" color={palette.neutral.grey400}>
                    <FormattedMessage id="tips.summary.table.column.tips.total.header.net" />
                  </Typography>
                </TableCell>
              )}
              {!hasOnlyPosPooledTips && (
                <>
                  <TableCell>
                    <Typography variant="caption" color={palette.neutral.grey400}>
                      <FormattedMessage id="tips.summary.table.column.tips.direct_tip.header" />
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption" color={palette.neutral.grey400}>
                      <FormattedMessage id="tips.summary.table.column.tips.pooled_tip.header" />
                    </Typography>
                  </TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {tipsBreakdown.tips.map((tips) =>
              (
                <TableRow
                  key={tips.waiterName}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    color: 'inherit',
                  }}
                >
                  <TableCell>
                    <Typography variant="subtitle1" sx={{ whiteSpace: 'pre' }}>
                      {tips.waiterName ||
                        <FormattedMessage id="tips.summary.table.waiter.no_waiter_associated" />}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="subtitle1">
                      <MoneyView value={totalGrossTips(tips)} />
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="subtitle1">
                      <MoneyView
                        value={businessIsUsingSundayTipping ? totalNetTips(tips) : totalGrossTips(tips)}
                      />
                    </Typography>
                  </TableCell>
                  {!hasOnlyPosPooledTips && (
                    <>
                      <TableCell align="left">
                        <Typography variant="subtitle1">
                          <MoneyView
                            value={businessIsUsingSundayTipping ? totalNetDirectTips(tips) : tips.directTipsAmount}
                          />
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="subtitle1">
                          <MoneyView
                            value={businessIsUsingSundayTipping ? totalNetPooledTips(tips) : totalPooledTips(tips)}
                          />
                        </Typography>
                      </TableCell>
                    </>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      )}
    </Box>
  );
});
