import { Controller, useFormContext } from 'react-hook-form';
import React from 'react';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useIntl } from 'src/app/i18n/TypedIntl';

export const DateOfIncorporation = () => {
  const { control } = useFormContext<CheckoutOnboardingInformationDraft>();
  const intl = useIntl();

  return <Controller
    name="dateOfIncorporation"
    control={control}
    rules={{ required: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.dateOfIncorporation.error' }) }}
    render={({
      field: {
        onChange,
        value,
      },
      fieldState: { error },
    }) => (
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={intl.locale}>
        <DatePicker
          views={['year', 'month']}
          label={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.dateOfIncorporation' })}
          format="MM/YYYY"
          value={value}
          onChange={event => onChange(event)}
          slotProps={{
            textField: {
              error: !!error,
              helperText: !!error ? error.message : intl.formatMessage({ id: 'settings.payments.checkout.onboarding.dateOfIncorporation.help' }),
              InputLabelProps: {
                shrink: true,
                required: true,
              },
            },
          }}
        />
      </LocalizationProvider>
    )}
  />;
};
