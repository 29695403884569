import { z } from 'zod';
import { CurrencyCode, Money } from '@sundayapp/web-money';
import { schemaForType } from 'src/utils/ZodUtils';

export const virtualTabIdSchema = z.string().brand<'virtual_tab_id'>();
export type VirtualTabId = z.infer<typeof virtualTabIdSchema>;

const MoneyZ = schemaForType<Money>()(
  z.object({
    amount: z.number(),
    currency: z.nativeEnum(CurrencyCode).catch(CurrencyCode.EUR),
  }),
);

export const virtualAuthorizationStatuses = {
  INITIATED: 'INITIATED',
  GRANTED: 'GRANTED',
  DECLINED: 'DECLINED',
  CAPTURED: 'CAPTURED',
  FAILED: 'FAILED',
  VOIDED: 'VOIDED',
} as const;

export const VirtualAuthorizationStatusSchema = z.nativeEnum(virtualAuthorizationStatuses);

export const VirtualAuthorizationSchema = z.object({
  authorizationId: z.string(),
  status: VirtualAuthorizationStatusSchema,
  declinedAt: z.coerce.date().optional(),
  capturedFailedAt: z.coerce.date().optional(),
  capturedAmount: MoneyZ.optional(),
  capturedAt: z.coerce.date().optional(),
  voidedAt: z.coerce.date().optional(),
  grantedAt: z.coerce.date().optional(),
});

export type VirtualTabAuthorization = z.infer<typeof VirtualAuthorizationSchema>;

export interface VirtualTab {
  id: VirtualTabId,
  billId: string,
  canBeCaptured: boolean,
  capturedAmount?: Money,
  authorizations: VirtualTabAuthorization[],
}
