import React from 'react';
import styled from 'styled-components';
import { IconButton, Typography } from '@mui/material';
import { themeV5 } from 'src/app/theme/ThemeV5';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

type Props = {
  currentPage: number;
  lastPage: number;
  previousPage: () => void;
  nextPage: () => void;
};

const Navigation = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  background: white;
  width: 100%;
  margin: -15px 0 0 0;
  border-top: 1px solid #dddde4;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  padding: 16px;
`;

export const PaymentNavigation = ({ lastPage, currentPage, previousPage, nextPage }: Props) => (
  <>
    {lastPage > 1 && (
      <Navigation>
        <IconButton
          data-testid="previousButton"
          size="small"
          onClick={previousPage}
          disabled={currentPage === 1}
          className="custom-small-iconButton"
          disableRipple
        >
          <ArrowBackIosNewIcon />
        </IconButton>

        <Typography color={themeV5.palette.text.primary} variant="body2">
          {currentPage}
          {' - '}
          {lastPage}
        </Typography>

        <IconButton
          data-testid="nextButton"
          size="small"
          onClick={nextPage}
          disabled={currentPage === lastPage}
          className="custom-small-iconButton"
          disableRipple
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </Navigation>
    )}
  </>
);
