import { Box, Tab, Tabs, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { useSearchProductsFilters } from 'src/menu/dynamic-menu/pages/ProductsPage/hooks/useSearchProductsFilters';
import styled from 'styled-components';
import { AllergensList } from 'src/menu/dynamic-menu/pages/ProductsPage/components/allergens/AllergensList';
import { ProductCatalog } from 'src/menu/dynamic-menu/pages/ProductsPage/components/products/ProductCatalog';
import { BlocksList } from 'src/menu/dynamic-menu/pages/ProductsPage/components/blocks/BlocksList';
import { menuElementTypes, translationByProductType } from 'src/menu/dynamic-menu/domain/Product';
import { MenuAssetsSourceSelector } from 'src/menu/dynamic-menu/pages/MenusPage/components/MenuAssetsSourceSelector';
import {
  useMenuAssetsSources,
  useMenuAssetsSourcesState,
} from 'src/menu/dynamic-menu/pages/MenusPage/DigitalMenusContext';
import { hasMultiSources } from 'src/menu/dynamic-menu/domain/MenuAssetsSource';

const TabTitle = styled('div')`
  display: flex;
  align-items: center;
  padding-right: 16px;
  padding-left: 16px;
`;

export const ProductsPage = () => {
  const business = useCurrentBusinessOrThrow();
  const menuAssetsSources = useMenuAssetsSources();
  const { setSearchParams, family } = useSearchProductsFilters();
  const {
    currentMenuAssetsSource,
    resourcesFilter,
    syncSource,
    updateCurrentMenuAssetsSource,
  } = useMenuAssetsSourcesState();

  return (
    <Box gap={3} display="flex" flexDirection="column">
      <Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          {hasMultiSources(menuAssetsSources) && <MenuAssetsSourceSelector menuAssetsSources={menuAssetsSources}
                                                                           value={currentMenuAssetsSource}
                                                                           allowAllSourcesOption
                                                                           onChange={updateCurrentMenuAssetsSource} />}
          <Tabs
            value={family}
            onChange={(_, currentFamily) =>
              setSearchParams((prev) => {
                prev.set('family', currentFamily);
                prev.set('productType', 'All');
                prev.delete('filter');
                prev.delete('advanced');
                return prev;
              })
            }
          >
            <Tab
              label={
                <TabTitle>
                  <Typography variant="body1">
                    <FormattedMessage id={translationByProductType[menuElementTypes.PRODUCT].plural} />
                  </Typography>
                </TabTitle>
              }
              value={menuElementTypes.PRODUCT}
            />
            <Tab
              label={
                <TabTitle>
                  <Typography variant="body1">
                    <FormattedMessage id={translationByProductType[menuElementTypes.MODIFIER].plural} />
                  </Typography>
                </TabTitle>
              }
              value={menuElementTypes.MODIFIER}
            />

            <Tab
              label={
                <TabTitle>
                  <Typography variant="body1">
                    <FormattedMessage id={'menus.products.search.tab.block'} />
                  </Typography>
                </TabTitle>
              }
              value={menuElementTypes.BLOCK}
            />

            <Tab
              label={
                <TabTitle>
                  <Typography variant="body1">
                    <FormattedMessage id="menus.products.search.tab.allergen" />
                  </Typography>
                </TabTitle>
              }
              value={menuElementTypes.ALLERGEN}
            />
          </Tabs>
        </Box>
      </Box>

      {family === menuElementTypes.BLOCK ? (
        <BlocksList business={business} />
      ) : family === menuElementTypes.ALLERGEN ? (
        <AllergensList business={business} />
      ) : (
        <ProductCatalog business={business} menuAssetsSource={{ syncSource, resourcesFilter }} />
      )}
    </Box>
  );
};
