import { useQueries } from '@tanstack/react-query';
import { distributionByRatingInTimeRangeQuery } from './useDistributionByRatingInTimeRange';
import { dimensionsAnalysisQuery } from './useDimensionsAnalysisInTimeRange';
import { DimensionsAnalysis } from '../domain/DimensionsAnalysis';
import { DistributionByRating } from '../domain/DistributionByRating';
import { BusinessId } from 'src/business/domain/Business';
import { DateRange } from 'src/sundayplus/reviews/domain/DateRange';

const useGuestExperienceQueries = (businessId: BusinessId, dateRange: DateRange) =>
  useQueries({
    queries: [
      dimensionsAnalysisQuery(businessId, dateRange),
      distributionByRatingInTimeRangeQuery(businessId, dateRange),
    ],
  });

export const useGuestExperience = (
  businessId: BusinessId,
  dateRange: DateRange,
): {
  isLoading: boolean;
  isError: boolean;
  data:
  | {
    dimensionAnalysis: DimensionsAnalysis | undefined;
    distributionByRating: DistributionByRating | undefined;
  }
  | undefined;
} => {
  const queryResults = useGuestExperienceQueries(businessId, dateRange);
  const isLoading = queryResults.some((result) => result.isLoading);
  const isError = queryResults.some((result) => result.isError);
  return {
    isLoading,
    isError,
    data:
      isLoading || isError
        ? undefined
        : {
          dimensionAnalysis: queryResults[0].data,
          distributionByRating: queryResults[1].data,
        },
  };
};
