import React from 'react';
import { ErrorPageWrapper, RobotImg } from './ErrorPage.style';
import robotImg from '../../../app/assets/robot.png';
import { Button, Typography } from '@mui/material';

const ErrorPage = () => {
  const handleReload = () => {
    window.location.reload();
  };

  return (
    <ErrorPageWrapper>
      <RobotImg src={robotImg} alt="error page" />
      <Typography variant='h3'>
        An error occurred, please retry.
      </Typography>
      <Button onClick={handleReload}>Reload Page</Button>
      <Button href="/">Go to Home Page</Button>

    </ErrorPageWrapper>
  );
};

export default ErrorPage;
