import { UploadButton } from '@sundayapp/b2b-react-component-library';
import { Button, CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import ErrorContainer from '../../../../ordering/common/components/ErrorContainer';
import { useSetStaticMenuContent } from '../../infrastructure/mutations/useSetStaticMenuConntent';
import { StaticMenuIdSchema } from '../../domain/StaticMenus';
import { useBusinessIdOrThrow } from 'src/business/hooks/useBusinessId';
import { spaceUsages } from 'src/app/theme/SpaceUsagesTheme';

type UploadMenuPdfButtonProps = {
  variant: 'primary' | 'secondary';
  text: string;
  onNewMenuPdfUploaded?: () => void;
};

const LoaderWrapper = styled.div`
  padding-left: ${spaceUsages.medium};
`;

const UploadButtonContainer = styled.div`
  justify-content: center;
  width: 100%;
  display: flex;
`;

const UploadMenuPdfButton = ({ variant, text, onNewMenuPdfUploaded }: UploadMenuPdfButtonProps) => {
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);

  const businessId = useBusinessIdOrThrow();
  const { menuId } = useParams();
  const { setStaticMenuContent } = useSetStaticMenuContent();

  const loadMenuPdf = async (file: File) => {
    setUploading(true);
    try {
      await setStaticMenuContent(businessId, StaticMenuIdSchema.parse(menuId!), file);
      onNewMenuPdfUploaded?.();
      setError(undefined);
    } catch (e) {
      setError({ name: 'Load error', message: 'Failed to load PDF menu' });
    } finally {
      setUploading(false);
    }
  };

  return (
    <>
      <UploadButtonContainer>
        {!uploading && (
          <UploadButton
            id="menuPdfUpload"
            accept="application/pdf"
            variant={variant}
            marginTop={spaceUsages.large}
            size="large"
            text={text}
            onFileUpload={(e: { target: { files: any } }) => loadMenuPdf(e.target?.files![0])}
          />
        )}
        {uploading && (
          <Button type="submit" color="secondary" size="large" sx={{ marginTop: spaceUsages.large }} disabled>
            {text}
            <LoaderWrapper>
              <CircularProgress size="medium" />
            </LoaderWrapper>
          </Button>
        )}
      </UploadButtonContainer>
      <ErrorContainer error={error} />
    </>
  );
};

export default UploadMenuPdfButton;
