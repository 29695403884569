import React, { ReactNode } from 'react';
import { Paper, Stack, Typography } from '@mui/material';
import { palette } from 'src/organization-report/design/palette';
import SundayTooltip from 'src/app/component/tooltip/SundayTooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

type Props = {
  icon: ReactNode;
  title: ReactNode;
  subtitle: ReactNode;
  tooltip: ReactNode;
  cta?: ReactNode;
};
export const BusinessTippingConfigurationCard = ({ icon, title, subtitle, tooltip, cta }: Props) =>
  <Paper
    sx={{
      padding: '1rem',
      borderRadius: '16px',
    }}
  >
    <Stack direction={'row'} gap={'16px'} alignItems={'center'}>
      <Paper
        sx={{
          maxHeight: '40px',
          padding: '8px',
          borderRadius: '4px',
          backgroundColor: palette.neutral.grey200,
        }}
      >
        {icon}
      </Paper>
      <Stack gap={'8px'}>
        <Stack direction={'row'} gap={'8px'} alignItems={'center'}>
          <Typography variant="body1" fontWeight="700">
            {title}
          </Typography>
          <SundayTooltip
            title={tooltip}
          >
            <InfoOutlinedIcon />
          </SundayTooltip>
        </Stack>
        <Typography variant="subtitle2" color="textSecondary">
          {subtitle}
        </Typography>
      </Stack>
      {cta && cta}
    </Stack>
  </Paper>;
