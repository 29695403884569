import { DateRange } from '../../domain/DateRange';
import { DimensionAnalysis, DimensionsAnalysis } from '../domain/DimensionsAnalysis';
import { Review } from '../../browse/domain/Review';
import { Instant } from '../../../Instant';
import { AverageRatingsViewModel, convertRatingsToTimeseriesData } from '../AverageRatingsViewModel';

export type ChartData = {
  period: DateRange;
  data: {
    FOOD_AND_DRINKS: (number | undefined)[];
    SERVICE: (number | undefined)[];
    AMBIANCE: (number | undefined)[];
    VALUE_FOR_MONEY: (number | undefined)[];
  };
};
export type LackOfData = 'LACK_OF_DATA';
type TimeFrameTooShort = 'TIME_FRAME_TOO_SHORT';

export type DimensionsTrendsViewModel = ChartData | LackOfData | TimeFrameTooShort;

export const isLackOfData = (viewModel: DimensionsTrendsViewModel): viewModel is LackOfData =>
  viewModel === 'LACK_OF_DATA';
export const isTimeFrameTooShort = (viewModel: DimensionsTrendsViewModel): viewModel is TimeFrameTooShort =>
  viewModel === 'TIME_FRAME_TOO_SHORT';

function averageForDimension(reviewsForDay: Review[], dimensionName: string): number | undefined {
  function average(numbers: number[]) {
    const sum = numbers.reduce((a, b) => a + b, 0);
    return Math.round((sum / numbers.length) * 100) / 100 || undefined;
  }

  return average(
    reviewsForDay.flatMap((review) =>
      review.dimensionRatings
        .filter((dimension) => dimension.dimension === dimensionName)
        .map((dimension) => dimension.rating),
    ),
  );
}

function averagesForDimensionPerDay(reviewsParDays: Review[][], dimension: string) {
  return reviewsParDays.map((reviewsForDay) => averageForDimension(reviewsForDay, dimension));
}

function datesAreAtTheSameDay(instant1: Instant, instant2: Instant) {
  return instant1.isSameDay(instant2);
}

function groupReviewsPerDay(period: DateRange, reviews: Review[]) {
  return period
    .allDays()
    .map((dayInPeriod) =>
      reviews.filter((review) => datesAreAtTheSameDay(Instant.fromEpoch(review.creationDate), dayInPeriod)),
    );
}

export const dimensionsTrendsViewModel = (
  dimensionsAnalysis: DimensionsAnalysis,
  period: DateRange,
): DimensionsTrendsViewModel => {
  if (period.numberOfDays() < 5) return 'TIME_FRAME_TOO_SHORT';
  if (dimensionsAnalysis.dimensions.length < 4) return 'LACK_OF_DATA';

  const periodHasLackOfData = dimensionsAnalysis.dimensions.filter((d) => d.numberOfReviews === 0).length === 4;
  const reviewsPerDay = groupReviewsPerDay(period, dimensionsAnalysis.reviews);
  return periodHasLackOfData
    ? 'LACK_OF_DATA'
    : {
      period,
      data: {
        FOOD_AND_DRINKS: averagesForDimensionPerDay(reviewsPerDay, 'FOOD_AND_DRINKS'),
        SERVICE: averagesForDimensionPerDay(reviewsPerDay, 'SERVICE'),
        AMBIANCE: averagesForDimensionPerDay(reviewsPerDay, 'AMBIANCE'),
        VALUE_FOR_MONEY: averagesForDimensionPerDay(reviewsPerDay, 'VALUE_FOR_MONEY'),
      },
    };
};

export const dimensionsTrendsViewModelForNewLayout = (
  dimensionsAnalysis: DimensionsAnalysis,
  period: DateRange,
  dimension: DimensionAnalysis,
): AverageRatingsViewModel => {
  if (period.numberOfDays() < 5) return 'TIME_FRAME_TOO_SHORT';
  if (dimensionsAnalysis.dimensions.length < 4) return 'LACK_OF_DATA';

  const reviewsPerDay = groupReviewsPerDay(period, dimensionsAnalysis.reviews);
  console.log(reviewsPerDay);
  return {
    period,
    data: {
      averageRating: convertRatingsToTimeseriesData(averagesForDimensionPerDay(reviewsPerDay, dimension.name), period),
    },
  };
};
