import React, { useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { RevenuePeriod, revenuesDatePresets } from '../../../revenues/domain/RevenuesDatePresets';
import { ExportDownloadError } from '../../../ExportDownloadError';
import { DownloadPayoutDetails } from './DownloadPayoutDetails';
import { DownloadPayoutPdqDetails } from './DownloadPayoutPdqDetails';
import { useConfiguration } from '../useConfiguration';
import { DownloadExport } from './DownloadExport';
import { TimeFrameFilter } from 'src/components/time-frame-filter/TimeFrameFilter';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

interface Props {
  timezone: string;
  period: RevenuePeriod;
}

export const PayoutsFilter: React.FC<Props> = ({
  timezone,
  period,
}: Props) => {
  const container = useConfiguration();
  const business = useCurrentBusinessOrThrow();
  const datePresets = revenuesDatePresets(timezone);

  const [inError, setInError] = useState<boolean>(false);
  const ref = useRef<number | null>(null);
  const onError = () => {
    if (ref.current) clearTimeout(ref.current);
    setInError(true);
    ref.current = window.setTimeout(() => {
      setInError(false);
    }, 5000);
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" mt={5} mb={5} gap={5}>
        <Box display="flex" alignItems="center">
          <Typography variant="body2" style={{ color: '#70707B' }} mr={2}>
            <FormattedMessage id="accounting.payout_tab.filter_label"
                              defaultMessage="filter by dates of operations" />
          </Typography>
          <TimeFrameFilter datePresets={datePresets} period={period} timezone={timezone} />
        </Box>
        <Box display="flex" alignItems="center" gap={8}>
          {business.address.countryCode === 'GB' &&
            <DownloadPayoutPdqDetails period={period} onError={onError} payoutPdqPort={container.payoutPdqPort} />
          }
          <DownloadPayoutDetails period={period} onError={onError} payoutPort={container.payoutPort} />
          <DownloadExport period={period} onError={onError} />
        </Box>
      </Box>

      {inError && <ExportDownloadError />}
    </>
  );
};
