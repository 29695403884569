import styled from 'styled-components';
import { spaceUsages } from 'src/app/theme/SpaceUsagesTheme';

export const UpdateUserRoleForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  min-width: 356px;
  gap: 1em;
`;

export const RoleLabelContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${spaceUsages.mediumSmall};
`;
