import React from 'react';
import { PendingTipsDispatchArray } from 'src/tips/dispatch/model/PendingTipsDispatch';
import { Box, Stack, Typography } from '@mui/material';
import { DispatchTips } from 'src/tips/dispatch/components/DispatchTips';
import { Staff } from 'src/tips/dispatch/model/Staff';
import { useIntl } from 'src/app/i18n/TypedIntl';

export const Dispatch = ({ tipsToDispatchArray, staff }: {
  tipsToDispatchArray: PendingTipsDispatchArray,
  staff: Staff[]
}) => {
  const intl = useIntl();
  return (
    <Stack spacing={1.5} direction={'column'}>
      <Box>
        <Typography variant={'h6'}>
          {`${tipsToDispatchArray.length} ${intl.formatMessage({ id: 'tips.sunday_pooling.dispatch.pending' })}`}
        </Typography>
      </Box>
      {tipsToDispatchArray.map((tipsToDispatch) => (
        <DispatchTips
          key={tipsToDispatch.id}
          tipsToDispatch={tipsToDispatch} staff={staff}/>
      ))}
    </Stack>
  );
};
