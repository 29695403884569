// eslint-disable-next-line @typescript-eslint/no-redeclare
import { colorUsage, IconWrapper, Panel, PencilSvg, PlusSvg } from '@sundayapp/b2b-react-component-library';
import React from 'react';
import styled from 'styled-components';
import { spaceUsages } from 'src/app/theme/SpaceUsagesTheme';
import { SundayText } from 'src/app/component/Text/Text';

export type MenuDetailProps = {
  label: string;
  inputValue?: string;
  onAction: () => void;
};

const MenuDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${spaceUsages.mediumLarge};
`;

const MenuDetailTitle = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
`;

const MenuDetailLabel = styled.div`
  display: flex;
  justify-content: start;
  width: 100%;
`;

const MenuDetailTool = styled.div`
  display: flex;
  justify-content: end;
  min-width: 88px;
  max-width: 88px;
`;

const MenuDetailInputPanel = ({ label, inputValue, onAction }: MenuDetailProps) => (
  <Panel paddingLeft={spaceUsages.mediumLarge} paddingRight={spaceUsages.mediumLarge}>
    <MenuDetailContainer>
      <MenuDetailTitle>
        <MenuDetailLabel>
          <SundayText size="xl">{label}</SundayText>
        </MenuDetailLabel>
        <MenuDetailTool>
          <IconWrapper size="small" cursor="pointer" onClick={onAction}>
            {inputValue ? <PencilSvg /> : <PlusSvg />}
          </IconWrapper>
        </MenuDetailTool>
      </MenuDetailTitle>
      {inputValue && (
        <SundayText isKeepCase size="small" color={colorUsage.secondaryText}>
          {inputValue}
        </SundayText>
      )}
    </MenuDetailContainer>
  </Panel>
);
export default MenuDetailInputPanel;
