import React from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { Button, Card, styled, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import { breakpoints, themeV5 } from 'src/app/theme/ThemeV5';
import { settingsReviewsPath } from 'src/app/navigation/pathHelpers';
import { BusinessId } from 'src/business/domain/Business';

type Props = {
  businessId: BusinessId;
};

const ReviewCard = styled(Card)`
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: black;
  min-height: 269px;

  @media (max-width: ${breakpoints.mobile}) {
    flex-direction: column;
  }

  @media (max-width: ${breakpoints.homeTablet}) {
    margin-bottom: 8px;
  }

  background-image: url('/img/reviews/review_marguerite.png');
  background-position: bottom right;
  background-size: 50%;
  background-repeat: no-repeat;
`;

const ReviewInfo = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 269px;
  margin-top: 20px;
  padding: 16px;
`;

const ReviewInfoTitle = styled('div')`
  color: ${themeV5.palette.text.disabled};
  margin-bottom: 16px;
`;

const ReviewInfoDescription = styled('div')`
  color: white;
  margin-bottom: 24px;
`;

const ReviewButton = styled(Button)`
  background-color: ${themeV5.palette.primary.main};
  letter-spacing: -0.3px;
  font-size: 14px;
  line-height: 16px;

  &:hover {
    background-color: ${themeV5.palette.primary.light};
  }
`;

export const HomeReviewsCardWithSubscriptionAndGoogleSetupNotFinished = ({ businessId }: Props) => {
  const navigate = useNavigate();
  return (
    <ReviewCard>
      <ReviewInfo data-testid="testGoogleSetupNotFinished">
        <ReviewInfoTitle>
          <Typography variant="caption">
            <FormattedMessage id="venue.home.reviews.subscribe.title" defaultMessage="Use sunday at is best" />
          </Typography>
        </ReviewInfoTitle>
        <ReviewInfoDescription>
          <Typography variant="h5">
            <FormattedMessage
              id="venue.home.reviews.subscribe.description"
              defaultMessage="Boost your online reputation and understand what your customers think"
            />
          </Typography>
        </ReviewInfoDescription>
        <ReviewButton color="primary" size="small" onClick={() => navigate(settingsReviewsPath(businessId))}>
          <Typography variant="subtitle2">
            <FormattedMessage id="venue.home.reviews.google_connect.cta" defaultMessage="Connect your google account" />
          </Typography>
        </ReviewButton>
      </ReviewInfo>
    </ReviewCard>
  );
};
