import { BusinessId } from 'src/business/domain/Business';
import axios from 'axios';
import { RelationType } from 'src/auth/domain/user';
import {
  BusinessRelationRepository,
  BusinessUserRelations,
  BusinessUserRelationsResponse,
} from 'src/domain/venue/BusinessRelationRepository';

export class HttpBusinessRelationRepository implements BusinessRelationRepository {
  constructor(private url: string) {}

  async listBusinessUserRelations(businessId: BusinessId): Promise<BusinessUserRelations> {
    try {
      const { data } = await axios.get<BusinessUserRelationsResponse>(
        `${this.url}/admin/enterprise/relations/${businessId}/entity`,
      );
      return data.relations.map(({ relation, user_id }) => ({
        userId: user_id,
        relation: relation as RelationType,
      }));
    } catch (error) {
      return [];
    }
  }
}
