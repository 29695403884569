export const SettingsIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        /* eslint-disable-next-line max-len */
        d="M14.9993 5.00004C14.0789 5.00004 13.3327 5.74623 13.3327 6.66671C13.3327 7.58718 14.0789 8.33337 14.9993 8.33337C15.9198 8.33337 16.666 7.58718 16.666 6.66671C16.666 5.74623 15.9198 5.00004 14.9993 5.00004ZM11.771 5.83337C12.1411 4.39569 13.4461 3.33337 14.9993 3.33337C16.8403 3.33337 18.3327 4.82576 18.3327 6.66671C18.3327 8.50766 16.8403 10 14.9993 10C13.4461 10 12.1411 8.93773 11.771 7.50004L2.49935 7.50004C2.03911 7.50004 1.66602 7.12694 1.66602 6.66671C1.66602 6.20647 2.03911 5.83337 2.49935 5.83337L11.771 5.83337ZM4.99935 11.6667C4.07887 11.6667 3.33268 12.4129 3.33268 13.3334C3.33268 14.2538 4.07887 15 4.99935 15C5.91982 15 6.66602 14.2538 6.66602 13.3334C6.66602 12.4129 5.91982 11.6667 4.99935 11.6667ZM1.66602 13.3334C1.66602 11.4924 3.1584 10 4.99935 10C6.55255 10 7.85763 11.0624 8.22767 12.5L17.4993 12.5C17.9596 12.5 18.3327 12.8731 18.3327 13.3334C18.3327 13.7936 17.9596 14.1667 17.4993 14.1667L8.22767 14.1667C7.85763 15.6044 6.55255 16.6667 4.99935 16.6667C3.1584 16.6667 1.66602 15.1743 1.66602 13.3334Z"
        fill="white"
        fillOpacity="0.56"
      />
    </svg>
  );
};
