import styled from 'styled-components';
import { InputText, Panel } from '@sundayapp/b2b-react-component-library';
import { breakpoints } from 'src/app/theme/ThemeV5';
import { spaceUsages } from 'src/app/theme/SpaceUsagesTheme';

export const BillsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: auto;
  gap: ${spaceUsages.mediumSmall};
  @media (max-width: ${breakpoints.mobile}) {
    width: fit-content;
  }
`;

export const BillsContainer = styled(Panel)`
  background: white;
  padding: ${spaceUsages.mediumSmall};
  flex-grow: 1;
`;

export const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  padding: 15px;
  align-items: center;
`;

export const SearchTextWrapper = styled(InputText)`
  justify-content: flex-end;
  width: 240px;
`;

export const OrderDetailWrapper = styled.div`
  position: sticky;
  top: 0;
  height: max-content;
`;
