import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { Box, Button, CircularProgress, Modal, Stack, styled, Typography, useTheme } from '@mui/material';
import GoogleWithoutStyle from '../../../../../../pages/assets/google.svg?react';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { SettingContainer, TextContainer } from '../../../ReviewsSettings.styles';
import { GoogleReviewDemoAccountSetup } from './GoogleReviewDemoAccountSetup';
import { styleButton, styleModal } from '../../../../../modal.style';
import { useBusinessIdOrThrow } from 'src/business/hooks/useBusinessId';
import { GoogleSetupWizard } from 'src/sundayplus/reviews/settings/setup/google/components/GoogleSetupWizard';
import { useGoogleSetupLocation } from 'src/sundayplus/reviews/settings/setup/google/infrastruscture/useGoogleSetupLocation';
import { GoogleOAuthFacadeProvider } from 'src/sundayplus/reviews/settings/setup/google/oauth-facade/GoogleOAuthFacadeProvider';
import { useLinkGoogleAccountToSunday } from 'src/sundayplus/reviews/settings/setup/google/infrastruscture/useLinkGoogleAccountToSunday';
import { RequireConnectedGoogleAccount } from 'src/sundayplus/reviews/settings/setup/google/components/RequireConnectedGoogleAccount';

export const Google = styled(GoogleWithoutStyle)`
  min-width: 24px;
  min-height: 24px;
  margin: 4px;
`;
export const LogoWrapper = styled('div')`
  display: flex;
  align-items: center;
`;

const GoogleConnected = () => {
  const { formatMessage } = useIntl();

  const [isOpened, setIsOpened] = useState(false);
  const { unlink } = useLinkGoogleAccountToSunday();

  const disconnect = async () => {
    await unlink();
    setIsOpened(false);
  };

  return (
    <>
      <Button size="medium" variant="outlined" onClick={() => setIsOpened(true)}>
        <FormattedMessage id="settings.reviews_and_loyalty.platform_setup.disconnect" defaultMessage="disconnect" />
      </Button>

      <Modal open={isOpened} onClose={() => setIsOpened(false)} aria-labelledby="modal-title">
        <Box marginTop={2} sx={{
          ...styleModal,
          width: 500,
        }}>
          <Stack direction="column" gap={3.5}>
            <Typography variant="h5" id="modal-title">
              {formatMessage({ id: 'menus.delete_menu.modal.name' })}
            </Typography>
            <Typography variant="h6">
              <FormattedMessage id="settings.reviews_and_loyalty.platform_setup.disconnect.confirmation" />
            </Typography>
            <Stack direction="column" gap={1.5} marginTop={3}>
              <Button size="large" variant="contained" style={{ ...styleButton }} onClick={() => disconnect()}>
                <FormattedMessage id="modal.confirm" defaultMessage="confirm" />
              </Button>
              <Button size="large" variant="outlined" style={{ ...styleButton }} onClick={() => setIsOpened(false)}>
                <FormattedMessage id="modal.cancel" defaultMessage="cancel" />
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

type Props = {
  isInitialSetup?: boolean;
};

export const GoogleReviewSetupTile = ({ isInitialSetup }: Props) => {
  const configuration = ConfigurationLoader.load();
  const businessId = useBusinessIdOrThrow();
  const theme = useTheme();

  const [showGoogleLocationsModal, setShowGoogleLocationsModal] = React.useState(false);

  const {
    data: businessLocation,
    isLoading: locationIsLoading,
  } = useGoogleSetupLocation(businessId);

  if (locationIsLoading) {
    return <CircularProgress />;
  }

  return (
    <GoogleOAuthFacadeProvider>
      {(configuration.envName ?? '') === 'demo' && <GoogleReviewDemoAccountSetup />}
      <RequireConnectedGoogleAccount isInitialSetup={isInitialSetup}>
        <SettingContainer>
          <TextContainer>
            <LogoWrapper>
              <Google />
              <Typography variant="h6">Google</Typography>
            </LogoWrapper>
            <Typography variant="caption" color={theme.palette.text.disabled}>
              <FormattedMessage id="settings.reviews_and_loyalty.platform_setup.connected.caption" />
              {!businessLocation && <>.</>}
              {businessLocation && (
                <>
                  {' '}
                  :{' '}
                  <Typography variant="caption" color={theme.palette.text.primary}>
                    {businessLocation.name},{' '}{businessLocation.address}.
                  </Typography>
                </>
              )}
            </Typography>
          </TextContainer>
          <Button onClick={() => setShowGoogleLocationsModal(true)} variant="outlined">
            <FormattedMessage id="settings.reviews_and_loyalty.platform_setup.change_location" />
          </Button>
          {showGoogleLocationsModal && <GoogleSetupWizard close={() => setShowGoogleLocationsModal(false)} />}
          {!isInitialSetup && (<GoogleConnected />)}
        </SettingContainer>
      </RequireConnectedGoogleAccount>
    </GoogleOAuthFacadeProvider>
  );
};
