import { Box, Switch, Typography } from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import * as React from 'react';
import { FC, useLayoutEffect, useState } from 'react';
import { UpdateProductDto } from '../../../repositories/ProductDto';
import { Product } from '../../../domain/Product';
import { themeV5 } from 'src/app/theme/ThemeV5';

type Props = {
  product: Product;
  productUpdate: UpdateProductDto;
  setProductUpdate: (productDto: UpdateProductDto) => void;
};

export const IsEnabledSwitch: FC<Props> = ({ product, productUpdate, setProductUpdate }) => {
  const [isEnabled, setIsEnabled] = useState(product.isEnabled);

  useLayoutEffect(() => {
    setIsEnabled(product.isEnabled);
  }, [product.isEnabled]);

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box display="flex" flexDirection="column">
        <Typography variant="body1">
          <FormattedMessage id="menus.product.details.is_enabled" />
        </Typography>
        <Typography color={themeV5.palette.text.secondary} variant="subtitle2">
          <FormattedMessage id="menus.product.details.is_enabled.subtitle" />
        </Typography>
      </Box>
      <Switch
          checked={isEnabled}
          onChange={(event) => {
            setProductUpdate({ ...productUpdate, isEnabled: event.target.checked });
            setIsEnabled(event.target.checked);
          }}
        />
    </Box>
  );
};
