import React, { useState } from 'react';
import { Mapping, MappingKey, ProductPaymentMethodMapping } from 'src/domain/pos-connection/PaymentMethodMapping';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  FormControlLabel,
  Radio,
  Typography,
} from '@mui/material';
import Grid2 from '@mui/material/Grid2';
import { SelectPosPaymentMethod } from 'src/pages/Settings/PaymentMethodMapping/SelectPosPaymentMethod';
import { PosPaymentMethod } from 'src/domain/pos-connection/PosPaymentMethods';
import { LocalisationKey } from 'src/lang/en';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import AddIcon from '@mui/icons-material/Add';
import { PaymentMethodBrand, PaymentMethodIcon } from 'src/accounting/common/Icons/PaymentMethodIcon';
import { AddMappingDialog } from './AddMappingDialog';
import { styled } from '@mui/material/styles';

export type ProductPaymentMethodMappingComponentProps = {
  product: ProductPaymentMethodMapping;
  availablePaymentMethods: PosPaymentMethod[];
  onProductMappingChanged: (product: ProductPaymentMethodMapping) => void;
};

export const paymentMethodNameLocalizations: Record<string, LocalisationKey> = {
  ['SUNDAY']: 'settings.posPaymentMethod.method.sunday',
  ['CARD']: 'settings.posPaymentMethod.method.card',
  ['CASH']: 'settings.posPaymentMethod.method.cash',
  ['AMEX']: 'settings.posPaymentMethod.method.amex',
  ['AMEX_KAPN']: 'settings.posPaymentMethod.method.amex_kapn',
  ['DISCOVER']: 'settings.posPaymentMethod.method.discover',
  ['BIMPLI']: 'settings.posPaymentMethod.method.bimpli',
  ['CB']: 'settings.posPaymentMethod.method.cb',
  ['EDENRED']: 'settings.posPaymentMethod.method.edenred',
  ['ELECTRON']: 'settings.posPaymentMethod.method.electron',
  ['KADEOS']: 'settings.posPaymentMethod.method.kadeos',
  ['MAESTRO']: 'settings.posPaymentMethod.method.maestro',
  ['MASTERCARD']: 'settings.posPaymentMethod.method.mastercard',
  ['MASTERCARD_DEBIT']: 'settings.posPaymentMethod.method.mastercard_debit',
  ['NATIXIS_INTERTITRES']: 'settings.posPaymentMethod.method.natixis',
  ['PLUXEE']: 'settings.posPaymentMethod.method.pluxee',
  ['SWILE']: 'settings.posPaymentMethod.method.swile',
  ['UP_DEJ']: 'settings.posPaymentMethod.method.updej',
  ['VISA']: 'settings.posPaymentMethod.method.visa',
  ['VISA_DEBIT']: 'settings.posPaymentMethod.method.visa_debit',
  ['CHEQUE_VACANCES']: 'settings.posPaymentMethod.method.chequevacances',
  ['CHEQUE_DEJEUNER']: 'settings.posPaymentMethod.method.chequedejeuner',
  ['JCB']: 'settings.posPaymentMethod.method.jcb',
  ['CONECS']: 'settings.posPaymentMethod.method.conecs',
  ['DCC']: 'settings.posPaymentMethod.method.dcc',
  ['CB_CONTACTLESS']: 'settings.posPaymentMethod.method.cb_contactless',
  ['LOYALTY_REWARD_APPLIED_VIA_SUNDAY']: 'settings.posPaymentMethod.method.loyalty_reward_applied_via_sunday',
};

export const paymentMethodBrandMapping: Record<string, PaymentMethodBrand> = {
  ['SUNDAY']: 'sunday',
  ['AMEX']: 'amex',
  ['AMEX_KAPN']: 'amex',
  ['BIMPLI']: 'bimpli',
  ['CB']: 'carteBancaires',
  ['EDENRED']: 'edenred',
  ['ELECTRON']: 'electron',
  ['MAESTRO']: 'maestro',
  ['MASTERCARD']: 'mastercard',
  ['MASTERCARD_DEBIT']: 'mastercard',
  ['NATIXIS_INTERTITRES']: 'bimpli',
  ['PLUXEE']: 'pluxee',
  ['SWILE']: 'swile',
  ['UP_DEJ']: 'up',
  ['VISA']: 'visa',
  ['VISA_DEBIT']: 'visa',
  ['CHEQUE_DEJEUNER']: 'up',
  ['JCB']: 'jcb',
  ['DISCOVER']: 'discover',
  ['CASH']: 'cash',
  ['CB_CONTACTLESS']: 'carteBancaires',
  ['LOYALTY']: 'reward',
};
const getLocalizationKey = (mappingKey: MappingKey) => {
  // If there's a qualifier, use it for localization
  if (mappingKey.qualifier) {
    return paymentMethodNameLocalizations[mappingKey.qualifier] || mappingKey.qualifier;
  }

  // Otherwise, use the default mappings
  if (mappingKey.paymentMethod === 'PAT' && !mappingKey.qualifier) {
    return paymentMethodNameLocalizations.SUNDAY;
  } else if (mappingKey.paymentMethod === 'PDQ' && !mappingKey.qualifier) {
    return paymentMethodNameLocalizations.CARD;
  } else if (mappingKey.paymentMethod === 'LOYALTY' && !mappingKey.qualifier) {
    return paymentMethodNameLocalizations.LOYALTY_REWARD_APPLIED_VIA_SUNDAY;
  }
  return paymentMethodNameLocalizations[mappingKey.paymentMethod] || mappingKey.paymentMethod;
};

export const getBrandForMappingKey = (mappingKey: MappingKey): PaymentMethodBrand => {
  if (mappingKey.paymentMethod === 'PAT' && !mappingKey.qualifier) {
    return paymentMethodBrandMapping.SUNDAY;
  }
  if (mappingKey.paymentMethod === 'CASH' && !mappingKey.qualifier) {
    return paymentMethodBrandMapping.CASH;
  }
  if (mappingKey.paymentMethod === 'LOYALTY' && !mappingKey.qualifier) {
    return paymentMethodBrandMapping.LOYALTY;
  }
  return paymentMethodBrandMapping[mappingKey.qualifier] || 'generic';
};


const GridCell = styled(Grid2)`
  border-bottom: 1px solid #ccc;
  padding: 10px;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const GridHeader = styled(Grid2)`
  color: #5e5e66;
`;

export const ProductPaymentMethodMappingComponent = ({
  product,
  availablePaymentMethods,
  onProductMappingChanged,
}: ProductPaymentMethodMappingComponentProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const defaultValueForDefaultMode = !product.mappings.find(value => value.mappingKey.qualifier && value.value);
  const isDefaultMode = product.defaultMode ?? defaultValueForDefaultMode;


  const handleChangeDefaultMode = (defaultMode: boolean) => {
    const newProduct = { ...product, defaultMode: defaultMode };
    onProductMappingChanged(newProduct);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleUpdateMapping = (newMapping: Mapping) => {
    const newMappings = product.mappings.map((mapping) => mapping.mappingKey === newMapping.mappingKey ? newMapping : mapping);
    onProductMappingChanged({ ...product, mappings: newMappings });
  };

  const addNewPaymentMethod = () => {
    setDialogOpen(true);
  };

  return (
      <>
        <Accordion expanded={isDefaultMode} onChange={() => handleChangeDefaultMode(true)}
                   sx={{ border: '1px solid lightgray' }}>
          <AccordionSummary aria-controls="defaultd-content" id="defaultd-header">
            <Box display="flex" alignItems="center" gap={1}>
              <FormControlLabel
                value="default"
                control={
                  <Radio
                    checked={isDefaultMode}
                    value="default"
                    name="radio-buttons"
                    size="medium"
                  />
                }
                label=""
                sx={{ margin: 0 }}
              />
              <Typography component="span" variant="subtitle1" fontWeight="bold">
                Default
              </Typography>
              <Chip size="medium" color="primary" label="Recommended" sx={{ fontSize: '0.75rem' }} />
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Grid2 container>
              <GridHeader size={{ xs: 6 }}>
                <Typography>
                  Payment method
                </Typography>
              </GridHeader>
              <GridHeader size={{ xs: 6 }}>
                <Typography>
                  How it will appear in the POS
                </Typography>
              </GridHeader>
              {product.mappings
                .filter((m) => !m.mappingKey.qualifier)
                .map((mapping, index) => (
                  <React.Fragment key={index}>
                    <GridCell size={{ xs: 6 }}>
                      <Box display="flex" alignItems="center" gap={1}>
                        <PaymentMethodIcon brand={getBrandForMappingKey(mapping.mappingKey)} />
                        <Typography>
                          <FormattedMessage id={getLocalizationKey(mapping.mappingKey)} />
                        </Typography>
                      </Box>
                    </GridCell>
                    <GridCell size={{ xs: 6 }}>
                      <SelectPosPaymentMethod
                        productId={product.id}
                        mappingKey={mapping.mappingKey}
                        availablePaymentMethods={availablePaymentMethods}
                        selectedValue={mapping.value}
                        onSelectedValueChange={(value: string | undefined) => {
                          handleUpdateMapping({ ...mapping, value });
                        }}
                      />
                    </GridCell>
                  </React.Fragment>
                ))}
            </Grid2>
          </AccordionDetails>
        </Accordion>
        {product.mappings.some((m) => m.mappingKey.qualifier) && (
          <Accordion expanded={!isDefaultMode} onChange={() => handleChangeDefaultMode(false)}
                     sx={{ border: '1px solid lightgray' }}>
            <AccordionSummary aria-controls="customd-content" id="customd-header">
              <Box display="flex" alignItems="center" gap={1}>
                <FormControlLabel
                  value="custom"
                  control={
                    <Radio
                      checked={!isDefaultMode}
                      value="custom"
                      name="radio-buttons"
                      size="medium"
                    />
                  }
                  label=""
                  sx={{ margin: 0 }}
                />
                <Typography component="span" variant="subtitle1" fontWeight="bold">
                  Custom
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid2 container>
                <GridHeader size={{ xs: 6 }}>
                  <Typography>
                    Payment method
                  </Typography>
                </GridHeader>
                <GridHeader size={{ xs: 6 }}>
                  <Typography>
                    How it will appear in the POS
                  </Typography>
                </GridHeader>
                {product.mappings
                  .filter((m) => m.value || !m.mappingKey.qualifier)
                  .map((mapping, index) => (
                    <React.Fragment key={index}>
                      <GridCell size={{ xs: 6 }}>
                        <Box display="flex" alignItems="center" gap={1}>
                          <PaymentMethodIcon brand={getBrandForMappingKey(mapping.mappingKey)} />
                          <Typography>
                            <FormattedMessage id={getLocalizationKey(mapping.mappingKey)} />
                          </Typography>
                        </Box>
                      </GridCell>
                      <GridCell size={{ xs: 6 }}>
                        <SelectPosPaymentMethod
                          productId={product.id}
                          mappingKey={mapping.mappingKey}
                          availablePaymentMethods={availablePaymentMethods}
                          selectedValue={mapping.value}
                          onSelectedValueChange={(value: string | undefined) => {
                            handleUpdateMapping({ ...mapping, value });
                          }}
                        />
                      </GridCell>
                    </React.Fragment>
                  ))}
                <Grid2 size={{ xs: 12 }}>
                  <Button
                    sx={{ marginTop: '20px' }}
                    variant="contained"
                    onClick={() => addNewPaymentMethod()}
                    startIcon={<AddIcon />}
                  >
                    Add new mapping
                  </Button>
                </Grid2>
              </Grid2>
            </AccordionDetails>
          </Accordion>
        )}
        <AddMappingDialog
          open={dialogOpen}
          onClose={handleDialogClose}
          onAddMapping={handleUpdateMapping}
          availableMappingKeys={product.mappings
            .filter((value) => value.mappingKey.qualifier && !value.value)
            .map((value) => value.mappingKey)}
          availablePaymentMethods={availablePaymentMethods}
        />
      </>
  );
}
;
