import { InputText, Modal } from '@sundayapp/b2b-react-component-library';
import { Button } from '@mui/material';
import styled from 'styled-components';
import React from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useUpdateStaticMenu } from '../../infrastructure/mutations/useUpdateStaticMenu';
import { useBusinessIdOrThrow } from 'src/business/hooks/useBusinessId';
import { StaticMenu } from '../../domain/StaticMenus';
import { spaceUsages } from 'src/app/theme/SpaceUsagesTheme';

type EditDescriptionModalProps = {
  createMode: boolean;
  isOpened: boolean;
  onClose: () => void;
  selectedMenu: StaticMenu;
};

type EditDescriptionModalInputs = {
  description: string;
};

export const EditDescriptionModalForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const EditDescriptionModal = ({ createMode, isOpened, onClose, selectedMenu }: EditDescriptionModalProps) => {
  const businessId = useBusinessIdOrThrow();
  const { formatMessage } = useIntl();
  const { updateStaticMenu } = useUpdateStaticMenu();

  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm<EditDescriptionModalInputs>({
    defaultValues: { description: selectedMenu.descriptionDetails },
    mode: 'all',
    criteriaMode: 'all',
  });

  const onSubmit: SubmitHandler<EditDescriptionModalInputs> = async ({ description }) => {
    const menu = {
      ...selectedMenu,
      descriptionDetails: description,
    };
    await updateStaticMenu(businessId, menu);
    onClose();
  };

  return (
    <Modal
      title={
        createMode
          ? formatMessage({ id: 'menus.edition.modal.add_description' })
          : formatMessage({ id: 'menus.edition.modal.update_description' })
      }
      cancelButtonMessage={formatMessage({ id: 'modal.cancel' })}
      isOpened={isOpened}
      onClose={onClose}
    >
      <EditDescriptionModalForm onSubmit={handleSubmit(onSubmit)}>
        <InputText
          label={formatMessage({ id: 'menus.edition.form.description' })}
          placeholder={formatMessage({ id: 'menus.edition.form.description.placeholder' })}
          id="menuDescription"
          {...register('description')}
          maxLength={320}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          disabled={!isValid || isSubmitting}
          sx={{ justifyContent: 'center', marginTop: spaceUsages.large, textTransform: 'lowercase', width: '100%' }}
        >
          <FormattedMessage id="menus.creation.form.submit" />
        </Button>
      </EditDescriptionModalForm>
    </Modal>
  );
};

export default EditDescriptionModal;
