import { differenceMoney, Money, sumMoneys } from '@sundayapp/web-money';

export type TipsSummaryByWaiter = {
  waiterName: string | undefined,
  tipsCount: number,
  directTipsAmount: Money;
  directTipsFee: Money;
  posPooledAmount: Money;
  sundayPooledTipsAmount: Money;
  sundayPooledTipsFee: Money;
};

export const totalGrossTips = (tips: TipsSummaryByWaiter): Money => sumMoneys(tips.directTipsAmount, tips.sundayPooledTipsAmount, tips.posPooledAmount);
const totalTipsFee = (tips: TipsSummaryByWaiter): Money => sumMoneys(tips.directTipsFee, tips.sundayPooledTipsFee);
export const totalNetTips = (tips: TipsSummaryByWaiter): Money => differenceMoney(totalGrossTips(tips), totalTipsFee(tips));

export const totalNetDirectTips = (tips: TipsSummaryByWaiter): Money => differenceMoney(tips.directTipsAmount, tips.directTipsFee);

export const totalPooledTips = (tips: TipsSummaryByWaiter): Money => sumMoneys(tips.posPooledAmount, tips.sundayPooledTipsAmount);
export const totalNetPooledTips = (tips: TipsSummaryByWaiter): Money => differenceMoney(totalPooledTips(tips), tips.sundayPooledTipsFee);

export const emphasisSpaceInWaiterName = (tips: TipsSummaryByWaiter): string | undefined => tips.waiterName !== undefined ? tips.waiterName.replace(/^\s+|\s+$/g, '␣') : undefined;
