import { BusinessId, Timezone } from 'src/business/domain/Business';
import { GoogleIcon } from 'src/staff-performance/assets/GoogleIcon';
import { Box } from '@mui/material';
import { NewHomeCard } from 'src/home/components/HomeCardv2';
import React from 'react';
import { useNavigate } from 'react-router';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { useGoogleSetupStageTermination } from 'src/sundayplus/reviews/settings/setup/google/infrastruscture/useGoogleSetupStageTermination';
import { HomeReviewsCardWithSubscriptionAndGoogleSetupNotFinished } from 'src/home/components/reviews/HomeReviewsCardWithSubscriptionAndGoogleSetupNotFinished';
import { EmptyCard } from 'src/home/components/reviews/EmptyCard';
import { VenueKpi } from 'src/home/domain/VenueKpi';
import { round } from 'lodash';

interface HomeReviewsCardV2Props {
  businessId: BusinessId;
  businessTimezone: Timezone;
  venueKpi: VenueKpi;
}

export const HomeReviewsCard = ({ businessId, venueKpi }: HomeReviewsCardV2Props) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const { isFinished: googleSetupFinished, loading } = useGoogleSetupStageTermination(businessId);

  if (!googleSetupFinished) {
    return <HomeReviewsCardWithSubscriptionAndGoogleSetupNotFinished businessId={businessId} />;
  }

  if (loading || !venueKpi) {
    return <EmptyCard />;
  }

  const reviewsTrend = venueKpi.n5StarsGoogleReviewsFromSunday - venueKpi.n5StarsGoogleReviewsFromSundayLastWeek || 0;
  const ratingDifference = round(venueKpi.averageGoogleRatingWithSunday - venueKpi.averageRating, 2) || 0;
  const signSymbol = ratingDifference && ratingDifference >= 0 ? '+' : '';

  const displayRatingIncrease =
    ratingDifference === Number.POSITIVE_INFINITY ? '' : `${signSymbol}${ratingDifference}pts`;
  return (
    <NewHomeCard
      handleCardRedirect={() => navigate(`/venues/${businessId}/sunday-plus/insights`)}
      subTitle={'Coming from sunday'}
      title={intl.formatMessage({ id: 'venue.home.reviews.fiveStarsFromSunday' })}
      titleIcon={<GoogleIcon height={'24'} width={'24'} />}
      trend={reviewsTrend}
      value={venueKpi.nRatingsGoogleFromSunday ?? 0}
      subItems={[
        {
          label: (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <GoogleIcon height={'24'} width={'24'} /> {intl.formatMessage({ id: 'venue.home.reviews.averageRating' })}
            </Box>
          ),
          value: `${venueKpi.averageGoogleRatingWithSunday}★`,
        },
        {
          label: (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <GoogleIcon height={'24'} width={'24'} />{' '}
              {intl.formatMessage({ id: 'venue.home.reviews.rating.increase.sunday' })}
            </Box>
          ),
          value: `${displayRatingIncrease}`,
        },
      ]}
    />
  );
};
