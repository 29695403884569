import React, { useEffect } from 'react';
import { Button, Slider } from '@sundayapp/b2b-react-component-library';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import Cropper from 'react-easy-crop';
import { Box } from '@mui/material';
import { useCropTool } from './useCropTool';
import { useColorPicker } from './useColorPicker';
import { ColorSelector } from './ColorSelector';
import { LoaderContainer } from './CropTool.styles';
import { prominent } from 'color.js';
import { spaceUsages } from 'src/app/theme/SpaceUsagesTheme';

type Shape = 'round' | 'rect';

interface CropArea {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface CropPictureData {
  shape: Shape;
  image: HTMLImageElement;
  background?: {
    color: string;
    alpha?: number;
  };
  area: CropArea;
}

export interface AdditionalGuides {
  guidesComponent: React.ReactElement;
  guideSettings: React.ReactElement;
}

interface CropToolProps {
  picture: string;
  submitCropData: (crop: CropPictureData) => void;
  aspect: number;
  cropShape: 'rect' | 'round';
  colorSelector: boolean;
  backgroundColor?: string;
  additionalGuides?: AdditionalGuides;
}

export const MAX_SIZE = 400;

export function CropTool({
  picture,
  submitCropData,
  aspect,
  cropShape,
  colorSelector,
  backgroundColor,
  additionalGuides,
}: CropToolProps) {
  const {
    background,
    palette,
    setPalette,
    setColor,
    togglePickingColor,
    isPickingColor,
    stopPickingColor,
    setPickingColor,
  } = useColorPicker();
  const {
    pictureImage,
    imageContainerRef,
    crop,
    setCrop,
    zoom,
    setZoom,
    onCropComplete,
    onCropperToolLoaded,
    cropData,
  } = useCropTool(cropShape, picture, isPickingColor, setPickingColor, background);

  useEffect(() => {
    if (pictureImage.src === '') return;

    prominent(pictureImage, { amount: 7, format: 'hex', group: 30 }).then((colors) => setPalette(colors as string[]));
  }, [pictureImage]);

  const save = async () => {
    submitCropData(cropData);
  };

  const backgroundColorForCropTool = backgroundColor ? { containerStyle: { backgroundColor: backgroundColor } } : {};

  return (
    <>
      {pictureImage.src !== '' ? (
        <>
          <Box
            style={{
              height: `${MAX_SIZE}px`,
              width: `${MAX_SIZE}px`,
              position: 'relative',
              backgroundColor: background.color,
              overflow: 'hidden',
            }}
            ref={imageContainerRef}
            onClick={stopPickingColor}
          >
            <Cropper
              image={pictureImage.src}
              crop={crop}
              minZoom={0.1}
              zoom={zoom}
              aspect={aspect}
              cropSize={cropShape === 'round' ? { width: MAX_SIZE * 0.75, height: MAX_SIZE * 0.75 } : undefined}
              cropShape={cropShape}
              onMediaLoaded={onCropperToolLoaded}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              restrictPosition={false}
              showGrid={false}
              style={{ ...backgroundColorForCropTool }}
              objectFit={pictureImage.height > pictureImage.width ? 'vertical-cover' : 'horizontal-cover'}
            />
            {additionalGuides?.guidesComponent}
          </Box>
          <Box style={{ marginTop: spaceUsages.largeMedium }}>
            <Slider
              value={zoom}
              min={0.1}
              max={3}
              step={0.01}
              handleChange={(value: number | readonly number[]) => {
                setZoom(Array.isArray(value) ? value[0] : value);
              }}
            />
            {additionalGuides?.guideSettings}
          </Box>
          {colorSelector && (
            <ColorSelector
              palette={palette}
              background={background.color}
              setColor={setColor}
              togglePickingColor={togglePickingColor}
            />
          )}
        </>
      ) : (
        <LoaderContainer maxSize={MAX_SIZE}>
          <h2>
            <FormattedMessage id="settings.app_settings.loading" />
          </h2>
        </LoaderContainer>
      )}

      <div style={{ marginTop: '1em' }}>
        <Button size="large" variant="primary" onClick={save} disabled={pictureImage.src === ''} fullWidth>
          <FormattedMessage id="settings.app_settings.custom_link.save" />
        </Button>
      </div>
    </>
  );
}
