import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { useRemoveGuestAppConfiguration } from 'src/guest-app/queries/useRemoveGuestAppConfiguration';

export const ConfigurationHeader = ({ enrollmentId }: { enrollmentId: string }) => {
  const { formatMessage } = useIntl();
  const snackBar = useSnackbar();
  const [visible, setVisible] = useState(false);
  const removeConfiguration = useRemoveGuestAppConfiguration(enrollmentId);

  const onClick = async () => {
    try {
      const result = await removeConfiguration.mutateAsync();
      if (result) {
        snackBar.addNotification({
          variant: 'success',
          text: formatMessage({ id: 'guest-app.remove.success' }),
        });
      } else {
        snackBar.addNotification({
          variant: 'error',
          text: formatMessage({ id: 'guest-app.configuration.errors.other' }),
        });
      }
      setVisible(false);
    } catch (error: any) {
      snackBar.addNotification({
        variant: 'error',
        text: formatMessage({ id: 'guest-app.configuration.errors.other' }),
      });
      setVisible(false);
    }
  };

  return (
    <Box padding="8px" display="flex" flexDirection="row" justifyContent="space-between">
      <Typography variant="h5">{formatMessage({ id: 'guest-app.configuration.title' })}</Typography>
      <Button variant="contained" onClick={() => setVisible(true)}>
        {formatMessage({ id: 'guest-app.remove.action' })}
      </Button>
      <Dialog
        open={visible}
        onClose={() => {
          setVisible(false);
        }}
      >
        <DialogTitle color="error">{formatMessage({ id: 'guest-app.remove.title' })}</DialogTitle>
        <DialogContent>
          <DialogContentText>{formatMessage({ id: 'guest-app.remove.content' })}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="error" onClick={onClick}>
            {formatMessage({ id: 'guest-app.configuration.continue' })}
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setVisible(false);
            }}
          >
            {formatMessage({ id: 'guest-app.configuration.cancel' })}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
