import { Divider, FormControl, InputLabel, Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { Business, BusinessId, isSuspended } from 'src/business/domain/Business';
import { useAllAuthorizedBusinesses } from 'src/business/hooks/useAllAuthorizedBusinesses';

type Props = {
  selectedBusiness: Business,
  onSelectBusiness: (selectedBusiness: Business) => void
};

export const SelectMenuSourceStep = (
  {
    selectedBusiness,
    onSelectBusiness,
  }: Props,
) => {
  const authorizedBusinesses = useAllAuthorizedBusinesses()
    .map((query) => query.data)
    .filter((business) => business !== undefined)
    .sort((a, b) => a.name.localeCompare(b.name))
    .sort((business) => (isSuspended(business) ? 1 : -1));

  const selectBusinessOfId = (businessId: BusinessId) => {
    const selection = authorizedBusinesses.find((business) => business.id === businessId)!!;
    onSelectBusiness(selection);
  };

  return (
    <>
      <Divider>
        <FormattedMessage id="common.from" />
      </Divider>
      <FormControl style={{ minWidth: '150px' }}>
        <InputLabel id="select-target-business-label">
          <FormattedMessage id="configuration_replication.select_source.source" />
        </InputLabel>
        <Select
          labelId="select-target-business-label"
          id="select-target-business"
          label={<FormattedMessage id="configuration_replication.select_source.source" />}
          value={selectedBusiness.id}
          onChange={(event) => selectBusinessOfId(event.target.value as BusinessId)}
        >
          {authorizedBusinesses.map((business) => (
            <MenuItem key={business.id} value={business.id}>
              {business.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};
