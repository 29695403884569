import React, { ReactNode } from 'react';
import { useCanManageTips } from 'src/tips/hooks/useCanManageTips';
import { Box, CircularProgress, Paper, Typography, useTheme } from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { useListBusinessUserRelations } from 'src/infrastructure/venue/useListBusinessUserRelations';
import { useListBusinessUsersQuery } from 'src/pages/Settings/UserPermissions/query/useListBusinessUsersQuery';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

type Props = {
  children: ReactNode;
};

export const CannotManageTipsPage = () => {
  const theme = useTheme();
  const business = useCurrentBusinessOrThrow();
  const { data: businessUsers, isLoading: isBusinessUsersLoading } = useListBusinessUsersQuery(business.id);
  const { data: businessUserRelations, isLoading: isBusinessUserRelationsLoading } = useListBusinessUserRelations(
    business.id,
  );

  if (isBusinessUserRelationsLoading || isBusinessUsersLoading || !businessUserRelations || !businessUsers) {
    return <CircularProgress />;
  }
  const userIds = businessUserRelations
    .filter(({ relation }) => relation === 'can_manage_tips')
    .map(({ userId }) => userId);

  const tipsManagers = businessUsers
    .filter((businessUser) => userIds.includes(businessUser.userId) && businessUser.firstName && businessUser.lastName)
    .map((user) => `${user.lastName} ${user.firstName}`)
    .join(', ');

  return (
    <Paper sx={{ borderRadius: '10px', padding: '20px' }}>
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap="30px">
        <img src="/tips_restricted_access.png" alt="access restricted" style={{ maxWidth: '500px' }} />

        <Box gap="16px" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <Typography variant="h5">
            <FormattedMessage id="tips.no_access.title" />
          </Typography>
          <Typography
            style={{
              whiteSpace: 'break-spaces',
            }}
            textAlign={'center'}
            variant="body1"
            color={theme.palette.text.secondary}
          >
            <FormattedMessage
              id="tips.no_access.subtitle"
              values={{
                tipsManager: <b>({tipsManagers})</b>,
              }}
            />
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

const RestrictedToTipsManager: React.FC<Props> = ({ children }: Props) => {
  const canManageTips = useCanManageTips();
  if (canManageTips) {
    return <>{children}</>;
  }
  return <CannotManageTipsPage />;
};

export function restrictedToTipsManager(Children: React.ComponentType): React.FC {
  const wrapped = (props: object) => (
    <RestrictedToTipsManager>
      <Children {...props} />
    </RestrictedToTipsManager>
  );
  wrapped.displayName = 'RestrictedToTipsManager';
  return wrapped;
}
