import styled from 'styled-components';
import { StepDetails } from './StepDetails';
import { LocalisationKey } from 'src/lang/en';
import React from 'react';
import { spaceUsages } from 'src/app/theme/SpaceUsagesTheme';

interface Props {
  stepNumber: number;
  title: LocalisationKey;
  details: string;
  moreDetails?: string;
  image: React.ReactNode;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  width: fit-content;
  max-width: 355px;
  border-radius: ${spaceUsages.largeSmall};
`;

export const Step = ({ title, details, stepNumber, image, moreDetails }: Props) => (
  <Container>
    {image}
    <StepDetails title={title} details={details} stepNumber={stepNumber} moreDetails={moreDetails} />
  </Container>
);
