import { Box, Card, CardContent, CircularProgress, Divider, styled, Tab, Typography } from '@mui/material';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { PaymentMethodsForm } from './PaymentMethodsForm';
import { useBusinessIdOrThrow } from 'src/business/hooks/useBusinessId';
import { PosConnection } from 'src/pos/domain/PosConnection';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { usePosConnections } from 'src/pos/queries/getPosConnections';

const PosConnectionTabPanel = styled(TabPanel)`
  width: 100%;
`;
type PosConnectionTab = {
  label: string;
  id: string;
  posConnection?: PosConnection;
  tabComponent?: ReactNode;
};

const ContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 600px;
  max-width: 1000px;
  align-items: start;
`;

export const PaymentMethodsMappingPage = () => {
  const businessId = useBusinessIdOrThrow();
  const { data: posConnections, isLoading } = usePosConnections(businessId);

  const [currentTab, setCurrentTab] = useState('');
  const [allTabs, setAllTabs] = useState<PosConnectionTab[]>([]);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => setCurrentTab(newValue);

  const loadTabs = useCallback(
    (connections: PosConnection[]) => {
      const posConnectionTabs: PosConnectionTab[] | undefined = connections?.map((connection) => ({
        label: connection.name && connection.name !== '' ? connection.name : 'Default',
        posConnection: connection,
        tabComponent: <PaymentMethodsForm businessId={businessId} posConnectionId={connection.id} />,
        id: connection.id,
      }));

      setAllTabs(posConnectionTabs ?? []);
      setCurrentTab(posConnectionTabs[0]?.id);
    },
    [businessId],
  );

  useEffect(() => {
    if (posConnections) {
      loadTabs(posConnections);
    }
  }, [posConnections, loadTabs]);

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Card>
      <CardContent>
        <Typography variant="h5">
          <FormattedMessage id="settings.posPaymentMethod" />
        </Typography>
        <Typography color="text.secondary">
          <FormattedMessage id="settings.posPaymentMethod.description" />
        </Typography>
        <Divider />
        <ContentWrapper>
          {allTabs.length > 0 && (
            <>
              <TabContext value={currentTab}>
                <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'center'}>
                  <TabList onChange={handleChangeTab}>
                    {allTabs.map((t) => (
                      <Tab key={t.label} value={t.id} label={t.label} />
                    ))}
                  </TabList>
                </Box>
                {allTabs
                  .filter((t) => !!t.tabComponent)
                  .map((t) => (
                    <PosConnectionTabPanel key={t.label} value={t.id}>
                      {t.tabComponent}
                    </PosConnectionTabPanel>
                  ))}
              </TabContext>
            </>
          )}
        </ContentWrapper>
      </CardContent>
    </Card>
  );
};
