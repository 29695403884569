import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { DateTimePicker } from '@sundayapp/b2b-react-component-library';
import { StaffPerformanceRangeType } from '../domain/StaffPerformance';
import { breakpoints } from 'src/app/theme/ThemeV5';
import { PaletteTheme } from 'src/app/theme/PaletteTheme';

type HeaderProps = {
  range: StaffPerformanceRangeType;
  startDate: Date;
  endDate: Date;
  setRange: (range: StaffPerformanceRangeType) => void;
  setCustomRangeDate: (startDate: Date, endDate: Date) => void;
};

const Container = styled.div`
  display: flex;
  margin: 24px 0 20px 0;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${breakpoints.tablet}) {
    align-items: start;
    flex-direction: column;
  }
`;

const TimeRangeLegend = styled(Typography)`
  color: ${PaletteTheme.text?.secondary};
`;

export const Header = ({ range, setRange, startDate, endDate, setCustomRangeDate }: HeaderProps) => {
  const intl = useIntl();
  const [datePickerIsOpened, updateDatePickerVisibility] = useState<boolean>(false);
  const [currentStartDate, updateStartDate] = useState<Date>(startDate);
  const [currentEndDate, updateEndDate] = useState<Date>(endDate);
  const [selectedTime, setSelectedTime] = React.useState('7_DAYS');

  const onCustomDate = () => updateDatePickerVisibility(true);

  const closeDatePicker = () => updateDatePickerVisibility(false);

  const handleChange = (event: SelectChangeEvent<string>) => {
    const rangeValue = (event.target as HTMLInputElement).value as StaffPerformanceRangeType;
    setSelectedTime(rangeValue);
    if (rangeValue === 'CUSTOM') {
      if (startDate === undefined || endDate === undefined) {
        return;
      }

      onCustomDate();
      return;
    }

    setRange(rangeValue);
  };

  const changeSelectedRange = (selectedRange: Date[]) => {
    updateStartDate(selectedRange[0]);
    updateEndDate(selectedRange[1]);
    // @ts-ignore
    setRange('CUSTOM');
    setCustomRangeDate(selectedRange[0], selectedRange[1]);
    updateDatePickerVisibility(false);
  };

  const customLabel = useMemo(() => {
    // @ts-ignore
    if (range === 'CUSTOM') {
      return intl.formatMessage(
        { id: 'staff_performance.custom_range_with_value', defaultMessage: 'from {startDate} to {endDate}' },
        { startDate: currentStartDate.toDateString(), endDate: currentEndDate.toDateString() },
      );
    }
    return intl.formatMessage({ id: 'staff_performance.custom_range' });
  }, [range, currentStartDate, currentEndDate]);

  return (
    <Container>
      <FormControl sx={{ minWidth: 160 }}>
        <InputLabel id="time-range-select-label">
          {intl.formatMessage({ id: 'datepicker.select_date_range' })}
        </InputLabel>
        <Select
          labelId="time-range-select-label"
          id="time-range-select"
          value={selectedTime}
          label={intl.formatMessage({ id: 'datepicker.select_date_range' })}
          onChange={handleChange}
          sx={{ '.MuiSelect-select': { padding: '8px 12px' } }}
        >
          <MenuItem value={'7_DAYS'}>{intl.formatMessage({ id: 'staff_performance.last_7_days' })}</MenuItem>
          <MenuItem value={'30_DAYS'}>{intl.formatMessage({ id: 'staff_performance.last_30_days' })}</MenuItem>
          <MenuItem value={'ALL_TIME'}>{intl.formatMessage({ id: 'staff_performance.all_time' })}</MenuItem>
          <MenuItem value={'CUSTOM'}>{customLabel}</MenuItem>
        </Select>
      </FormControl>

      {datePickerIsOpened && (
        <DateTimePicker
          onApply={changeSelectedRange}
          onClose={closeDatePicker}
          value={[currentStartDate, currentEndDate]}
          applyButtonLabel={intl.formatMessage({ id: 'datepicker.apply' })}
          cancelButtonLabel={intl.formatMessage({ id: 'datepicker.cancel' })}
          startTimeInputLabel={intl.formatMessage({ id: 'datepicker.start-time' })}
          endTimeInputLabel={intl.formatMessage({ id: 'datepicker.end-time' })}
        />
      )}
      <TimeRangeLegend variant="body2">
        <FormattedMessage id="staff_performance.sunday_payments_message" />
      </TimeRangeLegend>
    </Container>
  );
};
