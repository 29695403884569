import { z } from 'zod';
import { tipPoolDistributionFlows } from './TipPoolDistributionFlows';

const EnrollmentConfigurationSchema = z.discriminatedUnion('type', [
  z.object({ type: z.literal('PosPooled') }),
  z.object({
    type: z.literal('SundayTipping'),
    waiterRate: z.number(),
  }),
  z.object({
    type: z.literal('DirectTipping'),
    waiterRate: z.number(),
    onboardingStrategy: z.string(),
  }),
]);

const PosPooledConfigurationSchema = z.object({
  type: z.literal('PosPooled'),
});

const SundayTippingConfigurationSchema = z.object({
  type: z.literal('SundayTipping'),
  waiterRate: z.number(),
  sundayFeeRate: z.number(),
  tipPoolDistributionFlow: z.nativeEnum(tipPoolDistributionFlows),
});

const POCTippingConfigurationSchema = z.object({
  type: z.literal('POC'),
  pat: EnrollmentConfigurationSchema.optional(),
  pdq: EnrollmentConfigurationSchema.optional(),
});

export const TippingConfigurationSchema = z.discriminatedUnion('type', [
  PosPooledConfigurationSchema,
  SundayTippingConfigurationSchema,
  POCTippingConfigurationSchema,
]);

export type TippingConfiguration = z.infer<typeof TippingConfigurationSchema>;


