import styled from 'styled-components';
import { colorUsage } from '@sundayapp/b2b-react-component-library';
import { spaceUsages } from 'src/app/theme/SpaceUsagesTheme';

export const InputColor = styled.input`
  display: flex;
  margin-left: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  padding: 1px;
  margin-right: ${spaceUsages.mediumSmall};

  -webkit-appearance: none;
  border: none;
  width: 32px;
  height: 32px;

  &::-webkit-color-swatch-wrapper {
    padding: 0;
  }

  &::-webkit-color-swatch {
    border: none;
    border-radius: 50%;
  }
`;

export const InputColorWrapper = styled.div`
  display: flex;
  padding: ${spaceUsages.mediumSmall};
  border: solid 1px ${colorUsage.quaternaryBorder};
  border-radius: 40px;
  margin-left: ${spaceUsages.mediumSmall};
`;

export const BackgroundColorPicker = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const BackgroundColorPickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
`;
