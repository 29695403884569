import React, { useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
  Typography,
} from '@mui/material';
import { Mapping, MappingKey } from 'src/domain/pos-connection/PaymentMethodMapping';
import { PosPaymentMethod } from 'src/domain/pos-connection/PosPaymentMethods';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { PaymentMethodIcon } from 'src/accounting/common/Icons/PaymentMethodIcon';
import { getBrandForMappingKey, paymentMethodNameLocalizations } from './ProductPaymentMethodMappingComponent';

type AddMappingDialogProps = {
  open: boolean;
  onClose: () => void;
  onAddMapping: (mapping: Mapping) => void;
  availableMappingKeys: Array<MappingKey>;
  availablePaymentMethods: PosPaymentMethod[];
};

export const AddMappingDialog = ({
  open,
  onClose,
  onAddMapping,
  availableMappingKeys,
  availablePaymentMethods,
}: AddMappingDialogProps) => {
  const [selectedMappingKey, setSelectedMappingKey] = useState<MappingKey | null>(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PosPaymentMethod | null>(null);

  const handleClose = () => {
    setSelectedMappingKey(null);
    setSelectedPaymentMethod(null);
    onClose();
  };

  const handleAddMapping = () => {
    if (selectedMappingKey && selectedPaymentMethod) {
      // Create a new mapping
      const newMapping: Mapping = {
        mappingKey: selectedMappingKey,
        value: selectedPaymentMethod.id,
      };

      // Call the callback with the new mapping
      onAddMapping(newMapping);

      // Reset form and close dialog
      handleClose();
    }
  };

  const renderItem = (option: MappingKey, props: (React.HTMLAttributes<HTMLLIElement> & { key: any }) | undefined) => (
    <Box  {...props}  key={option.qualifier + option.paymentMethod} component="li"display="flex" alignItems="center" gap={1}>
      <PaymentMethodIcon brand={getBrandForMappingKey(option)} />
      <Typography>
        <FormattedMessage
          id={paymentMethodNameLocalizations[option.qualifier || ''] || option.qualifier || ''}
          defaultMessage={option.qualifier || ''}
        />
      </Typography>
    </Box>
  );

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Add New Mapping</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <Autocomplete
              options={availableMappingKeys}
              getOptionLabel={(option) => option.qualifier || ''}
              renderOption={(props, option) => renderItem(option, props)}
              value={selectedMappingKey}
              onChange={(event, newValue) => setSelectedMappingKey(newValue)}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    label="Select Payment Method"
                    slotProps={{
                      input: {
                        ...params.InputProps,
                        startAdornment: selectedMappingKey ? renderItem(selectedMappingKey, undefined) : null,
                      },
                    }}
                  />
                );
              }}
            />
          </FormControl>
          <FormControl fullWidth>
            <Autocomplete
              options={availablePaymentMethods}
              getOptionLabel={(option) => option.label + ' (' + option.id + ')'}
              value={selectedPaymentMethod}
              onChange={(event, newValue) => setSelectedPaymentMethod(newValue)}
              renderInput={(params) => {
                return <TextField {...params} label="Choose a POS payment method" />;
              }}
            />
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" onClick={handleAddMapping} disabled={!selectedMappingKey || !selectedPaymentMethod}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};
