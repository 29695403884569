import { FormControl, MenuItem, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import React from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';

interface UserLocationAccessFieldProps {
  row?: { id: string; locationAccess: string[] };
}

export const UserLocationAccessField = ({ row }: UserLocationAccessFieldProps) => {
  const intl = useIntl();
  const { control } = useFormContext();

  if (!row) return null;

  return (
    <Controller
      name={`users.${row.id}.locationAccess`}
      control={control}
      rules={{
        required: {
          value: true,
          message: `*${intl.formatMessage({ id: 'user_management.form.error.required' })}`,
        },
      }}
      render={({ field, fieldState: { error } }) => (
        <FormControl sx={{ flex: 1 }}>
          <TextField
            {...field}
            select
            value={field.value || ''}
            label={`${intl.formatMessage({ id: 'user_management.location_access' })}*`}
            helperText={error?.message}
            error={!!error}
          >
            <MenuItem value={'ALL'}>{intl.formatMessage({ id: 'user_management.location_access.all' })}</MenuItem>
          </TextField>
        </FormControl>
      )}
    />
  );
};
