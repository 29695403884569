import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import { CircularProgress } from '@mui/material';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { isOpsEmail, User, userType } from '../domain/user';
import { useAuthentication } from '../hooks/useAuthentication';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { HttpUserRepository } from 'src/infrastructure/user/HttpUserRepository';
import { configureAxiosWithAuthentication } from '../infrastructure/configureAxiosWithAuthentication';
import { configureRumForUser } from 'src/monitoring/datadog';
import { hasAccessToMultiLocations } from 'src/multi-locations/domain/User';

interface UserContextDefinition {
  setUser: (user: User) => void;
  currentUser: User;
}

const UserContext = createContext<UserContextDefinition>({
  currentUser: { authenticated: false, claims: [] },
  setUser: () => undefined,
});

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<User>({ authenticated: false, claims: [] });

  const auth = getAuth();
  const authentication = useAuthentication();
  const configuration = ConfigurationLoader.load();
  const userRepository = new HttpUserRepository(configuration.merchantAccountsBaseUrl);

  const handleCheckAuth0Authentication = async () => {
    try {
      const token = await userRepository.retrieveFirebaseToken();
      await signInWithCustomToken(auth, token);

      const userFromMerchant = await userRepository.getMyProfile();

      const userProfile = isOpsEmail(userFromMerchant.email || '') ? userType.OPS : userType.MERCHANT;
      const impersonate = authentication.getUser()?.email !== userFromMerchant.email;

      const authenticatedUser = {
        userId: userFromMerchant.id,
        email: userFromMerchant.email,
        userType: userProfile,
        lastName: userFromMerchant.lastName,
        firstName: userFromMerchant.firstName,
        phoneNumber: userFromMerchant.phoneNumber,
        claims: userFromMerchant.roles,
        authenticated: true,
        impersonated: impersonate,
        impersonator: impersonate ? authentication.getUser()?.email : undefined,
        relations: userFromMerchant.relations,
      };

      configureRumForUser({ userId: userFromMerchant.id, email: userFromMerchant.email, userType: userProfile, hasMultilocationsAccess: hasAccessToMultiLocations(authenticatedUser), impersonate });
      setUser(authenticatedUser);
    } catch (e: any) {
      datadogLogs.logger.warn('An error occurred while trying to confirm sign in.', {
        error: e,
        authenticated: authentication.isAuthenticated(),
        userId: authentication.getUser()?.userId,
        user,
      });
    }
  };

  useEffect(() => {
    if (!authentication.isAuthenticated()) {
      setUser({ authenticated: false, claims: [] });
    } else {
      configureAxiosWithAuthentication(authentication);
      handleCheckAuth0Authentication();
    }
  }, [authentication.isAuthenticated()]);

  if (authentication.isAuthenticated() && !user.authenticated) {
    return <CircularProgress />;
  }

  return (
    <UserContext.Provider
      value={{
        setUser,
        currentUser: user,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useCurrentUser = () => {
  const { currentUser } = useContext(UserContext);
  return currentUser;
};
