import { useQuery } from '@tanstack/react-query';
import { queryKeys } from 'src/app/queries/utils';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { GuestAppRepository } from 'src/guest-app/infrastructure/GuestAppRepository';

const configuration = ConfigurationLoader.load();
const guestAppRepository = new GuestAppRepository(configuration.guestAppApiBaseUrl);

export const useGetGuestAppConfiguration = (enrollmentId: string) => {
  return useQuery({
    queryKey: [queryKeys.guest_app.GET_CONFIGURATION, { enrollmentId }],
    queryFn: () => guestAppRepository.getGuestAppConfiguration(enrollmentId),
  });
};
