import { DependenciesContext } from 'src/tips/dispatch/Dependencies';
import React from 'react';
import { DispatchPooledTipsPage } from 'src/tips/dispatch/DispatchPooledTipsPage';
import { SundayPoolingRepositoryHttp } from 'src/tips/dispatch/infrastructure/SundayPoolingRepositoryHttp';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { TipsPromotionPage } from 'src/tips/page/TipsPromotionPage';
import { useIsSundayTippingEnabled } from 'src/tips/queries/useIsSundayTippingEnabled';
import { CircularProgress } from '@mui/material';

export const TipsDispatch = () => {
  const business = useCurrentBusinessOrThrow();
  const configuration = ConfigurationLoader.load();
  const { data: isSundayTippingEnabled, isLoading } = useIsSundayTippingEnabled(business.id);

  if (isLoading) return <CircularProgress />;

  return (
    <DependenciesContext.Provider
      value={{
        sundayPoolingRepository: new SundayPoolingRepositoryHttp(configuration.bookkeepingApiBaseUrl),
      }}
    >
      {isSundayTippingEnabled ? <DispatchPooledTipsPage /> : <TipsPromotionPage />}
    </DependenciesContext.Provider>
  );
};
