import { TagDto } from './TagDto';
import { ProductSource, ProductType, productTypes, SnoozePeriod } from '../domain/Product';
import { AllergenDto } from './allergen/AllergenDto';
import { z } from 'zod';
import { Days } from 'src/menu/dynamic-menu/domain/Days';
import { datadogLogs } from '@datadog/browser-logs';
import { nativeEnumKeySchema } from 'src/utils/ZodUtils';

const productTypeSchema = z.nativeEnum(productTypes);

const tagDtoSchema = z.object({
  id: z.string(),
  name: z.string(),
});

const allergenDtoSchema = z.object({
  id: z.string(),
  name: z.string(),
});

const overridableValueDtoSchema = z.object({
  value: z.array(z.string()).nullish(),
  originalValue: z.array(z.string()).nullish(),
  isOverridden: z.boolean().optional(),
  canUpdateValue: z.boolean().optional(),
});

const moneyDtoSchema = z.object({
  amount: z.number(),
  currency: z.string(),
});

export type MoneyDto = z.infer<typeof moneyDtoSchema>;

const menuBasedPricingStrategyDtoSchema = z.object({
  _type: z.literal('MenuBasedPricingStrategy'),
  price: moneyDtoSchema,
});


const productPriceTimeOverrideDtoSchema = z.object({
  _type: z.literal('ProductPriceTimeOverride'),
  value: moneyDtoSchema,
  days: z.array(nativeEnumKeySchema(Days)),
  timeRanges: z.array(z.object({
    start: z.string().time(),
    end: z.string().time(),
  })),
});

export const pricingStrategyDtoSchema = z.discriminatedUnion('_type', [
  menuBasedPricingStrategyDtoSchema,
  productPriceTimeOverrideDtoSchema,
]).catch( ({ error, input }) => {
  datadogLogs.logger.error('error parsing pricing strategy', { error, input });
  return input as any;
});

export type ProductPriceTimeOverrideDto = z.infer<typeof productPriceTimeOverrideDtoSchema>;

export type MenuBasedPricingStrategyDto = z.infer<typeof menuBasedPricingStrategyDtoSchema>;

export type PricingStrategyDto = z.infer<typeof pricingStrategyDtoSchema>;

export const ProductDtoSchema = z.object({
  id: z.string(),
  name: z.string(),
  partnerName: z.string().optional(),
  description: z.string().optional(),
  partnerDescription: z.string().optional(),
  shortDescription: z.string().optional(),
  type: productTypeSchema,
  source: z.union([z.literal('SUNDAY'), z.literal('PARTNER')]),
  sku: z.string().optional(),
  price: moneyDtoSchema,
  pictureUrl: z.string().optional(),
  partnerPictureUrl: z.string().optional(),
  tags: tagDtoSchema.array().default([]),
  allergens: allergenDtoSchema.array().default([]),
  relatedProductIds: z.array(z.string()).default([]),
  subProductIds: z.array(z.string()).default([]),
  subProductIdsInfos: overridableValueDtoSchema.optional(),
  partnerSubProductIds: z.array(z.string()).default([]),
  calories: z.number().optional(),
  eatInTax: z.number().optional(),
  takeAwayTax: z.number().optional(),
  deliveryTax: z.number().optional(),
  isAvailableForOrder: z.boolean().default(true),
  snoozePeriod: z.object({
    startAt: z.string(),
    endAt: z.string(),
  }).optional(),
  isEligibleForOrder: z.boolean().optional(),
  posConnection: z.object({
    posConnectionId: z.string(),
    externalRevenueCenterId: z.string().optional(),
  }).optional(),
  pricingStrategies: z.array(pricingStrategyDtoSchema).optional(),
  isEnabled: z.boolean().default(true),
  videoPlaybackId: z.string().optional(),
});

export type ProductDto = z.infer<typeof ProductDtoSchema>;

export interface UpdateProductDto {
  name?: string;
  description?: string;
  shortDescription?: string;
  price?: MoneyDto;
  tags?: TagDto[];
  allergens?: AllergenDto[];
  relatedProductIds?: string[];
  subProductIds?: string[];
  calories?: number;
  eatInTax?: number;
  takeAwayTax?: number;
  deliveryTax?: number;
  isAvailableForOrder?: boolean;
  isEnabled?: boolean;
}

export interface CreateProductDto {
  name: string;
  description: string;
  price: MoneyDto;
  type: ProductType;
}

export interface ProductSummaryDto {
  id: string;
  name: string;
  description: string;
  shortDescription: string;
  sku?: string;
  price: MoneyDto;
  basePrice: MoneyDto;
  pictureUrl: string;
  type: ProductType;
  hasDiscrepancies: boolean;
  snoozePeriod?: SnoozePeriod;
  posConnectionId?: string;
  externalRevenueCenterId?: string;
  source: (typeof ProductSource)[keyof typeof ProductSource];
  isEnabled?: boolean;
}

export function isProduct(type: string): type is ProductType {
  return type in productTypes;
}

export interface ProductsSummaryDto {
  products: ProductSummaryDto[];
}

export type ElementDto = {
  id: string;
  type: string;
};

export const PaginatedProductsDtoSchema = z.object({
  products: z.array(ProductDtoSchema),
  totalCount: z.number(),
});
export type PaginatedProductsDto = z.infer<typeof PaginatedProductsDtoSchema>;
