import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from 'src/app/queries/utils';
import { ConfigureGuestAppRequest, GuestAppRepository } from 'src/guest-app/infrastructure/GuestAppRepository';

export const useSaveGuestAppConfiguration = (enrollmentId: string) => {
  const queryClient = useQueryClient();
  const configuration = ConfigurationLoader.load();
  const repository = new GuestAppRepository(configuration.guestAppApiBaseUrl);
  return useMutation({
    mutationFn: (request: ConfigureGuestAppRequest) =>
      repository.saveGuestAppConfiguration(enrollmentId, request),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [queryKeys.guest_app.GET_CONFIGURATION, { enrollmentId }],
      }),
  });
};
