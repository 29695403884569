import React, { Dispatch, SetStateAction, useMemo, useRef, useState } from 'react';
import { Table, TableBody } from '@mui/material';
import {
  TableOrder,
  UserManagementData,
  UserManagementTableHead,
} from 'src/enterprise/configuration-replication/pages/UserManagementPage/Table/UserManagementTableHead';
import { BusinessUser } from 'src/domain/user/BusinessUser';
import { UserManagementTableBody } from 'src/enterprise/configuration-replication/pages/UserManagementPage/Table/UserManagementTableBody';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  const aName = typeof a[orderBy] === 'string' ? a[orderBy] : '';
  const bName = typeof b[orderBy] === 'string' ? b[orderBy] : '';

  if (bName < aName) {
    return -1;
  }
  if (bName > aName) {
    return 1;
  }

  return 0;
}

function getComparator<Key extends keyof UserManagementData>(
  order: TableOrder,
  orderBy: Key,
): (a: { [key in Key]?: string }, b: { [key in Key]?: string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export const UserManagementTable = ({
  data,
  setSelectedRowsBySection,
  selectedRowsBySection,
  sectionId,
}: {
  data: BusinessUser[];
  selectedRowsBySection: { [sectionId: string]: string[] };
  setSelectedRowsBySection: Dispatch<SetStateAction<{ [sectionId: string]: string[] }>>;
  sectionId: string;
}) => {
  const [order, setOrder] = useState<TableOrder>('asc');
  const [orderBy, setOrderBy] = useState<keyof UserManagementData>('lastName');

  const dataRows = useMemo(() => [...data].sort(getComparator(order, orderBy)), [data, order, orderBy]);
  const tableRef = useRef(null);

  const handleRequestSort = (property: keyof UserManagementData) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAll = () => {
    const isAllSelected = selectedRowsBySection[sectionId]?.length === dataRows.length;

    const newSelectedRows = isAllSelected ? [] : dataRows.map((row) => row.userId);

    setSelectedRowsBySection((prev) => ({
      ...prev,
      [sectionId]: newSelectedRows,
    }));
  };

  const handleSelectRow = (event: React.MouseEvent<HTMLTableRowElement>, id: string) => {
    const selectedRows = selectedRowsBySection[sectionId] || [];
    const selectedIndex = selectedRows.indexOf(id);
    let newlySelectedRows: string[] = [];

    if (selectedIndex === -1) {
      newlySelectedRows = [...selectedRows, id];
    } else {
      newlySelectedRows = selectedRows.filter((rowId) => rowId !== id);
    }

    setSelectedRowsBySection((prev) => ({
      ...prev,
      [sectionId]: newlySelectedRows,
    }));
  };

  if (!data.length) return null;

  return (
    <Table
      sx={{
        background: 'rgba(14, 7, 29, 0.04)',
        borderRadius: '12px',
        boxShadow: 'none',
      }}
      ref={tableRef}
    >
      <UserManagementTableHead
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
        countSelectedRows={selectedRowsBySection[`${sectionId}`]?.length || 0}
        rowCount={dataRows.length}
        onSelectAll={handleSelectAll}
      />
      <TableBody>
        {dataRows &&
          dataRows.map((user) => (
            <UserManagementTableBody
              key={user.userId}
              name={user.name ?? ''}
              email={user.email}
              phoneNumber={user.phoneNumber}
              isRowSelected={selectedRowsBySection[`${sectionId}`]?.includes(user.userId)}
              handleOnSelect={handleSelectRow}
              id={user.userId}
            />
          ))}
      </TableBody>
    </Table>
  );
};
