import { useQuery } from '@tanstack/react-query';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { HttpTipsRepository } from 'src/tips/infrastructure/HttpTipsRepository';
import axios from 'axios';
import { BusinessId } from 'src/business/domain/Business';
import { TippingConfiguration } from 'src/tips/domain/TippingConfiguration';

export const QUERY_KEY_BUSINESS_TIPPING_CONFIGURATION = 'business_tipping_configuration';

export const buildQueryKey = (businessId: BusinessId) => [QUERY_KEY_BUSINESS_TIPPING_CONFIGURATION, businessId] as const;

const getBusinessTippingConfigurationQuery = (businessId: BusinessId): Promise<TippingConfiguration> => {
  const configuration = ConfigurationLoader.load();
  const repository = new HttpTipsRepository(axios, configuration.bookkeepingApiBaseUrl);
  return repository.getTippingConfiguration(businessId);
};

export const useGetBusinessTippingConfiguration = (businessId: BusinessId) =>
  useQuery({
    queryKey: buildQueryKey(businessId),
    queryFn: () => getBusinessTippingConfigurationQuery(businessId),
  });
