import { Modal } from '@sundayapp/b2b-react-component-library';
import { Button } from '@mui/material';

import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import React, { useEffect, useState } from 'react';
import DaysSelector from './DaysSelector';
import MenuTimeRanges from './MenuTimeRanges';
import { GroupedHourConstraints } from './GroupedHourConstraints';
import { Hours } from '../../domain/StaticMenus';
import { spaceUsages } from 'src/app/theme/SpaceUsagesTheme';

type MenuScheduleModalProps = {
  groupedHourConstraints: GroupedHourConstraints;
  setGroupedHourConstraints: (groupedHourConstraints: GroupedHourConstraints) => void;
  isOpened: boolean;
  onClose: () => void;
};

const MenuScheduleModal = ({
  groupedHourConstraints,
  setGroupedHourConstraints,
  isOpened,
  onClose,
}: MenuScheduleModalProps) => {
  const OPEN_24H = { start: '00:00', end: '24:00' };
  const { formatMessage } = useIntl();
  const [selectedDays, setSelectedDays] = useState<boolean[]>(
    [
      groupedHourConstraints.monday,
      groupedHourConstraints.tuesday,
      groupedHourConstraints.wednesday,
      groupedHourConstraints.thursday,
      groupedHourConstraints.friday,
      groupedHourConstraints.saturday,
      groupedHourConstraints.sunday,
    ]!,
  );
  const [hours, setHours] = useState<Hours[]>(groupedHourConstraints.constraints!.hours!);
  const [canConfirm, setCanConfirm] = useState<boolean>(true);

  const updateCanConfirm = (updatedSelectedDays: boolean[], updatedHours: Hours[]) => {
    const validDays = updatedSelectedDays.findIndex((value) => value) < 0;
    const validHours = updatedHours.findIndex((hour) => hour.start >= hour.end) >= 0;
    setCanConfirm(validDays || validHours);
  };

  const onSelectedDayChanged = (selection: boolean[]) => {
    setSelectedDays(selection);
    updateCanConfirm(selection, hours);
  };

  const onHoursChanged = (updatedHours: Hours[]) => {
    setHours(updatedHours);
    updateCanConfirm(selectedDays, updatedHours);
  };

  const onConfirmation = () => {
    const newGroupedHourConstraints = {
      monday: selectedDays[0],
      tuesday: selectedDays[1],
      wednesday: selectedDays[2],
      thursday: selectedDays[3],
      friday: selectedDays[4],
      saturday: selectedDays[5],
      sunday: selectedDays[6],
      constraints: {
        available: true,
        hours: hours.length === 0 ? [OPEN_24H] : hours,
      },
    } as GroupedHourConstraints;

    setGroupedHourConstraints(newGroupedHourConstraints);
    onClose();
  };

  useEffect(() => {
    if (!groupedHourConstraints) {
      return;
    }

    updateCanConfirm(
      [
        groupedHourConstraints.monday,
        groupedHourConstraints.tuesday,
        groupedHourConstraints.wednesday,
        groupedHourConstraints.thursday,
        groupedHourConstraints.friday,
        groupedHourConstraints.saturday,
        groupedHourConstraints.sunday,
      ]!,
      groupedHourConstraints.constraints!.hours!,
    );
  }, [groupedHourConstraints]);

  return (
    <Modal
      title={formatMessage({ id: 'menus.schedule_selector.modal.name' })}
      cancelButtonMessage={formatMessage({ id: 'modal.cancel' })}
      isOpened={isOpened}
      onClose={onClose}
    >
      <DaysSelector selectedDays={selectedDays} onSelectionChanged={onSelectedDayChanged} />
      <MenuTimeRanges hours={hours} setHours={onHoursChanged} />
      <Button
        variant="contained"
        color="primary"
        fullWidth
        size="large"
        sx={{ justifyContent: 'center', marginTop: spaceUsages.largeSmall, textTransform: 'lowercase', width: '100%' }}
        onClick={onConfirmation}
        disabled={canConfirm}
      >
        <FormattedMessage id="menus.schedule_selector.modal.confirm" />
      </Button>
    </Modal>
  );
};

export default MenuScheduleModal;
