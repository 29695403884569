import { Select, Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';

import { InsightPeriod } from './InsightPeriod';
import { DateRange } from '../domain/DateRange';
import { AvailableRange } from './domain/AvailableRange';
import { ampli } from 'src/ampli';
import { Instant } from '../../Instant';
import { DateTimePickerAdapter } from 'src/sundayplus/reviews/browse/DateTimePickerAdapter';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { toDateRange } from '../browse/TimeFrame';

const trackDateRangeSelection = (customDateRange: DateRange) => {
  ampli.guestExperienceDateRangeFilterChanged({
    numberOfDaysInRange: customDateRange.numberOfDays(),
    daysSinceToday: Instant.fromEpoch(customDateRange.startDate().getTime()).deltaInDays(Instant.fromEpoch(Date.now())),
  });
};

export const GuestExperienceHeader = ({
  dateRange,
  onDateRangeChange,
}: {
  dateRange: DateRange;
  onDateRangeChange: (dateRange: DateRange) => void;
}) => {
  const business = useCurrentBusinessOrThrow();
  const [datePickerOpened, setDatePickerOpened] = useState<boolean>(false);

  const [selectedRange, selectRange] = useState<AvailableRange>('LAST_7_DAYS');

  const onRangeChange = (rangeType: AvailableRange) => {
    if (rangeType === 'custom') {
      return;
    }
    selectRange(rangeType);
    const fixedDateRange = toDateRange(rangeType, business.timezone);
    onDateRangeChange(fixedDateRange);
    trackDateRangeSelection(fixedDateRange);
  };

  const onCustomDateRangeSelected = (range: DateRange) => {
    selectRange('custom');
    onDateRangeChange(range);
    setDatePickerOpened(false);
    trackDateRangeSelection(range);
  };

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
      <Select value={selectedRange} onChange={(event) => onRangeChange(event.target.value as AvailableRange)}>
        <MenuItem value="YESTERDAY">
          <Typography>
            <FormattedMessage id="review.guest_experience.filter.yesterday" />
          </Typography>
        </MenuItem>
        <MenuItem value="LAST_7_DAYS">
          <Typography>
            <FormattedMessage id="review.guest_experience.filter.last7days" />
          </Typography>
        </MenuItem>
        <MenuItem value="LAST_30_DAYS">
          <Typography>
            <FormattedMessage id="review.guest_experience.filter.last30days" />
          </Typography>
        </MenuItem>
        <MenuItem
          value="custom"
          onClick={() => {
            setDatePickerOpened(true);
          }}
        >
          <Typography>
            <FormattedMessage id="review.guest_experience.filter.custom" />
          </Typography>
        </MenuItem>
      </Select>
      <InsightPeriod dateRange={dateRange} />
      {datePickerOpened && (
        <DateTimePickerAdapter
          onApply={onCustomDateRangeSelected}
          onClose={() => {
            setDatePickerOpened(false);
          }}
          business={business}
          selectedDateRange={dateRange}
        />
      )}
    </Stack>
  );
};
