import { Card, CardHeader, Switch, Typography } from '@mui/material';
import * as React from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { ConcertProtocolConfiguration } from '../../domain/PaymentTerminalConfiguration';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';

export type ConcertProtocolFormProps = {
  concertConfiguration: ConcertProtocolConfiguration;
  onConcertConfigUpdated: (configuration: ConcertProtocolConfiguration) => void;
};

export function ConcertConfigurationForm({ concertConfiguration, onConcertConfigUpdated }: ConcertProtocolFormProps) {
  const intl = useIntl();
  const configuration = ConfigurationLoader.load();

  return (
    <Card sx={{ padding: '16px' }}>
      <CardHeader
        title={intl.formatMessage({ id: 'payment.terminal.settings.concert.title' })}
        subheader={
          <Typography paddingTop="8px">
            {intl.formatMessage({ id: 'payment.terminal.settings.concert.subtitle' })}
          </Typography>
        }
        action={
          <Switch
            disabled={configuration.envName === 'production'}
            checked={concertConfiguration.enabled}
            onChange={(e) => onConcertConfigUpdated({ ...concertConfiguration, enabled: e.target.checked })}
          />
        }
      />
    </Card>
  );
}

