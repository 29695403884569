import { useState, useRef, useEffect } from 'react';
import { Card, styled, Typography, Button, CircularProgress } from '@mui/material';
import MuxPlayer from '@mux/mux-player-react';
import { Product } from 'src/menu/dynamic-menu/domain/Product';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { ProductRepositoryHttp } from 'src/menu/dynamic-menu/repositories/ProductRepositoryHttp';
import { BusinessId } from 'src/business/domain/Business';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';

// Styles
const CardContainer = styled(Card)({
  padding: 24,
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
});

const CenterContainer = styled('div')({
  marginTop: 16,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 8,
});

const HiddenInput = styled('input')({ display: 'none' });
const ProgressText = styled(Typography)({ marginTop: 8 });

type UploadUrlProvider = () => Promise<string>;

const useFileUpload = (uploadUrlProvider: UploadUrlProvider) => {
  const intl = useIntl();
  const [error, setError] = useState<string | null>(null);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [success, setSuccess] = useState(false);

  const uploadFile = async (file: File) => {
    try {
      if (success) {
        return;
      }

      setUploading(true);
      setProgress(0);
      setError(null);

      const uploadUrl = await uploadUrlProvider();

      const xhr = new XMLHttpRequest();

      xhr.upload.addEventListener('progress', (event) => {
        if (event.lengthComputable) {
          setProgress(Math.round((event.loaded / event.total) * 100));
        }
      });

      xhr.addEventListener('load', () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          setProgress(100);
          setSuccess(true);
          setUploading(false);
        } else {
          setError(intl.formatMessage({ id: 'menus.product.video.error' }));
          setUploading(false);
        }
      });

      xhr.addEventListener('error', () => {
        setError(intl.formatMessage({ id: 'menus.product.video.networkError' }));
        setUploading(false);
      });

      xhr.addEventListener('abort', () => {
        setError(intl.formatMessage({ id: 'menus.product.video.uploadAborted' }));
        setUploading(false);
      });

      xhr.open('PUT', uploadUrl);
      xhr.send(file);
    } catch (err) {
      setError(intl.formatMessage({ id: 'menus.product.video.networkError' }));
      setUploading(false);
    }
  };

  return { error, setError, uploading, progress, success, uploadFile };
};

const useVideoValidation = (onValidFile: (file: File) => void) => {
  const intl = useIntl();
  const validateVideo = (file: File): Promise<{ valid: boolean; error?: string }> => {
    return new Promise((resolve) => {
      if (!file.type.includes('video/mp4')) {
        resolve({ valid: false, error: intl.formatMessage({ id: 'menus.product.video.invalidFormat' }) });
        return;
      }

      const video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = () => {
        if (video.duration > 10) {
          resolve({ valid: false, error: intl.formatMessage({ id: 'menus.product.video.durationError' }) });
        } else {
          resolve({ valid: true });
        }
      };

      video.onerror = () => {
        resolve({ valid: false, error: intl.formatMessage({ id: 'menus.product.video.invalidFormat' }) });
      };

      video.src = URL.createObjectURL(file);
    });
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const result = await validateVideo(file);

    if (result.valid) {
      onValidFile(file);
    }

    return result;
  };

  return { handleFileChange };
};

export type ProductVideoPickerProps = {
  businessId: BusinessId;
  product: Product;
};

export const ProductVideoPicker = ({ businessId, product }: ProductVideoPickerProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const intl = useIntl();

  const configuration = ConfigurationLoader.load();
  const productRepository = new ProductRepositoryHttp(configuration.menuApiBaseUrl);
  const uploadUrlProvider = () => productRepository.requestMuxVideoDirectUploadUrl(businessId, product.id);

  const { error, setError, uploading, progress, success, uploadFile } = useFileUpload(uploadUrlProvider);
  const { handleFileChange } = useVideoValidation(uploadFile);

  const productHasVideo = !!product.videoPlaybackId;

  useEffect(() => {
    if (!product.pictureUrl) {
      setError(intl.formatMessage({ id: 'menus.product.video.pictureError' }));
    } else {
      setError(null);
    }
  }, [product]);

  const handleFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const result = await handleFileChange(event);
    if (!result?.valid && result?.error) {
      setError(result.error);
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <CardContainer>
      <div>
        <Typography variant="h6">
          <FormattedMessage id="menus.product.video.title" />
        </Typography>
        <Typography variant="body2">
          <FormattedMessage id="menus.product.video.description" />
        </Typography>
      </div>

      {productHasVideo && (
        <div>
          <MuxPlayer disableTracking muted playbackId={product.videoPlaybackId} thumbnailTime={0} />
        </div>
      )}
      <>
        <CenterContainer>
          <HiddenInput type="file" accept="video/mp4" ref={fileInputRef} onChange={handleFileInputChange} />

          {error && (
            <Typography color="error" variant="body2">
              {error}
            </Typography>
          )}

          {success && (
            <Typography color="success.main" variant="body2">
              <FormattedMessage id="menus.product.video.success" />
            </Typography>
          )}

          {!uploading && !success ? (
            <Button
              variant="contained"
              color="primary"
              disabled={!!error}
              onClick={() => fileInputRef.current?.click()}
            >
              {productHasVideo ? (
                <FormattedMessage id="menus.product.video.replace" />
              ) : (
                <FormattedMessage id="menus.product.video.add" />
              )}
            </Button>
          ) : uploading ? (
            <>
              <CircularProgress variant="determinate" value={progress} />
              <ProgressText variant="body2">{progress}%</ProgressText>
            </>
          ) : null}
        </CenterContainer>
      </>
    </CardContainer>
  );
};
