import BlockIcon from '@mui/icons-material/Block';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { TableCell, Tooltip, Typography } from '@mui/material';
import MUIIconButton from '@mui/material/IconButton';
import { Badge } from '@sundayapp/b2b-react-component-library';
import { Money, MoneyView, useRenderMoney } from '@sundayapp/web-money';
import { Link } from 'react-router';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { themeV5 } from 'src/app/theme/ThemeV5';
import { LocalisationKey } from 'src/lang/en';
import { Bill, BillStatus, billStatus } from '../domain/Bill';
import { BillToDisplay } from './BillToDisplay';
import { BillFreshness } from './BillsTable';
import { RefreshBillButtonView } from './RefreshButton';
import { BusinessDate } from 'src/components/date/BusinessDate';
import { VirtualTab } from 'src/bills/domain/tab/virtual_tab/VirtualTab';
import * as React from 'react';

const alreadyCaptured = 'already captured';
const tooOldToBeCaptured = 'too old to be captured';
const refreshBeforeCapture = 'refresh before capture';
const captureLeftToPay = 'capture left to pay';

const renderDate = (date: Date | undefined, locale: string) =>
  date?.toLocaleDateString(locale, {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }) ?? '';

const openTabTooltip = (freshness: BillFreshness, isAlreadyCaptured: boolean): string => {
  if (isAlreadyCaptured) {
    return alreadyCaptured;
  } else if (freshness.tooOld) {
    return tooOldToBeCaptured;
  } else if (freshness.stalled) {
    return refreshBeforeCapture;
  }
  return captureLeftToPay;
};

const virtualTabTooltip = (
  freshness: BillFreshness,
  virtualTab: VirtualTab,
  renderMoney: (value: Money) => string,
  locale: string,
): React.ReactNode => {
  const canBeCaptured = virtualTab.canBeCaptured;
  if (!canBeCaptured) {
    const nbAuthorizations = virtualTab.authorizations.length;
    return [
      <div key="0">{alreadyCaptured} ({nbAuthorizations} auth.):</div>,
      virtualTab.authorizations.map((authorization, index) => {
        switch (authorization.status) {
          case 'INITIATED':
            return <div key={index + 1}>- auth. {index + 1} initiated</div>;
          case 'GRANTED':
            return <div key={index + 1}>- auth. {index + 1} granted
              at: {renderDate(authorization.grantedAt, locale)}</div>;
          case 'DECLINED':
            return <div key={index + 1}>- auth. {index + 1} declined
              at: {renderDate(authorization.declinedAt, locale)}</div>;
          case 'CAPTURED':
            return <div key={index + 1}>- auth. {index + 1} captured
              at: {renderDate(authorization.capturedAt, locale)} ({renderMoney(virtualTab.capturedAmount!!)})</div>;
          case 'FAILED':
            return <div key={index + 1}>- auth. {index + 1} failed
              at: {renderDate(authorization.capturedFailedAt, locale)}</div>;
          case 'VOIDED':
            return <div key={index + 1}>- auth. {index + 1} voided
              at: {renderDate(authorization.voidedAt, locale)}</div>;
        }
      }),
    ];
  } else if (freshness.tooOld) {
    return <div>{tooOldToBeCaptured}</div>;
  } else if (freshness.stalled) {
    return <div>{refreshBeforeCapture}</div>;
  }
  return <div>{captureLeftToPay}</div>;
};

const OpenTabCaptureButton = ({
  bill,
  onCaptureClick,
  freshness,
}: {
  bill: Bill;
  onCaptureClick: () => void;
  freshness: BillFreshness;
}) => {
  if (bill.openTab !== undefined && bill.leftToPay.amount > 0) {
    const isAlreadyCaptured = !!bill.openTab.chargeLeftToPayAmountRequestedAt;
    const color = freshness.tooOld || freshness.stalled || isAlreadyCaptured ? 'disabled' : 'inherit';
    const tooltipLabel = openTabTooltip(freshness, isAlreadyCaptured);

    return (
      <MUIIconButton onClick={isAlreadyCaptured ? () => {
      } : onCaptureClick}>
        <Tooltip title={tooltipLabel}>
          <CurrencyExchangeIcon color={color} width="1em" />
        </Tooltip>
      </MUIIconButton>
    );
  }
  return null;
};

const VirtualTabCaptureButton = ({
  bill,
  onCaptureClick,
  freshness,
}: {
  bill: Bill;
  onCaptureClick: () => void;
  freshness: BillFreshness;
}) => {
  const intl = useIntl();
  const renderMoney = useRenderMoney(false, false);

  if (bill.virtualTab !== undefined && bill.leftToPay.amount > 0) {
    const isAlreadyCaptured = !bill.virtualTab.canBeCaptured;
    const color = freshness.tooOld || isAlreadyCaptured ? 'disabled' : 'inherit';
    const tooltipLabel = virtualTabTooltip({
      tooOld: freshness.tooOld,
      stalled: false,
    }, bill.virtualTab, renderMoney, intl.locale);

    return (
      <MUIIconButton onClick={isAlreadyCaptured ? () => {
      } : onCaptureClick}>
        <Tooltip title={tooltipLabel}>
          <CurrencyExchangeIcon color={color} width="1em" />
        </Tooltip>
      </MUIIconButton>
    );
  }
  return null;
};

const computeStatusToBadgeVariant = (status: BillStatus): string => {
  switch (status) {
    case BillStatus.NOT_PAID:
    case BillStatus.PARTIALLY_PAID:
      return 'yellow';
    case BillStatus.SETTLED:
      return 'green';
    case BillStatus.OVERPAID:
      return 'red';
    case BillStatus.EMPTY:
      return 'grey';
  }
};
const computeStatusToLabelKey = (bill: BillToDisplay): LocalisationKey => {
  if (bill.openTab && bill.openTab.chargeLeftToPayAmountRequestedAt !== undefined) {
    return 'orders.status.paid';
  }
  switch (billStatus(bill)) {
    case BillStatus.NOT_PAID:
    case BillStatus.PARTIALLY_PAID:
      return 'orders.status.to_be_paid';
    case BillStatus.SETTLED:
      return 'orders.status.paid';
    case BillStatus.OVERPAID:
      return 'orders.status.overpaid';
    case BillStatus.EMPTY:
      return 'orders.status.empty';
  }
};
const asAbsolute = ({ amount, currency }: Money): Money => ({
  amount: Math.abs(amount),
  currency,
});

const couldBeCaptured = (bill: BillToDisplay): boolean => bill.openTab !== undefined && bill.leftToPay.amount > 0;

interface Props {
  bill: BillToDisplay;
  hasCheckNumbers: boolean;
  onOpenTabCaptureClick: () => void;
  onVirtualTabCaptureClick: () => void;
  refreshBill: (billId: string, isTab: boolean) => Promise<void>;
  freshness: BillFreshness;
}

const IdCell = ({ bill }: { bill: BillToDisplay }) => {
  if (bill.openTab) {
    return (
      <TableCell align="left">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography>{bill.openTab.userName ?? bill.openTab.displayId}</Typography>
          <Typography color={themeV5.palette.text.secondary}>
            {bill.openTab.userName ? bill.openTab.displayId : ''}
          </Typography>
          <Typography color={themeV5.palette.text.secondary}>
            {bill.tableNumber !== 'N/A' ? bill.tableNumber : ''}
          </Typography>
        </div>
      </TableCell>
    );
  }
  return <TableCell align="left">{bill.tableNumber}</TableCell>;
};

export function BillTableRow({
  bill,
  hasCheckNumbers,
  onOpenTabCaptureClick,
  onVirtualTabCaptureClick,
  refreshBill,
  freshness,
}: Props) {
  const intl = useIntl();
  const billSettlementLabelKey = computeStatusToLabelKey(bill);

  return (
    <>
      <IdCell bill={bill} />
      {hasCheckNumbers && <TableCell align="left">{bill.order.checkNumber}</TableCell>}
      <TableCell align="left">{bill.issuedAt && <BusinessDate instant={bill.issuedAt} />}</TableCell>
      <TableCell align="left">{bill.order.staffName}</TableCell>
      <TableCell align="right">
        <Badge size="large" variant={computeStatusToBadgeVariant(billStatus(bill))} style={{ whiteSpace: 'pre' }}>
          {[BillStatus.PARTIALLY_PAID, BillStatus.NOT_PAID, BillStatus.OVERPAID].includes(billStatus(bill)) && (
            <span>
              <MoneyView value={asAbsolute(bill.leftToPay)} />{' '}
            </span>
          )}
          <FormattedMessage id={billSettlementLabelKey} />
        </Badge>
      </TableCell>
      <TableCell align="right" style={{ whiteSpace: 'pre' }}>
        <MoneyView value={bill.totalBillAmount} />
      </TableCell>
      <TableCell align="center">
        <Link to={bill.ticketLink} target="_blank" rel="noreferrer">
          <MUIIconButton>
            <ListAltIcon width="1em" />
          </MUIIconButton>
        </Link>
      </TableCell>
      <TableCell align="center">
        <OpenTabCaptureButton bill={bill} onCaptureClick={onOpenTabCaptureClick} freshness={freshness} />
        <VirtualTabCaptureButton bill={bill} onCaptureClick={onVirtualTabCaptureClick} freshness={freshness} />
        {bill.paymentLink && (
          <Link to={bill.paymentLink}>
            <MUIIconButton>
              <CreditCardIcon width="1em" />
            </MUIIconButton>
          </Link>
        )}
        {!couldBeCaptured && !bill.paymentLink && (
          <Tooltip title={intl.formatMessage({ id: 'orders.table.payments.no_sunday' })}>
            <BlockIcon width="1em" />
          </Tooltip>
        )}
      </TableCell>
      <TableCell align="center">
        <RefreshBillButtonView
          orderId={bill.order.orderId}
          refreshBill={refreshBill}
          freshness={freshness}
          isTab={bill.openTab !== undefined}
          billPaymentStatus={billStatus(bill)}
        />
      </TableCell>
    </>
  );
}
