/* eslint-disable max-len */
import * as React from 'react';
import { PaymentMethodIconProps } from './IconProps';

export function CashIcon(props: PaymentMethodIconProps) {
  return (
    <svg {...props} fill="#646765" viewBox="30 30 350 450"
         xmlns="http://www.w3.org/2000/svg"
         xmlSpace="preserve">
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <g>
          <g>
            <path
              d="M137.9,245.05c0,46.6,37.8,84.4,84.4,84.4s84.4-37.8,84.4-84.4s-37.8-84.4-84.4-84.4S137.9,198.45,137.9,245.05z M229.7,194.55c0,5.1,0,5.1,5.1,5.9c3.9,0.6,7.6,1.8,11.3,3.4c2,0.9,2.7,2.3,2.1,4.4c-0.9,3.1-1.8,6.3-2.8,9.4 c-0.9,2.9-1.9,3.4-4.7,2c-5.7-2.7-11.6-3.9-17.9-3.5c-1.6,0.1-3.2,0.3-4.8,1c-5.4,2.4-6.3,8.3-1.7,12c2.3,1.9,5,3.2,7.7,4.4 c4.8,2,9.6,3.9,14.1,6.4c14.5,8,18.4,26.2,8.2,38.6c-3.7,4.5-8.5,7.5-14.1,9c-2.4,0.7-3.5,1.9-3.4,4.5c0.1,2.5,0,5,0,7.5 c0,2.2-1.1,3.4-3.3,3.5c-2.7,0.1-5.4,0.1-8,0c-2.4,0-3.5-1.4-3.5-3.7c0-1.8,0-3.6,0-5.5c0-4-0.2-4.2-4-4.8 c-4.9-0.8-9.8-1.9-14.3-4.1c-3.6-1.7-3.9-2.6-2.9-6.3c0.8-2.8,1.5-5.5,2.4-8.3c1-3.2,1.9-3.6,4.8-2.1c5,2.6,10.3,4.1,15.9,4.8 c3.6,0.4,7.1,0.1,10.4-1.4c6.2-2.7,7.2-9.9,1.9-14.2c-1.8-1.5-3.8-2.6-5.9-3.5c-5.4-2.4-11.1-4.2-16.3-7.3c-8.3-5-13.6-11.8-13-22 c0.7-11.5,7.2-18.6,17.7-22.4c4.3-1.6,4.4-1.5,4.4-6c0-1.5,0-3.1,0-4.6c0.1-3.4,0.7-4,4.1-4.1c1.1,0,2.1,0,3.2,0 C229.6,187.25,229.6,187.25,229.7,194.55z M368.4,245.05c0,12.9-10.5,23.4-23.4,23.4s-23.4-10.5-23.4-23.4s10.5-23.4,23.4-23.4 S368.4,232.15,368.4,245.05z M99.5,221.65c12.9,0,23.4,10.5,23.4,23.4s-10.5,23.4-23.4,23.4s-23.4-10.5-23.4-23.4 S86.6,221.65,99.5,221.65z M0,344.15v-198.2c0-21.2,17.3-38.5,38.5-38.5H406c21.2,0,38.5,17.3,38.5,38.5v121.5h-32.9v-79.9 c-3.1,0.8-6.3,1.2-9.6,1.2c-21.5,0-39-17.5-39-39c0-3.3,0.4-6.5,1.2-9.5H78.1c0.4,2.3,0.7,4.7,0.7,7.2c0,21.5-17.5,39-39,39 c-2.3,0-4.6-0.2-6.8-0.6v118.4c2.2-0.4,4.5-0.6,6.8-0.6c21.5,0,39,17.5,39,39c0,2.5-0.2,4.8-0.7,7.2h228.5v32.9h-268 C17.3,382.65,0,365.35,0,344.15z M439.6,355.05c2.7,0,4.9,2.2,4.9,4.9v17.8c0,2.7-2.2,4.9-4.9,4.9h-118c-2.7,0-4.9-2.2-4.9-4.9 v-17.8c0-2.7,2.2-4.9,4.9-4.9H439.6z M462.5,343.95h-118c-2.7,0-4.9-2.2-4.9-4.9v-17.8c0-2.7,2.2-4.9,4.9-4.9h118 c2.7,0,4.9,2.2,4.9,4.9v17.8C467.4,341.75,465.2,343.95,462.5,343.95z M490.2,282.55v17.8c0,2.7-2.2,4.9-4.9,4.9h-118 c-2.7,0-4.9-2.2-4.9-4.9v-17.8c0-2.7,2.2-4.9,4.9-4.9h118C488,277.65,490.2,279.85,490.2,282.55z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
