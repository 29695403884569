import { useAsync } from 'react-use';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { DateRange } from '../../domain/DateRange';
import { HttpReviewRepository } from '../infrastructure/HttpReviewRepository';
import { Business } from 'src/business/domain/Business';

const configuration = ConfigurationLoader.load();
const repository = new HttpReviewRepository(configuration.venueFeedbackBaseUrl);

export const useReviews = (business: Business, dateRange: DateRange) =>
  useAsync(() => repository.listReviews(business.id, dateRange.toTimeRange()));
