import { ButtonsGroup } from '@sundayapp/b2b-react-component-library';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'src/app/i18n/TypedIntl';
// eslint-disable-next-line no-restricted-imports
import moment, { Moment } from 'moment/moment';
import { NonUSReportingView } from '../components/NonUSReportingView';
import { useReporting } from '../hooks/useReporting';
import { DateRange } from '../domain/DateRange';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { Stack } from '@mui/material';
import { spaceUsages } from 'src/app/theme/SpaceUsagesTheme';

const FiltersWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  white-space: nowrap;
  padding: ${spaceUsages.mediumLarge} ${spaceUsages.mediumLarge};
`;

export const ReportingPanel = () => {
  const { formatMessage } = useIntl();
  const business = useCurrentBusinessOrThrow();
  const { reportingDateRange, setSelectedRange, accountingRanges, setReportingDateRange } = useReporting();
  const [customRangeLabel, setCustomRangeLabel] = useState<string>(formatMessage({ id: 'accounting.custom_range' }));

  const displayDateRange = (range: DateRange) =>
    `${moment(range.startDate).format('LT')} - ${moment(range.endDate).format('LT')}`;

  const formatDate = (date: Moment): String => date.format('L').substring(0, 5);

  const onSelectedCustomRange = (ranges: Date[]) => {
    if (ranges.length === 2) {
      setReportingDateRange(new DateRange(ranges[0], ranges[1]));
      const startMoment = moment(ranges[0]);
      const endMoment = moment(ranges[1]);
      setCustomRangeLabel(`
            ${formatDate(startMoment)} - ${formatDate(endMoment)}
            ${startMoment.format('LT')}-${endMoment.format('LT')}
      `);
    }
  };

  const dateFilterButtons: any = [
    {
      id: 'today',
      label: `${formatMessage({ id: 'accounting.today' })} ${displayDateRange(accountingRanges.today)}`,
    },
    {
      id: 'lunch',
      label: `${formatMessage({ id: 'accounting.lunch' })} ${displayDateRange(accountingRanges.lunch)}`,
    },
    {
      id: 'dinner',
      label: `${formatMessage({ id: 'accounting.dinner' })} ${displayDateRange(accountingRanges.dinner)}`,
    },
    {
      id: 'yesterday',
      label: `${formatMessage({ id: 'accounting.yesterday' })} ${displayDateRange(accountingRanges.today.yesterday())}`,
    },
    {
      id: 'custom_range',
      label: customRangeLabel,
      type: 'datetime',
      onDateRangeSelection: onSelectedCustomRange,
    },
  ];

  const onChangeDateRange = (buttonKey: string) => {
    if (
      buttonKey === 'today' ||
      buttonKey === 'yesterday' ||
      buttonKey === 'lunch' ||
      buttonKey === 'dinner' ||
      buttonKey === 'custom_range'
    ) {
      setSelectedRange(buttonKey);
    }
  };

  return (
    <>
      <Stack direction={'column'} gap={5}>
        <FiltersWrapper>
          <ButtonsGroup
            buttons={dateFilterButtons}
            initialSelectedButton={dateFilterButtons[0].id}
            onChange={onChangeDateRange}
          />
        </FiltersWrapper>

        <NonUSReportingView selectedBusiness={business} reportingDateRange={reportingDateRange} />
      </Stack>
    </>
  );
};
