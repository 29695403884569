import React, { ReactNode, useState } from 'react';
import { Box, Button, CircularProgress, Stack, Tab, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { PosConnectionId } from 'src/pos/domain/PosConnection';
import {
  Mapping,
  PaymentMethodMapping,
  ProductPaymentMethodMapping,
} from 'src/domain/pos-connection/PaymentMethodMapping';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  ProductPaymentMethodMappingComponent,
} from 'src/pages/Settings/PaymentMethodMapping/ProductPaymentMethodMappingComponent';
import {
  useUpdatePaymentMethodMappingMutation,
} from 'src/pages/Settings/PaymentMethodMapping/usePaymentMethodMappingMutation';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { useGetPaymentMethodMapping } from 'src/pages/Settings/PaymentMethodMapping/usePaymentMethodMapping';
import { UseMutationResult } from '@tanstack/react-query';
import QrCodesSvg from 'src/app/component/icons/QrCodesSvg';
import PdqSvg from 'src/app/component/icons/PdqSvg';
import DiscountIcon from '@mui/icons-material/Discount';
import { useBusinessQuery } from 'src/business/hooks/useBusinessQuery';
import { Business, BusinessId } from 'src/business/domain/Business';

type ProductTab = {
  id: string;
  label: ReactNode;
  tabComponent?: ReactNode;
  mappings?: Mapping[];
};

type PaymentMethodsFormAlreadyLoadedProps = {
  paymentMethodMapping: PaymentMethodMapping;
  updatePaymentMethodMapping: UseMutationResult<void, Error, Mapping[], unknown>,
  business: Business
};
const PaymentMethodsFormAlreadyLoaded = ({
  paymentMethodMapping: initialPaymentMethodMapping,
  updatePaymentMethodMapping,
  business,
}: PaymentMethodsFormAlreadyLoadedProps) => {
  const snackBar = useSnackbar();
  const { formatMessage } = useIntl();
  const [paymentMethodMapping, setPaymentMethodMapping] = useState(initialPaymentMethodMapping);

  const updateProductMapping = (product: ProductPaymentMethodMapping) => {
    const updatedProducts = paymentMethodMapping.products.map((p) => {
      if (p.id === product.id) {
        return product;
      }
      return p;
    });

    setPaymentMethodMapping({ ...paymentMethodMapping, products: updatedProducts });
  };

  const getTabTitle = (product: ProductPaymentMethodMapping) => {
    switch (product.id) {
      case 'PAT':
        return <Stack direction={'row'} gap={1} alignItems={'center'}> <QrCodesSvg /> <Typography>QR Codes</Typography></Stack>;
      case 'PDQ':
        return <Stack direction={'row'} gap={1} alignItems={'center'}> <PdqSvg /> <Typography>Payment
          Terminal</Typography></Stack>;
      case 'LOYALTY':
        return <Stack direction={'row'} gap={1} alignItems={'center'}> <DiscountIcon /> <Typography>Loyalty</Typography></Stack>;
      default:
        return product.id;
    }
  };

  const isTabVisible = (p: ProductPaymentMethodMapping) => {
    switch (p.id) {
      case ('PDQ'):
        return !!business.pdqEnrollment;
      case ('PAT'):
        return !!business.patEnrollment || !!business.oapEnrollment;
      case ('LOYALTY'):
        return false;
      default:
        return false;
    }
  };

  const productTabs: ProductTab[] = paymentMethodMapping.products.filter(p => isTabVisible(p)).map((value) => {
    return {
      id: value.id,
      label: getTabTitle(value),
      mappings: value.mappings,
      tabComponent: (
        <ProductPaymentMethodMappingComponent
          product={value}
          availablePaymentMethods={paymentMethodMapping.posPaymentMethods}
          onProductMappingChanged={updateProductMapping}
        ></ProductPaymentMethodMappingComponent>
      ),
    };
  });

  const [currentTab, setCurrentTab] = useState(productTabs[0]?.id);

  if (paymentMethodMapping.posPaymentMethods.length < 1) {
    return (
      <Typography>
        <FormattedMessage id="settings.posPaymentMethod.not_supported_by_pos" />
      </Typography>
    );
  }

  const saveMappings = async () => {
    const mappings = paymentMethodMapping.products
      .flatMap(p => {
        const keepOnlyNoQualifierMappings = p.defaultMode;
        return p.mappings.filter(m => (keepOnlyNoQualifierMappings && !m.mappingKey.qualifier) || !keepOnlyNoQualifierMappings).map(m => m);
      })
      .filter(m => !!m.value);
    await updatePaymentMethodMapping
      .mutateAsync(mappings)
      .then(() =>
        snackBar.addNotification({
          variant: 'success',
          text: formatMessage({ id: 'settings.app_settings.success_saving' }),
        }),
      )
      .catch((e) => {
        if (e.response?.data === 'DO_NOT_USE_SAME_PAYMENT_METHOD_FOR_PAT_AND_PDQ') {
          snackBar.addNotification({
            variant: 'error',
            text: 'You can\'t use the same payment method for PAT and PDQ',
          });
          return;
        }
        snackBar.addNotification({
          variant: 'error',
          text: formatMessage({ id: 'settings.app_settings.error_saving' }),
        });
      });

  };

  return (
    <>
      <Button sx={{ float: 'right' }} variant="contained" onClick={() => saveMappings()}>Save changes</Button>
      <TabContext value={currentTab}>
        <Box display={'flex'} flexDirection={'row'}>
          <TabList onChange={(event, newValue) => setCurrentTab(newValue)}>
            {productTabs.map((t) => (
              <Tab key={t.id} value={t.id} label={t.label}></Tab>
            ))}
          </TabList>
        </Box>
        {productTabs
          .filter((t) => !!t.tabComponent)
          .map((t) => (
            <TabPanel key={t.id} value={t.id}>
              {t.tabComponent}
            </TabPanel>
          ))}
      </TabContext>
    </>
  )
  ;
};
export const PaymentMethodsForm = ({ businessId, posConnectionId }: {
  businessId: BusinessId,
  posConnectionId: PosConnectionId
}) => {
  const { isLoading, data: paymentMethodMapping } = useGetPaymentMethodMapping(posConnectionId);
  const updatePaymentMethodMapping = useUpdatePaymentMethodMappingMutation(posConnectionId);
  const { data: business } = useBusinessQuery(businessId as BusinessId);

  if (isLoading || !paymentMethodMapping || !business) {
    return <CircularProgress />;
  }

  return <PaymentMethodsFormAlreadyLoaded business={business} paymentMethodMapping={paymentMethodMapping}
                                          updatePaymentMethodMapping={updatePaymentMethodMapping} />;
};
