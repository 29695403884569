import { InputText, Modal } from '@sundayapp/b2b-react-component-library';
import { Button } from '@mui/material';
import styled from 'styled-components';
import React from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useUpdateStaticMenu } from '../../infrastructure/mutations/useUpdateStaticMenu';
import { useBusinessIdOrThrow } from 'src/business/hooks/useBusinessId';
import { StaticMenu } from '../../domain/StaticMenus';
import { spaceUsages } from 'src/app/theme/SpaceUsagesTheme';

type EditNameModalProps = {
  isOpened: boolean;
  onClose: () => void;
  selectedMenu: StaticMenu;
};

type EditNameModalInputs = {
  name: string;
};

export const EditNameModalForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const EditNameModal = ({ isOpened, onClose, selectedMenu }: EditNameModalProps) => {
  const businessId = useBusinessIdOrThrow();
  const { formatMessage } = useIntl();
  const { updateStaticMenu } = useUpdateStaticMenu();

  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm<EditNameModalInputs>({
    defaultValues: { name: selectedMenu.description },
    mode: 'all',
    criteriaMode: 'all',
  });

  const onSubmit: SubmitHandler<EditNameModalInputs> = async ({ name }) => {
    const menu = {
      ...selectedMenu,
      description: name,
      menuName: name,
    };
    await updateStaticMenu(businessId, menu);
    onClose();
  };

  return (
    <Modal
      title={formatMessage({ id: 'menus.edition.modal.name' })}
      cancelButtonMessage={formatMessage({ id: 'modal.cancel' })}
      isOpened={isOpened}
      onClose={onClose}
    >
      <EditNameModalForm onSubmit={handleSubmit(onSubmit)}>
        <InputText
          label={formatMessage({ id: 'menus.edition.form.name' })}
          placeholder={formatMessage({ id: 'menus.edition.form.name.placeholder' })}
          id="menuName"
          {...register('name', {
            required: formatMessage({ id: 'menus.creation.form.required' }),
            pattern: { value: /.*\S.*/, message: formatMessage({ id: 'menus.creation.form.required' }) },
          })}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          disabled={!isValid || isSubmitting}
          sx={{ justifyContent: 'center', marginTop: spaceUsages.large, textTransform: 'lowercase', width: '100%' }}
        >
          <FormattedMessage id="menus.creation.form.submit" />
        </Button>
      </EditNameModalForm>
    </Modal>
  );
};

export default EditNameModal;
