import React, { useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { ArrowIcon, colorUsage, IconWrapper, Panel } from '@sundayapp/b2b-react-component-library';
import { Button } from '@mui/material';
import UploadMenuPdfButton from './UploadMenuPdfButton';
import { StaticMenu } from '../../domain/StaticMenus';
import { refreshedImageUrl } from './refreshedImageUrl';
import { spaceUsages } from 'src/app/theme/SpaceUsagesTheme';

type MenuPdfImageHandlerProps = {
  menu: StaticMenu;
};

const MenuImgArrows = styled.div`
  display: flex;
  justify-content: end;
  gap: ${spaceUsages.largeSmall};
  width: 100%;
`;

const MenuImg = styled.img`
  max-width: 100%;
`;

const MenuPdfImageHandler = ({ menu }: MenuPdfImageHandlerProps) => {
  const intl = useIntl();
  const [currentMenuPage, setCurrentMenuPage] = useState<number>(0);

  const onMenuDownload = () => {
    if (!menu.link || !menu.fileName) {
      return;
    }

    const hyperlink = window.document.createElement('a');
    hyperlink.href = menu.link;
    hyperlink.target = '_blank';
    hyperlink.download = menu.fileName;
    hyperlink.target = '_blank';
    document.body.appendChild(hyperlink);
    hyperlink.click();
    document.body.removeChild(hyperlink);
  };

  const hasMultiplePages = (): boolean => {
    if (!menu.images) {
      return false;
    }

    return menu.images.length > 1;
  };

  const hasPreviousPage = (): boolean => {
    if (!menu.images) {
      return false;
    }

    return currentMenuPage > 0;
  };

  const onPreviousPage = () => {
    if (!hasPreviousPage()) {
      return;
    }

    setCurrentMenuPage(currentMenuPage - 1);
  };

  const hasNextPage = (): boolean => {
    if (!menu.images) {
      return false;
    }

    return currentMenuPage < menu.images.length - 1;
  };

  const onNextPage = () => {
    if (!hasNextPage()) {
      return;
    }

    setCurrentMenuPage(currentMenuPage + 1);
  };

  return (
    <>
      {menu.images && (
        <Panel width="auto">
          {hasMultiplePages() && (
            <MenuImgArrows>
              <IconWrapper
                size="small"
                cursor={hasPreviousPage() ? 'pointer' : 'default'}
                color={hasPreviousPage() ? colorUsage.primaryText : colorUsage.disabledText}
                onClick={onPreviousPage}
              >
                <ArrowIcon direction="left" />
              </IconWrapper>
              <IconWrapper
                size="small"
                cursor={hasNextPage() ? 'pointer' : 'default'}
                color={hasNextPage() ? colorUsage.primaryText : colorUsage.disabledText}
                onClick={onNextPage}
              >
                <ArrowIcon direction="right" />
              </IconWrapper>
            </MenuImgArrows>
          )}
          <MenuImg src={refreshedImageUrl(menu.images[currentMenuPage])} />
        </Panel>
      )}
      <UploadMenuPdfButton
        variant="secondary"
        text={intl.formatMessage({ id: 'menus.upload_new_pdf.button' })}
        onNewMenuPdfUploaded={() => setCurrentMenuPage(0)}
      />
      <Button
        variant="outlined"
        color="secondary"
        size="large"
        sx={{
          justifyContent: 'center',
          marginTop: spaceUsages.mediumLarge,
          textTransform: 'lowercase',
          height: '64px',
          width: '300px',
        }}
        onClick={onMenuDownload}
      >
        <FormattedMessage id="menus.download_pdf" />
      </Button>
    </>
  );
};

export default MenuPdfImageHandler;
