import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { useMemo } from 'react';
import { featureFlag } from 'src/feature-flipping/infrastructure/featureFlag';

export const useCanChangeProductVisibility = (): boolean => {
  const configuration = ConfigurationLoader.load();
  return useMemo(() => configuration.canChangeProductVisibility || featureFlag('menu-product-visibility'), []);
};

export const useCanBulkChangeProductVisibility = (): boolean => {
  const configuration = ConfigurationLoader.load();
  return useMemo(() => configuration.canBulkChangeProductVisibility || featureFlag('menu-product-visibility-bulk'), []);
};
