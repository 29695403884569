// eslint-disable-next-line @typescript-eslint/no-redeclare
import { Button, Dropdown, InputText, Title } from '@sundayapp/b2b-react-component-library';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AppSettingsForm, ButtonContainer, LineWrapper, NotificationContainer } from '../AppSettings.styles';
import { TipsOption } from '../constants';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { usePrompt } from 'src/app/component/Prompt/prompt';
import { Business } from 'src/business/domain/Business';
import { useGetSinglePosConnectionOptions } from 'src/pos/queries/getPosConnectionOptions';
import { useUpdatePosConnectionOptionsMutation } from 'src/pos/mutations/usePosMutation';
import { useTipsSuggestion } from '../tips/query/useListBusinessUsersQuery';
import { useSetTipsSuggestion } from '../tips/mutation/useSetTipsSuggestion';
import { Alert, Card, CardContent, Switch, Typography } from '@mui/material';
import { spaceUsages } from 'src/app/theme/SpaceUsagesTheme';
import { SundayText } from 'src/app/component/Text/Text';
// eslint-disable-next-line @typescript-eslint/no-redeclare

const CALCULATION_PRE_TAX = 'PRE_TAX';
const CALCULATION_POST_TAX = 'POST_TAX';
const CALCULATION_DEFAULT_STRATEGY_FOR_US_AND_CA = CALCULATION_POST_TAX;
const COUNTRIES_THAT_CAN_CHANGE_THEIR_TIPPING_CONFIGURATION = ['US', 'CA'];

const isAValidServiceChargeRate = (value: string): boolean => !Number.isNaN(Number.parseFloat(value));

type AppSettingsTipInputs = {
  sendTipsToPos: boolean;
  serviceChargeRate: string;
  tipsCalculationStrategy: TipsOption;
};

interface TipHandlerProps {
  business: Business;
}

const TipHandler = ({ business }: TipHandlerProps) => {
  const intl = useIntl();
  const useSnackBar = useSnackbar();
  const { data: posConnectionOptions } = useGetSinglePosConnectionOptions(business.id);
  const updatePosConnectionOption = useUpdatePosConnectionOptionsMutation();

  const { data: tipsSuggestion } = useTipsSuggestion(business.patEnrollment!.id);
  const { saveTips } = useSetTipsSuggestion(business.patEnrollment!.id);

  const [appSettingsTipUpdateError, setAppSettingsTipUpdateError] = useState('');

  const tipsStrategyOptions: TipsOption[] = [
    {
      id: CALCULATION_PRE_TAX,
      label: intl.formatMessage({ id: 'settings.app_settings.tips.calculation_strategy.pre_tax' }),
    },
    {
      id: CALCULATION_POST_TAX,
      label: intl.formatMessage({ id: 'settings.app_settings.tips.calculation_strategy.post_tax' }),
    },
  ];

  const {
    register,
    reset,
    watch,
    handleSubmit,
    setValue,
    formState: { isValid, isSubmitting, isDirty, errors },
  } = useForm<AppSettingsTipInputs>({
    mode: 'all',
    criteriaMode: 'all',
  });

  const watchSendTipsToPos = watch('sendTipsToPos');
  const watchServiceChargeRate = watch('serviceChargeRate');
  const watchTipsCalculationStrategy = watch('tipsCalculationStrategy');
  const canChangeTippingConfiguration = () =>
    COUNTRIES_THAT_CAN_CHANGE_THEIR_TIPPING_CONFIGURATION.includes(business.address.countryCode);

  useEffect(() => {
    if (!tipsSuggestion) {
      return;
    }
    const tipsStrategy = tipsStrategyOptions.find(
      (v) => v.id === (tipsSuggestion.tipsCalculationStrategy || CALCULATION_DEFAULT_STRATEGY_FOR_US_AND_CA),
    );

    if (tipsStrategy) {
      setValue('tipsCalculationStrategy', tipsStrategy);
    }
  }, [business, tipsSuggestion]);

  useEffect(() => {
    setValue('sendTipsToPos', posConnectionOptions?.sendTipsToPos ?? false);
    setValue('serviceChargeRate', ((posConnectionOptions?.serviceChargeRate ?? 0) / 100).toFixed(2));
  }, [posConnectionOptions]);

  usePrompt(isDirty, intl.formatMessage({ id: 'settings.confirm_leaving_tab' }));
  const onSubmit = async (inputs: AppSettingsTipInputs) => {
    setAppSettingsTipUpdateError('');
    try {
      const serviceChargeRateNumber = Math.round(parseFloat(inputs.serviceChargeRate) * 100);

      if (canChangeTippingConfiguration()) {
        await saveTips({ ...tipsSuggestion!, tipsCalculationStrategy: inputs.tipsCalculationStrategy.id });
      }

      await updatePosConnectionOption.mutateAsync({
        posConnectionId: posConnectionOptions!.posConnectionId,
        options: {
          sendTipsToPos: inputs.sendTipsToPos,
          serviceChargeRate: serviceChargeRateNumber,
        },
      });

      useSnackBar.addNotification({
        variant: 'success',
        text: intl.formatMessage({ id: 'settings.app_settings.success_saving' }),
      });
      reset(inputs, { keepDirty: false });
    } catch (e) {
      setAppSettingsTipUpdateError(intl.formatMessage({ id: 'settings.app_settings.error_saving' }));
    }
  };

  return (
    <>
      <AppSettingsForm onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <CardContent>
            <Typography variant="h6">
              <FormattedMessage id="settings.app_settings.tips.title" />
            </Typography>
            <LineWrapper>
              <Typography variant={'body1'}>
                <FormattedMessage id="settings.app_settings.tips.display_tips_on_pos" />
              </Typography>
              <Switch
                id="sendTipsToPos"
                onChange={(e) => setValue('sendTipsToPos', e.target.checked, { shouldDirty: true })}
                checked={watchSendTipsToPos}
                disabled={!posConnectionOptions}
              />
            </LineWrapper>
            {canChangeTippingConfiguration() && (
              <>
                <Title size="title4" marginTop={spaceUsages.largeMedium} marginBottom={spaceUsages.largeSmall}>
                  <FormattedMessage id="settings.app_settings.tips.calculation_strategy.title" />
                </Title>
                <LineWrapper>
                  <SundayText size="large" sx={{ marginRight: spaceUsages.medium }}>
                    <FormattedMessage id="settings.app_settings.tips.calculation_strategy.explanation" />
                  </SundayText>
                  <div>
                    <Dropdown
                      size="large"
                      options={tipsStrategyOptions}
                      value={watchTipsCalculationStrategy}
                      labelField="label"
                      onChange={(selectedValue: TipsOption) => {
                        setValue('tipsCalculationStrategy', selectedValue, { shouldDirty: true });
                      }}
                      autoComplete
                    />
                  </div>
                </LineWrapper>
                <div style={{ height: '80px' }}></div>
              </>
            )}
            {business.address.countryCode === 'GB' && (
              <InputText
                label={intl.formatMessage({ id: 'settings.app_settings.service_charge_rate' })}
                id="serviceChargeRate"
                placeholder={intl.formatMessage({ id: 'settings.app_settings.service_charge_rate.placeholder' })}
                inputIcon={!watchServiceChargeRate ? '' : 'check'}
                error={!!errors.serviceChargeRate}
                subCaption={errors.serviceChargeRate?.message}
                {...register('serviceChargeRate', {
                  required: true,
                  validate: {
                    isValidFloat: (value) =>
                      isAValidServiceChargeRate(value)
                        ? true
                        : intl.formatMessage({ id: 'settings.app_settings.service_charge_rate.error' }),
                  },
                })}
                marginTop={spaceUsages.mediumXLarge}
                marginBottom={spaceUsages.mediumXLarge}
              />
            )}
            {appSettingsTipUpdateError && (
              <NotificationContainer>
                <Alert severity="error">{appSettingsTipUpdateError}</Alert>
              </NotificationContainer>
            )}
            {isValid && isDirty && (
              <ButtonContainer>
                <Button type="submit" variant="primary" size="xSmall" disabled={!isValid || isSubmitting || !isDirty}>
                  <FormattedMessage id="settings.save_changes" />
                </Button>
              </ButtonContainer>
            )}
          </CardContent>
        </Card>
      </AppSettingsForm>
    </>
  );
};

export default TipHandler;
