import React from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { Button, Modal } from '@sundayapp/b2b-react-component-library';
import { RemoveUserContainer } from './RemoveUserRoleModal.styles';
import { BusinessUser } from 'src/domain/user/BusinessUser';
import { Typography } from '@mui/material';
import { chooseUserIdentifier } from '../stringUtils';
import { spaceUsages } from 'src/app/theme/SpaceUsagesTheme';

interface Props {
  onRemoveUserRole: (userId: string) => Promise<void>,
  userToRemove: BusinessUser,
  isOpen: boolean,
  handleClose: () => void
  handleConfirm: () => void,
}

export const RemoveUserRoleModal: React.FC<Props> = ({
  onRemoveUserRole,
  handleClose,
  handleConfirm,
  isOpen,
  userToRemove,
}: Props) => {
  const intl = useIntl();
  return (
    <Modal
      title={intl.formatMessage({ id: 'settings.user_permissions.remove_modal.title' })}
      isOpened={isOpen}
      onClose={handleClose}
      cancelButtonMessage={intl.formatMessage({ id: 'settings.user_permissions.remove_modal.cancel' })}
      maxWidth="420px"
    >
      <RemoveUserContainer>
        <Typography ><FormattedMessage id="settings.user_permissions.remove_modal.content" values={{ email: chooseUserIdentifier(userToRemove) }} /></Typography>
        <Button
          variant="primary"
          size="medium"
          onClick={async () => {
            await onRemoveUserRole(userToRemove.userId);
            handleConfirm();
          }}
          marginTop={spaceUsages.largeSmall}
          fullWidth
        >
          <FormattedMessage id="settings.user_permissions.remove_modal.confirm" />
        </Button>
      </RemoveUserContainer>
    </Modal>
  );
};
