import React from 'react';
import { PaymentMethodIconProps } from 'src/accounting/common/Icons/IconProps';

/* eslint-disable */
export function DiscoverIcon(props: PaymentMethodIconProps) {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="100%"
      viewBox="0 0 732 378"
      enableBackground="new 0 0 732 378"
      xmlSpace="preserve"
      {...props}
    >
      <path
        fill="#FFFFFF"
        opacity="1.000000"
        stroke="none"
        d="
M506.000000,379.000000
	C337.371216,379.000000 169.242401,379.000000 1.056803,379.000000
	C1.056803,253.066269 1.056803,127.132500 1.056803,1.099364
	C244.878769,1.099364 488.757599,1.099364 732.818237,1.099364
	C732.818237,126.999786 732.818237,252.999878 732.818237,379.000000
	C657.465881,379.000000 581.982910,379.000000 506.000000,379.000000
M686.115906,147.960938
	C685.982788,145.457153 685.849670,142.953384 685.363647,139.855820
	C683.581177,138.917542 681.798645,137.979248 679.617859,136.456207
	C678.810059,136.859512 678.002258,137.262817 677.194458,137.666122
	C678.593018,138.055069 679.991577,138.444031 681.513672,139.804703
	C679.666931,139.811996 677.820190,139.819305 675.993896,139.248138
	C674.337769,140.577057 671.625000,141.700699 671.315979,143.291336
	C670.992249,144.957825 672.976135,147.072586 674.130127,149.577515
	C678.207092,151.687134 682.179016,152.602341 686.115906,147.960938
M351.231476,230.633987
	C360.049286,236.104980 369.558167,239.062973 380.824982,238.696960
	C400.414276,237.344986 415.270447,228.485748 425.116638,210.418106
	C430.225159,200.126846 432.377502,189.402939 429.940979,177.222931
	C427.072754,170.595795 424.204559,163.968658 421.098511,156.739868
	C410.863495,142.952118 397.270172,135.569855 379.126495,135.027267
	C353.510834,135.473648 333.342133,152.109482 328.010834,177.824020
	C328.252991,184.910690 328.495148,191.997360 328.893646,199.838913
	C330.363159,203.565170 331.832672,207.291428 333.456238,211.659927
	C337.816742,219.147903 343.104492,225.760162 351.231476,230.633987
M46.996147,225.496490
	C46.996147,230.280624 46.996147,235.064774 46.996147,240.723587
	C61.594837,240.137329 75.812767,240.603073 89.726334,238.764954
	C110.668343,235.998337 127.774330,216.989532 129.993958,196.133331
	C132.488403,172.694748 121.223801,152.760315 100.740402,143.879974
	C83.340431,136.336395 65.136337,139.910507 46.996147,138.900574
	C46.996147,167.906464 46.996147,196.201813 46.996147,225.496490
M666.770813,183.129974
	C675.522522,161.024704 665.253906,142.471039 641.858337,139.979218
	C629.711975,138.685532 617.314331,139.751511 604.738220,139.751511
	C604.738220,172.923096 604.738220,206.462387 604.738220,240.264069
	C611.238342,240.264069 617.480347,240.264069 624.311523,240.264069
	C624.311523,226.711716 624.311523,213.369247 624.311523,200.026764
	C624.726929,199.829666 625.142334,199.632568 625.557739,199.435471
	C626.761536,200.753052 628.149780,201.945694 629.139832,203.408020
	C636.783813,214.698730 644.331726,226.054520 651.969849,237.349243
	C652.767273,238.528503 653.927307,240.305603 654.969299,240.341324
	C662.486633,240.599167 670.016907,240.479538 678.346619,240.479538
	C667.425293,225.809006 657.152344,212.009491 646.586670,197.816696
	C655.423340,195.470840 662.365234,191.597061 666.770813,183.129974
M559.644287,195.935806
	C569.734192,195.935806 579.824158,195.935806 589.746948,195.935806
	C589.746948,189.829727 589.746948,184.409424 589.746948,178.512238
	C578.025452,178.512238 566.626892,178.512238 555.317505,178.512238
	C555.317505,170.903763 555.317505,163.792084 555.317505,156.070312
	C567.399109,156.070312 579.255371,156.070312 591.059265,156.070312
	C591.059265,150.035706 591.059265,144.621536 591.059265,139.372742
	C572.367126,139.372742 553.980408,139.372742 535.720032,139.372742
	C535.720032,173.136353 535.720032,206.520035 535.720032,239.981476
	C554.373474,239.981476 572.661194,239.981476 591.142517,239.981476
	C591.142517,234.342072 591.142517,228.897980 591.142517,222.913025
	C579.049316,222.913025 567.164917,222.913025 555.081543,222.913025
	C555.081543,214.561295 555.222839,206.745697 555.011047,198.939651
	C554.927917,195.874390 556.483704,195.742401 559.644287,195.935806
M219.986023,159.368973
	C220.759979,160.240891 221.533951,161.112808 222.603897,162.318161
	C226.197083,157.618912 229.567169,153.211441 232.945602,148.793045
	C224.065659,141.104980 214.236938,137.581528 203.202515,137.528351
	C187.808243,137.454163 174.793320,147.195236 172.102127,160.590927
	C169.049896,175.783783 175.021347,185.637924 191.572418,192.553116
	C197.084137,194.855988 202.811935,196.686279 208.186249,199.263687
	C217.955795,203.948929 218.847412,216.929138 209.451035,222.154602
	C205.717392,224.230911 200.534683,224.761475 196.152771,224.379105
	C188.379486,223.700760 183.114655,218.445038 179.113113,211.926392
	C174.972122,216.168625 171.071411,220.164703 167.087280,224.246246
	C174.390244,237.592789 189.399124,244.788727 206.602585,242.922974
	C221.680344,241.287750 233.250244,230.775650 235.806366,216.389206
	C238.793442,199.577194 233.404068,189.512985 217.313690,181.961899
	C214.912079,180.834808 212.417694,179.906784 209.979523,178.855850
	C206.018448,177.148514 201.983612,175.583771 198.138351,173.644684
	C194.556290,171.838333 191.828171,169.101334 192.166138,164.663742
	C192.519501,160.023987 195.334763,156.980530 199.475357,155.383682
	C206.759735,152.574463 213.510071,153.645355 219.986023,159.368973
M493.799561,167.171860
	C488.552155,180.187988 483.304749,193.204117 477.748260,206.986938
	C468.949707,184.880066 460.578796,163.731079 452.041962,142.649261
	C451.445679,141.176743 449.678772,139.240768 448.356293,139.160141
	C442.246216,138.787674 436.100769,138.995728 429.166260,138.995728
	C443.622772,173.998169 457.854309,208.455963 472.094513,242.934616
	C473.435852,242.934616 474.463928,242.768402 475.419189,242.961884
	C481.177887,244.128174 483.628571,241.362762 485.763672,236.151230
	C498.479492,205.112808 511.612122,174.245209 524.590942,143.314423
	C525.137512,142.011887 525.546997,140.651825 526.135315,138.994690
	C519.790100,138.994690 513.954773,138.801300 508.148926,139.129135
	C506.676758,139.212280 504.581573,140.642136 503.994385,141.986694
	C500.473663,150.048569 497.309631,158.266235 493.799561,167.171860
M277.947784,239.626877
	C291.449005,244.003464 304.791901,243.798416 317.856659,238.021469
	C319.106079,237.469025 320.750641,235.961349 320.796539,234.839233
	C321.071930,228.108032 320.933136,221.359909 320.933136,214.277298
	C301.877106,227.538712 284.338226,227.432526 272.159790,214.338013
	C260.399078,201.692642 259.942657,180.172760 271.178101,166.600311
	C276.380646,160.315643 283.090210,156.469360 291.099182,155.227570
	C302.551300,153.451904 312.236816,157.396927 320.935486,166.434509
	C320.935486,162.318085 321.004822,159.532181 320.919830,156.750992
	C320.779388,152.156189 322.322968,145.360962 319.957153,143.445984
	C315.508575,139.845139 308.811066,138.957001 302.977386,137.174011
	C301.279114,136.654938 299.339844,136.868118 297.509766,136.845078
	C270.457977,136.504501 248.473984,154.635071 243.540344,181.336243
	C238.994400,205.939209 253.285782,230.508377 277.947784,239.626877
M139.409866,224.494995
	C139.409866,229.618118 139.409866,234.741226 139.409866,239.935669
	C146.226593,239.935669 152.480347,239.935669 158.770325,239.935669
	C158.770325,206.180084 158.770325,172.672867 158.770325,139.316071
	C152.186005,139.316071 145.953079,139.316071 139.409927,139.316071
	C139.409927,167.593567 139.409927,195.545303 139.409866,224.494995
z"
      />
      <path
        fill="#231F1F"
        opacity="1.000000"
        stroke="none"
        d="M46.996147,224.996826C46.996147,196.201813 46.996147,167.906464 46.996147,138.900574C65.136337,139.910507 83.340431,136.336395 100.740402,143.879974C121.223801,152.760315 132.488403,172.694748 129.993958,196.133331C127.774330,216.989532 110.668343,235.998337 89.726334,238.764954C75.812767,240.603073 61.594837,240.137329 46.996147,240.723587C46.996147,235.064774 46.996147,230.280624 46.996147,224.996826M66.798233,169.508804C66.798233,187.398285 66.798233,205.287766 66.798233,223.165222C82.742722,223.715088 97.264526,222.295944 105.478821,206.975540
	C112.240273,194.364838 111.815552,181.584366 103.696587,169.685501
	C94.834526,156.697586 81.222351,155.959854 66.798210,156.311691
	C66.798210,160.604919 66.798210,164.558701 66.798233,169.508804
z"
      />
      <path
        fill="#252121"
        opacity="1.000000"
        stroke="none"
        d="
M666.572266,183.448273
	C662.365234,191.597061 655.423340,195.470840 646.586670,197.816696
	C657.152344,212.009491 667.425293,225.809006 678.346619,240.479538
	C670.016907,240.479538 662.486633,240.599167 654.969299,240.341324
	C653.927307,240.305603 652.767273,238.528503 651.969849,237.349243
	C644.331726,226.054520 636.783813,214.698730 629.139832,203.408020
	C628.149780,201.945694 626.761536,200.753052 625.557739,199.435471
	C625.142334,199.632568 624.726929,199.829666 624.311523,200.026764
	C624.311523,213.369247 624.311523,226.711716 624.311523,240.264069
	C617.480347,240.264069 611.238342,240.264069 604.738220,240.264069
	C604.738220,206.462387 604.738220,172.923096 604.738220,139.751511
	C617.314331,139.751511 629.711975,138.685532 641.858337,139.979218
	C665.253906,142.471039 675.522522,161.024704 666.572266,183.448273
M624.117310,178.394073
	C624.117310,180.812317 624.117310,183.230560 624.117310,185.919815
	C633.182983,185.603668 642.055664,186.686478 647.195984,178.090897
	C650.383301,172.761047 650.449768,166.621384 646.719666,161.539734
	C641.093994,153.875427 632.608032,154.939575 624.117310,155.134003
	C624.117310,162.831268 624.117310,170.126770 624.117310,178.394073
z"
      />
      <path
        fill="#242020"
        opacity="1.000000"
        stroke="none"
        d="
M559.161804,195.935974
	C556.483704,195.742401 554.927917,195.874390 555.011047,198.939651
	C555.222839,206.745697 555.081543,214.561295 555.081543,222.913025
	C567.164917,222.913025 579.049316,222.913025 591.142517,222.913025
	C591.142517,228.897980 591.142517,234.342072 591.142517,239.981476
	C572.661194,239.981476 554.373474,239.981476 535.720032,239.981476
	C535.720032,206.520035 535.720032,173.136353 535.720032,139.372742
	C553.980408,139.372742 572.367126,139.372742 591.059265,139.372742
	C591.059265,144.621536 591.059265,150.035706 591.059265,156.070312
	C579.255371,156.070312 567.399109,156.070312 555.317505,156.070312
	C555.317505,163.792084 555.317505,170.903763 555.317505,178.512238
	C566.626892,178.512238 578.025452,178.512238 589.746948,178.512238
	C589.746948,184.409424 589.746948,189.829727 589.746948,195.935806
	C579.824158,195.935806 569.734192,195.935806 559.161804,195.935974
z"
      />
      <path
        fill="#232020"
        opacity="1.000000"
        stroke="none"
        d="
M219.716095,159.137436
	C213.510071,153.645355 206.759735,152.574463 199.475357,155.383682
	C195.334763,156.980530 192.519501,160.023987 192.166138,164.663742
	C191.828171,169.101334 194.556290,171.838333 198.138351,173.644684
	C201.983612,175.583771 206.018448,177.148514 209.979523,178.855850
	C212.417694,179.906784 214.912079,180.834808 217.313690,181.961899
	C233.404068,189.512985 238.793442,199.577194 235.806366,216.389206
	C233.250244,230.775650 221.680344,241.287750 206.602585,242.922974
	C189.399124,244.788727 174.390244,237.592789 167.087280,224.246246
	C171.071411,220.164703 174.972122,216.168625 179.113113,211.926392
	C183.114655,218.445038 188.379486,223.700760 196.152771,224.379105
	C200.534683,224.761475 205.717392,224.230911 209.451035,222.154602
	C218.847412,216.929138 217.955795,203.948929 208.186249,199.263687
	C202.811935,196.686279 197.084137,194.855988 191.572418,192.553116
	C175.021347,185.637924 169.049896,175.783783 172.102127,160.590927
	C174.793320,147.195236 187.808243,137.454163 203.202515,137.528351
	C214.236938,137.581528 224.065659,141.104980 232.945602,148.793045
	C229.567169,153.211441 226.197083,157.618912 222.603897,162.318161
	C221.533951,161.112808 220.759979,160.240891 219.716095,159.137436
z"
      />
      <path
        fill="#242020"
        opacity="1.000000"
        stroke="none"
        d="
M493.912781,166.801758
	C497.309631,158.266235 500.473663,150.048569 503.994385,141.986694
	C504.581573,140.642136 506.676758,139.212280 508.148926,139.129135
	C513.954773,138.801300 519.790100,138.994690 526.135315,138.994690
	C525.546997,140.651825 525.137512,142.011887 524.590942,143.314423
	C511.612122,174.245209 498.479492,205.112808 485.763672,236.151230
	C483.628571,241.362762 481.177887,244.128174 475.419189,242.961884
	C474.463928,242.768402 473.435852,242.934616 472.094513,242.934616
	C457.854309,208.455963 443.622772,173.998169 429.166260,138.995728
	C436.100769,138.995728 442.246216,138.787674 448.356293,139.160141
	C449.678772,139.240768 451.445679,141.176743 452.041962,142.649261
	C460.578796,163.731079 468.949707,184.880066 477.748260,206.986938
	C483.304749,193.204117 488.552155,180.187988 493.912781,166.801758
z"
      />
      <path
        fill="#242020"
        opacity="1.000000"
        stroke="none"
        d="
M277.596802,239.454163
	C253.285782,230.508377 238.994400,205.939209 243.540344,181.336243
	C248.473984,154.635071 270.457977,136.504501 297.509766,136.845078
	C299.339844,136.868118 301.279114,136.654938 302.977386,137.174011
	C308.811066,138.957001 315.508575,139.845139 319.957153,143.445984
	C322.322968,145.360962 320.779388,152.156189 320.919830,156.750992
	C321.004822,159.532181 320.935486,162.318085 320.935486,166.434509
	C312.236816,157.396927 302.551300,153.451904 291.099182,155.227570
	C283.090210,156.469360 276.380646,160.315643 271.178101,166.600311
	C259.942657,180.172760 260.399078,201.692642 272.159790,214.338013
	C284.338226,227.432526 301.877106,227.538712 320.933136,214.277298
	C320.933136,221.359909 321.071930,228.108032 320.796539,234.839233
	C320.750641,235.961349 319.106079,237.469025 317.856659,238.021469
	C304.791901,243.798416 291.449005,244.003464 277.596802,239.454163
z"
      />
      <path
        fill="#262222"
        opacity="1.000000"
        stroke="none"
        d="
M139.409897,223.996017
	C139.409927,195.545303 139.409927,167.593567 139.409927,139.316071
	C145.953079,139.316071 152.186005,139.316071 158.770325,139.316071
	C158.770325,172.672867 158.770325,206.180084 158.770325,239.935669
	C152.480347,239.935669 146.226593,239.935669 139.409866,239.935669
	C139.409866,234.741226 139.409866,229.618118 139.409897,223.996017
z"
      />
      <path
        fill="#F48115"
        opacity="1.000000"
        stroke="none"
        d="
M333.302216,211.017670
	C331.832672,207.291428 330.363159,203.565170 329.192078,199.412140
	C341.233612,192.932098 352.936432,186.798706 364.738983,180.863602
	C369.711731,178.362930 374.894897,176.280655 379.987366,174.457214
	C380.003235,177.600082 380.014130,180.293930 380.014496,183.457504
	C380.006500,190.614456 380.009003,197.301697 380.006592,204.425278
	C379.994202,206.894287 379.986786,208.926941 379.984100,210.973099
	C379.988861,210.986588 380.003662,211.007385 379.534607,211.003601
	C363.811127,211.005768 348.556671,211.011719 333.302216,211.017670
z"
      />
      <path
        fill="#E25A1D"
        opacity="1.000000"
        stroke="none"
        d="
M380.025024,182.987793
	C380.014130,180.293930 380.003235,177.600082 379.996307,173.987732
	C379.994324,165.716934 379.988342,158.364624 379.988861,150.544754
	C379.998047,145.116150 380.000732,140.155121 380.003387,135.194092
	C397.270172,135.569855 410.863495,142.952118 421.031982,157.387421
	C411.215576,164.240677 401.510010,170.517792 391.693207,176.615784
	C387.934692,178.950500 383.921906,180.875870 380.025024,182.987793
z"
      />
      <path
        fill="#EF7616"
        opacity="1.000000"
        stroke="none"
        d="
M379.982361,151.012329
	C379.988342,158.364624 379.994324,165.716934 379.991333,173.538727
	C374.894897,176.280655 369.711731,178.362930 364.738983,180.863602
	C352.936432,186.798706 341.233612,192.932098 329.113892,199.034698
	C328.495148,191.997360 328.252991,184.910690 328.499695,177.411331
	C329.963623,176.839539 331.068817,176.924469 331.894073,176.484406
	C342.871338,170.630920 353.791168,164.669739 364.765350,158.810410
	C369.792450,156.126343 374.907318,153.606598 379.982361,151.012329
z"
      />
      <path
        fill="#E8651B"
        opacity="1.000000"
        stroke="none"
        d="
M380.014496,183.457489
	C383.921906,180.875870 387.934692,178.950500 391.693207,176.615784
	C401.510010,170.517792 411.215576,164.240677 421.150940,157.688248
	C424.204559,163.968658 427.072754,170.595795 429.535645,177.616898
	C422.640961,181.297836 416.096466,184.481094 409.674988,187.895752
	C399.742981,193.177094 389.895111,198.616547 380.011536,203.988937
	C380.009003,197.301697 380.006500,190.614456 380.014496,183.457489
z"
      />
      <path
        fill="#E86A1C"
        opacity="1.000000"
        stroke="none"
        d="
M379.988861,150.544754
	C374.907318,153.606598 369.792450,156.126343 364.765350,158.810410
	C353.791168,164.669739 342.871338,170.630920 331.894073,176.484406
	C331.068817,176.924469 329.963623,176.839539 328.613342,177.000168
	C333.342133,152.109482 353.510834,135.473648 379.564941,135.110687
	C380.000732,140.155121 379.998047,145.116150 379.988861,150.544754
z"
      />
      <path
        fill="#EE7418"
        opacity="1.000000"
        stroke="none"
        d="
M380.006592,204.425278
	C389.895111,198.616547 399.742981,193.177094 409.674988,187.895752
	C416.096466,184.481094 422.640961,181.297836 429.496033,178.003357
	C432.377502,189.402939 430.225159,200.126846 424.463501,210.718353
	C409.200043,210.998947 394.589691,210.979279 379.979340,210.959610
	C379.986786,208.926941 379.994202,206.894287 380.006592,204.425278
z"
      />
      <path
        fill="#DE561F"
        opacity="1.000000"
        stroke="none"
        d="
M379.984100,210.973099
	C394.589691,210.979279 409.200043,210.998947 424.258759,211.024582
	C415.270447,228.485748 400.414276,237.344986 380.417908,238.185501
	C380.006012,230.450577 380.001160,223.227127 379.997498,215.587143
	C380.000305,213.782883 380.001984,212.395126 380.003662,211.007385
	C380.003662,211.007385 379.988861,210.986588 379.984100,210.973099
z"
      />
      <path
        fill="#E0581F"
        opacity="1.000000"
        stroke="none"
        d="
M379.534607,211.003601
	C380.001984,212.395126 380.000305,213.782883 379.607300,215.586517
	C377.851257,216.436295 376.391602,216.686295 375.137207,217.333923
	C367.012238,221.528732 358.932037,225.810211 350.837189,230.063293
	C343.104492,225.760162 337.816742,219.147903 333.379211,211.338806
	C348.556671,211.011719 363.811127,211.005768 379.534607,211.003601
z"
      />
      <path
        fill="#E45F1F"
        opacity="1.000000"
        stroke="none"
        d="
M351.034332,230.348633
	C358.932037,225.810211 367.012238,221.528732 375.137207,217.333923
	C376.391602,216.686295 377.851257,216.436295 379.606171,216.003021
	C380.001160,223.227127 380.006012,230.450577 380.016052,238.112396
	C369.558167,239.062973 360.049286,236.104980 351.034332,230.348633
z"
      />
      <path
        fill="#312D2D"
        opacity="1.000000"
        stroke="none"
        d="
M675.973450,139.826614
	C677.820190,139.819305 679.666931,139.811996 681.293823,139.070343
	C680.721313,137.904312 680.368713,137.472656 680.016174,137.040985
	C681.798645,137.979248 683.581177,138.917542 685.294312,140.336395
	C685.068726,140.949097 684.912537,141.081192 684.408691,140.921539
	C683.346252,140.203094 682.631531,139.776398 682.002502,139.400848
	C681.669312,141.517426 681.029480,143.325714 681.213745,145.045761
	C681.397766,146.764175 681.503052,149.598587 684.991333,147.990204
	C685.388306,148.312500 685.551636,148.435730 685.715027,148.558975
	C682.179016,152.602341 678.207092,151.687134 674.342163,149.088348
	C673.423035,145.415436 672.745605,142.305115 675.973450,139.826614
z"
      />
      <path
        fill="#E7E7E7"
        opacity="1.000000"
        stroke="none"
        d="
M675.983643,139.537384
	C672.745605,142.305115 673.423035,145.415436 674.252808,148.795288
	C672.976135,147.072586 670.992249,144.957825 671.315979,143.291336
	C671.625000,141.700699 674.337769,140.577057 675.983643,139.537384
z"
      />
      <path
        fill="#312D2D"
        opacity="1.000000"
        stroke="none"
        d="
M685.470703,140.633301
	C685.849670,142.953384 685.982788,145.457153 685.915466,148.259949
	C685.551636,148.435730 685.388306,148.312500 685.066528,147.581055
	C684.857544,145.052979 684.807007,143.133133 684.756409,141.213272
	C684.912537,141.081192 685.068726,140.949097 685.470703,140.633301
z"
      />
      <path
        fill="#E7E7E7"
        opacity="1.000000"
        stroke="none"
        d="
M679.817017,136.748596
	C680.368713,137.472656 680.721313,137.904312 681.232056,138.584473
	C679.991577,138.444031 678.593018,138.055069 677.194458,137.666122
	C678.002258,137.262817 678.810059,136.859512 679.817017,136.748596
z"
      />
      <path
        fill="#FEFDFD"
        opacity="1.000000"
        stroke="none"
        d="
M66.798218,169.010651
	C66.798210,164.558701 66.798210,160.604919 66.798210,156.311691
	C81.222351,155.959854 94.834526,156.697586 103.696587,169.685501
	C111.815552,181.584366 112.240273,194.364838 105.478821,206.975540
	C97.264526,222.295944 82.742722,223.715088 66.798233,223.165222
	C66.798233,205.287766 66.798233,187.398285 66.798218,169.010651
z"
      />
      <path
        fill="#FBFAFA"
        opacity="1.000000"
        stroke="none"
        d="
M624.117310,177.908173
	C624.117310,170.126770 624.117310,162.831268 624.117310,155.134003
	C632.608032,154.939575 641.093994,153.875427 646.719666,161.539734
	C650.449768,166.621384 650.383301,172.761047 647.195984,178.090897
	C642.055664,186.686478 633.182983,185.603668 624.117310,185.919815
	C624.117310,183.230560 624.117310,180.812317 624.117310,177.908173
z"
      />
      <path
        fill="#E7E7E7"
        opacity="1.000000"
        stroke="none"
        d="
M684.408691,140.921539
	C684.807007,143.133133 684.857544,145.052979 684.832886,147.381989
	C681.503052,149.598587 681.397766,146.764175 681.213745,145.045761
	C681.029480,143.325714 681.669312,141.517426 682.002502,139.400848
	C682.631531,139.776398 683.346252,140.203094 684.408691,140.921539
z"
      />
    </svg>
  )
}
