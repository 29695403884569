import axios from 'axios';
import { Bill } from '../domain/Bill';
import { BillResponse } from './BillResponse';
import { mapBills } from './Mapper';
import { TabCaptureStatusResponse } from './TabCaptureStatusResponse';
import { TabCaptureStatus } from 'src/bills/domain/tab/open_tab/TabCaptureStatus';
import { BusinessId } from 'src/business/domain/Business';
import { CurrencyCode, Money } from '@sundayapp/web-money';
import { VirtualTab } from 'src/bills/domain/tab/virtual_tab/VirtualTab';
import { virtualTabReadDtoSchema } from 'src/bills/infrastructure/tab/VirtualTabReadDto';
import { Instant } from 'src/sundayplus/Instant';
import _ from 'lodash';
import { Till } from 'src/bills/domain/Till';
import { datadogLogs } from '@datadog/browser-logs';

export class PATOrchestratorTill implements Till {
  constructor(private readonly patOrchestratorUrl: string) {
  }

  async searchByDate(businessId: BusinessId, from: Date, to: Date): Promise<(Bill)[]> {
    const queryParams = new URLSearchParams({ from: from.toISOString(), to: to.toISOString(), count: '0' });
    const billsResponse = await axios.get<BillResponse[]>(
      `${this.patOrchestratorUrl}/businesses/${businessId}/bills?${queryParams}`,
    );

    const bills = mapBills(billsResponse.data);
    return this.enrichWithVirtualTabs(businessId, bills);
  }

  private async enrichWithVirtualTabs(businessId: BusinessId, bills: Bill[]) {
    const fifteenHoursAgo = Instant.now().subtract(15, 'hours');
    const recentBillIds = bills
      .filter(b => b.issuedAt && fifteenHoursAgo.isBefore(b.issuedAt))
      .map(b => b.billId);

    const virtualTabs = await this.searchVirtualTabs(businessId, recentBillIds);
    const virtualTabsByBillId = _.keyBy(virtualTabs, t => t.billId);
    return bills.map(b => ({
      ...b,
      virtualTab: virtualTabsByBillId[b.billId],
    }));
  }

  async openTabCaptureStatus(
    businessId: BusinessId,
    tabId: string,
  ): Promise<(typeof TabCaptureStatus)[keyof typeof TabCaptureStatus]> {
    const status = await axios.get<TabCaptureStatusResponse>(
      `${this.patOrchestratorUrl}/admin/businesses/${businessId}/tabs/${tabId}/charge`,
    );
    switch (status.data.status) {
      case 'PROCESSING':
        return TabCaptureStatus.PROCESSING;
      case 'FAILURE':
        return TabCaptureStatus.FAILURE;
      case 'PARTIAL_SUCCESS':
        return TabCaptureStatus.PARTIAL_SUCCESS;
      case 'SUCCESS':
        return TabCaptureStatus.SUCCESS;
      default:
        return TabCaptureStatus.NOT_STARTED;
    }
  }

  async captureOpenTabLeftToPay(businessId: BusinessId, tabId: string): Promise<void> {
    return axios
      .put<void>(`${this.patOrchestratorUrl}/admin/businesses/${businessId}/tabs/${tabId}/charge`)
      .then(() => {/* void */
      });
  }

  async captureVirtualTabLeftToPay(businessId: BusinessId, tabId: string, amount: Money): Promise<void> {
    return axios
      .post<void>(`${this.patOrchestratorUrl}/v1/tabs/${tabId}/capture`, {
      amountToCapture: amount,
    }, {
      headers: {
        'business_id': businessId,
      },
    })
      .then(() => {/* void */
      });
  }

  async searchVirtualTabs(businessId: BusinessId, billIds: string[]): Promise<VirtualTab[]> {
    try {
      const headers  = { business_id: businessId };

      // Temp log to debug null business id sent to billing
      datadogLogs.logger.info('Enriching virtual tabs', { businessId, headers, billIds });

      const response = await axios.post<unknown>(
        `${this.patOrchestratorUrl}/v1/tabs/search`,
        { billIds },
        { headers },
      );

      const virtualTabDtos = virtualTabReadDtoSchema.array().parse(response.data);
      return virtualTabDtos.map(dto => ({
        id: dto.tabId,
        billId: dto.billId!,
        canBeCaptured: !dto.closedAt,
        capturedAmount: dto.capturedAmount ? {
          amount: dto.capturedAmount.amount,
          currency: (<any>CurrencyCode)[dto.capturedAmount.currency],
        } : undefined,
        authorizations: dto.authorizations,
      }));
    } catch (error) {
      datadogLogs.logger.error('Failed to get the virtual tabs', {
        businessId,
        billIds,
        error,
      });
      return [];
    }
  }
}
