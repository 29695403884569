import { createTheme } from '@mui/material/styles';
import { caES, deDE, enUS, esES, frFR, itIT, Localization, nlNL, ptPT } from '@mui/material/locale';
import { ButtonTheme } from './ButtonTheme';
import { CardTheme } from './CardTheme';
import { language, SupportedLanguage } from '../i18n/I18nProvider';
import { TypographyTheme } from './TypographyTheme';
import { PaletteTheme } from './PaletteTheme';
import { SwitchTheme } from './SwitchTheme';
import { InputTheme } from './InputTheme';
import { DataGridTheme } from './DatagridTheme';
//   /!\ Don't remove themeAugmentation import, it allows to override datagrid theme /!\
import type {} from '@mui/x-data-grid/themeAugmentation';
import { ChipTheme } from './ChipTheme';
import { ToggleButtonGroupTheme } from './ToggleButtonGroupTheme';
import { AlertTheme } from './AlertTheme';
import { TabsTheme } from './TabsTheme';
import { DialogTheme } from './DialogTheme';
import { TooltipTheme } from './ToolTipTheme';
import { CardContentTheme } from './CardContentTheme';
import { CircularProgressTheme } from './CircularProgressTheme';
import { CardHeaderTheme } from './CardHeaderTheme';
import { BreakpointsTheme } from './BreakpointTheme';
import { TableTheme } from './TableTheme';
import { IconButtonTheme } from './IconButtonTheme';

const muiLocales: Record<SupportedLanguage, Localization> = {
  en: enUS,
  ca: caES,
  es: esES,
  fr: frFR,
  pt: ptPT,
  nl: nlNL,
  it: itIT,
  de: deDE,
  uz: frFR, // for testing purposes
};

const getMuiLocale = (locale: SupportedLanguage): Localization => muiLocales[locale];

export const breakpoints = {
  mobile: '480px',
  tablet: '1024px',
  homeTablet: '1280px' /* This one has been added and need to be discussed between designer */,
};

// TODO:: Replace isMobileScreen() & isTabletScreen() with useMediaBreakpoints hook
export const isMobileScreen = () => window.matchMedia(`(max-width: ${breakpoints.mobile})`).matches;
export const isTabletScreen = () => window.matchMedia(`(max-width: ${breakpoints.tablet})`).matches;
export const isTouchDevice = () => 'ontouchstart' in window || navigator.maxTouchPoints;

export const themeV5 = createTheme(
  {
    breakpoints: BreakpointsTheme,
    palette: PaletteTheme,
    typography: TypographyTheme,
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            fontSize: '0.875rem',
            lineHeight: 1.43,
            letterSpacing: '0.01071em',
          },
        },
      },
      MuiTabs: TabsTheme,
      MuiButton: ButtonTheme,
      MuiAlert: AlertTheme,
      MuiCard: CardTheme,
      MuiCardHeader: CardHeaderTheme,
      MuiCardContent: CardContentTheme,
      MuiTooltip: TooltipTheme,
      MuiDialog: DialogTheme,
      MuiChip: ChipTheme,
      MuiCircularProgress: CircularProgressTheme,
      MuiSwitch: SwitchTheme,
      MuiDataGrid: DataGridTheme,
      MuiToggleButtonGroup: ToggleButtonGroupTheme,
      MuiSnackbarContent: {
        styleOverrides: {
          root: {
            borderRadius: '8px',
            backgroundColor: 'black',
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            borderRadius: '8px',
          },
        },
      },
      MuiInput: InputTheme,

      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: '8px',
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            borderRadius: '8px',
          },
        },
      },
      MuiLinearProgress: {
        styleOverrides: {
          root: {
            borderRadius: '8px',
          },
        },
      },
      MuiStack: {
        defaultProps: {
          width: '100%',
        },
      },
      MuiPaper: {
        styleOverrides: {
          elevation1: {
            boxShadow:
              '0px 2px 1px -1px rgba(0, 0, 0, 0.06), 0px 1px 1px rgba(0, 0, 0, 0.04), 0px 1px 3px rgba(0, 0, 0, 0.04)',
          },
          elevation2: {
            boxShadow:
              '0px 3px 1px -2px rgba(0, 0, 0, 0.06), 0px 2px 2px rgba(0, 0, 0, 0.04), 0px 1px 5px rgba(0, 0, 0, 0.04)',
          },
          elevation3: {
            boxShadow:
              '0px 3px 3px -2px rgba(0, 0, 0, 0.06), 0px 3px 4px rgba(0, 0, 0, 0.04), 0px 1px 8px rgba(0, 0, 0, 0.04)',
          },
          elevation4: {
            boxShadow:
              '0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 5px rgba(0, 0, 0, 0.04), 0px 1px 10px rgba(0, 0, 0, 0.04)',
          },
          elevation5: {
            boxShadow:
              '0px 3px 5px -1px rgba(0, 0, 0, 0.06), 0px 5px 8px rgba(0, 0, 0, 0.04), 0px 1px 14px rgba(0, 0, 0, 0.04)',
          },
          elevation6: {
            boxShadow:
              '0px 3px 5px -1px rgba(0, 0, 0, 0.06), 0px 6px 10px rgba(0, 0, 0, 0.04), 0px 1px 18px rgba(0, 0, 0, 0.04)',
          },
          elevation7: {
            boxShadow:
              '0px 4px 5px -2px rgba(0, 0, 0, 0.06), 0px 7px 10px 1px rgba(0, 0, 0, 0.04), 0px 2px 16px 1px rgba(0, 0, 0, 0.04)',
          },
          elevation8: {
            boxShadow:
              '0px 5px 5px -3px rgba(0, 0, 0, 0.06), 0px 8px 10px 1px rgba(0, 0, 0, 0.04), 0px 3px 14px 2px rgba(0, 0, 0, 0.04)',
          },
        },
      },
      MuiTable: TableTheme,
      MuiIconButton: IconButtonTheme,
    },
  },
  getMuiLocale(language()),
);
