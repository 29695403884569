import { colorUsage } from '@sundayapp/b2b-react-component-library';
import React from 'react';
import { Box } from '@mui/material';
import { SundayText } from 'src/app/component/Text/Text';

export type ContractItemNameProps = {
  name: string;
};

export const IlotItemName = ({ name }: ContractItemNameProps) => {
  const fontColor = colorUsage.primaryText;

  return (
    <Box flexDirection="row" display="flex" textOverflow="ellipsis">
      <SundayText size="xl" color={fontColor} sx={{ textOverflow: 'ellipsis' }}>
        {name}
      </SundayText>
    </Box>
  );
};
