import { PageCard } from 'src/enterprise/configuration-replication/pages/UserManagementPage';
import { Box, Button, styled, Typography } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { useNavigate } from 'react-router';
import { AddUserForm } from 'src/enterprise/configuration-replication/pages/UserManagementPage/Form/AddUserForm';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useStoreUsers } from 'src/pages/Settings/UserPermissions/mutation/useBusinessUsersQuery';
import { UserRole } from 'src/domain/user/UserRole';

interface User {
  id: number;
  role: UserRole;
  email: string;
  phoneNumber: string;
  locationAccess: string[];
}

interface Users {
  users: User[];
}

export const SecondaryButton = styled(Button)({
  padding: '6px 16px',
});

export const PrimaryButton = styled(Button)({
  padding: '6px 16px',
  '&:disabled': {
    backgroundColor: '#DDDDE4',
    borderColor: '#DDDDE4',
    color: '#B4B4BB',
  },
});

export const AddUserPage = () => {
  const intl = useIntl();

  const navigate = useNavigate();
  const userFields = { id: 0, role: '' as UserRole, email: '', phoneNumber: '', locationAccess: [] };
  const methods = useForm<Users>({
    defaultValues: { users: [userFields] },
  });
  const mutation = useStoreUsers();

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'users',
  });

  const watchFieldArray = methods.watch('users');
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  const addUser = async (data: User[]) => {
    try {
      mutation.mutate(data);
    } catch (e: any) {
      console.log(e.message);
    }
  };

  const handleAddAnotherUser = () => {
    append({ id: fields.length, role: '' as UserRole, email: '', phoneNumber: '', locationAccess: [] });
  };

  const handleBackNavigation = () => {
    navigate(-1);
  };

  const hasEmptyFields =
    watchFieldArray.filter(
      (s: User) =>
        s.role === ('' as UserRole) && s.email === '' && s.phoneNumber === '' && s.locationAccess.length === 0,
    ).length > 0;

  const onSubmit = (data: Users) => {
    const dataToSubmit = data.users.map((user) => {
      return {
        ...user,
        role: user.role as UserRole,
      };
    });
    addUser(dataToSubmit).then();
  };

  return (
    <PageCard>
      <Box sx={{ display: 'flex', alignItems: 'center', alignSelf: 'stretch' }}>
        <Box
          sx={{ display: 'flex', alignItems: 'center', alignSelf: 'stretch', cursor: 'pointer', gap: '8px' }}
          onClick={handleBackNavigation}
        >
          <ChevronLeft sx={{ width: '20px', height: '20px' }} />
          <Typography sx={{ fontSize: '20px' }}>
            {intl.formatMessage({ id: 'user_management.add_user.pageTitle' })}
          </Typography>
        </Box>
      </Box>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            {controlledFields.map((row, index) => (
              <AddUserForm
                row={row}
                key={row.id}
                hasMultipleRows={controlledFields.length > 1}
                onDeleteRow={() => remove(index)}
              />
            ))}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', marginTop: '36px' }}>
            <SecondaryButton
              variant="contained"
              color="secondary"
              onClick={handleAddAnotherUser}
              disabled={hasEmptyFields}
            >
              {intl.formatMessage({ id: 'user_management.button.add_another_user' })}
            </SecondaryButton>
            <PrimaryButton variant="contained" disabled={hasEmptyFields} type={'submit'}>
              {intl.formatMessage({ id: 'user_management.button.send_invite' })}
            </PrimaryButton>
          </Box>
        </form>
      </FormProvider>
    </PageCard>
  );
};
