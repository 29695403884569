// @ts-ignore
import { alpha, Theme } from '@mui/material';
import { ButtonProps } from '@mui/material/Button/Button';

export const ButtonTheme = {
  styleOverrides: {
    root: ({ ownerState, theme }: { ownerState: ButtonProps; theme: Theme }) => ({
      color: 'black',
      display: 'flex',
      justifyContent: 'start',
      width: 'fit-content',
      padding: '16px',
      borderRadius: '36px',
      boxShadow: 'none',
      height: '46px',
      '&:hover': {
        backgroundColor: alpha('#000000', 0.04),
        borderColor: '#8d8d8d',
      },
      ...(ownerState.size === 'small' && {
        height: '34px',
        fontSize: 15,
      }),
      ...(ownerState.size === 'large' && {
        height: '56px',
        fontSize: 20,
      }),
      ...(ownerState.variant === 'outlined' && {
        padding: '16px 32px 16px 32px',
        backgroundColor: 'white',
        border: '1px solid #000000',
        color: 'black',
        '&:hover': {
          backgroundColor: alpha('#000000', 0.04),
          borderColor: '#8d8d8d',
        },
        '&:disabled': {
          backgroundColor: '#B4B4BB',
          borderColor: '#B4B4BB',
          color: '#696969',
        },
      }),
      ...(ownerState.variant === 'contained' && {
        padding: '16px 32px 16px 32px',
        backgroundColor: 'black',
        color: 'white',
        '&:hover': {
          backgroundColor: '#424248',
          borderColor: '#424248',
        },
        '&:disabled': {
          backgroundColor: '#B4B4BB',
          borderColor: '#B4B4BB',
          color: 'white',
        },
      }),
      ...(ownerState.variant === 'contained' &&
        ownerState.color === 'error' && {
          backgroundColor: theme.palette.error.light,
          color: theme.palette.error.main,
          '&:hover': {
            backgroundColor: theme.palette.error.main,
            color: 'white',
          },
        }),
      ...(ownerState.variant === 'contained' &&
        ownerState.color === 'secondary' && {
          backgroundColor: theme.palette.secondary.light,
          color: theme.palette.text.primary,
          '&:hover': {
            backgroundColor: 'white',
            color: theme.palette.secondary.contrastText,
          },
          '&:disabled': {
            backgroundColor: '#DDDDE4',
            borderColor: '#DDDDE4',
            color: '#B4B4BB',
          },
        }),
      ...(ownerState.variant === 'outlined' &&
        ownerState.color === 'error' && {
          backgroundColor: 'transparent',
          border: `1px solid ${theme.palette.error.main}`,
          color: theme.palette.error.main,
          '&:hover': {
            transition: 'background-color 0.3s, border-color 0.3s',
            backgroundColor: 'rgb(254, 242, 242)',
          },
        }),
      ...(ownerState.variant === 'outlined' &&
        ownerState.color === 'secondary' && {
          backgroundColor: `${theme.palette.background.paper}`,
          border: '1px solid #DDDDE4',
          color: '#000',
          fontSize: 18,
          '&:hover': {
            backgroundColor: `${theme.palette.background.paper}`,
          },
        }),
    }),
  },
};
