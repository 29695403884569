// eslint-disable-next-line @typescript-eslint/no-redeclare
import { colorUsage } from '@sundayapp/b2b-react-component-library';
import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { Menu } from '../../../domain/Menu';
import { spaceUsages } from 'src/app/theme/SpaceUsagesTheme';
import { SundayText } from 'src/app/component/Text/Text';

type MenuLastEditionDateProps = {
  menu: Menu;
};

const MenuLastEdition = styled.div`
  padding-right: ${spaceUsages.mediumLarge};
`;

const options = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
} as Intl.DateTimeFormatOptions;

const MenuLastEditionDate = ({ menu }: MenuLastEditionDateProps) => {
  const intl = useIntl();

  return (
    <MenuLastEdition>
      {menu.lastModifiedAt && (
        <SundayText size="small" color={colorUsage.secondaryText}>
          {`${intl.formatMessage({ id: 'menus.edition.last_edition' })} ${new Date(
            menu.lastModifiedAt,
          ).toLocaleDateString(intl.locale, options)}`}
        </SundayText>
      )}
    </MenuLastEdition>
  );
};

export default MenuLastEditionDate;
