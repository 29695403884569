import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { usersApi } from 'src/pages/Settings/UserPermissions/query/UsersApi';
import { useBusinessIdOrThrow } from 'src/business/hooks/useBusinessId';
import { queryKeys } from 'src/app/queries/utils';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { UserRole } from 'src/domain/user/UserRole';
import { useDispatch } from 'react-redux';
import { showSnackbar } from 'src/utils/snackbar/SnackbarSlice';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { useState } from 'react';
import { BusinessUser } from 'src/domain/user/BusinessUser';
import { useNavigate } from 'react-router';

export interface User {
  role: UserRole;
  email: string;
  phoneNumber: string;
  locations?: string[];
}

interface UpdateUser {
  id: string;
  role: UserRole;
  email: string;
  phoneNumber: string;
  locations?: string[];
}

export const useListUsers = ({ businessId = '', userIds = [''] }) => {
  const configuration = ConfigurationLoader.load();
  const baseUrl = configuration.merchantAccountsBaseUrl;
  const hasUserIds = userIds.filter((userId) => userId !== '').length;

  return useQuery({
    queryKey: [queryKeys.LIST_BUSINESS_USERS, businessId, baseUrl],
    queryFn: () => usersApi.listUsers(baseUrl, businessId),
    select: (data) => {
      if (!hasUserIds) return data;

      return data.filter((user: BusinessUser) => userIds.includes(user.userId));
    },
  });
};

export const useShowUser = ({ businessId = '', userId = '' }) => {
  const configuration = ConfigurationLoader.load();
  const baseUrl = configuration.merchantAccountsBaseUrl;

  return useQuery({
    queryKey: ['show user', { userId }, businessId, baseUrl],
    queryFn: () => usersApi.showUser(baseUrl, businessId, userId),
  });
};

export const useStoreUsers = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const businessId = useBusinessIdOrThrow();
  const configuration = ConfigurationLoader.load();
  const baseUrl = configuration.merchantAccountsBaseUrl;
  const waiterAppBackendUrl = configuration.waiterAppBackendUrl;
  const [countUsers, setCountUsers] = useState(0);

  return useMutation({
    mutationFn: async (users: User[]) => {
      dispatch(
        showSnackbar({
          title: intl.formatMessage({ id: 'user_management.add.status.loading' }),
          text: '',
          positionType: 'bottom',
          isLoader: true,
          isAlert: false,
        }),
      );
      setCountUsers(users.length);
      try {
        const userPromises = users.map(async (user) => {
          if (['ADMIN', 'GENERAL_MANAGER', 'MANAGER', 'STAFF'].includes(user.role))
            await usersApi.inviteUsers(businessId, user.email, baseUrl);
          if (['WAITER'].includes(user.role))
            await usersApi.inviteUserWithRole(
              [
                {
                  role: user.role,
                  phoneNumber: user.phoneNumber,
                  expirationDate: null,
                },
              ],
              businessId,
              waiterAppBackendUrl,
            );
          await usersApi.storeUsers(user, baseUrl, businessId);
        });

        await Promise.all(userPromises);

        return users;
      } catch (error) {
        throw error;
      }
    },
    onMutate: async (users: User[]) => {
      const previousUsers = queryClient.getQueryData([queryKeys.LIST_BUSINESS_USERS, businessId, baseUrl]);

      queryClient.setQueryData([queryKeys.LIST_BUSINESS_USERS, businessId, baseUrl], (old: User[] | undefined) => {
        if (!old) return [];
        return [...old, ...users];
      });

      return { previousUsers };
    },
    onSuccess: () => {
      queryClient
        .invalidateQueries({
          queryKey: [queryKeys.LIST_BUSINESS_USERS, businessId, baseUrl],
        })
        .then(() => {
          navigate('..');

          dispatch(
            showSnackbar({
              title: intl.formatMessage({ id: 'user_management.add.status.success.title' }),
              text: intl.formatMessage({ id: 'user_management.add.status.success.text' }, { countUsers: countUsers }),
              positionType: 'bottom',
              type: 'success',
              isLoader: false,
              isAlert: true,
            }),
          );
        });
    },
    onError: (error, users, context) => {
      queryClient.setQueryData([queryKeys.LIST_BUSINESS_USERS, businessId, baseUrl], context?.previousUsers);
      dispatch(
        showSnackbar({
          title: intl.formatMessage({ id: 'user_management.add.status.error.title' }),
          text: intl.formatMessage({ id: 'user_management.add.status.error.subTitle' }),
          positionType: 'bottom',
          type: 'error',
          isLoader: false,
          isAlert: true,
        }),
      );
    },
  });
};

export const useUpdateUsers = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const businessId = useBusinessIdOrThrow();
  const configuration = ConfigurationLoader.load();
  const baseUrl = configuration.merchantAccountsBaseUrl;
  const [countUsers, setCountUsers] = useState(0);

  return useMutation({
    mutationFn: async (users: UpdateUser[]) => {
      dispatch(
        showSnackbar({
          title: intl.formatMessage({ id: 'user_management.edit.status.loading' }),
          text: '',
          positionType: 'bottom',
          isLoader: true,
          isAlert: false,
        }),
      );
      setCountUsers(users.length);
      try {
        const userPromises = users.map(async (user) => {
          await usersApi.updateUsers(businessId, user.id, user, baseUrl);
        });

        await Promise.all(userPromises);

        return users;
      } catch (error) {
        throw error;
      }
    },
    onMutate: async (users: User[]) => {
      const previousUsers = queryClient.getQueryData([queryKeys.LIST_BUSINESS_USERS, businessId, baseUrl]);

      queryClient.setQueryData([queryKeys.LIST_BUSINESS_USERS, businessId, baseUrl], (old: User[] | undefined) => {
        if (!old) return [];
        return [...old, ...users];
      });

      return { previousUsers };
    },
    onSuccess: () => {
      queryClient
        .invalidateQueries({
          queryKey: [queryKeys.LIST_BUSINESS_USERS, businessId, baseUrl],
        })
        .then(() => {
          navigate('..');
          dispatch(
            showSnackbar({
              title: intl.formatMessage({ id: 'user_management.edit.status.success.title' }),
              text: intl.formatMessage(
                { id: 'user_management.edit.status.success.subTitle' },
                { countUsers: countUsers },
              ),
              positionType: 'bottom',
              type: 'success',
              isLoader: false,
              isAlert: true,
            }),
          );
        });
    },
    onError: (error, users, context) => {
      queryClient.setQueryData([queryKeys.LIST_BUSINESS_USERS, businessId, baseUrl], context?.previousUsers);
      dispatch(
        showSnackbar({
          title: intl.formatMessage({ id: 'user_management.edit.status.error.title' }),
          text: intl.formatMessage({ id: 'user_management.edit.status.error.subTitle' }),
          positionType: 'bottom',
          type: 'error',
          isLoader: false,
          isAlert: true,
        }),
      );
    },
  });
};

export const useDeleteUsers = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const businessId = useBusinessIdOrThrow();
  const configuration = ConfigurationLoader.load();
  const baseUrl = configuration.merchantAccountsBaseUrl;
  const [countDeletedUsers, setCountDeletedUsers] = useState(0);

  return useMutation({
    mutationFn: async (userIds: string[]) => {
      dispatch(
        showSnackbar({
          title: intl.formatMessage({ id: 'user_management.delete.status.loading.title' }),
          text: '',
          positionType: 'bottom',
          isLoader: true,
          isAlert: false,
        }),
      );
      setCountDeletedUsers(userIds.length);
      await Promise.all(userIds.map((userId) => usersApi.deleteUsers(businessId, userId, baseUrl)));
      return userIds;
    },
    onMutate: async (userIds: string[]) => {
      const previousUsers = queryClient.getQueryData([queryKeys.LIST_BUSINESS_USERS, businessId, baseUrl]);
      queryClient.setQueryData([queryKeys.LIST_BUSINESS_USERS, businessId, baseUrl], (old: BusinessUser[]) =>
        old.filter((user) => !userIds.includes(user.userId)),
      );
      return { previousUsers };
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [queryKeys.LIST_BUSINESS_USERS, { businessId }] }).then(() => {
        dispatch(
          showSnackbar({
            title: intl.formatMessage({ id: 'user_management.delete.status.success.title' }),
            text: intl.formatMessage(
              { id: 'user_management.delete.status.success.text' },
              { countUsers: countDeletedUsers },
            ),
            positionType: 'bottom',
            type: 'success',
            isLoader: false,
            isAlert: true,
          }),
        );
      }),
    onError: (error, users, context) => {
      queryClient.setQueryData([queryKeys.LIST_BUSINESS_USERS, businessId, baseUrl], context?.previousUsers);
      dispatch(
        showSnackbar({
          title: intl.formatMessage({ id: 'user_management.delete.status.error.title' }),
          text: intl.formatMessage({ id: 'user_management.delete.status.error.text' }),
          positionType: 'bottom',
          type: 'error',
          isLoader: false,
          isAlert: true,
        }),
      );
    },
  });
};
