import axios from 'axios';
import { CorrelationIdGenerator } from 'src/utils/correlation-id/CorrelationIdGenerator';
import { Authentication } from '../domain/authentication';

export const configureAxiosWithAuthentication = (authentication: Authentication): number =>

  axios.interceptors.request.use(async (config) => {
    const accessToken = await authentication.getToken();
    config.headers.Authorization = `Bearer ${accessToken}`;
    if (!config.headers.get('correlation_id')) {
      config.headers.set('correlation_id', CorrelationIdGenerator.generate());
    }

    return config;
  });
