import React, { useState } from 'react';
import { DateRange } from 'src/sundayplus/reviews/domain/DateRange';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { useDimensionsAnalysisInTimeRange } from 'src/sundayplus/reviews/guest-experience/infrastructure/useDimensionsAnalysisInTimeRange';
import { Column } from 'src/sundayplus/Column';
import { GuestExperienceHeader } from 'src/sundayplus/reviews/guest-experience/GuestExperienceHeader';
import { OverallSatisfaction } from 'src/sundayplus/reviews/guest-experience/overallSatisfaction/OverallSatisfaction';
import { DimensionsAnalysis } from 'src/sundayplus/reviews/guest-experience/dimension/DimensionsAnalysis';
import { tShirt } from 'src/sundayplus/Size';

export const GuestExperience = () => {
  const business = useCurrentBusinessOrThrow();
  const [dateRange, setDateRange] = useState<DateRange>(DateRange.lastSevenDays(business.timezone));
  const dimensionAnalysisQueryResult = useDimensionsAnalysisInTimeRange(business.id, dateRange);

  return (
    <>
      <Column gap={tShirt('l')}>
        <GuestExperienceHeader dateRange={dateRange} onDateRangeChange={setDateRange} />
        <OverallSatisfaction dateRange={dateRange} />
        <DimensionsAnalysis dateRange={dateRange} dimensionAnalysisQueryResult={dimensionAnalysisQueryResult} />
      </Column>
    </>
  );
};
