import styled from 'styled-components';
import { spaceUsages } from 'src/app/theme/SpaceUsagesTheme';

export const CustomizationForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
`;

export const NotificationContainer = styled.div`
  margin-bottom: ${spaceUsages.mediumLarge};
  width: 100%;
`;
