import EmptyStateDesktopBanner from 'src/home/assets/banner-empty-staff-perf-desktop.png';
import EmptyStateMobileBanner from 'src/home/assets/banner-empty-staff-perf.png';
import { Box, Typography } from '@mui/material';
import { useIntl } from 'src/app/i18n/TypedIntl';

const WarningRoundedIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        /* eslint-disable-next-line max-len */
        d="M17.0828 2.22453C16.393 1.91783 15.6056 1.91783 14.9158 2.22453C14.3831 2.4614 14.0371 2.87863 13.7963 3.21859C13.5595 3.55285 13.3061 3.99075 13.0273 4.47234L2.00443 23.5118C1.72455 23.9952 1.47021 24.4344 1.29776 24.8072C1.12249 25.186 0.932351 25.6953 0.99295 26.2765C1.07136 27.0287 1.46542 27.7121 2.07704 28.1568C2.5497 28.5005 3.08569 28.5911 3.50137 28.6292C3.91037 28.6668 4.41791 28.6667 4.97643 28.6667H27.0221C27.5807 28.6667 28.0883 28.6668 28.4973 28.6292C28.9129 28.5911 29.4489 28.5005 29.9216 28.1568C30.5332 27.7121 30.9273 27.0287 31.0057 26.2765C31.0663 25.6953 30.8761 25.186 30.7009 24.8072C30.5284 24.4345 30.2741 23.9953 29.9943 23.5119L18.9713 4.47229C18.6925 3.99073 18.4391 3.55283 18.2023 3.21859C17.9615 2.87863 17.6155 2.4614 17.0828 2.22453ZM17.3327 12C17.3327 11.2637 16.7357 10.6667 15.9993 10.6667C15.263 10.6667 14.666 11.2637 14.666 12V17.3334C14.666 18.0698 15.263 18.6667 15.9993 18.6667C16.7357 18.6667 17.3327 18.0698 17.3327 17.3334V12ZM15.9993 21.3334C15.263 21.3334 14.666 21.9303 14.666 22.6667C14.666 23.4031 15.263 24 15.9993 24H16.0127C16.7491 24 17.346 23.4031 17.346 22.6667C17.346 21.9303 16.7491 21.3334 16.0127 21.3334H15.9993Z"
        fill="black"
      />
    </svg>
  );
};

export const EmptyStaffPerformanceCard = () => {
  const intl = useIntl();

  return (
    <Box
      sx={{
        background: `url(${EmptyStateDesktopBanner})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '268px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        ['@media (min-width: 1300px)']: {
          backgroundSize: 'cover',
        },

        ['@media (max-width: 768px)']: {
          background: `url(${EmptyStateMobileBanner})`,
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          gap: '8px',
        }}
      >
        <WarningRoundedIcon />
        <Typography sx={{ fontSize: '21px', fontWeight: 500 }}>
          {intl.formatMessage({ id: 'venue.home.section.staffPerformance.empty.title' })}
        </Typography>
        <Typography sx={{ fontSize: '18px', color: 'text.secondary', fontWeight: 500 }}>
          {intl.formatMessage({ id: 'venue.home.section.staffPerformance.empty.message' })}
        </Typography>
      </Box>
    </Box>
  );
};
