import React from 'react';
import { Stack } from '@mui/material';
import { PendingTipsDispatchLoader } from 'src/tips/dispatch/PendingTipsDispatchLoader';
import { TipsDispatchedLoader } from 'src/tips/dispatch/TipsDispatchedLoader';
import { restrictedToTipsManager } from 'src/tips/page/restrictedToTipsManager';

export const DispatchPooledTipsPage = restrictedToTipsManager(() => {
  return (
    <Stack spacing={4}>
      <PendingTipsDispatchLoader />
      <TipsDispatchedLoader />
    </Stack>
  );
});
