import { useQuery } from '@tanstack/react-query';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { HttpTipsRepository } from 'src/tips/infrastructure/HttpTipsRepository';
import axios from 'axios';
import { BusinessId } from 'src/business/domain/Business';
import { SourceFilterValue } from 'src/tips/domain/Tips';
import { CurrencyCode } from '@sundayapp/web-money';

const QUERY_KEY_BUSINESS_TIPPING_CONFIGURATION = 'business_tipping_breakdown';

export const useGetBusinessTipsBreakdown = (
  businessId: BusinessId,
  currency: CurrencyCode,
  filters: {
    sourceFilter: SourceFilterValue;
    from: Date;
    to: Date;
  },
) =>
  useQuery({
    queryKey: [
      QUERY_KEY_BUSINESS_TIPPING_CONFIGURATION,
      businessId,
      currency,
      filters.sourceFilter,
      filters.from,
      filters.to,
    ],
    queryFn: () => {
      const configuration = ConfigurationLoader.load();
      const repository = new HttpTipsRepository(axios, configuration.bookkeepingApiBaseUrl);
      return repository.getBusinessTipsBreakdown(businessId, currency, filters.sourceFilter, filters.from, filters.to);
    },
  });
