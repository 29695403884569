import { Box, CircularProgress, Grid, IconButton, Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { ArrowBack } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { LAST_30_DAYS, LAST_7_DAYS, TimeframePicker, TimeframeSelection } from './TimeframePicker';
import { ReviewPreviewCard } from './ReviewPreviewCard';
import { SelectedReview } from './SelectedReview';
import { Review } from '../browse/domain/Review';
import { emptyReviews, Reviews } from '../browse/domain/Reviews';
import { useListReviews } from './infrastructure/useListReviews';
import { AllReviewsWereReplied } from './AllReviewsWereReplied';
import { useReplyToReviewFromReviews } from './domain/useReplyToReview';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

export function MobileReplyToReviews() {
  const business = useCurrentBusinessOrThrow();
  const [selectedReview, setSelectedReview] = useState<Review | undefined>(undefined);
  const [timeRange, setTimeRange] = useState<TimeframeSelection>(LAST_7_DAYS(business.timezone));
  const [reviews, setReviews] = useState<Reviews>(emptyReviews().forDateRange(timeRange.range));
  const replyToReview = useReplyToReviewFromReviews();

  const { isLoading, data: fetchedReviews } = useListReviews(business, timeRange.range.toTimeRange());

  const { data: thirtyDaysData } = useListReviews(business, LAST_30_DAYS(business.timezone).range.toTimeRange());
  const updateReviewsState = (newReviews: Reviews) => {
    const updatedReviews = newReviews.withoutReviewReply();
    setReviews(updatedReviews);
    setSelectedReview(undefined);
  };

  useEffect(() => {
    if (fetchedReviews) {
      updateReviewsState(fetchedReviews.forDateRange(timeRange.range));
    }
  }, [fetchedReviews]);

  useEffect(() => {
    if (thirtyDaysData) {
      updateReviewsState(reviews.updateWithReviews(thirtyDaysData));
    }
  }, [thirtyDaysData]);

  const onReply = (review: Review, reply: string) =>
    replyToReview(review, reply, reviews).then((allReviews: Reviews) => updateReviewsState(allReviews));

  const showListOfReviews = (): boolean => !selectedReview;

  const showSelectedReview = (): boolean => !!selectedReview;

  if (isLoading) {
    return <CircularProgress />;
  }

  if (reviews.length() === 0) {
    return <AllReviewsWereReplied />;
  }

  return (
    <Stack direction="column" gap={2}>
      <Stack direction="row" alignItems="baseline">
        <TimeframePicker timeframe={timeRange} onChange={setTimeRange} timezone={business.timezone} />
        <Box padding="1rem">
          <Typography>
            <FormattedMessage id="venue.reviews.reply.subtitle" values={{ reviewCount: reviews.length() }} />
          </Typography>
        </Box>
      </Stack>
      {showSelectedReview() && (
        <IconButton size="large" onClick={() => setSelectedReview(undefined)}>
          <ArrowBack sx={{ fontSize: 30 }} />
        </IconButton>
      )}
      <Grid container direction="row">
        {showListOfReviews() && (
          <Grid item sm={5} xs={12} overflow="scroll" maxHeight="85vh">
            <Stack spacing={2} padding="0 1rem 0 0">
              {reviews.asArray().map((review) => (
                <ReviewPreviewCard
                  review={review}
                  key={review.id}
                  onSelect={setSelectedReview}
                  selected={review.id === selectedReview?.id}
                />
              ))}
            </Stack>
          </Grid>
        )}
        {showSelectedReview() && (
          <Grid item sm={7} xs={12}>
            {selectedReview && <SelectedReview review={selectedReview} onReply={onReply} />}
          </Grid>
        )}
      </Grid>
    </Stack>
  );
}
