import { CurrencyCode, Money } from '@sundayapp/web-money';
import { ValuesOf } from 'src/utils/typescript/valuesOf';
import { NeptingConfiguration } from './PaymentTerminalRepository';
import { CountryCode } from 'src/domain/CountryCode';

export type PaymentTerminalConfiguration = {
  id?: string;
  tips: TipsConfiguration;
  waiter: WaiterConfiguration;
  psoConnection: PsoConnectionConfiguration;
  scan: ScanConfiguration;
  fastPayment: FastPaymentConfiguration;
  general: GeneralConfiguration;
  userSelectionMode: UserSelectionMode;
  users: UserConfiguration;
  concertConfiguration: ConcertProtocolConfiguration;
};

export const userSelectionMode = {
  LEGACY_WAITER_LIST: 'LEGACY_WAITER_LIST',
  USER_LIST_FROM_MERCHANT_ACCOUNT: 'USER_LIST_FROM_MERCHANT_ACCOUNT',
} as const;

type UserSelectionMode = ValuesOf<typeof userSelectionMode>;

export type GeneralConfiguration = {
  name: string;
  externalCardAcquirerId: string | undefined;
  neptingWsdlUrl: string | undefined;
  ilotId?: string;
  printMerchantTickets: boolean;
  alertOnHighAmountEnabled: boolean;
  alertOnLowAmountEnabled: boolean;
  lowAmountThreshold: Money;
  highAmountThreshold: Money;
};

export type WaiterConfiguration = {
  enabled: boolean;
  waiterList: string[];
  waiters: Waiter[];
};

export type UserConfiguration = {
  enabled: boolean;
  users: User[];
  userGuestEnabled: boolean;
};

export type User = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  enabled: boolean;
  codeEnabled: boolean;
};

export type PsoConnectionConfiguration = {
  enabled: boolean;
};

export type ScanConfiguration = {
  enabled: boolean;
};

export type ConcertProtocolConfiguration = {
  enabled: boolean;
};

export type FastPaymentConfiguration = {
  enabled: boolean;
};

export type Waiter = {
  id: string;
  name: string;
};

export type TipsConfiguration = {
  userExperienceEnabled: boolean;
  roundedTipsEnabled: boolean;
  tipsEnabled: boolean;
  tipsLevels: {
    level1: TipsLevelConfiguration;
    level2: TipsLevelConfiguration;
    level3: TipsLevelConfiguration;
  };
};

export const tipsKind = {
  AMOUNT: 'AMOUNT',
  PERCENTAGE: 'PERCENTAGE',
} as const;

type TipsKind = ValuesOf<typeof tipsKind>;

export type TipsLevelConfiguration = {
  enabled: boolean;
  kind: TipsKind;
  value1: number;
  value2: number;
  value3: number;
  value4: number;
};

export const generalDefaultValue:GeneralConfiguration = {
  name: '',
  externalCardAcquirerId: '',
  neptingWsdlUrl: '',
  printMerchantTickets: true,
  alertOnHighAmountEnabled: false,
  alertOnLowAmountEnabled: false,
  lowAmountThreshold: { amount: 1, currency: CurrencyCode.EUR },
  highAmountThreshold: { amount: 1000, currency: CurrencyCode.EUR },
};
export const waiterDefaultValue = {
  enabled: false,
  waiterList: [],
  waiters: [],
};
export const posConnectionDefaultValue = {
  enabled: true,
};
export const scanDefaultValue = {
  enabled: true,
};
export const concertConfigurationDefaultValue = {
  enabled: true,
};
export const fastPaymentDefaultValue = {
  enabled: true,
};
export const tipsDefaultValue: TipsConfiguration = {
  userExperienceEnabled: true,
  roundedTipsEnabled: true,
  tipsEnabled: true,
  tipsLevels: {
    level1: {
      enabled: false,
      kind: 'AMOUNT',
      value1: 0,
      value2: 1,
      value3: 2,
      value4: 3,
    },
    level2: {
      enabled: true,
      kind: 'AMOUNT',
      value1: 0,
      value2: 2,
      value3: 4,
      value4: 6,
    },
    level3: {
      enabled: true,
      kind: 'PERCENTAGE',
      value1: 0,
      value2: 10,
      value3: 15,
      value4: 20,
    },
  },
};

export const defaultConfiguration = {
  id: undefined,
  tips: tipsDefaultValue,
  waiter: waiterDefaultValue,
  psoConnection: posConnectionDefaultValue,
  scan: scanDefaultValue,
  fastPayment: fastPaymentDefaultValue,
  general: generalDefaultValue,
  userSelectionMode: userSelectionMode.LEGACY_WAITER_LIST,
  users: {
    enabled: false,
    users: [],
    userGuestEnabled: false,
  },
  concertConfiguration: concertConfigurationDefaultValue,
};

export const defaultConfigurationWithCountry = (country: string): PaymentTerminalConfiguration => {
  if (country === CountryCode.US.toString()) {
    return {
      ...defaultConfiguration,
      tips: {
        ...defaultConfiguration.tips,
        roundedTipsEnabled: false,
      },
    };
  } else {
    return defaultConfiguration;
  }
};

export const defaultNeptingConfiguration: NeptingConfiguration = {
  merchantId: 'Pas de merchantId',
  webServiceUrl: 'pas de code',
  neptingIlots: [],
};
