import { Box, Button, IconButton, Typography } from '@mui/material';
import { themeV5 } from 'src/app/theme/ThemeV5';
import { SettingsIcon } from 'src/enterprise/configuration-replication/components/icons/SettingsIcon';
import { DeleteBinIcon } from 'src/enterprise/configuration-replication/components/icons/DeleteBinIcon';
import CloseIcon from '@mui/icons-material/Close';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { useState } from 'react';
import { DeleteUsersModal } from 'src/enterprise/configuration-replication/pages/UserManagementPage/Modal/DeleteUsersModal';
import { useNavigate } from 'react-router';

interface UserManageBarProps {
  countSelectedRows?: number;
  handleHideManageBar: () => void;
  selectedIds: string[];
}

export const UserManageBar = ({ countSelectedRows, handleHideManageBar, selectedIds }: UserManageBarProps) => {
  const navigate = useNavigate();
  const intl = useIntl();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDeleteUsers = () => {
    setShowDeleteModal(true);
  };

  const handleEditUsers = () => {
    navigate('edit?users=' + selectedIds.join(','));
  };

  if (countSelectedRows === 0) return;

  return (
    <Box
      sx={{
        display: 'flex',
        padding: '4px 8px',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '8px',
        background: themeV5.palette.text.primary,
        color: 'white',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <IconButton onClick={handleHideManageBar}>
          <CloseIcon sx={{ color: 'white' }} />
        </IconButton>
        <Typography>
          {intl.formatMessage({ id: 'user_management.select.selectedUsers' }, { countUsers: countSelectedRows })}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <Button sx={{ gap: '8px' }} onClick={handleEditUsers}>
          <SettingsIcon />
          <Typography sx={{ color: 'white' }}>
            {intl.formatMessage({ id: 'user_management.select.action.edit' })}
          </Typography>
        </Button>
        <Button sx={{ gap: '8px' }} onClick={handleDeleteUsers}>
          <DeleteBinIcon />
          <Typography sx={{ color: 'white' }}>
            {intl.formatMessage({ id: 'user_management.select.action.delete' })}
          </Typography>
        </Button>
      </Box>
      <DeleteUsersModal
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        selectedIds={selectedIds}
      />
    </Box>
  );
};
