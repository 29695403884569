import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { PosConnectionId } from 'src/pos/domain/PosConnection';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { PaymentMethodMapping } from 'src/domain/pos-connection/PaymentMethodMapping';
import { VposVenueRepositoryHttp } from 'src/infrastructure/pos/VposVenueRepositoryHttp';

export const QUERYKEY_PAYMENT_METHOD_MAPPING = 'payment_method_mapping';
const buildQueryKey = (posConnectionId: PosConnectionId | undefined) => [QUERYKEY_PAYMENT_METHOD_MAPPING, posConnectionId] as const;
export const getPaymentMethodMappingQuery = async ({
  queryKey: [, posConnectionId],
}: QueryFunctionContext<ReturnType<typeof buildQueryKey>>): Promise<PaymentMethodMapping | null> => {
  const configuration = ConfigurationLoader.load();

  const vposRepository = new VposVenueRepositoryHttp(configuration.vposVenueUrl);
  return vposRepository.getPaymentMethodsMapping(posConnectionId!!);
};

export const useGetPaymentMethodMapping = (posConnectionId: PosConnectionId | undefined) =>
  useQuery({
    queryKey: buildQueryKey(posConnectionId),
    enabled: !!posConnectionId,
    queryFn: getPaymentMethodMappingQuery,
  });
