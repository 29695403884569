import axios, { HttpStatusCode } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { PongoStore } from '../domain/PongoStore';
import { LoyaltyBusiness } from '../domain/LoyaltyBusiness';

export type ConfigureLoyaltyBusinessRequest =
  | PaytronixConfigureBusinessRequest
  | ComoConfigureBusinessRequest
  | ZerosixConfigureBusinessRequest;
type PaytronixConfigureBusinessRequest = {
  loyaltyReference: string;
  loyaltyConfiguration: {
    storeCode: string;
    merchantId: string;
    cardTemplateCode: string;
    burnEnabled: boolean;
    theme: string;
  };
};

type ComoConfigureBusinessRequest = {
  loyaltyReference: string;
  loyaltyConfiguration: {
    posId: string;
    apiKey: string;
    branchId: string;
    theme: string;
  };
};

type ZerosixConfigureBusinessRequest = {
  loyaltyReference: string;
  loyaltyConfiguration: {
    api_key: string;
    theme: string;
  };
};

export type UpdateBusinessConfigurationRequest = {
  loyaltyConfiguration: {
    rewardCode?: string;
    burnEnabled: boolean;
    theme: string;
  };
};

type GetLoyaltyBusinessResponse = {
  loyaltyReference: string;
  burnEnabled?: boolean;
  theme: string;
  loyaltyConfiguration: {
    posId?: string;
    apiKey?: string;
    branchId?: string;
    storeCode?: string;
    merchantId?: string;
    cardTemplateCode?: string;
    rewardCode?: string;
    burnEnabled?: boolean;
    theme?: string;
  };
};

export class LoyaltyRepository {
  constructor(private baseUrl: string) {}

  async getLoyaltyBusiness(businessId: string): Promise<LoyaltyBusiness | null> {
    const response = await axios.get<GetLoyaltyBusinessResponse | undefined>(
      `${this.baseUrl}/businesses/${businessId}`,
    );

    return response.data
      ? {
        loyaltyReference: response.data.loyaltyReference,
        burnEnabled: response.data.burnEnabled ? response.data.burnEnabled : false,
        theme: response.data.theme,
        loyaltyConfiguration: response.data.loyaltyConfiguration,
      }
      : null;
  }

  async deleteLoyaltyBusiness(businessId: string): Promise<boolean> {
    const response = await axios.delete(`${this.baseUrl}/businesses/${businessId}`);

    return response.status === HttpStatusCode.Ok;
  }

  async listStores(businessId: string): Promise<PongoStore[]> {
    const response = await axios.get(`${this.baseUrl}/businesses/${businessId}/pongo/stores`);
    return response.data;
  }

  async authenticateWithPongo(businessId: string, code: string) {
    try {
      await axios.post(
        `${this.baseUrl}/businesses/${businessId}/pongo/authenticate`,
        { code },
        {
          withCredentials: true,
          headers: {
            correlation_id: uuidv4(),
          },
        },
      );
      return true;
    } catch (e) {
      return false;
    }
  }

  async saveStore(businessId: string, storeId: string, rewardCode: string): Promise<boolean> {
    try {
      await axios.post(
        `${this.baseUrl}/businesses/${businessId}/pongo/stores`,
        { id: storeId, rewardCode },
        {
          withCredentials: true,
          headers: {
            correlation_id: uuidv4(),
          },
        },
      );
      return true;
    } catch (e) {
      return false;
    }
  }

  async saveBusinessConfiguration(businessId: string, request: ConfigureLoyaltyBusinessRequest) {
    try {
      await axios.post(`${this.baseUrl}/businesses/${businessId}/configure`, request, {
        headers: {
          correlation_id: uuidv4(),
        },
      });
      return true;
    } catch (e) {
      return false;
    }
  }

  async updateBusinessConfiguration(businessId: string, request: UpdateBusinessConfigurationRequest) {
    try {
      await axios.put(`${this.baseUrl}/businesses/${businessId}/configure`, request, {
        headers: {
          correlation_id: uuidv4(),
        },
      });
      return true;
    } catch (e) {
      return false;
    }
  }
}
