import axios, { AxiosError } from 'axios';
import {
  ConfigurationUpdateRequestDto,
  DnaConfiguration,
  NeptingConfiguration,
  PaymentTerminal,
  PaymentTerminalConfigurationDto,
  PaymentTerminalConfigurationSummaryDto,
  PaymentTerminalDetails,
  PaymentTerminalRepository,
  PayrocConfiguration,
  Provisioning,
  RefundRequestDto,
  StripeConfiguration, UpdateUserDto,
} from '../domain/PaymentTerminalRepository';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { Money } from '@sundayapp/web-money';
import { SuccessfulRequest } from 'src/domain/payment/Refund';

export class HttpPaymentTerminalRepository implements PaymentTerminalRepository {
  constructor(private paymentTerminalBackendUrl: string) {
  }

  async getNeptingConfiguration(enrollmentId: EnrollmentId): Promise<NeptingConfiguration> {
    return (await axios.get(`${this.paymentTerminalBackendUrl}/enrollments/${enrollmentId}/neptingConfiguration`)).data;
  }

  async saveNeptingConfiguration(enrollmentId: EnrollmentId, configuration: NeptingConfiguration): Promise<NeptingConfiguration> {
    return (await axios.post(`${this.paymentTerminalBackendUrl}/enrollments/${enrollmentId}/neptingConfiguration`, configuration)).data;
  }

  async getPayrocConfiguration(enrollmentId: EnrollmentId): Promise<PayrocConfiguration> {
    return (await axios.get(`${this.paymentTerminalBackendUrl}/enrollments/${enrollmentId}/payrocConfiguration`)).data;
  }

  async savePayrocConfiguration(enrollmentId: EnrollmentId, configuration: PayrocConfiguration): Promise<PayrocConfiguration> {
    return (await axios.post(`${this.paymentTerminalBackendUrl}/enrollments/${enrollmentId}/payrocConfiguration`, configuration)).data;
  }

  async getStripeConfiguration(enrollmentId: EnrollmentId): Promise<StripeConfiguration> {
    return (await axios.get(`${this.paymentTerminalBackendUrl}/enrollments/${enrollmentId}/stripeConfiguration`)).data;
  }

  async saveStripeConfiguration(enrollmentId: EnrollmentId, configuration: StripeConfiguration): Promise<StripeConfiguration> {
    return (await axios.post(`${this.paymentTerminalBackendUrl}/enrollments/${enrollmentId}/stripeConfiguration`, configuration)).data;
  }

  async getDnaConfiguration(enrollmentId: EnrollmentId): Promise<DnaConfiguration> {
    return (await axios.get(`${this.paymentTerminalBackendUrl}/enrollments/${enrollmentId}/dnaConfiguration`)).data;
  }

  async saveDnaConfiguration(enrollmentId: EnrollmentId, configuration: DnaConfiguration): Promise<PayrocConfiguration> {
    return (await axios.post(`${this.paymentTerminalBackendUrl}/enrollments/${enrollmentId}/dnaConfiguration`, configuration)).data;
  }

  async provision(enrollmentId: EnrollmentId, name: string, configurationId: string): Promise<Provisioning> {
    return (await axios.post(`${this.paymentTerminalBackendUrl}/enrollments/${enrollmentId}/paymentTerminals`, {
      name,
      configurationId,
    })).data;
  }

  async updateProvision(enrollmentId: EnrollmentId, terminalId: string, name: string, configurationId: string): Promise<any> {
    return (axios.put(`${this.paymentTerminalBackendUrl}/enrollments/${enrollmentId}/paymentTerminals/${terminalId}`, {
      name,
      configurationId,
    }));
  }

  async listPaymentTerminals(enrollmentId: EnrollmentId | undefined, withVersion: Boolean): Promise<PaymentTerminal[]> {
    if (enrollmentId === undefined) return [];
    return (await axios.get(`${this.paymentTerminalBackendUrl}/enrollments/${enrollmentId}/paymentTerminals?withVersion=${withVersion}`)).data;
  }

  async onlineStatuses(enrollmentId: EnrollmentId | undefined): Promise<PaymentTerminalDetails[]> {
    if (enrollmentId === undefined) return [];
    return (await axios.get(`${this.paymentTerminalBackendUrl}/enrollments/${enrollmentId}/paymentTerminals/statuses`)).data;
  }

  async delete(enrollmentId: EnrollmentId, id: string): Promise<any> {
    return axios.delete(`${this.paymentTerminalBackendUrl}/enrollments/${enrollmentId}/paymentTerminals/${id}`);
  }

  async configuration(enrollmentId: EnrollmentId, configurationId: string): Promise<PaymentTerminalConfigurationDto> {
    return (await axios.get(`${this.paymentTerminalBackendUrl}/enrollments/${enrollmentId}/configurations/${configurationId}`)).data;
  }

  async configurations(enrollmentId: EnrollmentId): Promise<PaymentTerminalConfigurationSummaryDto[]> {
    return (await axios.get(`${this.paymentTerminalBackendUrl}/enrollments/${enrollmentId}/configurations`)).data;
  }

  async updateConfiguration(enrollmentId: EnrollmentId, configId: string, configuration: ConfigurationUpdateRequestDto): Promise<any> {
    return axios.put(
      `${this.paymentTerminalBackendUrl}/enrollments/${enrollmentId}/configurations/${configId}`,
      configuration,
    );
  }

  async createConfiguration(enrollmentId: EnrollmentId, configuration: ConfigurationUpdateRequestDto): Promise<any> {
    return axios.post(
      `${this.paymentTerminalBackendUrl}/enrollments/${enrollmentId}/configurations`,
      configuration,
    );
  }

  async deleteConfiguration(enrollmentId: EnrollmentId, configId: string): Promise<any> {
    return axios.delete(`${this.paymentTerminalBackendUrl}/enrollments/${enrollmentId}/configurations/${configId}`);
  }

  async refund(enrollmentId: EnrollmentId, paymentId: string, amount: Money, comment: string, paymentTerminalId?: string): Promise<any> {
    const body: RefundRequestDto = {
      amount: amount.amount,
      currency: amount.currency,
      paymentTerminalId: paymentTerminalId?.length === 0 ? undefined : paymentTerminalId,
      comment,
    };

    function buildErrorMessageForPdq(error: AxiosError<void, any>) {
      if (error.response?.status == 401) {
        return 'Configuration invalid, please contact support';
      } else if (error.response?.status == 406) {
        return 'Already refunded please refresh the page';
      } else {
        return 'UNKNOWN';
      }
    }

    return axios.post(`${this.paymentTerminalBackendUrl}/enrollments/${enrollmentId}/payments/${paymentId}/refund`,
      body,
    ).then(
      () =>
        ({
          success: true,
          refundId: 'No refundId on PDQ',
        }) as SuccessfulRequest,
    )
      .catch((error: Error | AxiosError<void>) => {
        if (axios.isAxiosError(error)) {
          return {
            success: false,
            error: buildErrorMessageForPdq(error),
          };
        }
        return {
          success: false,
          error: 'UNKNOWN',
        };
      });
  }

  async cancelAuthorization(enrollmentId: EnrollmentId, paymentId: string): Promise<any> {
    return axios.post(`${this.paymentTerminalBackendUrl}/enrollments/${enrollmentId}/payments/${paymentId}/cancel`);
  }

  async onboardingCode(enrollmentId: EnrollmentId, paymentTerminalId: string): Promise<string> {
    return (await axios.put(`${this.paymentTerminalBackendUrl}/enrollments/${enrollmentId}/paymentTerminals/${paymentTerminalId}/onboardingCode`)).data;
  }

  async changeUserPassCode(enrollmentId: EnrollmentId, userId: string, code: string): Promise<any> {
    const body: UpdateUserDto = {
      code: code,
    };
    return (await axios.put(`${this.paymentTerminalBackendUrl}/enrollments/${enrollmentId}/user/${userId}`, body)).data;
  }
}
