import { z } from 'zod';
import { MoneyZ } from 'src/operations/pos-reconciliation/domain/MoneyZ';

export const DoneTipsDispatchZ = z.object({
  id: z.string(),
  amount: MoneyZ,
  startInclusive: z.coerce.date(),
  endExclusive: z.coerce.date(),
  dispatchedAt: z.coerce.date(),
  triggeredBy: z.string(),
});

export const DoneTipsDispatchZArray = z.array(DoneTipsDispatchZ);

export type DoneTipsDispatch = z.infer<typeof DoneTipsDispatchZ>;

export const computeNextTipsPayoutDate = (dispatchedAt: Date = new Date()) => {
  const dayOfWeek = dispatchedAt.getDay();
  const daysUntilMonday = dayOfWeek === 0 ? 1 : 8 - dayOfWeek;
  const nextMonday = new Date(dispatchedAt);
  nextMonday.setDate(dispatchedAt.getDate() + daysUntilMonday);
  return nextMonday;
};

export const computeScheduledDispatchPayoutDate = ({ dispatchedAt }: DoneTipsDispatch) => {
  /* We do not have dispatch statuses so:
   * Little hack to infer whether a dispatch has been made in the past or not.
   */
  const nextMonday = computeNextTipsPayoutDate(dispatchedAt);
  return nextMonday < new Date() ? null : nextMonday;
};

export const formatDispatchPayoutDate = (date: Date) =>
  date.toLocaleDateString(navigator.language, {
    month: 'long',
    day: 'numeric',
  });
