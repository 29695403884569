import { Box, Typography, useTheme } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import React from 'react';

export const ErrorLoadingFormData = () => {
  const theme = useTheme();
  return <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap="24px"
              padding="24px">

    <ErrorIcon sx={{ fontSize: 64 }} color={'error'} />

    <Box gap="16px" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <Typography variant="h4">
        <FormattedMessage id="settings.payments.checkout.onboarding.error.loadForm.title" />
      </Typography>

      <Typography variant="subtitle1" color={theme.palette.text.secondary} align="center">
        <FormattedMessage id="settings.payments.checkout.onboarding.error.loadForm.subtitle" />
      </Typography>
    </Box>
  </Box>;
};
