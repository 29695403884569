import * as React from 'react';
import { useState } from 'react';
import { Box, Button, Card, CardContent, styled, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { Product, ProductSource } from '../../domain/Product';
import { UpdateProductDto } from '../../repositories/ProductDto';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { ProductTagsInput } from './components/ProductTagsInput';
import { ProductName } from './components/ProductName';
import { ProductDescription } from './components/ProductDescription';
import { ProductShortDescription } from './components/ProductShortDescription';
import { ProductPrice } from './components/ProductPrice';
import { ProductAllergensInput } from './components/ProductAllergensInput';
import { useProductMutation } from '../../mutations/product/useProductMutation';
import { ProductCalories } from './components/ProductCalories';
import { BusinessId } from 'src/business/domain/Business';
import { ProductVat, TaxKind } from './components/ProductVat';
import { IsAvailableForOrderSwitch } from './components/IsAvailableForOrderSwitch';
import { IsEnabledSwitch } from 'src/menu/dynamic-menu/pages/ProductPage/components/IsEnabledSwitch';
import Divider from '@mui/material/Divider';
import {
  useCanChangeProductVisibility,
} from 'src/menu/dynamic-menu/pages/ProductsPage/hooks/useCanChangeProductVisibility';

type Props = {
  businessId: BusinessId;
  product: Product;
  orderingAllowed: boolean;
};

const DetailsCardContent = styled(CardContent)`
  flex-direction: column;
  display: flex;
  padding: 32px;
  gap: 24px;
`;

export const ProductDetailsPanel = ({
  businessId,
  product,
  orderingAllowed,
}: Props) => {
  const intl = useIntl();

  const [needSave, setNeedSave] = useState(false);
  const [productUpdateDto, setProductUpdateDto] = useState<UpdateProductDto>({});
  const updateProduct = useProductMutation(businessId, product.id);
  const snackBar = useSnackbar();
  const canChangeProductVisibility = useCanChangeProductVisibility();

  function checkNeedSave(productUpdate: UpdateProductDto) {
    if (Object.values(productUpdate).every((el) => el === undefined)) {
      setNeedSave(false);
    } else {
      setNeedSave(true);
    }
  }

  const showUpdateError = () => {
    snackBar.addNotification({
      variant: 'error',
      text: intl.formatMessage({
        id: 'menus.product.update.notification.error',
        defaultMessage: 'unable to update product',
      }),
    });
  };

  const showUpdateSuccess = () => {
    snackBar.addNotification({
      variant: 'success',
      text: intl.formatMessage({ id: 'menus.product.update.notification.success', defaultMessage: 'product updated' }),
    });
  };

  const updateProductUpdateDto = (updateDto: UpdateProductDto) => {
    setProductUpdateDto(updateDto);
    checkNeedSave(updateDto);
  };

  const sendProductUpdate = () => {
    updateProduct
      .mutateAsync(productUpdateDto)
      .then(() => {
        updateProductUpdateDto({});
        showUpdateSuccess();
      })
      .catch(() => showUpdateError());
  };
  const canEditPrice = product.source !== ProductSource.PARTNER;

  return (
    <Card>
      <DetailsCardContent>
        <Typography fontSize="large">
          <FormattedMessage id="menus.product.details.header" defaultMessage="about the item" />
        </Typography>
        <Box component="div" display="flex" flexDirection="row" width="100%" gap={3}>
          <ProductName
            product={product}
            productUpdate={productUpdateDto}
            setProductUpdate={updateProductUpdateDto}
          />
          <ProductPrice
            product={product}
            productUpdate={productUpdateDto}
            setProductUpdate={updateProductUpdateDto}
            disabled={!canEditPrice}
          />
        </Box>
        <ProductShortDescription
          product={product}
          productUpdate={productUpdateDto}
          setProductUpdate={updateProductUpdateDto}
        />
        <ProductDescription
          product={product}
          productUpdate={productUpdateDto}
          setProductUpdate={updateProductUpdateDto}
        />
        <ProductTagsInput
          businessId={businessId}
          product={product}
          setProductUpdateDto={updateProductUpdateDto}
          productUpdateDto={productUpdateDto}
        />
        <ProductAllergensInput
          businessId={businessId}
          product={product}
          setProductUpdateDto={updateProductUpdateDto}
          productUpdateDto={productUpdateDto}
        />
        <Box component="div" display="flex" flexDirection="row" width="100%" gap={3}>
          <ProductVat
            product={product}
            productUpdate={productUpdateDto}
            setProductUpdate={updateProductUpdateDto}
            disabled={!canEditPrice}
            taxKind={TaxKind.EAT_IN}
          />
          <ProductVat
            product={product}
            productUpdate={productUpdateDto}
            setProductUpdate={updateProductUpdateDto}
            disabled={!canEditPrice}
            taxKind={TaxKind.TAKE_AWAY}
          />
          <ProductVat
            product={product}
            productUpdate={productUpdateDto}
            setProductUpdate={updateProductUpdateDto}
            disabled={!canEditPrice}
            taxKind={TaxKind.DELIVERY}
          />
        </Box>
        <ProductCalories
          product={product}
          productUpdate={productUpdateDto}
          setProductUpdate={updateProductUpdateDto}
        />
        { (orderingAllowed || canChangeProductVisibility) && <Divider />}
        {orderingAllowed && (
            <IsAvailableForOrderSwitch
              product={product}
              productUpdate={productUpdateDto}
              setProductUpdate={updateProductUpdateDto}
            />
        )}
        { canChangeProductVisibility && (
        <IsEnabledSwitch
            product={product}
            productUpdate={productUpdateDto}
            setProductUpdate={updateProductUpdateDto}
            />
        )}
        <Button variant="contained" disabled={!needSave} onClick={sendProductUpdate}>
          <FormattedMessage id="menus.product.details.save_changes" />
        </Button>
      </DetailsCardContent>
    </Card>
  );
};
