import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from 'src/app/queries/utils';
import { GuestAppRepository } from 'src/guest-app/infrastructure/GuestAppRepository';

export const useRemoveGuestAppConfiguration = (enrollmentId: string) => {
  const queryClient = useQueryClient();
  const configuration = ConfigurationLoader.load();
  const repository = new GuestAppRepository(configuration.guestAppApiBaseUrl);

  return useMutation({
    mutationFn: () => repository.deleteGuestAppConfiguration(enrollmentId),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [queryKeys.guest_app.GET_CONFIGURATION, { enrollmentId }],
      }),
  });
};
