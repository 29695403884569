import { GuestAppConfiguration } from '../domain/GuestAppConfiguration';
import axios, { HttpStatusCode } from 'axios';
import { v4 as uuidv4 } from 'uuid';

export type ConfigureGuestAppRequest = {
  notificationDelay: number;
};

export type GuestAppConfigurationResponse = {
  notificationDelay: {
    value: number;
  };
  code: string;
};

export class GuestAppRepository {
  constructor(private baseUrl: string) {}

  async getGuestAppConfiguration(enrollmentId: string): Promise<GuestAppConfiguration | null> {
    try {
      const response = await axios.get<GuestAppConfigurationResponse | undefined>(
        `${this.baseUrl}/public/enrollments/${enrollmentId}/configuration`,
      );
      return response.data
        ? {
          notificationDelay: response.data.notificationDelay.value / 60,
        }
        : null;
    } catch (e) {
      return null;
    }
  }

  async saveGuestAppConfiguration(enrollmentId: string, request: ConfigureGuestAppRequest) {
    try {
      await axios.post(
        `${this.baseUrl}/enrollments/${enrollmentId}/configuration`,
        { notificationDelay: request.notificationDelay * 60 },
        {
          headers: {
            correlation_id: uuidv4(),
          },
        },
      );
      return true;
    } catch (e) {
      return false;
    }
    return false;
  }

  async deleteGuestAppConfiguration(enrollmentId: string): Promise<boolean> {
    try {
      const response = await axios.delete(`${this.baseUrl}/enrollments/${enrollmentId}/configuration`);

      return response.status === HttpStatusCode.Ok;
    } catch (e) {
      return false;
    }
  }
}
