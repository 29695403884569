import { Box, Button, Card, styled, Typography } from '@mui/material';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useBusinessIdOrThrow } from 'src/business/hooks/useBusinessId';
import { BusinessUser } from 'src/domain/user/BusinessUser';
import { UserRole } from 'src/domain/user/UserRole';
import { UserManagementSection } from 'src/enterprise/configuration-replication/pages/UserManagementPage/Table/UserManagementSection';
import { UserManagementSearchBar } from 'src/enterprise/configuration-replication/pages/UserManagementPage/UserManagementSearchBar';
import { UserManageBar } from 'src/enterprise/configuration-replication/pages/UserManagementPage/UserManageBar';
import { useListUsers } from 'src/pages/Settings/UserPermissions/mutation/useBusinessUsersQuery';
import { useNavigate, useSearchParams } from 'react-router';
import { InfoOutlinedIcon } from 'src/enterprise/configuration-replication/components/icons/InfoOutlinedIcon';
import Link from '@mui/material/Link';

export const PageCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px 16px;
`;

const PageCardHeader = styled(Box)`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

const HeaderTitleWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
`;

const PageCardBody = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const FaqLink = styled(Link)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  textDecoration: 'none',
  color: theme.palette.text.secondary,
}));

type GroupedUsers = {
  [key in UserRole]?: BusinessUser[];
};

export const UserManagementPage = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const businessId = useBusinessIdOrThrow();
  const { data, isLoading } = useListUsers({ businessId });

  const [users, setUsers] = useState<BusinessUser[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedRowsBySection, setSelectedRowsBySection] = useState<{ [sectionId: string]: string[] }>({});

  const handleAddUser = () => {
    navigate('new');
  };

  useEffect(() => {
    if (data) {
      setUsers(data);
    }
  }, [data]);

  useEffect(() => {
    if (searchParams.get('search_query')) {
      setSearchQuery(searchParams.get('search_query') ?? '');
    }
  }, [searchParams]);

  const handleOnSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchQuery(value);
    setSearchParams({ search_query: value });
  };

  const handleOnClear = () => {
    setSearchQuery('');
    setSearchParams({ search_query: '' });
  };

  const filteredUsers = useMemo(() => {
    const lowercasedQuery = searchQuery.toLowerCase();

    if (searchQuery === '') {
      return users.map((user) => ({
        ...user,
        name: `${user.firstName ?? ''} ${user.lastName ?? ''}`,
      }));
    }

    return users
      .filter(
        (user) =>
          user.firstName?.toLowerCase().includes(lowercasedQuery) ||
          user.lastName?.toLowerCase().includes(lowercasedQuery) ||
          user.email.toLowerCase().includes(lowercasedQuery) ||
          user.phoneNumber?.toLowerCase().includes(lowercasedQuery),
      )
      .map((user) => ({
        ...user,
        name: `${user.firstName ?? ''} ${user.lastName ?? ''}`,
      }));
  }, [searchQuery, users]);

  const groupedUsers = useMemo(() => {
    return filteredUsers.reduce((groupedUser, user) => {
      const role = user.role;

      if (!groupedUser[role]) {
        groupedUser[role] = [];
      }

      groupedUser[role]?.push(user);
      return groupedUser;
    }, {} as GroupedUsers);
  }, [filteredUsers]);

  const allSelectedRows = Object.keys(selectedRowsBySection)
    .map((sectionId) => selectedRowsBySection[sectionId])
    .flat();

  const admin = groupedUsers.ADMIN || [];
  const generalManagers = groupedUsers.GENERAL_MANAGER || [];
  const managers = groupedUsers.MANAGER || [];
  const staff = groupedUsers.STAFF || groupedUsers.WAITER || [];

  if (isLoading || !groupedUsers) return null;

  return (
    <PageCard>
      <PageCardHeader>
        <HeaderTitleWrapper>
          <Typography sx={{ fontSize: '20px' }}>{intl.formatMessage({ id: 'user_management.title' })}</Typography>
          <Typography
            sx={{
              fontSize: '14px',
              lineHeight: '16px',
              letterSpacing: '-0.42px',
            }}
          >
            {intl.formatMessage({ id: 'user_management.subTitle' })}
          </Typography>
        </HeaderTitleWrapper>
        <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', gap: '16px' }}>
          <FaqLink href="/settings/users/faq" target="_blank">
            <InfoOutlinedIcon />
            <Typography>{intl.formatMessage({ id: 'user_management.hint' })}</Typography>
          </FaqLink>
          <Button variant="contained" sx={{ padding: '6px 16px' }} onClick={handleAddUser}>
            {intl.formatMessage({ id: 'user_management.button.add_user' })}
          </Button>
        </Box>
      </PageCardHeader>
      <UserManagementSearchBar onSearch={handleOnSearch} searchQuery={searchQuery} onClear={handleOnClear} />
      <UserManageBar
        countSelectedRows={allSelectedRows.length}
        handleHideManageBar={() => setSelectedRowsBySection({ ['']: [] })}
        selectedIds={allSelectedRows}
      />
      <PageCardBody>
        <UserManagementSection
          title={intl.formatMessage({ id: 'user_management.roles.admin' })}
          data={admin}
          selectedRowsBySection={selectedRowsBySection}
          setSelectedRowsBySection={setSelectedRowsBySection}
          sectionId={'admin'}
        />
        <UserManagementSection
          title={intl.formatMessage({ id: 'user_management.roles.general_manager' })}
          data={generalManagers}
          selectedRowsBySection={selectedRowsBySection}
          setSelectedRowsBySection={setSelectedRowsBySection}
          sectionId={'general_manager'}
        />
        <UserManagementSection
          title={intl.formatMessage({ id: 'user_management.roles.manager' })}
          data={managers}
          selectedRowsBySection={selectedRowsBySection}
          setSelectedRowsBySection={setSelectedRowsBySection}
          sectionId={'manager'}
        />
        <UserManagementSection
          title={intl.formatMessage({ id: 'user_management.roles.staff' })}
          data={staff}
          selectedRowsBySection={selectedRowsBySection}
          setSelectedRowsBySection={setSelectedRowsBySection}
          sectionId={'staff'}
        />
      </PageCardBody>
    </PageCard>
  );
};
