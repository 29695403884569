import { Money } from '@sundayapp/web-money';
import { Products } from '../model/products';

export interface RowProps {
  name: string,
  numberOfPayment: number,
  revenue: Money,
  tips: Money,
  sundayTips: Money,
  serviceCharges: Money,
  total: Money,
  product?: Products,
  details: RowProps[],
}

export const salesSummaryRevenueNames = [
  'sales_summary.cb_total',
  'sales_summary.no_waiter',
  'sales_summary.other_total',
  'sales_summary.pat_total',
  'sales_summary.cnp_total',
  'sales_summary.oap_total',
  'sales_summary.summary',
  'sales_summary.tpe_total',
  'sales_summary.trd_total',
  'sales_summary.ancv',
  'sales_summary.dcc',
] as const;

