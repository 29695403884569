import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { SetURLSearchParams, useNavigate } from 'react-router';
import {
  MenuProductElementType,
  menuProductElementTypes,
  ProductType,
  productTypes,
  translationByProductType,
} from 'src/menu/dynamic-menu/domain/Product';
import { Box, Button, FormControl, InputAdornment, InputLabel, Select, TextField, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { SearchOutlined } from '@mui/icons-material';
import { debounce } from 'lodash';
import AddIcon from '@mui/icons-material/Add';
import {
  ProductRefreshButton,
} from 'src/menu/dynamic-menu/pages/ProductsPage/components/products/ProductRefreshButton';
import { Business } from 'src/business/domain/Business';
import { MenuAssetsSource } from 'src/menu/dynamic-menu/domain/MenuAssetsSource';
import ProductCreationModal from 'src/menu/dynamic-menu/pages/ProductsPage/components/products/ProductCreationModal';


export const ProductsCatalogToolbar = ({
  business,
  syncSource,
  productType,
  productTypesByCurrentElementType,
  setSearchParams,
  menuProductElementType,
  filter,
  advanced,
}: {
  business: Business,
  syncSource: MenuAssetsSource | undefined,
  productType: string,
  productTypesByCurrentElementType: ProductType[],
  setSearchParams: SetURLSearchParams,
  menuProductElementType: MenuProductElementType,
  filter: string | undefined,
  advanced: string
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [createProductDialogOpened, setCreateProductDialogOpened] = useState<boolean>(false);

  const isSnoozableElement = menuProductElementType === productTypes.PRODUCT;

  const navigateToProductCreation = () => {
    switch (menuProductElementType) {
      case menuProductElementTypes.MODIFIER:
        setCreateProductDialogOpened(true);
        break;
      case menuProductElementTypes.PRODUCT:
        navigate('new');
        break;
    }
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" gap={2}>
          {productTypesByCurrentElementType.length > 1 && (
            <FormControl fullWidth size="small">
              <InputLabel id="productTypeSelectLabel">
                <FormattedMessage id={'menus.products.search.filter.type'} />
              </InputLabel>
              <Select
                labelId="productTypeSelectLabel"
                id="productTypeSelect"
                style={{ maxWidth: '250px', minWidth: '160px' }}
                value={productType}
                label={intl.formatMessage({ id: 'menus.products.search.filter.type' })}
                onChange={(event) => {
                  setSearchParams((prev) => {
                    prev.set('productType', event.target.value);
                    return prev;
                  });
                }}
              >
                <MenuItem value={'All'}>All</MenuItem>
                {productTypesByCurrentElementType.map((sub) => {
                  return (
                    <MenuItem key={sub} value={sub}>
                      <FormattedMessage id={translationByProductType[sub].plural} />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
          {isSnoozableElement && (
            <FormControl fullWidth size="small">
              <InputLabel id="advancedFilterLabel">
                <FormattedMessage id={'menus.products.search.filter.advanced'} />
              </InputLabel>
              <Select
                labelId="advancedFilterLabel"
                id="advancedFilterSelect"
                value={advanced}
                style={{ maxWidth: '250px', minWidth: '160px' }}
                label={<FormattedMessage id={'menus.products.search.filter.advanced'} />}
                onChange={(event) => {
                  setSearchParams((prev) => {
                    prev.set('advanced', event.target.value);
                    return prev;
                  });
                }}
              >
                <MenuItem value={'All'}>
                  <FormattedMessage id={'menus.products.search.filter.advanced.option.all'} />
                </MenuItem>
                <MenuItem value={'SNOOZED'}>
                  <FormattedMessage id={'menus.products.search.filter.advanced.option.snoozed'} />
                </MenuItem>
                <MenuItem value={'AVAILABLE_FOR_ORDER'}>
                  <FormattedMessage id={'menus.products.search.filter.advanced.option.orderable'} />
                </MenuItem>
              </Select>
            </FormControl>
          )}
          <FormControl fullWidth>
            <TextField
              variant="outlined"
              size="small"
              style={{ maxWidth: '250px', minWidth: '160px' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchOutlined />
                  </InputAdornment>
                ),
              }}
              defaultValue={filter}
              label={intl.formatMessage({ id: 'menus.products.search.placeholder', defaultMessage: 'search' })}
              onChange={debounce((e) =>
                setSearchParams((prev) => {
                  prev.set('filter', e.target.value);
                  prev.set('page', '0');
                  return prev;
                }),
              800,
              )}
            />
          </FormControl>
        </Box>
        <Box display="flex" gap={2} alignItems="center" justifyContent={'center'}>
          <Button startIcon={<AddIcon />} variant={'contained'} size="small" onClick={navigateToProductCreation}>
            <Typography variant={'body1'}>
              <FormattedMessage
                id={'menus.products.new'}
                values={{
                  elementType: intl
                    .formatMessage({ id: translationByProductType[menuProductElementType].single })
                    .toLowerCase(),
                }}
              />
            </Typography>
          </Button>
          <ProductRefreshButton businessId={business.id} menuAssetsSource={syncSource} />
        </Box>
      </Box>
      <ProductCreationModal
        key={menuProductElementType}
        business={business}
        productElementType={menuProductElementType}
        isOpened={createProductDialogOpened}
        onClose={() => setCreateProductDialogOpened(false)}
      />
    </>
  );
};
