import { useRenderMoney } from '@sundayapp/web-money';
import { Box, styled, Typography } from '@mui/material';
import { VenueKpi } from '../domain/VenueKpi';
import { HomeHeader } from './header/HomeHeader';
import { breakpoints } from 'src/app/theme/ThemeV5';
import React from 'react';
import { Business } from 'src/business/domain/Business';
import { ReferralBlock } from './referral/ReferralBlock';
import { featureFlag } from 'src/feature-flipping/infrastructure/featureFlag';
import { TableStatus } from './status/TableStatus';
import { HomeActions } from './actions/HomeActions';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { HomeRevenueCard } from 'src/home/components/revenue/HomeRevenueCard';
import { HomeTipsCard } from 'src/home/components/tips/HomeTipsCard';
import { HomeReviewsCard } from 'src/home/components/reviews/HomeReviewsCard';
import { useCanManageTips } from 'src/tips/hooks/useCanManageTips';
import { HomeBanner } from 'src/home/components/header/HomeBanner';
import { NewHomeTipsCard } from 'src/home/components/tips/HomeTipsCardv2';
import { HomeReviewsCard as HomeReviewsCardv2 } from 'src/home/components/reviews/HomeReviewsCardv2';
import { HomeCarouselSection } from 'src/home/components/carousel/HomeCarouselSection';
import { HomeStaffPerformanceCard } from 'src/home/components/staff-performance/HomeStaffPerformanceCard';

type HomeContentProps = {
  business: Business;
  venueKpi: VenueKpi;
};

const MetricsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '32px',
  alignSelf: 'stretch',
  backgroundColor: theme.palette.background.paper,
  padding: '32px',
  borderRadius: '16px',
  marginBottom: '2em',
  width: '100%',

  '&[data-has-new-home-page-access="true"]': {
    marginTop: '-6em',
    paddingTop: '6em',
  },

  ['@media (max-width: 500px)']: {
    width: 'calc(100% + 64px)',
    marginLeft: '-32px',
    marginRight: '-32px',
    padding: '32px 24px',
    textAlign: 'center',
  },
}));

const CardSectionWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  flex: 1,
  width: '100%',

  ['@media (max-width: 1024px)']: {
    flexDirection: 'column',
  },
}));

export const CardsWrapper = styled(Box)(() => ({
  display: 'flex',
  gap: '16px',
  flex: 1,
  width: '100%',

  ['@media (max-width: 1024px)']: {
    flexDirection: 'column',
  },

  '&[data-his-staff-performance="true"]': {
    justifyContent: 'space-between',
  },
}));

const Cards = styled('div')`
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  gap: 24px;

  @media (max-width: ${breakpoints.homeTablet}) {
    flex-direction: column;
    margin-bottom: 32px;
    gap: 32px;
  }
`;

const ActionsAndStatus = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 24px;

  @media (max-width: ${breakpoints.homeTablet}) {
    flex-direction: column;
  }
`;

export const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '40px',
  fontWeight: 500,
  lineHeight: '40px',
  letterSpacing: '-2px',
  color: theme.palette.text.disabled,
}));

export const HomeContent = ({ business, venueKpi }: HomeContentProps) => {
  const intl = useIntl();
  const canManageTips = useCanManageTips();
  const renderMoney = useRenderMoney(true, true);
  const isPAT = business.patEnrollment !== undefined;
  const { adoptionRate, billViewRate, totalRevenue } = venueKpi;
  const referralCampaignActive = featureFlag('referral-campaign');
  const urlParams = new URLSearchParams(window.location.search);
  const hasNewHomePageAccess = urlParams.get('new-home-page') != null;

  return (
    <>
      {!hasNewHomePageAccess && (
        <HomeHeader adoptionRate={adoptionRate} totalRevenue={renderMoney(venueKpi.totalRevenue)} />
      )}
      {hasNewHomePageAccess && (
        <>
          <HomeBanner
            business={business}
            adoptionRate={adoptionRate}
            billViewRate={billViewRate}
            totalRevenue={renderMoney(totalRevenue)}
          />
          <MetricsWrapper data-has-new-home-page-access={hasNewHomePageAccess}>
            <Box>
              <Typography
                sx={{
                  fontSize: '40px',
                  fontWeight: 500,
                  lineHeight: '40px',
                  letterSpacing: '-2px',
                  color: 'text.disabled',
                }}
              >
                {intl.formatMessage({ id: 'venue.home.section.getMore' })}
              </Typography>
            </Box>
            <CardsWrapper>
              <NewHomeTipsCard businessId={business.id} venueKpi={venueKpi} />
              <HomeReviewsCardv2 businessTimezone={business.timezone} businessId={business.id} venueKpi={venueKpi} />
            </CardsWrapper>
            <CardSectionWrapper>
              <Box>
                <Typography
                  sx={{
                    fontSize: '40px',
                    fontWeight: 500,
                    lineHeight: '40px',
                    letterSpacing: '-2px',
                    color: 'text.disabled',
                  }}
                >
                  {intl.formatMessage({ id: 'venue.home.section.staffPerformance.title' })}
                </Typography>
              </Box>
              <HomeStaffPerformanceCard business={business} />
            </CardSectionWrapper>
            {hasNewHomePageAccess && (
              <CardSectionWrapper>
                <HomeCarouselSection />
              </CardSectionWrapper>
            )}
          </MetricsWrapper>
        </>
      )}
      {!hasNewHomePageAccess && (
        <Cards>
          <HomeRevenueCard business={business} venueKpi={venueKpi} />
          {canManageTips && <HomeTipsCard business={business} venueKpi={venueKpi} />}
          <HomeReviewsCard business={business} />
        </Cards>
      )}
      <ActionsAndStatus>
        <HomeActions business={business} />
        {referralCampaignActive ? <ReferralBlock business={business} /> : isPAT && <TableStatus business={business} />}
      </ActionsAndStatus>
    </>
  );
};
