import styled from 'styled-components';
import { spaceUsages } from 'src/app/theme/SpaceUsagesTheme';

const MenuItemLine = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  padding-left: ${spaceUsages.largeSmall};
`;

export default MenuItemLine;
