import { CurrencyCode } from '@sundayapp/web-money';
import { chain } from 'lodash';
import { oneReporting, Reporting, sumReportings } from './ReportingDigest';
import { isCB, isOnPdq, isTrd, Payment, Payments, serviceChargesOrZero } from './Payments';
import { safeSumMoney } from 'src/utils/MoneyUtils';
import { NO_REVENUE_CENTER } from './RevenueCenter';
import { toWaitersProductsReporting } from './toWaitersProductsReporting';
import { EndOfServiceConfiguration } from './EndOfServiceConfiguration';
import { emptyWaitersReporting, isCnp, isCp, KindOfPayment, mapProductToKindOfPayment, products, Products } from './products';
import { toTpePaymentMethodReporting, toWaiterProductsReportingByPaymentContract } from 'src/operations/end-of-service/model/toTpeMethodReportingByContratMonetique';

function getSchemePayments(product: Products, payments: Payment[], currency: CurrencyCode) {
  return chain(payments)
    .groupBy('scheme')
    .toPairs()
    .map(([key, schemePayments]) => {
      const revenue = safeSumMoney(
        schemePayments.map((payment) => payment.salesAmount),
        currency,
      );
      const tips = safeSumMoney(
        schemePayments.map((payment) => payment.tipsAmount),
        currency,
      );
      const sundayTips = safeSumMoney(
        schemePayments.map((payment) => payment.sundayTipsAmount),
        currency,
      );
      const serviceCharges = safeSumMoney(
        schemePayments.map((payment) => serviceChargesOrZero(payment, currency)),
        currency,
      );
      return {
        name: key,
        numberOfPayment: schemePayments.length,
        product,
        revenue,
        tips,
        sundayTips,
        serviceCharges,
        total: safeSumMoney([revenue, serviceCharges, tips], currency),
        details: [],
      };
    });
}

export function toSchemeReporting(
  product: Products,
  name: string,
  payments: Payment[],
  currency: CurrencyCode,
  filter: (payment: Payment) => {},
): Reporting {
  const filteredPayments = payments.filter(filter)
    .filter((payment) => payment.scheme !== 'AMEX');
  const revenue = safeSumMoney(
    filteredPayments.map((payment) => payment.salesAmount),
    currency,
  );
  const tips = safeSumMoney(
    filteredPayments.map((payment) => payment.tipsAmount),
    currency,
  );
  const sundayTips = safeSumMoney(
    filteredPayments.map((payment) => payment.sundayTipsAmount),
    currency,
  );
  const serviceCharges = safeSumMoney(
    filteredPayments.map((payment) => serviceChargesOrZero(payment, currency)),
    currency,
  );
  return {
    name,
    numberOfPayment: filteredPayments.length,
    product,
    revenue,
    tips,
    sundayTips,
    serviceCharges,
    total: safeSumMoney([revenue, serviceCharges, tips], currency),
    details: getSchemePayments(product, filteredPayments, currency)
      .value(),
  };
}

function keepPaymentWithScheme(scheme: string) {
  return (payment: Payment) => payment.scheme === scheme;
}

function keepDccPayment() {
  return (payment: Payment) => payment.isDcc && payment.scheme != 'AMEX';
}

export function toOneLineReporting(
  product: Products,
  name: string,
  payments: Payment[],
  currency: CurrencyCode,
  filter: (payment: Payment) => boolean,
): Reporting {
  const filteredPayments = payments.filter(filter);
  const revenue = safeSumMoney(
    filteredPayments.map((payment) => payment.salesAmount),
    currency,
  );
  const tips = safeSumMoney(
    filteredPayments.map((payment) => payment.tipsAmount),
    currency,
  );
  const sundayTips = safeSumMoney(
    filteredPayments.map((payment) => payment.sundayTipsAmount),
    currency,
  );
  const serviceCharges = safeSumMoney(
    filteredPayments.map((payment) => serviceChargesOrZero(payment, currency)),
    currency,
  );
  return {
    name,
    numberOfPayment: filteredPayments.length,
    product,
    revenue,
    tips,
    sundayTips,
    serviceCharges,
    total: safeSumMoney([revenue, serviceCharges, tips], currency),
    details: [],
  };
}

export function paymentHasRevenueCenter(revenueCentersSelected: string[], payment: Payment) {
  const allRevenueCenter = revenueCentersSelected.length === 0;
  return allRevenueCenter || revenueCentersSelected.includes(payment.revenueCenter || NO_REVENUE_CENTER);
}

function composeReportings(card: Reporting, amex: Reporting, ancv: Reporting, displayTrd: boolean, trd: Reporting, dcc: Reporting) {
  const reportings = [card];
  if (amex.numberOfPayment > 0) reportings.push(amex);
  if (ancv.numberOfPayment > 0) reportings.push(ancv);
  if (dcc.numberOfPayment > 0) reportings.push(dcc);
  if (displayTrd) reportings.push(trd);
  return reportings;
}

export function toPaymentMethodReporting(
  eosConfiguration: EndOfServiceConfiguration,
  name: string,
  payments: Payments,
  product: Products,
  currency: CurrencyCode,
  revenueCentersSelected: string[] = [],
): Reporting {
  const isCandidateForEOS = (payment: Payment) => {
    if (product === products.PAT) {
      return payment.origin === products.PAT && paymentHasRevenueCenter(revenueCentersSelected, payment);
    }

    if (product === products.OAP) {
      return payment.origin === products.OAP && paymentHasRevenueCenter(revenueCentersSelected, payment);
    }

    return isOnPdq(payment) && paymentHasRevenueCenter(revenueCentersSelected, payment);
  };

  const filteredPayments = payments.payments.filter(isCandidateForEOS);

  const card = toSchemeReporting(product, 'sales_summary.cb_total', filteredPayments, currency, isCB);
  const trd = toSchemeReporting(product, 'sales_summary.trd_total', filteredPayments, currency, isTrd);

  if (product === products.PDQ) {
    const ancv = toOneLineReporting(product, 'sales_summary.ancv', filteredPayments, currency, keepPaymentWithScheme('CHEQUE_VACANCES'));
    const amex = toOneLineReporting(product, 'AMEX', filteredPayments, currency, keepPaymentWithScheme('AMEX'));
    const dcc = toOneLineReporting(product, 'sales_summary.dcc', filteredPayments, currency, keepDccPayment());
    const reportings = composeReportings(card, amex, ancv, eosConfiguration.displayTrd ?? false, trd, dcc);
    return sumReportings(name, currency, reportings);
  }

  if (eosConfiguration.displayTrd) return sumReportings(name, currency, [card, trd]);
  return oneReporting(name, currency, card);
}

function toWaiterProductsReporting(
  eosConfiguration: EndOfServiceConfiguration,
  waiterPayments: Payment[],
  currency: CurrencyCode,
): Reporting[] {
  const result = waiterPayments.reduce((acc, payment) => {
    const kindOfPayment: KindOfPayment = mapProductToKindOfPayment(payment.origin);

    return {
      ...acc,
      [kindOfPayment]: {
        name: acc[kindOfPayment].name,
        numberOfPayment: waiterPayments.length,
        revenue: safeSumMoney([acc[kindOfPayment].revenue, payment.salesAmount], currency),
        tips: safeSumMoney([acc[kindOfPayment].tips, payment.tipsAmount], currency),
        sundayTips: safeSumMoney([acc[kindOfPayment].sundayTips, payment.sundayTipsAmount], currency),
        serviceCharges: safeSumMoney(
          [acc[kindOfPayment].serviceCharges, serviceChargesOrZero(payment, currency)],
          currency,
        ),
        total: safeSumMoney(
          [
            acc[kindOfPayment].total,
            safeSumMoney([payment.salesAmount, serviceChargesOrZero(payment, currency), payment.tipsAmount], currency),
          ],
          currency,
        ),
        details: [],
      },
    };
  }, emptyWaitersReporting(currency));

  const waiterProductsReporting = Object.values(result);

  const patWaiterPayments = waiterPayments.filter((p) => isCnp(p.origin));
  waiterProductsReporting[0].product = products.PAT;
  waiterProductsReporting[0].details = [
    toSchemeReporting(products.PAT, 'sales_summary.cb_total', patWaiterPayments, currency, isCB),
  ];
  waiterProductsReporting[0].numberOfPayment = patWaiterPayments.length;

  if (eosConfiguration.displayTrd) {
    waiterProductsReporting[0].details.push(
      toSchemeReporting(products.PAT, 'sales_summary.trd_total', patWaiterPayments, currency, isTrd),
    );
  }

  if (eosConfiguration.displayPaymentTerminal) {
    waiterProductsReporting[1].product = products.PDQ;

    const tpeWaiterPayments = waiterPayments.filter((p) => isCp(p.origin));
    waiterProductsReporting[1].numberOfPayment = tpeWaiterPayments.length;

    const card = toSchemeReporting(products.PDQ, 'sales_summary.cb_total', tpeWaiterPayments, currency, isCB);
    const trd = toSchemeReporting(products.PDQ, 'sales_summary.trd_total', tpeWaiterPayments, currency, isTrd);

    const amexPayments = tpeWaiterPayments.filter((payment) => payment.scheme === 'AMEX');

    waiterProductsReporting[1].details = [card];
    if (eosConfiguration.displayTrd) waiterProductsReporting[1].details.push(trd);
    if (amexPayments.length > 0) {
      const amex = toOneLineReporting(products.PDQ, 'AMEX', tpeWaiterPayments, currency, keepPaymentWithScheme('AMEX'));
      waiterProductsReporting[1].details.push(amex);
    }
  } else {
    waiterProductsReporting.pop();
  }

  return waiterProductsReporting;
}

export function createReportingDigest(
  eosConfiguration: EndOfServiceConfiguration,
  payments: Payments,
  currency: CurrencyCode,
  revenueCentersSelected: string[],
) {
  return {
    patPaymentMethod: toPaymentMethodReporting(
      eosConfiguration,
      'sales_summary.pat_total',
      payments,
      products.PAT,
      currency,
      revenueCentersSelected,
    ),
    oapPaymentMethod: toPaymentMethodReporting(
      eosConfiguration,
      'sales_summary.oap_total',
      payments,
      products.OAP,
      currency,
      revenueCentersSelected,
    ),
    tpePaymentMethod: eosConfiguration.displayByPaymentContract
      ? toTpePaymentMethodReporting('sales_summary.tpe_total', payments, currency, revenueCentersSelected)
      : toPaymentMethodReporting(
        eosConfiguration,
        'sales_summary.tpe_total',
        payments,
        products.PDQ,
        currency,
        revenueCentersSelected,
      ),
    waitersProducts: toWaitersProductsReporting(
      eosConfiguration,
      payments,
      currency,
      revenueCentersSelected,
      eosConfiguration.displayByPaymentContract ? toWaiterProductsReportingByPaymentContract : toWaiterProductsReporting,
    ),
  };
}
