import styled from 'styled-components';
import { Box } from '@mui/material';
import { colorPalette } from 'src/ordering/stylesheet';
import { spaceUsages } from 'src/app/theme/SpaceUsagesTheme';

export const MenuToolBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  border-radius: ${spaceUsages.largeSmall};
  height: 125px;
  background-color: ${colorPalette.grey200};
  margin-bottom: ${spaceUsages.mediumLarge};
  cursor: pointer;
  width: 100%;
`;
