import React from 'react';
import { usePayments } from './usePayments';
import { PaymentsContainer } from './index.style';
import { PaymentsTable } from './PaymentsTable';
import CsvExportButton from '../components/CsvExportButton';
import { PaymentsFilter } from './PaymentsFilter';
import { Business } from 'src/business/domain/Business';
import { Box, Grid, Typography } from '@mui/material';
import { themeV5 } from 'src/app/theme/ThemeV5';
import { revenueTabDatePresets } from 'src/accounting/revenues/domain/RevenuesDatePresets';
import { translation } from 'src/accounting/common/translation';
import { TableEmptyState } from 'src/components/table/TableEmptyState';
import { TableLoadingState } from 'src/components/table/TableLoadingState';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { Payment } from '../domain/Payment';

type PaymentListProps = {
  business: Business;
  tpeVenue: boolean;
};

export const PaymentList = ({ business, tpeVenue }: PaymentListProps) => {
  const {
    displayablePayments,
    currentPage,
    lastPage,
    previousPage,
    nextPage,
    changeFilter,
    filters,
    refreshPayments,
    allPayments,
    cancelPayment,
    hasTabPaymentsToDisplay,
    isLoading,
    datePresets,
    period,
    waiters,
  } = usePayments(business);

  const formatDate = (date: Date) => ({
    date: date.toLocaleDateString(navigator.language, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }),
    time: date.toLocaleTimeString(navigator.language, {
      hour: '2-digit',
      minute: '2-digit',
    }),
  });
  const buildEndDateDisplay = () => {
    const endDateCopy = period.dateRange.endDate.clone();
    const endDate = period.filter === revenueTabDatePresets.CUSTOM ? endDateCopy : endDateCopy.subtract(1, 'day');
    return formatDate(endDate.toDate());
  };

  const startDateDisplay = formatDate(period.dateRange.startDate.toDate());
  const endDateDisplay = buildEndDateDisplay();
  const isSameDay = startDateDisplay.date === endDateDisplay.date;

  const snackbar = useSnackbar();
  const releasePaymentAuthorization = (payment: Payment) => {
    cancelPayment(payment)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then((_) =>
        snackbar.addNotification({
          variant: 'success',
          text: 'Successfully canceled authorization',
        }),
      )
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .catch((_) => {
        snackbar.addNotification({
          variant: 'error',
          text: 'Error during cancelation of the authorization',
        });
      });
  };

  return (
    <PaymentsContainer>
      <Grid container pt={3} pb={3} spacing={1}>
        <Grid item xs={12} md={9} display="flex" alignItems="end" justifyContent="end">
          <PaymentsFilter
            changeFilter={changeFilter}
            filters={filters}
            business={business}
            hasTabPaymentsToDisplay={hasTabPaymentsToDisplay}
            tpeVenue={tpeVenue}
            datePresets={datePresets}
            period={period}
            waiters={waiters}
          />
        </Grid>
        <Grid item xs={12} md={3} alignItems="center" display="flex" justifyContent="center">
          <CsvExportButton payments={allPayments} status={filters.status} />
        </Grid>
      </Grid>
      <Grid
        p={2}
        sx={{
          backgroundColor: '#FAFAFA',
          borderRadius: '16px 16px 0 0',
          borderBottom: '1px solid #dddde0',
          display: 'flex',
          gap: '8px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        container
      >
        <Box sx={{ display: 'flex' }} flexDirection={'column'} alignItems={'center'}>
          <Typography variant="body1" color={themeV5.palette.text.secondary}>
            {startDateDisplay.date}
          </Typography>
          <Box>
            <Typography variant="body2" color={themeV5.palette.text.secondary}>
              {startDateDisplay.time} {isSameDay ? `- ${endDateDisplay.time}` : ''}
            </Typography>
          </Box>
        </Box>
        {!isSameDay && (
          <>
            {' '}
            <Box sx={{ display: 'flex' }} flexDirection={'column'} alignItems={'start'}>
              <Typography variant="body1" color={themeV5.palette.text.secondary}>
                -
              </Typography>
            </Box>
            <Box sx={{ display: 'flex' }} flexDirection={'column'} alignItems={'center'}>
              <Typography variant="body1" color={themeV5.palette.text.secondary}>
                {endDateDisplay.date}
              </Typography>
              <Box>
                <Typography variant="body2" color={themeV5.palette.text.secondary}>
                  {endDateDisplay.time}
                </Typography>
              </Box>
            </Box>
          </>
        )}
      </Grid>
      {isLoading ? (
        <TableLoadingState
          title={translation('payments.table.loading_state.title')}
          subtitle={translation('payments.table.loading_state.subtitle')}
        />
      ) : displayablePayments.length === 0 ? (
        <TableEmptyState
          title={translation('payments.table.empty_state.title')}
          subtitle={translation('payments.table.empty_state.subtitle')}
        />
      ) : (
        <PaymentsTable
          payments={displayablePayments}
          currentPage={currentPage}
          lastPage={lastPage}
          previousPage={previousPage}
          nextPage={nextPage}
          releasePaymentAuthorization={releasePaymentAuthorization}
          handleRefreshPayment={refreshPayments}
          business={business}
        />
      )}
    </PaymentsContainer>
  );
};
