import DataAnalyticsIframeComponent from './DataAnalyticsIframeComponent';
import { MetabaseDashboard } from 'src/data-analytics/domain/MetabaseDashboard';

export const MultiLocationDataAnalyticsDigitalBill = () => {
  // useEffect(() => {
  //   ampli.dataAnalyticsReviewsClicked({
  //     businessId: business.id,
  //     businessName: business.name,
  //   });
  // }, []);

  return (
    <DataAnalyticsIframeComponent
      dashboard={MetabaseDashboard.DIGITAL_BILL_ENGLISH}
    />
  );
};
