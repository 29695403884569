import { Button, CircularProgress, Stack, TextField, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import DoneIcon from '@mui/icons-material/Done';
import { HttpGoogleSetup } from '../infrastruscture/HttpGoogleSetup';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { Column } from '../../../../../Column';
import { tShirt } from '../../../../../Size';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

export const GoogleReviewDemoAccountSetup = () => {
  const business = useCurrentBusinessOrThrow();
  const configuration = ConfigurationLoader.load();
  const repository = new HttpGoogleSetup(configuration.venueFeedbackBaseUrl);
  const sundayPlaceId = configuration.sundayPlaceId;

  const [placeId, setPlaceId] = useState<string>(sundayPlaceId);
  const [demoAccountCreated, setDemoAccountCreated] = useState(false);
  const [creatingDemoAccount, setCreatingDemoAccount] = useState(false);

  const saveDemoAccount = useCallback(async () => {
    setCreatingDemoAccount(true);
    repository.createDemoAccount(business.id, placeId).then(() => {
      setDemoAccountCreated(true);
    });
    setCreatingDemoAccount(false);
  }, [placeId, business.id]);
  return (
    <Column gap={tShirt('s')}>
      <Typography variant="h5">Set up a demo google account</Typography>
      <Stack direction="row" spacing="20px" alignItems="center">
        <TextField id="demo-place-id" label="demo place id" defaultValue={sundayPlaceId}
                   onChange={(e) => setPlaceId(e.target.value.trim())} />
        <Button disabled={placeId.length < 1} variant="contained" onClick={saveDemoAccount}>
          connect demo account
          {creatingDemoAccount && (
            <>
              &nbsp;
              <CircularProgress size="small" />
            </>
          )}
          {demoAccountCreated && (
            <>
              &nbsp;
              <DoneIcon color="success" />
            </>
          )}
        </Button>
      </Stack>
    </Column>
  );
};
