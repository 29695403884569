import React from 'react';
import { GridColumnGroupingModel } from '@mui/x-data-grid/models/gridColumnGrouping';
import styled from 'styled-components';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';

const HeaderGroupCell = styled.span`
  color: #000;
  padding-top: 8px;
  font-weight: 400;
`;

export const buildGroupColumns = (canManageTips: boolean): GridColumnGroupingModel => {
  const definedGroupColumns = [
    {
      groupId: 'Waiter',
      description: '',
      children: [{ field: 'waiterName' }],
      renderHeaderGroup: () => (
        <HeaderGroupCell>
          <FormattedMessage id="staff_performance.group.waiter" />
        </HeaderGroupCell>
      ),
    },
    {
      groupId: 'Adoption',
      description: '',
      children: [{ field: 'billViewRate' }, { field: 'sundayPaymentsRate' }],
      renderHeaderGroup: () => (
        <HeaderGroupCell>
          <FormattedMessage id="staff_performance.group.sunday_usage" />
        </HeaderGroupCell>
      ),
    },
    {
      groupId: 'Reviews',
      description: '',
      children: [
        { field: 'ratingOutOf5' },
        { field: 'nbOfReviews' },
        { field: 'n5StarsGoogleReview' },
        { field: 'avgServiceRating' },
      ],
      renderHeaderGroup: () => (
        <HeaderGroupCell>
          <FormattedMessage id="staff_performance.group.reviews" />
        </HeaderGroupCell>
      ),
    },
    {
      groupId: 'Revenue',
      description: '',
      children: [{ field: 'totalRevenue' }, { field: 'basketSize' }],
      renderHeaderGroup: () => (
        <HeaderGroupCell>
          <FormattedMessage id="staff_performance.group.revenues" />
        </HeaderGroupCell>
      ),
    },
    {
      groupId: 'walkouts',
      description: '',
      children: [{ field: 'nbOfWalkouts' }, { field: 'walkoutsSalesAmount' }],
      renderHeaderGroup: () => (
        <HeaderGroupCell>
          <FormattedMessage id="staff_performance.walkouts" />
        </HeaderGroupCell>
      ),
    },
  ];

  const tipsColumns = {
    groupId: 'Tip',
    description: '',
    children: [{ field: 'totalTips' }, { field: 'tipsRate' }],
    renderHeaderGroup: () => (
      <HeaderGroupCell>
        <FormattedMessage id="staff_performance.group.sunday_tips" />
      </HeaderGroupCell>
    ),
  };

  if (canManageTips) {
    definedGroupColumns.splice(5, 0, tipsColumns);
  }

  return definedGroupColumns;
};
