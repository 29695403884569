import {
  Box,
  Chip,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useRenderMoney } from '@sundayapp/web-money';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import {
  computeScheduledDispatchPayoutDate,
  DoneTipsDispatch,
  formatDispatchPayoutDate,
} from 'src/tips/dispatch/model/DoneTipsDispatch';
import { themeV5 } from 'src/app/theme/ThemeV5';
import { DispatchedBy } from 'src/tips/dispatch/components/DispatchedBy';
import { PeriodDisplay } from 'src/tips/dispatch/components/PeriodDisplay';
import { DispatchDetailsModal } from 'src/tips/dispatch/components/DispatchDetailsModal';
import { DescriptionOutlined } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router';

export const Dispatched = ({ tipsDispatch }: { tipsDispatch: DoneTipsDispatch[] }) => {
  const intl = useIntl();
  const renderMoney = useRenderMoney(true, false);
  const navigate = useNavigate();
  const location = useLocation();
  const selectedDispatchId = location.hash.substring(1);

  const onClose = () => navigate(location.pathname);

  const goToDispatchDetail = (dispatchId: string) => {
    navigate(`#${dispatchId}`);
  };

  if (tipsDispatch.length === 0) {
    return (
      <Stack spacing={1.5}>
        <Box>
          <Typography variant={'h6'}>
            <FormattedMessage id="tips.sunday_pooling.dispatch.already_dispatched" />
          </Typography>
        </Box>
        <Paper sx={{ padding: '1.5rem', borderRadius: '1.5rem' }}>
          <Typography variant={'body1'}>
            <FormattedMessage id="tips.sunday_pooling.dispatch.no_previous_dispatch" />
          </Typography>
        </Paper>
      </Stack>
    );
  }

  return (
    <Stack spacing={1.5}>
      <Box>
        <Typography variant={'h6'}>
          <FormattedMessage id={'tips.sunday_pooling.dispatch.already_dispatched'} />
        </Typography>
      </Box>
      <TableContainer component={Paper} sx={{ borderRadius: '1.5rem' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 400 }}>
                <Typography variant={'body2'} color={themeV5.palette.text.secondary}>
                  <FormattedMessage id={'tips.sunday_pooling.dispatch.collection_date'} />
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant={'body2'} color={themeV5.palette.text.secondary}>
                  <FormattedMessage id={'tips.sunday_pooling.dispatch.dispatched'} />
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant={'body2'} color={themeV5.palette.text.secondary}>
                  <FormattedMessage id={'tips.sunday_pooling.dispatch.status'} />
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant={'body2'} color={themeV5.palette.text.secondary}>
                  <FormattedMessage id={'tips.sunday_pooling.dispatch.actions'} />
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tipsDispatch.map((dispatch) => {
              const dispatchPayoutDate = computeScheduledDispatchPayoutDate(dispatch);
              return (
                <TableRow key={dispatch.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>
                    <PeriodDisplay start={dispatch.startInclusive} end={new Date(dispatch.endExclusive.getTime() - 43200000)} variant={'body2'} />
                  </TableCell>
                  <TableCell>
                    <Stack spacing={0.5} direction={'column'}>
                      <Typography variant={'body2'}>{renderMoney(dispatch.amount)}</Typography>
                      <DispatchedBy triggeredBy={dispatch.triggeredBy} dispatchedAt={dispatch.dispatchedAt} />
                    </Stack>
                  </TableCell>
                  <TableCell>
                    <Stack>
                      <Box sx={{ display: 'inline-flex' }}>
                        {dispatchPayoutDate ? (
                          <Chip
                            color="info"
                            sx={{ marginLeft: '-10px' }}
                            label={intl.formatMessage({ id: 'tips.sunday_pooling.dispatch.scheduled' })}
                          />
                        ) : (
                          <Chip
                            color="success"
                            sx={{ marginLeft: '-10px' }}
                            label={intl.formatMessage({ id: 'tips.sunday_pooling.dispatch.dispatched' })}
                          />
                        )}
                      </Box>
                      <div>
                        {dispatchPayoutDate && (
                          <Typography variant={'caption'} color={'textSecondary'}>
                            {intl.formatMessage(
                              { id: 'tips.sunday_pooling.dispatch.staff_payout' },
                              {
                                payoutDate: formatDispatchPayoutDate(dispatchPayoutDate),
                              },
                            )}
                          </Typography>
                        )}
                      </div>
                    </Stack>
                  </TableCell>
                  <TableCell>
                    <Link
                      color="text.primary"
                      variant="body2"
                      sx={{ cursor: 'pointer' }}
                      onClick={() => goToDispatchDetail(dispatch.id)}
                    >
                      <Box display="flex" alignItems="center" gap={1}>
                        <DescriptionOutlined />
                        <FormattedMessage id={'tips.sunday_pooling.dispatch.actions.view'} />
                      </Box>
                    </Link>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {selectedDispatchId && <DispatchDetailsModal dispatchId={selectedDispatchId} onClose={onClose} />}
    </Stack>
  );
};
