import { useAuthenticatedUserOrThrow } from 'src/auth/hooks/useAuthenticatedUserOrThrow';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { useGetBusinessTippingConfiguration } from 'src/tips/queries/useGetBusinessTippingConfiguration';
import { useMemo } from 'react';
import { useListBusinessUserRelations } from 'src/infrastructure/venue/useListBusinessUserRelations';

export const useCanManageTips = () => {
  const business = useCurrentBusinessOrThrow();
  const authenticatedUser = useAuthenticatedUserOrThrow();
  const { data: tippingConfiguration } = useGetBusinessTippingConfiguration(business.id);
  const { data: businessPermissions } = useListBusinessUserRelations(business.id);

  return useMemo(() => {
    if (
      business?.address?.countryCode === 'FR' &&
      tippingConfiguration?.type === 'SundayTipping' &&
      businessPermissions?.some(({ relation }) => relation === 'can_manage_tips')
    ) {
      return authenticatedUser.relations.some(
        ({ objectId, relation }) => relation === 'can_manage_tips' && objectId === business.id,
      );
    }
    return true;
  }, [tippingConfiguration, businessPermissions, business, authenticatedUser.relations]);
};
