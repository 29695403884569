import React from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { Card, CardContent, Stack, Switch, Typography, useTheme } from '@mui/material';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { useReviewsEnabled, useSaveReviewConfiguration } from 'src/sundayplus/reviews/settings/ReviewConfiguration';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

export const EnableReviewsSettings = () => {
  const intl = useIntl();
  const useSnackBar = useSnackbar();
  const reviewsEnabled = useReviewsEnabled();
  const business = useCurrentBusinessOrThrow();
  const { mutate: updateReviewConfiguration } = useSaveReviewConfiguration(business.id);

  const theme = useTheme();

  const save = async (value: boolean) => {
    updateReviewConfiguration(
      { reviewsEnabled: value },
      {
        onSuccess: () => {
          useSnackBar.addNotification({
            variant: 'success',
            text: intl.formatMessage({ id: 'settings.reviews_and_loyalty.success_saving' }),
          });
        },
      },
    );
  };

  return (
    <Card>
      <CardContent>
        <Stack direction="column" gap={2}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h6" alignSelf="center">
              <FormattedMessage id="settings.reviews_and_loyalty.title" />
            </Typography>
            <Switch onChange={(e) => save(e.target.checked)} checked={reviewsEnabled} />
          </Stack>
          <Typography variant="caption" color={theme.palette.text.disabled}>
            <FormattedMessage id="settings.reviews_and_loyalty.description" />
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};
