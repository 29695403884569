import React, { useRef, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-redeclare
import { colorUsage, Modal, UploadSvg } from '@sundayapp/b2b-react-component-library';
import { Button, CircularProgress, IconButton } from '@mui/material';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import {
  ExistingImageActionsContainer,
  ExistingImageContainer,
  ImagePreview,
  ImageViz,
  InputFile,
  UploadInputContainer,
  VerticalDivider,
} from './PictureContainer.styles';
import { CropPictureData } from 'src/domain/venue/CropperService';
import { AdditionalGuides, CropTool } from '../Cropping/CropTool';
import { Column } from 'src/sundayplus/Column';
import DownloadIcon from '@mui/icons-material/Download';
import { downloadImage } from 'src/utils/DownloadImage';
import { spaceUsages } from 'src/app/theme/SpaceUsagesTheme';
import { SundayText } from 'src/app/component/Text/Text';

interface PictureContainerProps {
  inputId: string;
  uploadFile: (file: File) => void;
  url: string;
  title: React.ReactNode;
  editPicture: () => void;
  tempPicture: string;
  deletePicture: () => void;
  saveCroppedPicture: (crop: CropPictureData) => void;
  onCloseCropTool: () => void;
  colorSelector: boolean;
  aspect: number;
  cropShape: 'rect' | 'round';
  loading: boolean;
  previewWidth?: string;
  previewHeight?: string;
  previewBackgroundColor?: string;
  downloadFileName?: string;
  additionalGuides?: AdditionalGuides;
}

const PictureContainer = ({
  inputId,
  uploadFile,
  tempPicture,
  loading,
  cropShape,
  aspect,
  colorSelector,
  onCloseCropTool,
  saveCroppedPicture,
  deletePicture,
  editPicture,
  title,
  url,
  previewWidth,
  previewHeight,
  previewBackgroundColor,
  downloadFileName,
  additionalGuides,
}: PictureContainerProps) => {
  const fileUploadRef = useRef<HTMLInputElement>(null);
  const intl = useIntl();
  const [isOpened, setIsOpened] = useState(false);

  const onUploadPicture = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files![0];
    if (file) {
      if (fileUploadRef.current) {
        fileUploadRef.current.value = '';
      }
      setIsOpened(true);
      uploadFile(file);
    }
  };

  const onSaveCroppedPicture = (crop: CropPictureData) => {
    setIsOpened(false);
    saveCroppedPicture(crop);
  };

  const onCloseCropToolModal = () => {
    setIsOpened(false);
    onCloseCropTool();
  };

  const onEditPicture = () => {
    setIsOpened(true);

    editPicture();
  };

  if (loading) {
    return <CircularProgress size="large" />;
  }
  return (
    <Column>
      <SundayText size="small" color={colorUsage.tertiaryText} isKeepCase>
        <FormattedMessage id="settings.customization.banner.menu.pictureTitle" />
      </SundayText>
      {!url ? (
        <UploadInputContainer htmlFor={inputId}>
          <ImagePreview url={url} />
          <InputFile id={inputId} type="file" onChange={onUploadPicture} accept="image/png, image/jpg, image/jpeg" />
          <IconButton
            component="div"
            size="medium"
            sx={(theme) => ({
              color: colorUsage.sundayPrimary,
              backgroundColor: colorUsage.fogBackground,
              padding: '12px',

              '& svg': {
                width: '22px',
                height: '22px',
                fill: theme.palette.grey[600],
              },
            })}
          >
            <UploadSvg />
          </IconButton>
          <SundayText size="medium" color={colorUsage.sundayPrimary} sx={{ marginTop: spaceUsages.mediumSmall }}>
            <FormattedMessage id="settings.app_settings.browse" />
          </SundayText>
          <SundayText
            size="xs"
            textAlign="center"
            color={colorUsage.disabledText}
            sx={{ marginTop: spaceUsages.mediumSmall }}
          >
            <FormattedMessage id="settings.app_settings.drop_to_upload" />
          </SundayText>
        </UploadInputContainer>
      ) : (
        <ExistingImageContainer>
          <ImageViz
            url={url}
            style={{
              width: previewWidth,
              height: previewHeight,
            }}
          />
          <ExistingImageActionsContainer>
            {downloadFileName && (
              <>
                <Button onClick={() => downloadImage(url, downloadFileName)}>
                  <FormattedMessage id={'common.download'} />
                  <DownloadIcon />
                </Button>
                <VerticalDivider />
              </>
            )}
            <Button onClick={onEditPicture}>
              <FormattedMessage id="settings.app_settings.edit" />
            </Button>
            <VerticalDivider />
            <Button onClick={deletePicture}>
              <FormattedMessage id="settings.app_settings.delete" />
            </Button>
          </ExistingImageActionsContainer>
        </ExistingImageContainer>
      )}
      <Modal
        title={title}
        isOpened={isOpened}
        onClose={onCloseCropToolModal}
        cancelButtonMessage={intl.formatMessage({ id: 'settings.app_settings.cancel' })}
        maxWidth="470px"
        minWidth="470px"
      >
        <CropTool
          picture={tempPicture}
          submitCropData={onSaveCroppedPicture}
          aspect={aspect}
          cropShape={cropShape}
          colorSelector={colorSelector}
          backgroundColor={previewBackgroundColor}
          additionalGuides={additionalGuides}
        />
      </Modal>
    </Column>
  );
};

export default PictureContainer;
