import { Box, styled, Typography } from '@mui/material';
import { Staff } from 'src/staff-performance/page/StaffPerformanceViewModel';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { MetricCard } from 'src/staff-performance/component/PerformersKeyMetrics/MetricCard';
import { GoogleIcon } from 'src/staff-performance/assets/GoogleIcon';
import { breakpoints } from 'src/app/theme/ThemeV5';

interface PerformersKeyMetricsProps {
  data?: Staff[] | undefined;
}

const PerformersKeyMetricsWrapper = styled(Box)`
  display: flex;
  gap: 16px;
  margin-bottom: 26px;

  @media (max-width: ${breakpoints.homeTablet}) {
    flex-direction: column;
  }
`;

const getNestedProperty = (obj: any, path: string) => {
  return path.split('.').reduce((acc, key) => acc?.[key], obj);
};

const getMinMaxPerformers = (data: Staff[], notation: string) => {
  const initialValues = {
    waiterName: '',
    ratingOutOf5: 0,
    nbOfReviews: 0,
    totalRevenue: 0,
    basketSize: 0,
    nbSundayPayments: 0,
    sundayPaymentsRate: 0,
    totalTips: 0,
    tipsRate: 0,
    n5StarsGoogleReview: 0,
    totalTipsPaymentTerminal: 0,
    avgServiceRating: 0,
    nbOfWalkouts: 0,
    walkoutsSalesAmount: 0,
    walkoutsTipsAmount: 0,
    billViewRate: 0,
  };

  let minNotation = Infinity;
  let maxNotation = -Infinity;
  let minNotationUser: Staff = initialValues;
  let maxNotationUser: Staff = initialValues;

  data.forEach((item) => {
    if ((item.nbSundayPayments ?? 0) < 10) return;

    const note = getNestedProperty(item, notation);

    if (note < minNotation) {
      minNotation = note;
      minNotationUser = item;
    }

    if (note > maxNotation) {
      maxNotation = note;
      maxNotationUser = item;
    }
  });

  return { minNotationUser, maxNotationUser, minNotation, maxNotation };
};

export const PerformersKeyMetrics = ({ data }: PerformersKeyMetricsProps) => {
  const intl = useIntl();

  const { maxNotationUser: maxBillViewRate, minNotationUser: minBillViewRate } = getMinMaxPerformers(
    data ?? [],
    'billViewRate',
  );

  const { maxNotationUser: maxGoogleReviews, minNotationUser: minGoogleReviews } = getMinMaxPerformers(
    data ?? [],
    'n5StarsGoogleReview',
  );

  const { maxNotationUser: maxTipsRate, minNotationUser: minTipsRate } = getMinMaxPerformers(data ?? [], 'tipsRate');

  if (!data?.length) return null;

  return (
    <PerformersKeyMetricsWrapper>
      {maxBillViewRate.waiterName && maxBillViewRate?.billViewRate && minBillViewRate.waiterName && minBillViewRate && (
        <MetricCard
          headerTitle={intl.formatMessage({ id: 'staff_performance.group.sunday_usage' })}
          headerSubtitle={intl.formatMessage({ id: 'staff_performance.group.bill_view' })}
          upTitle={maxBillViewRate.waiterName}
          upMetric={maxBillViewRate.billViewRate * 100}
          downTitle={minBillViewRate.waiterName}
          downMetric={(minBillViewRate.billViewRate ? minBillViewRate.billViewRate : 0) * 100}
          isProgressBar
        />
      )}

      {maxGoogleReviews.waiterName &&
        maxGoogleReviews?.n5StarsGoogleReview &&
        minGoogleReviews.waiterName &&
        minGoogleReviews && (
          <MetricCard
            headerTitle={intl.formatMessage({ id: 'staff_performance.group.reviews' })}
            headerSubtitle={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                <GoogleIcon />
                <Typography sx={{ textTransform: 'lowercase', fontSize: '14px' }}>
                  5* {intl.formatMessage({ id: 'staff_performance.group.reviews' })}
                </Typography>
              </Box>
            }
            upTitle={maxGoogleReviews.waiterName}
            upMetric={maxGoogleReviews.n5StarsGoogleReview}
            downTitle={minGoogleReviews.waiterName}
            downMetric={minGoogleReviews.n5StarsGoogleReview ?? 0}
          />
      )}

      {maxTipsRate.waiterName && maxTipsRate?.tipsRate && minTipsRate.waiterName && minTipsRate && (
        <MetricCard
          headerTitle={intl.formatMessage({ id: 'staff_performance.group.tips' })}
          headerSubtitle={intl.formatMessage({ id: 'staff_performance.tips.avg' })}
          upTitle={maxTipsRate.waiterName}
          upMetric={`${(maxTipsRate.tipsRate * 100).toFixed(1)}%`}
          downTitle={minTipsRate.waiterName}
          downMetric={minTipsRate.tipsRate ? `${(minTipsRate.tipsRate * 100).toFixed(1)}%` : '0%'}
        />
      )}
    </PerformersKeyMetricsWrapper>
  );
};
