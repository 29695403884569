import { FC, useMemo } from 'react';
import { Tooltip } from '@sundayapp/b2b-react-component-library';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { FormattedMessage, unknownKey, useIntl } from 'src/app/i18n/TypedIntl';
import {
  computePaymentStatus,
  isCheckInTabPayment,
  isPayAtTable,
  isPaymentTerminal,
  PaymentFailedDetails,
  PaymentProvider,
  PaymentStatuses,
  RefundedPaymentStatuses,
  SpecificPayment,
  TabPaymentStatuses,
} from '../domain/Payment';
import { Box, Chip, ChipProps, IconButton } from '@mui/material';
import { LocalisationKey } from 'src/lang/en';
import { lowerCase } from 'lodash';

interface Props {
  specificPayment: SpecificPayment;
}

const badgeVariant: Record<PaymentStatuses | RefundedPaymentStatuses | TabPaymentStatuses, ChipProps['color']> = {
  partially_refunded: 'warning',
  refunded: 'warning',
  refund_pending: 'warning',
  unspecified: 'secondary',
  pending: 'secondary',
  succeeded: 'success',
  failed: 'error',
  canceled: 'error',
  action_required: 'secondary',
  authorized: 'warning',
  released: 'success',
};

const knownFailCodes = [
  'authentication_required',
  'bad_account_configuration',
  'call_issuer',
  'card_brand_not_accepted',
  'card_decline_rate_limit_exceeded',
  'card_not_accepted',
  'card_not_supported',
  'card_velocity_exceeded',
  'custom_rule_blocked',
  'do_not_honor',
  'expired_card',
  'fraudulent',
  'generic_decline',
  'incorrect_cvc',
  'incorrect_number',
  'insufficient_amount',
  'insufficient_funds',
  'international_card_not_accepted',
  'invalid_account',
  'invalid_amount',
  'invalid_card_information',
  'invalid_expiration_date',
  'invalid_pin',
  'invalid_zip',
  'lost_card',
  'meal_voucher_not_supported',
  'meal_voucher_wrong_psp',
  'merchant_blacklist',
  'not_permitted',
  'offline_pin_required',
  'payment_intent_authentication_failure',
  'payment_intent_payment_attempt_failed',
  'payment_method_token_expired',
  'pickup_card',
  'pin_try_exceeded',
  'pos_unacknowledged',
  'processing_error',
  'reenter_transaction',
  'restricted_card',
  'revocation_of_authorization',
  'security_violation',
  'service_not_allowed',
  'stolen_card',
  'timeout',
  'transaction_not_allowed',
  'try_again_later',
  'unknown_error',
  'withdrawal_count_limit_exceeded',
] as const;

const explanationMessageKeyFor = (failDetail: PaymentFailedDetails): LocalisationKey | undefined => {
  const code = failDetail.declineCode || failDetail.failCode;
  const found = knownFailCodes.find((known) => known === code);
  if (found) {
    return `payment.error.${found}`;
  }
  return undefined;
};

const knownNextSteps = [
  'change_payment_method',
  'require_assistance',
  'retry',
  'topup_or_change_payment_method',
  'use_another_card_brand',
  'use_meal_voucher_payment_method',
  'use_non_international_card',
] as const;

const PaymentStatusBadge: FC<Props> = ({ specificPayment }: Props) => {
  const status = useMemo(() => computePaymentStatus(specificPayment.payment), [specificPayment.payment]);
  const intl = useIntl();
  const getExplicitMessageWithNextStep = (failDetail: PaymentFailedDetails, provider: PaymentProvider) => {
    const explanationMessageKey = explanationMessageKeyFor(failDetail) ?? unknownKey;
    const explanationMessage = intl.formatMessage({
      id: explanationMessageKey,
      defaultMessage: failDetail.rawFailMessage,
    });

    const nextStepMessageKey = knownNextSteps.find((known) => known === failDetail.nextStep?.toLowerCase());
    const nextStepMessage =
      failDetail.nextStep === 'NONE'
        ? ''
        : ` - ${intl.formatMessage({ id: `payment.next_step.${nextStepMessageKey!}`, defaultMessage: lowerCase(failDetail.nextStep) })}`;
    return `${provider === 'TOAST' ? `${failDetail.rawFailMessage} - ` : ''}${explanationMessage}${nextStepMessage}`;
  };

  // TODO: this code is temporary waiting payment team to fix canceled states translation
  function buildStatusLabel(): LocalisationKey {
    if (
      status === 'canceled' &&
      ((isPayAtTable(specificPayment) && isCheckInTabPayment(specificPayment)) || isPaymentTerminal(specificPayment))
    ) {
      return 'payments.status.released';
    } else {
      return `payments.status.${status}`;
    }
  }

  function buildChipColor(): ChipProps['color'] {
    if (
      status === 'canceled' &&
      ((isPayAtTable(specificPayment) && isCheckInTabPayment(specificPayment)) || isPaymentTerminal(specificPayment))
    ) {
      return 'success';
    } else {
      return badgeVariant[status];
    }
  }

  const paymentStatusLabel: LocalisationKey = buildStatusLabel();
  const chipColor: ChipProps['color'] = buildChipColor();
  const badge = (
    <Box display="flex" alignItems="center" data-testid="status-badge">
      <Chip label={<FormattedMessage id={paymentStatusLabel} />} color={chipColor} />
      {status === 'failed' && (
        <IconButton disableRipple>
          <InfoIcon />
        </IconButton>
      )}
    </Box>
  );

  if (specificPayment.payment?.failedDetails?.rawFailMessage) {
    return (
      <Tooltip
        title={getExplicitMessageWithNextStep(
          specificPayment.payment.failedDetails,
          specificPayment.payment.paymentProvider,
        )}
        direction="left"
      >
        {badge}
      </Tooltip>
    );
  }

  return badge;
};

export default PaymentStatusBadge;
