// eslint-disable-next-line @typescript-eslint/no-redeclare
import { colorUsage, Text } from '@sundayapp/b2b-react-component-library';
import React, { useState } from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import styled from 'styled-components';
import MenuDeletionConfirmationModal from './MenuDeletionConfirmationModal';
import { StaticMenu } from '../../domain/StaticMenus';
import { spaceUsages } from 'src/app/theme/SpaceUsagesTheme';

type MenuDeletionButtonProps = {
  menu: StaticMenu;
};

const MenuDeletionButtonContainer = styled.div`
  justify-content: center;
  width: 100%;
  display: flex;
  margin-top: ${spaceUsages.largeMedium};
`;

const MenuDeletionButton = ({ menu }: MenuDeletionButtonProps) => {
  // @ts-ignore
  const [deleteMenuDialogOpened, setDeleteMenuDialogOpened] = useState<boolean>(false);

  return (
    <MenuDeletionButtonContainer>
      <Text
        size="large"
        color={colorUsage.error}
        style={{ cursor: 'pointer' }}
        onClick={() => setDeleteMenuDialogOpened(true)}
      >
        <FormattedMessage id="menus.delete_menu.button" />
      </Text>
      <MenuDeletionConfirmationModal
        menuId={menu.id}
        menuName={menu.description}
        isOpened={deleteMenuDialogOpened}
        onClose={() => setDeleteMenuDialogOpened(false)}
      />
    </MenuDeletionButtonContainer>
  );
};

export default MenuDeletionButton;
