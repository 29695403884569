import React from 'react';
import { TableHead, TableHeader, TableRow } from '@sundayapp/b2b-react-component-library';
import { InfoOutlined } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { Business } from 'src/business/domain/Business';

type Props = {
  isDigitalFeeDisplayed: boolean;
  canManageTips: boolean;
  business: Business;
};

export const PaymentsHeader = ({ isDigitalFeeDisplayed, canManageTips, business }: Props) => {
  const intl = useIntl();
  const patOrPDQ = business.patEnrollment || business.pdqEnrollment;
  return (
    <TableHead>
      <TableRow>
        <TableHeader textAlign="start" />
        <TableHeader textAlign="start">
          <FormattedMessage id="payments.table.date_time" />
        </TableHeader>
        <TableHeader textAlign="start">
          <FormattedMessage id="payments.table.source" />
        </TableHeader>
        {patOrPDQ && 
          <TableHeader textAlign="start">
            <FormattedMessage id="payments.table.waiter_name" />
          </TableHeader>}

        <TableHeader textAlign="start">
          <FormattedMessage id="payments.table.method" />
        </TableHeader>
        {canManageTips && (
          <>
            <TableHeader textAlign="start">
              <FormattedMessage id="payments.table.tips" />
            </TableHeader>
            <TableHeader textAlign="start">
              <FormattedMessage id="payments.table.sub_total" />
            </TableHeader>
          </>
        )}
        <TableHeader textAlign="start">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FormattedMessage id="payments.table.total" />
            &nbsp;
            {isDigitalFeeDisplayed && (
              <Tooltip title={intl.formatMessage({ id: 'payments.table.total.tooltip' })} placement="right">
                <InfoOutlined />
              </Tooltip>
            )}
          </div>
        </TableHeader>
        <TableHeader textAlign="start">
          <FormattedMessage id="payments.table.status" />
        </TableHeader>
        <TableHeader textAlign="start">
          <FormattedMessage id="payments.table.pos_status" />
        </TableHeader>
        <TableHeader textAlign="start" style={{ paddingRight: '21px' }}>
          <FormattedMessage id="payments.table.actions" />
        </TableHeader>
      </TableRow>
    </TableHead>
  );
};
