import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import styled from 'styled-components';
import DownloadIcon from '@mui/icons-material/Download';
import React, { useEffect, useState } from 'react';
import { RevenuePeriod } from '../../../domain/RevenuesDatePresets';
import { Level } from 'src/utils/logs/plugins';
import { downloadBlob } from '../../../../payout/pages/utils';
import { useLog } from 'src/utils/logs/LogProviderContext';
import { useConfiguration } from '../ConfigurationContainerContext';
import { useBusinessIdOrThrow } from 'src/business/hooks/useBusinessId';
import { Button, CircularProgress } from '@mui/material';

interface Props {
  period: RevenuePeriod;
  onError: () => void;
}

const DownloadWrapper = styled(DownloadIcon)`
  margin-right: 5px;
`;

export const DownloadOperations: React.FC<Props> = ({ period, onError }: Props) => {
  const [inProgress, setInProgress] = useState<boolean>(false);
  const businessId = useBusinessIdOrThrow();
  const configuration = useConfiguration();

  const log = useLog();
  const intl = useIntl();

  const downloadExport = () => {
    setInProgress(true);

    configuration.payoutPort
      .getPayoutsDetailsExport(businessId, period.dateRange.startDate.toDate(), period.dateRange.endDate.toDate())
      .then((blobWithMetadata) => {
        downloadBlob(
          blobWithMetadata,
          `${intl.formatMessage({ id: 'accounting.payout_tab.payouts_details_export_file' })}.csv`,
        );
      })
      .catch((error) => {
        log(
          Level.ERROR,
          `Could not retrieve payouts details export for venue id ${businessId} (${period.filter})`,
          error,
        );
        onError();
      })
      .finally(() => setInProgress(false));
  };

  useEffect(
    () => () => {
      setInProgress(false);
    },
    [],
  );

  return (
    <>
      <Button
        color="primary"
        size="medium"
        onClick={() => !inProgress && downloadExport()}
        data-testid="download-payouts-details-button"
      >
        {inProgress ? (
          <CircularProgress color="inherit" size="large" />
        ) : (
          <>
            <DownloadWrapper />
            <FormattedMessage
              id="accounting.revenue_tab.panel.downloadOperations"
              defaultMessage="download operations"
            />
          </>
        )}
      </Button>
    </>
  );
};
