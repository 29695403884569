import { TimeFrame } from 'src/sundayplus/reviews/browse/TimeFrame';

export type PlatformFilter = 'GOOGLE' | 'SUNDAY' | 'ALL';

export const toPlatformFilter = (queryParams: string) => {
  const givenPlatform = new URLSearchParams(queryParams).get('platform');
  if (givenPlatform === 'ALL') return 'ALL';
  if (givenPlatform === 'SUNDAY') return 'SUNDAY';
  if (givenPlatform === 'GOOGLE') return 'GOOGLE';
  return 'ALL';
};
export const toTimeRangeFilter = (queryParams: string): TimeFrame => {
  const givenTimeRange = new URLSearchParams(queryParams).get('timerange');
  if (givenTimeRange) return givenTimeRange as TimeFrame;
  return 'LAST_7_DAYS';
};

export const toServerFilter = (queryParams: string, allWaiters: string): string => {
  const server = new URLSearchParams(queryParams).get('server');
  if (server != null) return server;
  return allWaiters;
};
export const toRatingFilter = (queryParams: string): string => {
  const rating = new URLSearchParams(queryParams).get('rating');
  if (rating === 'BAD') return 'BAD';
  if (rating === 'TOP') return 'TOP';
  return 'ALL';
};

export const toDimensionFilter = (queryParams: string) => {
  const givenDimension = new URLSearchParams(queryParams).get('dimension');
  if (givenDimension !== null) return givenDimension;
  return 'ALL_DIMENSION';
};
