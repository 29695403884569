import {
  Button,
  ClickableTooltip,
  colorUsage,
  Dropdown,
  IconWrapper,
  InfoSvg,
  InputText,
  Modal,
} from '@sundayapp/b2b-react-component-library';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { isEmailValid } from 'src/domain/user/Email';
import { goToFaqTooltip } from '../../GoToFaqTooltip';
import { GrantRoleForm, RoleLabelContainer } from './GrantRoleModal.styles';
import { UserRole, userRoles } from 'src/domain/user/UserRole';
import { Alert } from '@mui/material';
import { userRoleLocalisations } from 'src/pages/Settings/UserPermissions/i18n/UserRoleLocalisation';
import { spaceUsages } from 'src/app/theme/SpaceUsagesTheme';
import { SundayText } from 'src/app/component/Text/Text';

type Option = {
  id: UserRole;
  label: string;
};

interface Props {
  onGrantUserRole: (email: string, userRole: UserRole) => Promise<void>;
  isOpen: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
}

export const GrantRoleModal: React.FC<Props> = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onGrantUserRole,
  handleClose,
  handleConfirm,
  isOpen,
}: Props) => {
  const intl = useIntl();
  const [hasSubmitError, setHasSubmitError] = useState(false);
  const options: Option[] = (Object.values(userRoles) as UserRole[]).map((role) => ({
    id: role,
    label: intl.formatMessage({ id: userRoleLocalisations[role] }),
  }));
  const [selectedUserRole, setSelectedUserRole] = useState<Option | undefined>(undefined);

  type GrantRoleInputs = {
    email: string;
    userRole: string;
  };

  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitting, errors },
  } = useForm<GrantRoleInputs>({
    mode: 'all',
    criteriaMode: 'all',
  });

  const onSubmit = async ({ email }: GrantRoleInputs) => {
    setHasSubmitError(false);
    try {
      await onGrantUserRole(email, selectedUserRole!.id);
      handleConfirm();
    } catch (e) {
      setHasSubmitError(true);
    }
  };

  return (
    <Modal
      title={intl.formatMessage({ id: 'settings.user_permissions.add_modal.title' })}
      isOpened={isOpen}
      onClose={handleClose}
      cancelButtonMessage={intl.formatMessage({ id: 'settings.user_permissions.add_modal.cancel' })}
      maxWidth="420px"
      minWidth="420px"
    >
      <GrantRoleForm onSubmit={handleSubmit(onSubmit)}>
        <InputText
          label={intl.formatMessage({ id: 'settings.user_permissions.email', defaultMessage: 'Email' })}
          id="email"
          placeholder={intl.formatMessage({ id: 'settings.user_permissions.form.email.placeholder' })}
          error={!!errors.email}
          subCaption={errors.email?.message}
          {...register('email', {
            required: intl.formatMessage({ id: 'settings.user_permissions.form.email_required' }),
            validate: {
              isEmail: (value) =>
                isEmailValid(value) ? true : intl.formatMessage({ id: 'settings.user_permissions.form.email_invalid' }),
            },
          })}
        />
        <RoleLabelContainer>
          <SundayText size="small" color={colorUsage.tertiaryText}>
            <FormattedMessage id="settings.user_permissions.add_modal.user_permission" />
          </SundayText>
          <ClickableTooltip
            direction="top"
            title={intl.formatMessage({ id: 'settings.user_permissions.add_modal.user_permission' })}
            subtitle={goToFaqTooltip()}
          >
            <IconWrapper
              color={colorUsage.tertiaryBackground}
              size="small"
              marginLeft={spaceUsages.mediumSmall}
              cursor="pointer"
            >
              <InfoSvg />
            </IconWrapper>
          </ClickableTooltip>
        </RoleLabelContainer>
        <Dropdown
          size="small"
          options={options}
          value={selectedUserRole}
          labelField="label"
          onChange={setSelectedUserRole}
          placeholder={intl.formatMessage({ id: 'settings.user_permissions.form.role.placeholder' })}
        />

        {hasSubmitError && (
          <Alert severity="error">
            <FormattedMessage id="error.generic_error" />
          </Alert>
        )}
        <Button
          type="submit"
          variant="primary"
          size="medium"
          disabled={!isValid || !selectedUserRole || isSubmitting}
          fullWidth
        >
          <FormattedMessage id="settings.user_permissions.add_modal.confirm" />
        </Button>
      </GrantRoleForm>
    </Modal>
  );
};
