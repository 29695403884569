import { alpha, Box, Card, IconButton, styled, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import { SectionTitle } from '../HomeContent';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { useMediaBreakpoints } from '@hooks/useMediaBreakpoints';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperType } from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

const CarouselCard = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  display: 'flex',
  width: '-webkit-fill-available',
  padding: theme.spacing(3),
  alignItems: 'flex-start',
  gap: '18px',
  borderRadius: theme.spacing(2),
  border: `1px solid ${alpha(theme.palette.text.secondary, 0.05)}`,

  ['@media screen and (max-width: 768px)']: {
    width: '100%',
  },

  ['@media screen and (min-width: 769px) and (max-width: 1023px)']: {
    width: '48%',
  },
}));

const CarouselCardBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  whiteSpace: 'nowrap',
  gap: theme.spacing(1),

  ['@media screen and (max-width: 768px)']: {
    whiteSpace: 'wrap',
  },
}));

const CarouselCardTitle = styled(Typography)(() => ({
  fontSize: '48px',
  fontWeight: 500,
  lineHeight: '45.6px',
  letterSpacing: '-2.4px',
  textAlign: 'left',
}));

const CarouselCardSubTitle = styled(Typography)(() => ({
  fontSize: '18px',
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: '-0.54px',
  textAlign: 'left',
}));

const MobileCarouselWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(3),
  justifyContent: 'center',

  ['@media screen and (max-width: 768px)']: {
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
}));

const SliderIconButton = styled(IconButton)(({ theme }) => ({
  display: 'flex',
  padding: '12px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '80px',
  border: `1px solid ${alpha(theme.palette.text.secondary, 0.14)}`,
}));

export const HomeCarouselSection = () => {
  const intl = useIntl();

  const [cards] = useState<React.ReactElement[]>([
    <CarouselCard key={'fasterTableTurns'}>
      <CarouselCardBox>
        <CarouselCardTitle>7.3%</CarouselCardTitle>
        <CarouselCardSubTitle>
          {intl.formatMessage({ id: 'venue.home.section.carousel.card.fasterTableTurns' })}
        </CarouselCardSubTitle>
      </CarouselCardBox>
    </CarouselCard>,
    <CarouselCard key={'guestSplittedCheck'}>
      <CarouselCardBox>
        <CarouselCardTitle>52.5%</CarouselCardTitle>
        <CarouselCardSubTitle>
          {intl.formatMessage({ id: 'venue.home.section.carousel.card.guestSplittedCheck' })}
        </CarouselCardSubTitle>
      </CarouselCardBox>
    </CarouselCard>,
    <CarouselCard key={'digitalWallet'}>
      <CarouselCardBox>
        <CarouselCardTitle>64%</CarouselCardTitle>
        <CarouselCardSubTitle>
          {intl.formatMessage({ id: 'venue.home.section.carousel.card.digitalWallet' })}
        </CarouselCardSubTitle>
      </CarouselCardBox>
    </CarouselCard>,
    <CarouselCard key={'paymentsWithRate'}>
      <CarouselCardBox>
        <CarouselCardTitle>95%</CarouselCardTitle>
        <CarouselCardSubTitle>
          {intl.formatMessage({ id: 'venue.home.section.carousel.card.paymentsWithRate' })}
        </CarouselCardSubTitle>
      </CarouselCardBox>
    </CarouselCard>,
  ]);

  const swiperRef = useRef<SwiperType | null>(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const { isDesktopScreen } = useMediaBreakpoints();

  const updateButtons = (swiper: SwiperType) => {
    setIsBeginning(swiper.isBeginning);
    setIsEnd(swiper.isEnd);
  };

  const handleSlideRight = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
      updateButtons(swiperRef.current);
    }
  };

  const handleSlideLeft = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
      updateButtons(swiperRef.current);
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <SectionTitle>{intl.formatMessage({ id: 'venue.home.section.carousel.title' })}</SectionTitle>
        {isDesktopScreen && (
          <Box sx={{ display: 'flex', gap: '8px' }}>
            <SliderIconButton size="medium" onClick={handleSlideRight} disabled={isBeginning}>
              <ChevronLeft fontSize={'medium'} />
            </SliderIconButton>
            <SliderIconButton size="medium" onClick={handleSlideLeft} disabled={isEnd}>
              <ChevronRight fontSize={'medium'} />
            </SliderIconButton>
          </Box>
        )}
      </Box>
      {!isDesktopScreen && <MobileCarouselWrapper>{cards}</MobileCarouselWrapper>}
      {isDesktopScreen && (
        <Swiper
          rewind={false}
          slidesPerView={2.2}
          spaceBetween={10}
          onSwiper={(swiper) => {
            return (swiperRef.current = swiper);
          }}
          onSlideChange={(swiper) => updateButtons(swiper)}
          direction={'horizontal'}
          style={{
            width: '100%',
            height: '100%',
          }}
          modules={[Pagination, Navigation]}
        >
          {cards.map((card, index) => (
            <SwiperSlide key={`card-${index}`} style={{ width: '480px' }}>
              {card}
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </>
  );
};
