// eslint-disable-next-line @typescript-eslint/no-redeclare
import { IconWrapper, PlusSvg } from '@sundayapp/b2b-react-component-library';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Card, CardContent } from '@mui/material';
import MenuScheduleModal from './MenuScheduleModal';
import { Menu } from '../../domain/Menu';
import { GroupedTimeRanges } from './GroupedTimeRanges';
import { MenuSchedulesUtils } from '../../utils/menuSchedules/MenuSchedulesUtils';
import GroupedTimeRangeLine from './GroupedConstraintLine';
import { useUpdateMenuMutation } from '../../mutations/menu/useMenuMutation';
import { MenuDtoConverter } from '../../repositories/MenuDtoConverter';
import { useBusinessIdOrThrow } from 'src/business/hooks/useBusinessId';
import { spaceUsages } from 'src/app/theme/SpaceUsagesTheme';
import { SundayText } from 'src/app/component/Text/Text';

type MenuSchedulePanelProps = {
  menu: Menu;
};

const MenuDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${spaceUsages.mediumLarge};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

const GroupedTimeRangesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaceUsages.mediumXLarge};
  margin-top: ${spaceUsages.largeSmall};
  width: 100%;
`;

const MenuSchedulePanel = ({ menu }: MenuSchedulePanelProps) => {
  const businessId = useBusinessIdOrThrow();

  const DEFAULT_GROUPED_TIME_RANGE: GroupedTimeRanges = {
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: true,
    sunday: true,
    ranges: [{ startTime: '00:00', endTime: '24:00' }],
  };

  const [groupedTimeRanges, setGroupedTimeRanges] = useState<GroupedTimeRanges[]>([]);
  const [scheduleSelectorDialogOpened, setScheduleSelectorDialogOpened] = useState<boolean>(false);

  const updateMenu = useUpdateMenuMutation(businessId, menu.id);

  const refreshGroupedTimeRangeStates = async (timeRanges: GroupedTimeRanges[]) => {
    const newSchedules = MenuSchedulesUtils.groupedTimesToSchedules(timeRanges);
    const freshGroupedTimeRange = MenuSchedulesUtils.schedulesToGroupedTimes(newSchedules);
    const optimizedConstraints = MenuSchedulesUtils.optimizeGroupedTimes(freshGroupedTimeRange);
    setGroupedTimeRanges(optimizedConstraints);

    const schedulesDto = MenuDtoConverter.fromSchedules(
      MenuSchedulesUtils.groupedTimesToSchedules(optimizedConstraints),
    );
    await updateMenu.mutateAsync(schedulesDto);
  };

  const onGroupedTimeRangeUpdated = async (updatedConstraints: GroupedTimeRanges, index: number) => {
    const updatedGroupedConstraints = groupedTimeRanges.map((constraints, idx) => {
      if (index === idx) {
        return updatedConstraints;
      }
      return constraints;
    });
    await refreshGroupedTimeRangeStates(updatedGroupedConstraints);
  };

  const onGroupedTimeRangeCreated = async (timeRanges: GroupedTimeRanges) => {
    const newConstraints = groupedTimeRanges.concat(timeRanges);
    await refreshGroupedTimeRangeStates(newConstraints);
  };

  const onGroupedTimeRangeDeleted = async (index: number) => {
    const updatedGroupedConstraints = groupedTimeRanges.filter((constraints, idx) => index !== idx);
    await refreshGroupedTimeRangeStates(updatedGroupedConstraints);
  };

  useEffect(() => {
    setGroupedTimeRanges(MenuSchedulesUtils.schedulesToGroupedTimes(menu.schedules));
  }, [menu]);

  return (
    <Card>
      <CardContent>
        <MenuDetailContainer>
          <Header>
            <SundayText size="xl">
              <FormattedMessage id="menus.schedule_selector.panel.title" />
            </SundayText>
            <IconWrapper size="small" cursor="pointer" onClick={() => setScheduleSelectorDialogOpened(true)}>
              <PlusSvg />
            </IconWrapper>
          </Header>
        </MenuDetailContainer>

        {groupedTimeRanges.length > 0 && (
          <GroupedTimeRangesContainer>
            {groupedTimeRanges.map((timeRange, index) => (
              <GroupedTimeRangeLine
                // eslint-disable-next-line react/no-array-index-key
                key={String(index)}
                groupedTimeRanges={timeRange}
                onUpdated={(ranges) => onGroupedTimeRangeUpdated(ranges, index)}
                onDelete={() => onGroupedTimeRangeDeleted(index)}
                deleteAvailable={groupedTimeRanges.length > 1}
              />
            ))}
          </GroupedTimeRangesContainer>
        )}

        <MenuScheduleModal
          groupedTimeRanges={DEFAULT_GROUPED_TIME_RANGE}
          setGroupedTimeRanges={onGroupedTimeRangeCreated}
          isOpened={scheduleSelectorDialogOpened}
          onClose={() => setScheduleSelectorDialogOpened(false)}
        />
      </CardContent>
    </Card>
  );
};

export default MenuSchedulePanel;
