import { Modal } from '@sundayapp/b2b-react-component-library';
import { Button } from '@mui/material';
import React from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { useNavigate } from 'react-router';
import { venueDynamicMenusPath } from 'src/app/navigation/pathHelpers';
import { useDeleteMenuMutation } from '../../../mutations/menu/useMenuMutation';
import { BusinessId } from 'src/business/domain/Business';

type DeleteMenuModalProps = {
  businessId: BusinessId;
  isOpen: boolean;
  onClose: () => void;
  menuId: string;
  menuName: string;
};

const MenuDeletionModal = ({ isOpen, onClose, menuId, menuName, businessId }: DeleteMenuModalProps) => {
  const { formatMessage } = useIntl();
  const navigateTo = useNavigate();
  const deleteMenuMutation = useDeleteMenuMutation(businessId, menuId);

  const deleteMenu = async () => {
    try {
      await deleteMenuMutation.mutateAsync();
    } catch (e) {
      console.error(e);
    }
    onClose();

    navigateTo(venueDynamicMenusPath(businessId));
  };

  return (
    <Modal
      title={formatMessage({ id: 'menus.edition.delete_title' })}
      cancelButtonMessage={formatMessage({ id: 'menus.modal.edition.cancel' })}
      isOpened={isOpen}
      onClose={onClose}
    >
      <p>{menuName}</p>
      <Button
        variant="contained"
        color="primary"
        size="large"
        fullWidth
        onClick={deleteMenu}
        data-tesid="confirm-delete-menu"
        sx={{
          fontSize: '16px',
          justifyContent: 'center',
          textTransform: 'lowercase',
          width: '100%',
        }}
      >
        <FormattedMessage id="menus.edition.delete_button" />
      </Button>
    </Modal>
  );
};

export default MenuDeletionModal;
