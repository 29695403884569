import { Box, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import React, { useEffect, useState } from 'react';
import { LAST_30_DAYS, LAST_7_DAYS, TimeframePicker, TimeframeSelection } from './TimeframePicker';
import { ReviewPreviewCard } from './ReviewPreviewCard';
import { SelectedReview } from './SelectedReview';
import { haveComment, isGoogle, rating, Review } from '../browse/domain/Review';
import { emptyReviews, Reviews, withoutReviewReply } from '../browse/domain/Reviews';
import { useListReviews } from './infrastructure/useListReviews';
import { AllReviewsWereReplied } from './AllReviewsWereReplied';
import { useReplyToReviewFromReviews } from './domain/useReplyToReview';
import { ReplyToFiveStarReviews } from './bulk/ReplyToFiveStarReviews';
import { Comparator } from './comparator/Comparator';
import InformationSvg from '../../../app/component/icons/InformationSvg';
import { themeV5 } from 'src/app/theme/ThemeV5';
import { ThemeTooltip } from 'src/app/component/ThemeTooltip';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

const sortByRating: Comparator<Review> = Comparator.sortingBy(rating());
const sortByDate: Comparator<Review> = Comparator.sortingBy((r: Review) => r.creationDate).reverse();

const withCommentFirst = Comparator.sortByFirst(haveComment);
const googleFirst = Comparator.sortByFirst(isGoogle);

const smartSorting = sortByRating.then(googleFirst).then(withCommentFirst).then(sortByDate);

export function DesktopReplyToReviews() {
  const business = useCurrentBusinessOrThrow();
  const [selectedReview, setSelectedReview] = useState<Review | undefined>(undefined);
  const [timeframe, setTimeframe] = useState<TimeframeSelection>(LAST_7_DAYS(business.timezone));
  const [reviews, setReviews] = useState<Reviews>(emptyReviews().forDateRange(timeframe.range));
  const replyToReview = useReplyToReviewFromReviews();

  const { isLoading, data: fetchedReviews } = useListReviews(business, timeframe.range.toTimeRange());

  const { data: thirtyDaysData } = useListReviews(business, LAST_30_DAYS(business.timezone).range.toTimeRange());
  const updateReviewsState = (newReviews: Reviews) => {
    const updatedReviews = newReviews.keep(withoutReviewReply).sort(smartSorting);
    setReviews(updatedReviews);

    const firstReview = updatedReviews.first();
    if (firstReview) {
      setSelectedReview(firstReview);
    }
  };

  const onReply = (review: Review, reply: string) =>
    replyToReview(review, reply, reviews).then((allReviews: Reviews) => updateReviewsState(allReviews));

  useEffect(() => {
    if (fetchedReviews) {
      updateReviewsState(fetchedReviews.forDateRange(timeframe.range));
    }
  }, [fetchedReviews]);

  useEffect(() => {
    if (thirtyDaysData) {
      updateReviewsState(reviews.updateWithReviews(thirtyDaysData));
    }
  }, [thirtyDaysData]);

  if (isLoading) return <CircularProgress />;

  const onBulkReply = (repliedReviews: Review[]) => updateReviewsState(reviews.upsertWith(new Reviews(repliedReviews)));
  return (
    <Stack direction="column" gap={5}>
      <Stack direction="row" alignItems="baseline" justifyContent="space-between">
        <TimeframePicker timeframe={timeframe} onChange={setTimeframe} timezone={business.timezone} />
        <ReplyToFiveStarReviews reviews={reviews} onBulkReply={onBulkReply} selectedTimeFrame={timeframe} />
      </Stack>
      {reviews.length() === 0 ? (
        <AllReviewsWereReplied />
      ) : (
        <Grid container direction="row">
          <Grid item sm={5} xs={12} direction="column">
            <Box padding="1rem 1rem 1rem 0.5rem">
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="subtitle2">
                  <FormattedMessage id="venue.reviews.reply.subtitle" values={{ reviewCount: reviews.length() }} />
                </Typography>
                <Stack direction="row" gap="0.2rem">
                  <Typography variant="subtitle2">
                    <FormattedMessage id="venue.reviews.reply.sorting.subtitle" defaultMessage="sorted by priority" />
                  </Typography>
                  <ThemeTooltip
                    title={
                      <FormattedMessage
                        id="venue.reviews.reply.sorting.tooltip"
                        defaultMessage="We sort reviews by rating, comment status, and date. Recent bad reviews with comments are given priority."
                      />
                    }
                    placement="top"
                    arrow
                  >
                    <Stack>
                      <InformationSvg color={themeV5.palette.text.secondary} size={16} />
                    </Stack>
                  </ThemeTooltip>
                </Stack>
              </Stack>
            </Box>
            <Grid item sm={5} xs={12} overflow="scroll" maxHeight="85vh">
              <Stack spacing={2} padding="0 1rem 0 0">
                {reviews.asArray().map((review) => (
                  <ReviewPreviewCard
                    review={review}
                    key={review.id}
                    onSelect={setSelectedReview}
                    selected={selectedReview?.id === review.id}
                  />
                ))}
              </Stack>
            </Grid>
          </Grid>
          <Grid item sm={7} xs={12}>
            {selectedReview && <SelectedReview review={selectedReview} onReply={onReply} />}
          </Grid>
        </Grid>
      )}
    </Stack>
  );
}
