import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SnackbarState {
  isVisible: boolean;
  title: string;
  text: string;
  type: 'success' | 'error' | 'info' | 'warning';
  positionType: 'top' | 'bottom' | undefined;
  duration: number | undefined;
  isAlert?: boolean;
  isLoader?: boolean;
}

const initialState: SnackbarState = {
  isVisible: false,
  title: '',
  text: '',
  type: 'info',
  positionType: 'top',
  duration: 3000,
  isAlert: true,
  isLoader: false,
};

const slice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    showSnackbar: (
      state,
      action: PayloadAction<{
        title: string;
        text: string;
        type?: 'success' | 'error' | 'info' | 'warning';
        positionType?: 'top' | 'bottom';
        duration?: number;
        isAlert?: boolean;
        isLoader?: boolean;
      }>,
    ) => {
      const { title, text, type = 'info', duration, positionType, isAlert = true, isLoader = false } = action.payload;
      state.isVisible = true;
      state.title = title;
      state.text = text;
      state.type = type;
      state.positionType = positionType;
      state.duration = duration;
      state.isAlert = isAlert;
      state.isLoader = isLoader;
    },

    hideSnackbar: (state) => {
      state.isVisible = false;
      state.title = '';
      state.text = '';
    },
  },
});

export const { showSnackbar, hideSnackbar } = slice.actions;
export default slice.reducer;
