import { useQueries } from '@tanstack/react-query';
import { useAuthenticatedUserOrThrow } from 'src/auth/hooks/useAuthenticatedUserOrThrow';
import { BusinessId } from 'src/business/domain/Business';
import { businessQuery } from 'src/business/queries/businessQuery';

export const useAllAuthorizedBusinesses = () => {
  const user = useAuthenticatedUserOrThrow();
  return useQueries({
    queries: user.claims.map((c) => businessQuery(c.businessId as BusinessId)),
  });
};
