import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { TipPoolDistributionFlow } from 'src/tips/domain/TipPoolDistributionFlows';
import { HttpTipsRepository } from 'src/tips/infrastructure/HttpTipsRepository';
import axios from 'axios';
import { buildQueryKey } from '../queries/useGetBusinessTippingConfiguration';
import { BusinessId } from 'src/business/domain/Business';

export const useUpdateTipPoolDistributionFlowMutation = (businessId: BusinessId) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const repository = new HttpTipsRepository(axios, configuration.bookkeepingApiBaseUrl);
  return useMutation({
    mutationFn: (dto: UpdateTipPoolDistributionFlowDto) =>
      repository.updateTipPoolDistributionFlow(businessId, dto.flow),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: buildQueryKey(businessId) });
    },
  });
};

export interface UpdateTipPoolDistributionFlowDto {
  flow: TipPoolDistributionFlow;
}
