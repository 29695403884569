import { BusinessId } from 'src/business/domain/Business';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ProductRepositoryHttp } from 'src/menu/dynamic-menu/repositories/ProductRepositoryHttp';
import { QUERYKEY_MENU_PRODUCTS } from 'src/menu/dynamic-menu/queries/product/getAllProductByBusinessIdQuery';
import { Tag } from 'src/menu/dynamic-menu/domain/Tag';
import { Allergen } from 'src/menu/dynamic-menu/domain/Allergen';
import { QUERYKEY_MENU_PRODUCT } from 'src/menu/dynamic-menu/queries/product/getProductByIdQuery';

export const useBulkDeleteProductsMutation = (businessId: BusinessId) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const productRepository = new ProductRepositoryHttp(configuration.menuApiBaseUrl);
  return useMutation({
    mutationFn: (productIds: string[]) => productRepository.deleteProducts(businessId, productIds),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_PRODUCTS] });
    },
  });
};

export const useBulkSnoozeProductsMutation = (businessId: BusinessId) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const productRepository = new ProductRepositoryHttp(configuration.menuApiBaseUrl);
  return useMutation({
    mutationFn: (productSkus: string[]) => productRepository.snoozeProducts(businessId, productSkus),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_PRODUCTS] });
    },
  });
};

export const useBulkUnsnoozeProductsMutation = (businessId: BusinessId) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const productRepository = new ProductRepositoryHttp(configuration.menuApiBaseUrl);
  return useMutation({
    mutationFn: (productSkus: string[]) => productRepository.unSnoozeProducts(businessId, productSkus),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_PRODUCTS] });
    },
  });
};

export const useBulkEnableProductsForOrderingMutation = (businessId: BusinessId) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const productRepository = new ProductRepositoryHttp(configuration.menuApiBaseUrl);
  return useMutation({
    mutationFn: (productIds: string[]) => productRepository.enableProductsForOrdering(businessId, productIds),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_PRODUCTS] });
    },
  });
};

export const useBulkDisableProductsForOrderingMutation = (businessId: BusinessId) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const productRepository = new ProductRepositoryHttp(configuration.menuApiBaseUrl);
  return useMutation({
    mutationFn: (productIds: string[]) => productRepository.disableProductsForOrdering(businessId, productIds),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_PRODUCTS] });
    },
  });
};

export type SetTagsToProductsDto = {
  productIds: string[];
  tags: Tag[];
};

export const useBulkTagsProductsMutation = (businessId: BusinessId) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const productRepository = new ProductRepositoryHttp(configuration.menuApiBaseUrl);
  return useMutation({
    mutationFn: (setTagsDto: SetTagsToProductsDto) => productRepository.setTagsToProducts(businessId, setTagsDto.productIds, setTagsDto.tags),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_PRODUCT] });
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_PRODUCTS] });
    },
  });
};

export type SetAllergensToProductsDto = {
  productIds: string[];
  allergens: Allergen[];
};

export const useBulkAllergensProductsMutation = (businessId: BusinessId) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const productRepository = new ProductRepositoryHttp(configuration.menuApiBaseUrl);
  return useMutation({
    mutationFn: (setAllergensDto: SetAllergensToProductsDto) => productRepository.setAllergensToProducts(businessId, setAllergensDto.productIds, setAllergensDto.allergens),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_PRODUCT] });
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_PRODUCTS] });
    },
  });
};

export type SetRelatedProducts = {
  productIds: string[];
  relatedProductIds: string[];
};

export const useBulkLinkRelatedProducts = (businessId: BusinessId) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const productRepository = new ProductRepositoryHttp(configuration.menuApiBaseUrl);
  return useMutation({
    mutationFn: (setRelatedProducts: SetRelatedProducts) => productRepository.linkRelatedProducts(businessId, setRelatedProducts.productIds, setRelatedProducts.relatedProductIds),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_PRODUCT] });
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_PRODUCTS] });
    },
  });
};

export type SetProductsVisibility = {
  productIds: string[];
  visible: boolean;
};

export const useBulkSetProductsVisibility = (businessId: BusinessId) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const productRepository = new ProductRepositoryHttp(configuration.menuApiBaseUrl);
  return useMutation({
    mutationFn: (params: SetProductsVisibility) => productRepository.setProductsVisibility(businessId, params.productIds, params.visible),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_PRODUCT] });
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_PRODUCTS] });
    },
  });
};

