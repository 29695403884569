import { useLocation } from 'react-router';
import { NavigationItem } from './NavigationItems';
import { useBusinessIsNoPos } from 'src/business/hooks/useBusinessIsNoPos';

export type NavigationItemState = {
  disabled: boolean;
  selected: boolean;
};

export const useNavigationMenuItemState = (menuItem: NavigationItem): NavigationItemState => {
  const isANoPos = useBusinessIsNoPos();
  const currentRoute = useLocation().pathname;
  return {
    disabled: isANoPos && !menuItem.availableForNoPos,
    selected: currentRoute.includes(menuItem.route!),
  };
};
