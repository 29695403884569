import { Box, IconButton } from '@mui/material';
import { DeleteBinIcon } from 'src/enterprise/configuration-replication/components/icons/DeleteBinIcon';
import { useFormContext } from 'react-hook-form';
import React, { MouseEventHandler } from 'react';
import { UserRoleField } from 'src/enterprise/configuration-replication/pages/UserManagementPage/Form/InputFields/UserRole';
import { UserEmailField } from 'src/enterprise/configuration-replication/pages/UserManagementPage/Form/InputFields/UserEmail';
import { UserPhoneNumberField } from 'src/enterprise/configuration-replication/pages/UserManagementPage/Form/InputFields/UserPhoneNumber';
import { UserLocationAccessField } from 'src/enterprise/configuration-replication/pages/UserManagementPage/Form/InputFields/UserLocationAccess';

interface AddUserFormProps {
  row: { id: number; role: string; email: string; locationAccess: string[] };
  hasMultipleRows?: boolean;
  onDeleteRow?: MouseEventHandler<HTMLButtonElement>;
}

export const AddUserForm = ({ row, hasMultipleRows, onDeleteRow }: AddUserFormProps) => {
  const { watch } = useFormContext();

  const selectedRole = watch(`users.${row.id}.role`);

  if (!row) return;

  return (
    <Box sx={{ alignItems: 'baseline', alignSelf: 'stretch', display: 'flex', gap: '16px', flexGrow: 1 }}>
      <UserRoleField row={{ ...row, id: row.id.toString() }} />
      <UserEmailField row={{ ...row, id: row.id.toString() }} />
      {['WAITER', 'STAFF'].includes(selectedRole) && (
        <UserPhoneNumberField row={{ ...row, id: row.id.toString(), phoneNumber: '' }} />
      )}
      <UserLocationAccessField row={{ ...row, id: row.id.toString() }} />

      {hasMultipleRows && (
        <IconButton component={'button'} onClick={onDeleteRow}>
          <DeleteBinIcon />
        </IconButton>
      )}
    </Box>
  );
};
