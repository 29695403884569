import axios from 'axios';
import { PosPaymentMethod } from 'src/domain/pos-connection/PosPaymentMethods';
import { VposVenueRepository } from 'src/domain/pos-connection/VposVenueRepository';
import { PosConnectionId } from 'src/pos/domain/PosConnection';
import { RevenueCenterListDto, RevenueCenterListDtoSchema } from '../venue/dto/RevenueCenterDto';
import { RevenueCenter } from 'src/domain/pos-connection/RevenueCenter';
import { Mapping, PaymentMethodMapping } from 'src/domain/pos-connection/PaymentMethodMapping';


type VposVenuePosPaymentMethods = {
  posPaymentMethods: VposVenuePosPaymentMethod[],
};

type VposVenuePosPaymentMethod = {
  id: string,
  label: string,
};

export class VposVenueRepositoryHttp implements VposVenueRepository {
  constructor(private vposUrl: string) {
  }

  async getPaymentMethodsMapping(posConnectionId: PosConnectionId): Promise<PaymentMethodMapping> {
    const response = await axios.get<PaymentMethodMapping>(
      `${this.vposUrl}/v2/payment-methods/${posConnectionId}/mapping`,
    );
    return response.data;
  }

  async updatePaymentMethodsMapping(posConnectionId: PosConnectionId, mappings: Mapping[]): Promise<void> {
    await axios.post(
      `${this.vposUrl}/v2/payment-methods/${posConnectionId}/mapping`,
      mappings,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  }

  async getPosPaymentMethods(posConnectionId: PosConnectionId): Promise<PosPaymentMethod[]> {
    const response = await axios.get<VposVenuePosPaymentMethods>(`${this.vposUrl}/payment-methods/${posConnectionId}/pos`);

    return response.data.posPaymentMethods.map((posPaymentMethod) => (
      {
        id: posPaymentMethod.id,
        label: posPaymentMethod.label,
      }
    ));
  }

  async getRevenueCenters(posConnectionId: PosConnectionId): Promise<RevenueCenter[]> {
    const response = await axios.get<unknown>(`${this.vposUrl}/revenue-centers/${posConnectionId}`);
    const list = RevenueCenterListDtoSchema.parse(response.data);
    return list.revenueCenters.map((dto) => ({
      id: dto.externalId,
      label: dto.name,
      isActive: dto.supported,
    }));
  }

  async postRevenueCenters(posConnectionId: PosConnectionId, revenueCenters: RevenueCenter[]): Promise<void> {
    const list: RevenueCenterListDto = {
      revenueCenters: revenueCenters.map((center: RevenueCenter) => ({
        externalId: center.id,
        name: center.label,
        supported: center.isActive,
      })),
    };

    await axios.post(
      `${this.vposUrl}/revenue-centers/${posConnectionId}`,
      list,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  }
}
