import axios from 'axios';
import { StaffPort } from 'src/tips/domain/StaffPort';
import { Staff, StaffSchema } from 'src/tips/domain/AllStaff';

export class StaffHttpPort implements StaffPort {
  constructor(private staffTippingUrl: string) {}

  async getStaff(businessId: string): Promise<Staff[]> {
    return axios
      .get(`${this.staffTippingUrl}/sunday-tipping/businesses/${businessId}/staff`, {
        headers: {
          accept: 'application/json',
        },
      })
      .then((response) => {
        const safeParse = StaffSchema.safeParse(response.data);
        if (safeParse.success) {
          return safeParse.data;
        }
        throw Error(`fail parsing ${JSON.stringify(response.data)}, parsing error=${safeParse.error}`);
      });
  }

  async updateRate(businessId: string, staffId: string, directTippingRate: number): Promise<void> {
    return axios.put(
      `${this.staffTippingUrl}/sunday-tipping/businesses/${businessId}/staff/${staffId}/directTippingRate`,
      { directTippingRate },
    );
  }
}
