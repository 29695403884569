import { ReviewConfiguration } from 'src/sundayplus/reviews/browse/domain/ReviewConfigurationFactory';
import { reviewConfigurationRepository } from 'src/sundayplus/reviews/browse/infrastructure/HttpReviewConfigurationRepository';
import { BusinessId } from 'src/business/domain/Business';
import type { UseQueryResult } from '@tanstack/react-query/src/types';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from 'src/app/queries/utils';

type ReviewConfigurationSelector<T> = (reviewConfiguration: ReviewConfiguration) => T;

const reviewConfigurationCacheKey = (businessId: BusinessId) => [queryKeys.REVIEWS_CONFIGURATION, { businessId }];

const useReviewConfiguration = <T = ReviewConfiguration>(
  selector?: ReviewConfigurationSelector<T>,
): UseQueryResult<T> => {
  const business = useCurrentBusinessOrThrow();
  return useQuery({
    queryKey: reviewConfigurationCacheKey(business.id),
    queryFn: (): Promise<ReviewConfiguration> => reviewConfigurationRepository.get(business.id),
    select: selector,
  });
};

export const useReviewsEnabled = (): boolean => {
  const { data } = useReviewConfiguration((configuration) => configuration.reviewsEnabled);
  return data ?? false;
};

export const useExternalSiteRatingThreshold = (): number => {
  const { data } = useReviewConfiguration((configuration) => configuration.externalSiteRatingThreshold);
  return data ?? 1;
};

export const useSaveReviewConfiguration = (businessId: BusinessId) => {
  const queryClient = useQueryClient();
  const reviewsEnabled = useReviewsEnabled();
  const externalSiteRatingThreshold = useExternalSiteRatingThreshold();

  const updateConfiguration = async (newConfigurationFields: Partial<ReviewConfiguration>) => {
    const updatedConfig: ReviewConfiguration = {
      reviewsEnabled,
      externalSiteRatingThreshold,
      ...newConfigurationFields,
    };
    await reviewConfigurationRepository.save(businessId, updatedConfig);
  };

  return useMutation({
    mutationFn: updateConfiguration,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: reviewConfigurationCacheKey(businessId) }),
  });
};
