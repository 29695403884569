export const GoogleIcon = ({ width = '11', height = '12' }: { width?: string; height?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 11 12" fill="none">
      <path
        /* eslint-disable-next-line max-len */
        d="M5.71824 11.2498C7.16462 11.2498 8.37887 10.7832 9.26578 9.97821L7.57533 8.69487C7.12297 9.00403 6.51583 9.21986 5.71824 9.21986C4.30162 9.21986 3.09927 8.30407 2.67067 7.03827L2.60784 7.0435L0.954242 8.29764L0.932617 8.35655C1.81354 10.0715 3.62303 11.2498 5.71824 11.2498Z"
        fill="#34A853"
      />
      <path
        /* eslint-disable-next-line max-len */
        d="M10.8615 6.11651C10.8615 5.68486 10.8258 5.36987 10.7484 5.04321H5.71875V6.99148H8.67105C8.61155 7.47565 8.29012 8.20481 7.57584 8.69477L7.56583 8.76L9.15611 9.96733L9.26629 9.97811C10.2782 9.06228 10.8615 7.7148 10.8615 6.11651Z"
        fill="#4285F4"
      />
      <path
        /* eslint-disable-next-line max-len */
        d="M2.67078 7.03829C2.55769 6.71163 2.49224 6.36161 2.49224 5.99997C2.49224 5.63829 2.55769 5.28831 2.66483 4.96166L2.66183 4.89209L0.987512 3.6178L0.932731 3.64333C0.569659 4.35499 0.361328 5.15416 0.361328 5.99997C0.361328 6.84579 0.569659 7.64491 0.932731 8.35657L2.67078 7.03829Z"
        fill="#FBBC05"
      />
      <path
        /* eslint-disable-next-line max-len */
        d="M5.71824 2.78C6.72416 2.78 7.4027 3.20583 7.78961 3.56168L9.30148 2.11503C8.37295 1.26922 7.16462 0.750061 5.71824 0.750061C3.62303 0.750061 1.81354 1.92836 0.932617 3.64331L2.66472 4.96163C3.09927 3.69583 4.30161 2.78 5.71824 2.78Z"
        fill="#EB4335"
      />
    </svg>
  );
};
