import { useQuery } from '@tanstack/react-query';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { StaffHttpPort } from 'src/tips/infrastructure/StaffHttpPort';
import { Staff } from 'src/tips/domain/AllStaff';

export const QUERYKEY_BUSINESS_STAFF = { scope: 'business_staff' };

const buildQueryKey = (businessId: string) => [QUERYKEY_BUSINESS_STAFF, businessId] as const;

export const getBusinessStaffQuery = (businessId: string): Promise<Staff[]> => {
  const configuration = ConfigurationLoader.load();
  const staffPort = new StaffHttpPort(configuration.bookkeepingApiBaseUrl);
  return staffPort.getStaff(businessId);
};

export const useGetBusinessStaff = (businessId: string) =>
  useQuery({ queryKey: buildQueryKey(businessId), queryFn: () => getBusinessStaffQuery(businessId) });
