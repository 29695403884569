import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { UserType } from '../auth/domain/user';

export type RumIdentity = {
  userId: string;
  email: string;
  userType: UserType;
  hasMultilocationsAccess: boolean;
  impersonate: boolean;
  impersonator?: string;
};

export const configureRumForUser = ({ userId, userType, email, hasMultilocationsAccess, impersonate, impersonator }: RumIdentity) => {
  datadogRum.setUser({ id: userId, type: userType, email, hasMultilocationsAccess, impersonate });
  if (impersonate) {
    datadogRum.setUserProperty('impersonator', impersonator);
  }
  datadogRum.setGlobalContextProperty('user_id', userId);
};

export const configureDatadogForVenue = (venueId: string, venueName: string) => {
  datadogLogs.setGlobalContextProperty('venueId', venueId);
  datadogLogs.setGlobalContextProperty('venueName', venueName);
};

export const configureDatadogForMultiLocations = () => {
  datadogLogs.setGlobalContextProperty('venueId', undefined);
  datadogLogs.setGlobalContextProperty('venueName', undefined);
};
