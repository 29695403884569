import { DateRange } from 'src/sundayplus/reviews/domain/DateRange';

export type TimeFrame = 'LAST_7_DAYS' | 'LAST_30_DAYS' | 'PREVIOUS_7_DAYS' | 'TODAY' | 'YESTERDAY';
export const toDateRange = (timeFrame: TimeFrame, timeZone?: string) => {
  switch (timeFrame) {
    case 'TODAY':
      return DateRange.today(timeZone);
    case 'YESTERDAY':
      return DateRange.yesterday(timeZone);
    case 'LAST_7_DAYS':
      return DateRange.lastSevenDays(timeZone);
    case 'PREVIOUS_7_DAYS':
      return DateRange.previousSevenDays(timeZone);
    case 'LAST_30_DAYS':
      return DateRange.lastThirtyDays(timeZone);
  }
};
