import React, { useRef, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { DownloadOperations } from './DownloadOperations';
import { DownloadPayouts } from './DownloadPayouts';
import { RevenuePeriod } from '../../../domain/RevenuesDatePresets';
import { DownloadError } from './DownloadError';

export const Downloads = ({
  period,
}: { period: RevenuePeriod }) => {

  const [inError, setInError] = useState<boolean>(false);
  const ref = useRef<number  | null >(null);
  const onError = () => {
    if (ref.current) clearTimeout(ref.current);
    setInError(true);
    ref.current = window.setTimeout(() => {
      setInError(false);
    }, 5000);
  };

  return (
    <Box sx={{
      position: 'sticky',
      bottom: 0,
      borderTop: '1px solid #dddde0',
      background: 'white',
    }}
    >
      <Grid p={2} container>
        <Grid
          item
          mt={1}
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
        >
          <DownloadPayouts onError={onError} period={period} />
          <DownloadOperations period={period} onError={onError} />
        </Grid>
        <Grid
          item
          mt={1}
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {inError && <DownloadError onClose={() => setInError(false)}/>}
        </Grid>
      </Grid>
    </Box>
  );
};
