import { useNavigate, useSearchParams } from 'react-router';
import { Box, Typography } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import { PageCard } from 'src/enterprise/configuration-replication/pages/UserManagementPage';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import {
  PrimaryButton,
  SecondaryButton,
} from 'src/enterprise/configuration-replication/pages/UserManagementPage/AddUserPage';
import { useListUsers, useUpdateUsers } from 'src/pages/Settings/UserPermissions/mutation/useBusinessUsersQuery';
import { useBusinessIdOrThrow } from 'src/business/hooks/useBusinessId';
import { UserRole } from 'src/domain/user/UserRole';
import { EditUserForm } from 'src/enterprise/configuration-replication/pages/UserManagementPage/Form/EditUserForm';
import { useEffect, useMemo } from 'react';

interface User {
  id: string;
  name: string;
  role: UserRole;
  email: string;
  phoneNumber: string;
  locationAccess: string[];
}

interface Users {
  users: User[];
}

export const EditUserPage = () => {
  const intl = useIntl();
  const businessId = useBusinessIdOrThrow();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const userIds = searchParams.get('users')?.split(',');
  const userFields = { id: '', name: '', role: '' as UserRole, email: '', phoneNumber: '', locationAccess: [] };

  const { data: users, isLoading } = useListUsers({ businessId, userIds });
  const usersData = useMemo(() => {
    if (!users) return [];
    return users.map((user) => {
      return {
        id: user.userId,
        name: user.firstName && user.firstName ? `${user.firstName} ${user.lastName}` : '',
        role: user.role,
        email: user.email,
        phoneNumber: user.phoneNumber ?? '',
        locationAccess: ['ALL'],
      };
    });
  }, [users]);

  const methods = useForm<Users>({
    defaultValues: { users: [userFields] },
  });

  const mutation = useUpdateUsers();

  const handleBackNavigation = () => {
    navigate(-1);
  };

  const { fields } = useFieldArray({
    control: methods.control,
    name: 'users',
    keyName: 'id',
  });

  const watchFieldArray = methods.watch('users');
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray?.[index],
    };
  });

  const updateUser = async (data: User[]) => {
    try {
      mutation.mutate(data);
    } catch (e: any) {
      console.log(e.message);
    }
  };

  const onSubmit = (data: Users) => {
    const dataToSubmit = data.users.map((user) => {
      const firstName = user.name.split(' ')[0];
      const lastName = user.name.split(' ')[1];

      return {
        ...user,
        firstName: firstName,
        lastName: lastName,
        userId: user.id,
        role: user.role as UserRole,
      };
    });

    updateUser(dataToSubmit).then();
  };

  const hasEmptyFields = !methods.formState.isDirty;

  useEffect(() => {
    if (usersData && !methods.formState.isDirty) {
      methods.reset({
        users: usersData,
      });
    }
  }, [usersData, methods]);

  return (
    <PageCard>
      <Box sx={{ display: 'flex', alignItems: 'center', alignSelf: 'stretch' }}>
        <Box
          sx={{ display: 'flex', alignItems: 'center', alignSelf: 'stretch', cursor: 'pointer', gap: '8px' }}
          onClick={handleBackNavigation}
        >
          <ChevronLeft sx={{ width: '20px', height: '20px' }} />
          <Typography sx={{ fontSize: '20px' }}>
            {intl.formatMessage({ id: 'user_management.button.edit_user' })}
          </Typography>
        </Box>
      </Box>
      {!isLoading && usersData && (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
              {controlledFields.map((row, index) => (
                <EditUserForm row={{ ...row, id: index.toString() }} key={index} />
              ))}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', marginTop: '36px' }}>
              <SecondaryButton variant="contained" color="secondary" onClick={handleBackNavigation}>
                {intl.formatMessage({ id: 'user_management.button.dismiss' })}
              </SecondaryButton>
              <PrimaryButton variant="contained" disabled={hasEmptyFields} type={'submit'}>
                {intl.formatMessage({ id: 'user_management.button.save_changes' })}
              </PrimaryButton>
            </Box>
          </form>
        </FormProvider>
      )}
    </PageCard>
  );
};
