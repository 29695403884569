import { NewHomeCard } from 'src/home/components/HomeCardv2';
import { CardsWrapper } from 'src/home/components/HomeContent';
import { useMemo, useState } from 'react';
import { useStaffPerformance } from 'src/staff-performance/page/useStaffPerformance';
import { StaffPerformanceRangeType } from 'src/staff-performance/domain/StaffPerformance';
import { Instant } from 'src/sundayplus/Instant';
import { Business } from 'src/business/domain/Business';
import { Staff } from 'src/staff-performance/page/StaffPerformanceViewModel';
import { useNavigate } from 'react-router';
import { StaffPerformanceTable } from 'src/home/components/staff-performance/HomeStaffPerformanceCard/StaffPerformanceTable';
import { EmptyStaffPerformanceCard } from 'src/home/components/staff-performance/HomeStaffPerformanceCard/EmptyStaffPerformanceCard';
import { useIntl } from 'src/app/i18n/TypedIntl';

const BestPerformanceIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      /* eslint-disable-next-line max-len */
      d="M15 6C14.4477 6 14 6.44772 14 7C14 7.55228 14.4477 8 15 8H19.5858L13.4243 14.1615C13.2146 14.3712 13.0993 14.4854 13.0101 14.5611L13 14.5696L12.9899 14.5611C12.9007 14.4854 12.7854 14.3712 12.5757 14.1615L9.81592 11.4017C9.6375 11.2232 9.4637 11.0494 9.30449 10.9142C9.12964 10.7658 8.90775 10.6062 8.61803 10.5121C8.21635 10.3816 7.78365 10.3816 7.38197 10.5121C7.09225 10.6062 6.87036 10.7658 6.69551 10.9142C6.5363 11.0494 6.36252 11.2232 6.18409 11.4017L1.29289 16.2929C0.902369 16.6834 0.902369 17.3166 1.29289 17.7071C1.68342 18.0976 2.31658 18.0976 2.70711 17.7071L7.57574 12.8385C7.78541 12.6288 7.90069 12.5146 7.98987 12.4389L8 12.4304L8.01013 12.4389C8.09931 12.5146 8.21459 12.6288 8.42426 12.8385L11.1841 15.5983C11.3625 15.7768 11.5363 15.9506 11.6955 16.0858C11.8704 16.2342 12.0922 16.3938 12.382 16.4879C12.7837 16.6184 13.2163 16.6184 13.618 16.4879C13.9078 16.3938 14.1296 16.2342 14.3045 16.0858C14.4637 15.9506 14.6375 15.7768 14.8159 15.5983L21 9.41421V14C21 14.5523 21.4477 15 22 15C22.5523 15 23 14.5523 23 14V7C23 6.44772 22.5523 6 22 6H15Z"
      fill="#0BAE37"
    />
  </svg>
);

const WorstPerformanceIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      /* eslint-disable-next-line max-len */
      d="M1.79289 6.29289C2.18342 5.90237 2.81658 5.90237 3.20711 6.29289L8.07574 11.1615C8.28541 11.3712 8.40069 11.4854 8.48987 11.5611C8.49342 11.5641 8.49679 11.5669 8.5 11.5696C8.50321 11.5669 8.50658 11.5641 8.51013 11.5611C8.59931 11.4854 8.71459 11.3712 8.92426 11.1615L11.6841 8.40171C11.8625 8.22323 12.0363 8.0494 12.1955 7.91424C12.3704 7.76581 12.5922 7.60624 12.882 7.5121C13.2837 7.38158 13.7163 7.38158 14.118 7.5121C14.4078 7.60624 14.6296 7.76581 14.8045 7.91424C14.9637 8.0494 15.1375 8.22322 15.3159 8.40169L21.5 14.5858V10C21.5 9.44772 21.9477 9 22.5 9C23.0523 9 23.5 9.44772 23.5 10V17C23.5 17.5523 23.0523 18 22.5 18H15.5C14.9477 18 14.5 17.5523 14.5 17C14.5 16.4477 14.9477 16 15.5 16H20.0858L13.9243 9.83848C13.7146 9.6288 13.5993 9.51462 13.5101 9.43892C13.5066 9.4359 13.5032 9.43307 13.5 9.4304C13.4968 9.43307 13.4934 9.4359 13.4899 9.43892C13.4007 9.51462 13.2854 9.6288 13.0757 9.83848L10.3159 12.5983C10.1375 12.7768 9.96371 12.9506 9.80449 13.0858C9.62964 13.2342 9.40775 13.3938 9.11803 13.4879C8.71635 13.6184 8.28365 13.6184 7.88197 13.4879C7.59225 13.3938 7.37036 13.2342 7.19551 13.0858C7.03629 12.9506 6.86251 12.7768 6.68406 12.5983C6.67656 12.5908 6.66904 12.5833 6.66152 12.5757L1.79289 7.70711C1.40237 7.31658 1.40237 6.68342 1.79289 6.29289Z"
      fill="#D11023"
    />
  </svg>
);

interface HomeStaffPerformanceCardProps {
  business: Business;
}

export const getTopDownPerformers = (data: Staff[]) => {
  if (!data.length) return;

  const metrics = ['billViewRate'] as const;
  const minMaxResults = [];
  const topPerformers = [];
  const downPerformers = [];

  for (const metric of metrics) {
    // @ts-ignore
    const sortedDesc = [...data].sort((a, b) => {
      if (a[metric] && b[metric]) return b[metric] - a[metric];
    });
    const top3Max = sortedDesc.slice(0, 3);

    // @ts-ignore
    const sortedAsc = [...data].sort((a, b) => {
      if (a[metric] && b[metric]) return a[metric] - b[metric];
    });
    const top3Min = sortedAsc.slice(0, 3);

    topPerformers.push(top3Max);
    downPerformers.push(top3Min);
  }

  minMaxResults.push(topPerformers, downPerformers);

  return minMaxResults;
};

export const HomeStaffPerformanceCard = ({ business }: HomeStaffPerformanceCardProps) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const [range] = useState<StaffPerformanceRangeType>('7_DAYS');
  const [startDate] = useState<Instant>(Instant.now().startOf('day'));
  const [endDate] = useState<Instant>(Instant.now().endOfDay());

  const { isLoading, data } = useStaffPerformance({
    range,
    businessId: business.id,
    timezone: business.timezone,
    startDate: startDate.toDate(),
    endDate: endDate.toDate(),
  });

  const dataForMetrics = useMemo(() => {
    return data;
  }, [data]);

  const topDown = getTopDownPerformers(dataForMetrics ?? []);

  if (isLoading) return;

  if (data && !data.length) {
    return <EmptyStaffPerformanceCard />;
  }

  return (
    <CardsWrapper data-his-staff-performance="true">
      <NewHomeCard
        handleCardRedirect={() => navigate('/staff-performance')}
        title={intl.formatMessage({ id: 'venue.home.section.staffPerformance.best.title' })}
        titleIcon={BestPerformanceIcon}
        hasDivider={false}
        dataTable={
          <StaffPerformanceTable
            data={(topDown && topDown[0][0]) || []}
            businessCurrency={business.currency}
            businessLocale={business.address.countryCode}
            testId={'highestStaffPerformanceTable'}
          />
        }
        hasFixedWidth
      />
      <NewHomeCard
        handleCardRedirect={() => navigate('/staff-performance')}
        title={intl.formatMessage({ id: 'venue.home.section.staffPerformance.worst.title' })}
        titleIcon={WorstPerformanceIcon}
        hasDivider={false}
        dataTable={
          <StaffPerformanceTable
            data={(topDown && topDown[1][0]) || []}
            businessCurrency={business.currency}
            businessLocale={business.address.countryCode}
            testId={'lowestStaffPerformanceTable'}
          />
        }
        hasFixedWidth
      />
    </CardsWrapper>
  );
};
