import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { v4 as uuidv4 } from 'uuid';
import { CurrencyCode } from '@sundayapp/web-money';
import { HttpPaymentTerminalRepository } from '../infrastructure/HttpPaymentTerminalRepository';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import {
  defaultConfiguration,
  PaymentTerminalConfiguration,
  tipsKind,
  TipsLevelConfiguration,
  Waiter,
  User,
} from '../domain/PaymentTerminalConfiguration';
import { PaymentTerminalConfigurationDto, TipsLevel, UserDto } from '../domain/PaymentTerminalRepository';
import { EnrollmentId } from 'src/business/domain/Enrollment';

const PAYMENT_TERMINAL_CONFIGURATION = 'payment_terminal_configuration';
export const PaymentTerminalConfigurationQueryKeys = {
  configuration: (enrollmentId: EnrollmentId, configurationId: string | undefined) => [PAYMENT_TERMINAL_CONFIGURATION, enrollmentId, configurationId ] as const,
  allConfigurations: () => [PAYMENT_TERMINAL_CONFIGURATION] as const,
};

type TerminalQueryKey = ReturnType<typeof PaymentTerminalConfigurationQueryKeys.configuration>;

export const paymentTerminalConfigurationQuery = async ({
  queryKey,
}: QueryFunctionContext<TerminalQueryKey>): Promise<PaymentTerminalConfiguration> => {
  const [, enrollmentId, configurationId] = queryKey;

  if (!configurationId) {
    return defaultConfiguration;
  }

  const configuration = ConfigurationLoader.load();
  const repository = new HttpPaymentTerminalRepository(configuration.paymentTerminalBaseUrl);
  const response: PaymentTerminalConfigurationDto = await repository.configuration(enrollmentId, configurationId);

  function mapToTipsLevelConfiguration(tipsLevelDto: TipsLevel): TipsLevelConfiguration {
    const valuesConfig =
      tipsLevelDto.kind === tipsKind.AMOUNT
        ? {
          value1: tipsLevelDto.amount1?.amount!! / 100000,
          value2: tipsLevelDto.amount2?.amount!! / 100000,
          value3: tipsLevelDto.amount3?.amount!! / 100000,
          value4: tipsLevelDto.amount4?.amount!! / 100000,
        }
        : {
          value1: tipsLevelDto.rate1!! / 100,
          value2: tipsLevelDto.rate2!! / 100,
          value3: tipsLevelDto.rate3!! / 100,
          value4: tipsLevelDto.rate4!! / 100,
        };

    return {
      enabled: tipsLevelDto.enabled,
      kind: tipsLevelDto.kind,
      ...valuesConfig,
    };
  }

  function toWaiter(name: string): Waiter {
    return {
      id: uuidv4(),
      name,
    };
  }

  function toUser(dto: UserDto): User {
    return {
      id: dto.id,
      firstName: dto.firstName,
      lastName: dto.lastName,
      email: dto.email,
      role: dto.role,
      enabled: dto.enabled,
      codeEnabled: dto.codeEnabled,
    };
  }

  return {
    id: response.id,
    tips: {
      userExperienceEnabled: response.userExperienceEnabled,
      tipsEnabled: response.tipsEnabled,
      roundedTipsEnabled: response.roundedTipsEnabled,
      tipsLevels: {
        level1: mapToTipsLevelConfiguration(response.tipsLevels.level1),
        level2: mapToTipsLevelConfiguration(response.tipsLevels.level2),
        level3: mapToTipsLevelConfiguration(response.tipsLevels.level3),
      },
    },
    waiter: {
      enabled: response.waiterListEnabled,
      waiterList: response.waiterList,
      waiters: response.waiters ?? response.waiterList.map((n) => toWaiter(n)),
    },
    general: {
      name: response.name,
      printMerchantTickets: response.printMerchantTickets,
      alertOnLowAmountEnabled: response.alertOnLowAmountEnabled,
      highAmountThreshold: {
        amount: response.highAmountThreshold.amount / 100000,
        currency: response.highAmountThreshold.currency as CurrencyCode,
      },
      alertOnHighAmountEnabled: response.alertOnHighAmountEnabled,
      lowAmountThreshold: {
        amount: response.lowAmountThreshold.amount / 100000,
        currency: response.lowAmountThreshold.currency as CurrencyCode,
      },
      neptingWsdlUrl: response.cardAcquirer?.neptingWsdlUrl,
      externalCardAcquirerId: response.cardAcquirer?.neptingId,
      ilotId: response.neptingIlot?.id,
    },
    scan: {
      enabled: response.scanEnabled,
    },
    psoConnection: {
      enabled: response.posConnectionEnabled,
    },
    fastPayment: {
      enabled: response.fastPaymentEnabled,
    },
    users: {
      enabled: response.userListEnabled,
      userGuestEnabled: response.userGuestEnabled,
      users: response.users.map((dto) => toUser(dto)),
    },
    userSelectionMode: response.userSelectionMode,
    concertConfiguration: {
      enabled: response.concertProtocolEnabled,
    },
  };
};

export const usePaymentTerminalConfiguration = (
  enrollmentId: EnrollmentId,
  configurationId: string | undefined,
  options?: { onSuccess: (conf: PaymentTerminalConfiguration) => void },
) =>
  useQuery<PaymentTerminalConfiguration, unknown, PaymentTerminalConfiguration, TerminalQueryKey>({
    queryKey: PaymentTerminalConfigurationQueryKeys.configuration(enrollmentId, configurationId),
    queryFn: paymentTerminalConfigurationQuery,
    ...options,
  });
