import * as React from 'react';
import { ReactNode } from 'react';
import { BimpliIcon } from './BimpliIcon';
import { EdenredIcon } from './EdenredIcon';
import { SodexoIcon } from './SodexoIcon';
import { SwileIcon } from './SwileIcon';
import { CbIcon } from './CbIcon';
import { AmexIcon } from './AmexIcon';
import { ElectronIcon } from './ElectronIcon';
import { MaestroIcon } from './MaestroIcon';
import { MastercardIcon } from './MastercardIcon';
import { VisaIcon } from './VisaIcon';
import { UpIcon } from './UpIcon';
import { CardIcon } from './Cardcon';
import { UnionPayIcon } from 'src/components/icons/paymentIcons/UnionPayIcon';
import { ChequeVacancesIcon } from 'src/accounting/common/Icons/ChequeVacancesIcon';

const sx = {
  width: 32,
  height: 20,
  borderRadius: '3px',
  border: '1px solid #eff0f4',
  backgroundColor: 'white',
};
const icons: { [key: string]: ReactNode } = {
  BIMPLI: <BimpliIcon sx={sx} />,
  CHEQUE_DEJEUNER: <></>,
  'sales_summary.ancv': <ChequeVacancesIcon {...sx} />,
  EDENRED: <EdenredIcon sx={sx} />,
  METHOD_EDENRED: <EdenredIcon sx={sx} />,
  NATIXIS_INTERTITRES: <BimpliIcon sx={sx} />,
  PASS_RESTAURANT: <SodexoIcon sx={sx} />,
  SODEXO: <SodexoIcon sx={sx} />,
  PLUXEE: <SodexoIcon sx={sx} />,
  SWILE: <SwileIcon sx={sx} />,
  CARD: <CardIcon sx={sx} />,
  CB: <CbIcon sx={sx} />,
  AMEX: <AmexIcon sx={sx} />,
  ELECTRON: <ElectronIcon sx={sx} />,
  MAESTRO: <MaestroIcon sx={sx} />,
  MASTERCARD: <MastercardIcon sx={sx} />,
  VISA: <VisaIcon sx={sx} />,
  UP: <UpIcon sx={sx} />,
  UP_DEJ: <UpIcon sx={sx} />,
  UNION_PAY: <UnionPayIcon sx={sx} />,
  UNKNOWN: <></>,
};

export const paymentMethodIcon = (paymentMethod: string): ReactNode => (paymentMethod in icons ? icons[paymentMethod] : icons.UNKNOWN);
