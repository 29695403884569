import { Business } from 'src/business/domain/Business';
import { MenuMigrationReview } from 'src/enterprise/configuration-replication/domain/menu/MenuReplicationState';
import { MenuRepository } from 'src/menu/dynamic-menu/repositories/MenuRepository';
import { BoxRepository } from 'src/ordering/box/repositories/BoxRepository';
import { PosConnectionRepository } from 'src/pos/domain/PosConnectionRepository';

export class ApplyMenuChanges {

  constructor(
    private boxRepository: BoxRepository,
    private menuRepository: MenuRepository,
    private posConnectionRepository: PosConnectionRepository,
  ) {
  }

  private async getPosConnection(targetBusiness: Business) {
    const targetBoxes = targetBusiness.oapEnrollment ?
      await this.boxRepository.getVenueBoxes(targetBusiness.oapEnrollment.id)
      : [];

    if (targetBoxes.length > 1) {
      throw new Error('Only one target box is allowed');
    }

    const box = targetBoxes.length > 0 ? targetBoxes[0] : undefined;
    if (box && box.posIntegration.type === 'DIRECT_INTEGRATION') {
      return {
        posConnectionId: box.posIntegration.posConnectionId,
        revenueCenterId: box.posIntegration.externalRevenueCenterId,
      };
    }

    const posConnections = await this.posConnectionRepository.getPosConnections(targetBusiness.id);
    const eligiblePosConnection = (posConnections[targetBusiness.id] ?? [])
      .filter(posConnection => posConnection.status === 'ACTIVE')
      .map(posConnection => ({
        posConnectionId: posConnection.id,
        revenueCenterId: undefined,
      }))
      .find(() => true); // take first

    if (!eligiblePosConnection) {
      throw new Error('No active POS connection found for business ' + targetBusiness.name);
    }
    return eligiblePosConnection;
  }

  async apply(currentState: MenuMigrationReview) {
    return Promise.all(
      currentState.targets.map(targetBusiness =>
        this.getPosConnection(targetBusiness)
          .then(posConnection =>
            this.menuRepository.migrateMenu(
              currentState.origin.id,
              currentState.menus.map(menu => menu.id),
              targetBusiness.id,
              posConnection?.posConnectionId,
              posConnection?.revenueCenterId,
            ))
          .then(() => {
            return '';
          })
          .catch((reason) => {
            const message = 'Error while migrating menu(s) : [' + currentState.menus.map(menu => menu.name) + '] to ' + targetBusiness.name;
            console.error(message, reason);
            return message;
          })),
    ).then(errors => errors.filter(s => s.length > 0).flat());
  }
}
