import { Button } from '@sundayapp/b2b-react-component-library';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useSendBillReceipt, useSendRefundReceipt } from 'src/infrastructure/payment/HttpBillReceiptRepository';
import { isEmailValid } from 'src/domain/user/Email';
import { Payment } from '../domain/Payment';
import { ServiceType } from 'src/domain/venue/ServiceType';
import { useGlobalConfiguration } from 'src/venueconfiguration/infrastructure/useGlobalConfiguration';
import { BusinessId } from 'src/business/domain/Business';
import { spaceUsages } from 'src/app/theme/SpaceUsagesTheme';

type EmailReceiptModalProps = {
  businessId: BusinessId;
  payment: Payment;
  serviceType: ServiceType;
  onClose: () => void;
  orderId?: string;
  onSendReceiptError: () => void;
};

type EmailReceiptModalInputs = {
  email: string;
  guests: number | undefined;
  guestsNames: string | undefined;
  businessPurpose: string | undefined;
  companyName: string | undefined;
  companyAddress: string | undefined;
  companyZipCode: string | undefined;
  companyCity: string | undefined;
  companyCountry: string | undefined;
};

type ReceiptType = 'RECEIPT' | 'BILL';

/* eslint-disable react/jsx-props-no-spreading */
export const EmailReceiptAndRefundModal = ({
  businessId,
  payment,
  serviceType,
  onClose,
  orderId,
  onSendReceiptError,
}: EmailReceiptModalProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const sendBillReceipt = useSendBillReceipt(serviceType);
  const sendRefundReceipt = useSendRefundReceipt();
  const { data: venueConfiguration } = useGlobalConfiguration();

  const [receiptType, setReceiptType] = useState<ReceiptType>('RECEIPT');
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReceiptType((event.target as HTMLInputElement).value as ReceiptType);
  };

  const [isProcessing, updateProcessingState] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting, isValid },
  } = useForm<EmailReceiptModalInputs>({
    mode: 'all',
  });

  const onSubmit: SubmitHandler<EmailReceiptModalInputs> = async ({
    email,
    guests,
    guestsNames,
    businessPurpose,
    companyName,
    companyAddress,
    companyZipCode,
    companyCity,
    companyCountry,
  }) => {
    if (!isEmailValid(email)) {
      setError('email', {
        type: 'custom',
        message: intl.formatMessage({
          id: 'email-receipt.form.email.invalid',
          defaultMessage: 'invalid email format',
        }),
      });
      return;
    }

    if (receiptType == 'BILL') {
      updateProcessingState(true);
      sendBillReceipt(
        payment,
        email,
        guests,
        guestsNames,
        businessPurpose,
        companyName,
        companyAddress,
        companyZipCode,
        companyCity,
        companyCountry,
        orderId,
      )
        .catch(() => onSendReceiptError())
        .finally(() => {
          onClose();
          updateProcessingState(false);
        });
    } else {
      updateProcessingState(true);
      if (payment.refunds.length != 0) {
        payment.refunds.forEach((refund) => {
          const issuedAt = refund.timestamp;
          const amount = refund.amount.amount;
          const currency = refund.amount.currency.toString();
          sendRefundReceipt(businessId, email, issuedAt, amount, currency).finally(() => {
            onClose();
            updateProcessingState(false);
          });
        });
      }
    }
  };

  return (
    <Modal open onClose={onClose}>
      <Box>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            background: 'white',
            borderRadius: '24px',
            padding: '24px',
            maxWidth: '540px',
            width: '100%',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            position: 'absolute',
          }}
        >
          <Typography variant="h5" sx={{ marginBottom: 2.5 }}>
            <FormattedMessage id="email-receipt.modal.name" />
          </Typography>
          <Box display="flex" flexDirection="column" alignItems="center" maxHeight="450px" overflow="auto">
            <FormControl fullWidth sx={{ marginBottom: 1 }}>
              <FormLabel sx={{ marginBottom: 0.5 }}>
                <Typography variant="body2" sx={{ letterSpacing: '-0.5px' }} color={theme.palette.text.secondary}>
                  <FormattedMessage
                    id="emails_receipts.chose_which_receipt"
                    defaultMessage="Select which receipt you want to send"
                  />
                </Typography>
              </FormLabel>
              <RadioGroup defaultValue="RECEIPT" onChange={handleChange}>
                <Card
                  variant="outlined"
                  sx={{
                    borderRadius: 1,
                    marginBottom: 1,
                  }}
                >
                  <CardContent sx={{ paddingTop: 3 }}>
                    <Box display="flex" justifyContent="space-between">
                      <Box>
                        <Typography variant="body1" sx={{ letterSpacing: '-0.5px' }} color={theme.palette.text.primary}>
                          <FormattedMessage id="emails_receipts.refund" defaultMessage="Refund" />
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ letterSpacing: '-0.5px' }}
                          color={theme.palette.text.secondary}
                        >
                          {payment.refunds.length > 1 ? (
                            <FormattedMessage
                              id="emails_receipts.refund.subtitle_multiple_refunds"
                              defaultMessage="User will receive an email by refund with details for each refund"
                            />
                          ) : (
                            <FormattedMessage
                              id="emails_receipts.refund.subtitle"
                              defaultMessage="User will receive an email with details of the refund"
                            />
                          )}
                        </Typography>
                      </Box>
                      <FormControlLabel sx={{ marginRight: 0 }} value="RECEIPT" control={<Radio />} label="" />
                    </Box>
                  </CardContent>
                </Card>

                <Card
                  variant="outlined"
                  sx={{
                    borderRadius: 1,
                    marginBottom: 2,
                  }}
                >
                  <CardContent sx={{ paddingTop: 3 }}>
                    <Box display="flex" justifyContent="space-between">
                      <Box>
                        <Typography variant="body1" sx={{ letterSpacing: '-0.5px' }} color={theme.palette.text.primary}>
                          <FormattedMessage id="emails_receipts.bill" defaultMessage="Bill" />
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ letterSpacing: '-0.5px' }}
                          color={theme.palette.text.secondary}
                        >
                          <FormattedMessage
                            id="emails_receipts.bill.subtitle"
                            defaultMessage="User will receive an email with details of the bill"
                          />
                        </Typography>
                      </Box>
                      <FormControlLabel sx={{ marginRight: 0 }} value="BILL" control={<Radio />} label="" />
                    </Box>
                  </CardContent>
                </Card>
              </RadioGroup>
            </FormControl>

            <Box sx={{ width: '100%' }}>
              <Typography variant="body2" sx={{ letterSpacing: '-0.5px' }} color={theme.palette.text.secondary}>
                <FormattedMessage id="email-receipt.form.input-email" />
              </Typography>
              <TextField
                placeholder={intl.formatMessage({
                  id: 'email-receipt.form.email.placeholder',
                  defaultMessage: "guest's email here",
                })}
                id="receiptEmail"
                error={!!errors.email}
                helperText={errors.email?.message}
                {...register('email', {
                  required: intl.formatMessage({
                    id: 'email-receipt.form.email.required',
                    defaultMessage: 'email required',
                  }),
                })}
                sx={{
                  width: '100%',
                  marginTop: 0.5,
                  marginBottom: 2,
                }}
              />
            </Box>
            {serviceType === ServiceType.PAY_AT_TABLE && receiptType === 'BILL' && (
              <>
                <Box
                  sx={{
                    width: '100%',
                    marginTop: 1,
                  }}
                >
                  <Typography variant="h6" sx={{ marginBottom: 2.5 }}>
                    <FormattedMessage id="email-receipt.modal.expense-note" />
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    marginBottom: 1,
                  }}
                >
                  <Typography variant="body2" sx={{ letterSpacing: '-0.5px' }} color={theme.palette.text.secondary}>
                    <FormattedMessage id="email-receipt.form.guestsNumber" />
                  </Typography>
                  <TextField
                    {...register('guests', {
                      min: {
                        value: 0,
                        message: intl.formatMessage({ id: 'email-receipt.form.guestsNumber.errorMessage' }),
                      },
                      setValueAs: (v) => (v === '' ? null : parseInt(v)),
                    })}
                    error={!!errors.guests}
                    helperText={errors.guests?.message}
                    placeholder={intl.formatMessage({ id: 'email-receipt.form.guestsNumber' })}
                    type="number"
                    sx={{
                      width: '100%',
                      marginTop: 0.5,
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    marginBottom: 1,
                  }}
                >
                  <Typography variant="body2" sx={{ letterSpacing: '-0.5px' }} color={theme.palette.text.secondary}>
                    <FormattedMessage id="email-receipt.form.guestsNames" />
                  </Typography>
                  <TextField
                    {...register('guestsNames', { setValueAs: (v) => (v === '' ? null : v) })}
                    placeholder={intl.formatMessage({ id: 'email-receipt.form.guestsNames' })}
                    sx={{
                      width: '100%',
                      marginTop: 0.5,
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    marginBottom: 1,
                  }}
                >
                  <Typography variant="body2" sx={{ letterSpacing: '-0.5px' }} color={theme.palette.text.secondary}>
                    <FormattedMessage id="email-receipt.form.businessPurpose" />
                  </Typography>
                  <TextField
                    {...register('businessPurpose', { setValueAs: (v) => (v === '' ? null : v) })}
                    placeholder={intl.formatMessage({ id: 'email-receipt.form.businessPurpose' })}
                    sx={{
                      width: '100%',
                      marginTop: 0.5,
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    marginBottom: 1,
                  }}
                >
                  <Typography variant="body2" sx={{ letterSpacing: '-0.5px' }} color={theme.palette.text.secondary}>
                    <FormattedMessage id="email-receipt.form.companyName" />
                  </Typography>
                  <TextField
                    {...register('companyName', { setValueAs: (v) => (v === '' ? null : v) })}
                    placeholder={intl.formatMessage({ id: 'email-receipt.form.companyName' })}
                    sx={{
                      width: '100%',
                      marginTop: 0.5,
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    marginTop: 2,
                  }}
                >
                  <Typography variant="body1" sx={{ marginBottom: 1 }}>
                    <FormattedMessage id="email-receipt.modal.companyAddressTitle" />
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    marginBottom: 1,
                  }}
                >
                  <Typography variant="body2" sx={{ letterSpacing: '-0.5px' }} color={theme.palette.text.secondary}>
                    <FormattedMessage id="email-receipt.form.companyAddress" />
                  </Typography>
                  <TextField
                    {...register('companyAddress', { setValueAs: (v) => (v === '' ? null : v) })}
                    placeholder={intl.formatMessage({ id: 'email-receipt.form.companyAddress' })}
                    sx={{
                      width: '100%',
                      marginTop: 0.5,
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    marginBottom: 1,
                  }}
                >
                  <Typography variant="body2" sx={{ letterSpacing: '-0.5px' }} color={theme.palette.text.secondary}>
                    <FormattedMessage id="email-receipt.form.companyZipCode" />
                  </Typography>
                  <TextField
                    {...register('companyZipCode', { setValueAs: (v) => (v === '' ? null : v) })}
                    placeholder={intl.formatMessage({ id: 'email-receipt.form.companyZipCode' })}
                    sx={{
                      width: '100%',
                      marginTop: 0.5,
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    marginBottom: 1,
                  }}
                >
                  <Typography variant="body2" sx={{ letterSpacing: '-0.5px' }} color={theme.palette.text.secondary}>
                    <FormattedMessage id="email-receipt.form.companyCity" />
                  </Typography>
                  <TextField
                    {...register('companyCity', { setValueAs: (v) => (v === '' ? null : v) })}
                    placeholder={intl.formatMessage({ id: 'email-receipt.form.companyCity' })}
                    sx={{
                      width: '100%',
                      marginTop: 0.5,
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    marginBottom: 1,
                  }}
                >
                  <Typography variant="body2" sx={{ letterSpacing: '-0.5px' }} color={theme.palette.text.secondary}>
                    <FormattedMessage id="email-receipt.form.companyCountry" />
                  </Typography>
                  <TextField
                    {...register('companyCountry', { setValueAs: (v) => (v === '' ? null : v) })}
                    placeholder={intl.formatMessage({ id: 'email-receipt.form.companyCountry' })}
                    sx={{
                      width: '100%',
                      marginTop: 0.5,
                    }}
                  />
                </Box>
              </>
            )}
          </Box>
          <Button
            type="submit"
            variant="primary"
            size="large"
            fullWidth
            disabled={!isValid || isSubmitting || isProcessing || !venueConfiguration}
            marginTop={spaceUsages.largeMedium}
          >
            <FormattedMessage id="email-receipt.form.email.confirm" />
          </Button>
        </form>
      </Box>
    </Modal>
  );
};
