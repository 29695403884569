import { Autocomplete, FormControl, TextField } from '@mui/material';
import React from 'react';
import { PosPaymentMethod } from 'src/domain/pos-connection/PosPaymentMethods';
import { MappingKey } from 'src/domain/pos-connection/PaymentMethodMapping';


type SelectPosPaymentMethodProps = {
  productId: string,
  mappingKey: MappingKey,
  availablePaymentMethods: PosPaymentMethod[],
  selectedValue: string | undefined
  onSelectedValueChange: (newValue: string | undefined) => void,
};
export const SelectPosPaymentMethod = ({
  availablePaymentMethods,
  selectedValue,
  onSelectedValueChange,
}: SelectPosPaymentMethodProps) => {
  //const [value, setValue] = useState<PosPaymentMethod | null>(availablePaymentMethods.find(pm => pm.id === selectedValue) ?? null);
  const value = selectedValue ? availablePaymentMethods.find(pm => pm.id === selectedValue) ?? null : null;

  return (
    <>
      <FormControl fullWidth>
        <Autocomplete
          disablePortal
          options={availablePaymentMethods}
          sx={{ width: 300 }}
          onChange={(event, newValue) => onSelectedValueChange(newValue?.id)}
          value={value}
          getOptionLabel={(option) => option.label + ' (' + option.id + ')'}
          renderInput={(params) => <TextField {...params} />}
        />
      </FormControl>
    </>
  );
};
