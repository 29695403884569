import {
  Alert,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useBusinessIdOrThrow } from '../business/hooks/useBusinessId';
import { useGetBusinessLoyaltyReference } from './queries/useGetBusinessLoyaltyReference';
import MenuItem from '@mui/material/MenuItem';
import { useSaveLoyaltyConfiguration } from 'src/loyalty/mutations/useSaveLoyaltyBusinessConfiguration';
import { SubmitHandler, useForm } from 'react-hook-form';
import { datadogLogs } from '@datadog/browser-logs';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import SundayTooltip from 'src/app/component/tooltip/SundayTooltip';
import { PaytronixValidationBurnModal } from 'src/loyalty/PaytronixValidationBurnModal';

type OnboardingInputs = {
  storeCode: string;
  merchantId: string;
  cardTemplateCode: string;
  burnEnabled: boolean;
  theme: string;
};
export const PaytronixOnboardingForm = () => {
  const businessId = useBusinessIdOrThrow();
  const { formatMessage } = useIntl();
  const {
    data: loyaltyBusiness,
    isLoading: loyaltyReferenceIsLoading,
  } = useGetBusinessLoyaltyReference(businessId);

  const saveConfiguration = useSaveLoyaltyConfiguration(businessId);
  const [onboardingError, setOnboardingError] = useState<string>('');
  const [visible, setVisible] = useState(false);

  const {
    getValues,
    register,
    handleSubmit,
    formState: {
      errors,
      isValid,
      isSubmitting,
    },
  } = useForm<OnboardingInputs>({
    mode: 'all',
    criteriaMode: 'all',
  });

  const snackBar = useSnackbar();
  const onSubmit: SubmitHandler<OnboardingInputs> = async ({
    storeCode,
    merchantId,
    cardTemplateCode,
    burnEnabled,
    theme,
  }) => {
    setOnboardingError('');
    try {
      saveConfiguration.mutate({
        loyaltyReference: 'PAYTRONIX',
        loyaltyConfiguration: {
          storeCode,
          merchantId,
          cardTemplateCode,
          burnEnabled,
          theme,
        },
      });
      snackBar.addNotification({
        variant: 'success',
        text: formatMessage({ id: 'loyalty.onboarding.success', defaultMessage: 'Configuration saved' }),
      });
    } catch (error: any) {
      snackBar.addNotification({ variant: 'error', text: formatMessage({ id: 'loyalty.onboarding.errors.other' }) });
      datadogLogs.logger.error('An error occurred while trying to configure a Paytronix business', { error });
      setOnboardingError(formatMessage({ id: 'loyalty.onboarding.errors.other' }));
    }
  };

  if (loyaltyReferenceIsLoading) {
    return <CircularProgress />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap="16px" maxWidth="576px">
        <Typography variant="h5">
          {formatMessage({ id: 'loyalty.onboarding.title' }, { loyaltyReference: 'Paytronix' })}
        </Typography>

        <TextField
          label="Store Code"
          required
          error={!!errors.storeCode}
          id="storeCode"
          defaultValue={loyaltyBusiness?.loyaltyConfiguration?.storeCode}
          helperText={errors.storeCode?.message}
          {...register('storeCode', {
            required: formatMessage({ id: 'loyalty.onboarding.errors.field' }, { field: 'Store code' }),
          })}
        />
        <TextField
          label="Merchant id"
          required
          error={!!errors.merchantId}
          id="merchantId"
          defaultValue={loyaltyBusiness?.loyaltyConfiguration?.merchantId}
          helperText={errors.merchantId?.message}
          {...register('merchantId', {
            required: formatMessage({
              id: 'loyalty.onboarding.errors.field',
            }, { field: 'Merchant id' }),
          })}
        />
        <TextField
          label="Card Template Code"
          required
          error={!!errors.cardTemplateCode}
          id="cardTemplateCode"
          defaultValue={loyaltyBusiness?.loyaltyConfiguration?.cardTemplateCode}
          helperText={errors.cardTemplateCode?.message}
          {...register('cardTemplateCode', {
            required: formatMessage({ id: 'loyalty.onboarding.errors.field' }, { field: 'Card Template Code' }),
          })}
        />
        <FormControl>
          <InputLabel id="theme">Theme</InputLabel>
          <Select
            label="Program"
            defaultValue={loyaltyBusiness?.loyaltyConfiguration?.theme || 'default'}
            id="theme"
            {...register('theme')}
          >
            <MenuItem value="default">Default Theme</MenuItem>
            <MenuItem value="BHG">BHG</MenuItem>
            <MenuItem value="CENTURION">Centurion</MenuItem>
            <MenuItem value="DRAKES">Drakes</MenuItem>
            <MenuItem value="LETTUCE">Lettuce</MenuItem>
            <MenuItem value="DINEAMIC">DineAmic</MenuItem>
            <MenuItem value="PARCHED_HOSPITALITY">Parched Hospitality</MenuItem>
            <MenuItem value="WALK_ONS">Walk On's</MenuItem>
          </Select>
        </FormControl>
        <SundayTooltip title="⚠️ only available for TOAST POS" placement="top-start">
          <FormControlLabel
            label="Enable burn"
            control={
              <Checkbox
                id="burnEnabled"
                defaultChecked={loyaltyBusiness?.burnEnabled}
                {...register('burnEnabled')} />}
          />
        </SundayTooltip>
        {onboardingError && (
          <Alert severity="error">{onboardingError}</Alert>
        )}
        <Button variant="contained" disabled={!isValid || isSubmitting} onClick={() => {
          if (getValues('burnEnabled')) {
            setVisible(true);
          } else {
            handleSubmit(onSubmit)();
          }
        }}>
          {formatMessage({ id: 'loyalty.onboarding.save', defaultMessage: 'Save' })}
        </Button>
      </Stack>
      <PaytronixValidationBurnModal
        visible={visible}
        disabled={!isValid || isSubmitting}
        onClick={() => {
          handleSubmit(onSubmit)();
          setVisible(false);
        }}
        onClose={() => {
          setVisible(false);
        }} />
    </form>
  );
};
