import { Button, colorUsage, InputText, Panel } from '@sundayapp/b2b-react-component-library';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { ButtonContainer, CustomizationForm, NotificationContainer } from '../Customization.styles';
import { usePrompt } from 'src/app/component/Prompt/prompt';
import { useBusinessMutation } from 'src/menu/common/mutations/useBusinessMutation';
import { useGetMenuBusiness } from 'src/menu/common/queries/getMenuBusinessQuery';
import { BusinessId, invalidBusinessIdToRefactor } from 'src/business/domain/Business';
import { Alert } from '@mui/material';
import { spaceUsages } from 'src/app/theme/SpaceUsagesTheme';
import { SundayText } from 'src/app/component/Text/Text';

const isAValidUrl = (urlToValidate: string): boolean => {
  let url: URL;
  try {
    url = new URL(urlToValidate);
  } catch (e) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};

type AppSettingsMenuLinkInputs = {
  menuLink: string;
};

interface MenuLinkHandlerProps {
  businessId: BusinessId;
}

const MenuLinkHandler = ({ businessId }: MenuLinkHandlerProps) => {
  const intl = useIntl();
  const updateVenue = useBusinessMutation(businessId);

  const useSnackBar = useSnackbar();

  const [appSettingsMenuLinkUpdateError, setAppSettingsMenuLinkUpdateError] = useState('');

  const { data: menuVenue } = useGetMenuBusiness(invalidBusinessIdToRefactor(businessId));

  const {
    register,
    reset,
    watch,
    handleSubmit,
    formState: { isValid, isSubmitting, isDirty, errors },
  } = useForm<AppSettingsMenuLinkInputs>({
    mode: 'all',
    criteriaMode: 'all',
  });

  const watchMenuLink = watch('menuLink');

  useEffect(() => {
    reset({
      menuLink: menuVenue?.externalMenuUrl,
    });
  }, [menuVenue]);

  usePrompt(isDirty, intl.formatMessage({ id: 'settings.confirm_leaving_tab' }));

  const onSubmit = async (inputs: AppSettingsMenuLinkInputs) => {
    setAppSettingsMenuLinkUpdateError('');
    try {
      await updateVenue.mutateAsync({ externalMenuUrl: inputs.menuLink });
      useSnackBar.addNotification({
        variant: 'success',
        text: intl.formatMessage({ id: 'settings.app_settings.success_saving' }),
      });
      reset(inputs, { keepDirty: false });
    } catch (e) {
      setAppSettingsMenuLinkUpdateError(intl.formatMessage({ id: 'settings.app_settings.error_saving' }));
    }
  };

  return (
    <CustomizationForm onSubmit={handleSubmit(onSubmit)}>
      <Panel flexDirection="column" style={{ gap: '1em' }}>
        <SundayText size="large" sx={{ marginBottom: spaceUsages.medium }}>
          <FormattedMessage id="settings.app_settings.menu_link.title" />
        </SundayText>
        <SundayText size="small" color={colorUsage.tertiaryText}>
          <FormattedMessage id="settings.app_settings.menu_link.explanation" />{' '}
          <FormattedMessage id="settings.app_settings.menu_link.explanation2" />
        </SundayText>
        <InputText
          id="menuLink"
          placeholder={intl.formatMessage({ id: 'settings.app_settings.menu_link.menu_site_link.placeholder' })}
          inputIcon={!watchMenuLink ? '' : 'check'}
          error={!!errors.menuLink}
          subCaption={errors.menuLink?.message}
          {...register('menuLink', {
            deps: [],
            required: false,
            validate: {
              isValidUrl: (value) => {
                if (value) {
                  return isAValidUrl(value) ? true : 'Invalid url';
                }
                return true;
              },
            },
          })}
        />
        {appSettingsMenuLinkUpdateError && (
          <NotificationContainer>
            <Alert severity="error">{appSettingsMenuLinkUpdateError}</Alert>
          </NotificationContainer>
        )}
        {isValid && isDirty && (
          <ButtonContainer>
            <Button type="submit" variant="primary" size="xSmall" disabled={!isValid || isSubmitting || !isDirty}>
              <FormattedMessage id="settings.save_changes" />
            </Button>
          </ButtonContainer>
        )}
      </Panel>
    </CustomizationForm>
  );
};

export default MenuLinkHandler;
