import { Button, Modal, Stack, styled, Switch, TextField, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { IconWrapper } from '@sundayapp/b2b-react-component-library';
import CrossSvg from '../../../app/component/icons/CrossSvg';
import { themeV5 } from 'src/app/theme/ThemeV5';
import * as React from 'react';
import { User } from 'src/payment-terminal/domain/PaymentTerminalConfiguration';
import { useState } from 'react';
import { useChangeUserPassCodeMutation } from 'src/payment-terminal/mutations/useChangeUserPassCodeMutation';
import { EnrollmentId } from 'src/business/domain/Enrollment';

const UserContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
`;

const Center = styled('div')`
  display: flex;
  margin: auto;
`;
export const styleModal = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '1rem',
  padding: '1.5rem',
  width: '500px',
};

interface Props {
  user: User
  enrollmentId: EnrollmentId;
  isOpen: boolean
  onClose: () => void
}

export const PTUserPassCodeModal = ({
  user,
  enrollmentId,
  isOpen,
  onClose,
}: Props) => {
  const intl = useIntl();
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const [isDisabled, setIsDisabled] = useState(!user.codeEnabled);
  const changePassCode = useChangeUserPassCodeMutation(enrollmentId);

  const handleChange = (value: string) => {
    if (/^\d{0,4}$/.test(value)) {
      setCode(value);
      setError('');
    } else {
      setError(intl.formatMessage({ id: 'payment.terminal.manage.user.code.error.invalid' }));
    }
  };

  const handleSubmit = () => {
    if (isDisabled || code.length === 4) {
      changePassCode.mutateAsync({
        userId: user.id,
        code: isDisabled ? '0000' : code,
      }).then(() => onClose());
    } else {
      setError(intl.formatMessage({ id: 'payment.terminal.manage.user.code.error.invalid' }));
    }
  };

  return (
    <Modal open={isOpen}>
      <Stack direction="column" sx={styleModal} spacing={2}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h4" sx={{ marginBottom: '40px' }}>
            <FormattedMessage id="payment.terminal.manage.user.code.update" />
          </Typography>
          <IconWrapper onClick={onClose}>
            <CrossSvg size={24} color={themeV5.palette.text.secondary} />
          </IconWrapper>
        </Stack>
        <UserContainer>
          <Stack direction="row" justifyContent="space-between" spacing="20px" alignItems="center">
            <Typography>
              <FormattedMessage id="payment.terminal.manage.user.code.enable" />
            </Typography>
            <Switch
              checked={!isDisabled}
              onChange={() => setIsDisabled(!isDisabled)}
            />
          </Stack>

          <TextField
            label={intl.formatMessage({ id: 'payment.terminal.manage.user.code' })}
            type="password"
            value={code}
            onChange={(e) => handleChange(e.target.value)}
            error={!!error}
            helperText={error}
            disabled={isDisabled}
            autoComplete="one-time-code"
          />

          <Button
            variant="contained"
            onClick={handleSubmit}
            style={{ width: '100%' }}
            disabled={!isDisabled && code.length !== 4}
          >
            <Center>
              <FormattedMessage id="payment.terminal.manage.edit.button" />
            </Center>
          </Button>
        </UserContainer>
      </Stack>
    </Modal>
  );
};

