import * as React from 'react';
import { SetStateAction, useState } from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { Box, Button, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useEndOfServiceSummary } from '../hooks/useEndOfServiceSummary';
import { ExpandableRows } from '../components/ExpandableRows';
import { DatePicker } from 'src/components/date-picker/DatePicker';
import { RestaurantShiftPicker } from '../../restaurant-shifts/RestaurantShiftPicker';
import { useRestaurantShiftTimesOptions } from '../../restaurant-shifts/hooks/useRestaurantShiftTimesOptions';
import { RevenueCentersMultipleSelect } from '../components/RevenueCentersMultipleSelect';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { EndOfServiceConfiguration } from '../model/EndOfServiceConfiguration';
import { Business, isActiveServiceType } from 'src/business/domain/Business';
import { isCnp, products } from 'src/operations/end-of-service/model/products';
import { useAuthenticatedUserOrThrow } from 'src/auth/hooks/useAuthenticatedUserOrThrow';
import { ReconciliationCard } from 'src/operations/end-of-service/components/ReconciliationCard';
import ExportPaymentOperations from '../components/ExportPaymentOperations';
import { useSearchParams } from 'react-router';
import { useGetBusinessTippingConfiguration } from 'src/tips/queries/useGetBusinessTippingConfiguration';
import {
  ReconciliationAnalysisPanel,
} from 'src/operations/end-of-service/components/reconciliationAnalysis/ReconciliationAnalysisPanel';
import { useCanManageTips } from 'src/tips/hooks/useCanManageTips';
import { ServiceType } from 'src/domain/venue/ServiceType';

/**
 *
 * Mode moyen de paiement:
 * Decision made on 09/12/24. Will be changed at some point.
 * QR code  : showPooledTips = true, showDirectTips = false
 * PDQ instant : showPooledTips = true, showDirectTips = true
 * PDQ pooled : showPooledTips = true, showDirectTips = false
 * Waiter products : showPooledTips = true, showDirectTips = false
 *
 */
export const EndOfService = ({
  eosConfiguration,
  business,
}: {
  eosConfiguration: EndOfServiceConfiguration;
  business: Business;
}) => {
  const canManageTips = useCanManageTips();
  const { options } = useRestaurantShiftTimesOptions();
  const user = useAuthenticatedUserOrThrow();
  const [selected, setSelected] = useState<number>(0);
  const [hideTips, setHideTips] = useState(false);
  const [, setSearchParams] = useSearchParams();
  const { data: businessTippingConfiguration } = useGetBusinessTippingConfiguration(business.id);
  const isPosPooledTipsConfiguration = businessTippingConfiguration?.type === 'PosPooled';
  const minDate = new Date();
  minDate.setDate(minDate.getDate() - 60);

  const {
    reportingDigest,
    reportingDate,
    setReportingDate,
    restaurantShiftTimesOption,
    setRestaurantShiftTimesOption,
    revenueCentersSelected,
    setRevenueCentersSelected,
    revenueCentersOptions,
    reconciliationDigest,
    payments,
  } = useEndOfServiceSummary(eosConfiguration);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue === null) {
      return;
    }
    setSelected(newValue);
  };

  if (!reportingDigest || !restaurantShiftTimesOption) {
    return <div />;
  }

  const displayReconciliationDigests = user.impersonated || user.email.endsWith('@sundayapp.com');

  return (
    <Box
      sx={{
        paddingTop: 3,
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
      }}
    >
      <Stack direction="row" spacing={2} justifyContent={'space-between'} alignItems={'center'}>
        <Stack direction="row" spacing={2}>
          <DatePicker
            date={reportingDate}
            minDate={minDate}
            maxDate={new Date()}
            onClickDay={(date: SetStateAction<Date>) => {
              {
                setSearchParams({ reportingDate: (date as Date).toISOString() });
                setReportingDate(date);
              }
            }}
          />
          <RestaurantShiftPicker
            value={restaurantShiftTimesOption}
            onChange={setRestaurantShiftTimesOption}
            options={options}
          />
          <RevenueCentersMultipleSelect
            onChange={setRevenueCentersSelected}
            revenueCentersOptions={revenueCentersOptions}
            values={revenueCentersSelected}
          />
        </Stack>

        <Stack direction="row" spacing={2} justifyContent={'right'} alignContent={'center'}>
          {eosConfiguration.displayByPaymentContract && eosConfiguration.displayPrintMode && (
            <Button
              variant="text"
              onClick={() => setHideTips(!hideTips)}
              startIcon={hideTips ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
            >
              {hideTips && <FormattedMessage id="sales_summary.title.see_tips" defaultMessage="Details mode" />}
              {!hideTips && <FormattedMessage id="sales_summary.title.hide_tips" defaultMessage="Printing mode" />}
            </Button>
          )}
          <ToggleButtonGroup value={selected} exclusive onChange={handleChange}>
            <ToggleButton value={0}>
              <FormattedMessage id="sales_summary.title.payment_method" />
            </ToggleButton>
            <ToggleButton value={1}>
              <FormattedMessage id="sales_summary.title.waiter" />
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
        <ExportPaymentOperations payments={payments.payments} />
      </Stack>
      <ReconciliationAnalysisPanel
        reportingDate={reportingDate}
        options={options}
        business={business}
        restaurantShiftTimesOption={restaurantShiftTimesOption}
      />
      {reportingDigest && (
        <>
          {selected === 0 && (
            <>
              {}
              {isActiveServiceType(business, ServiceType.PAY_AT_TABLE) && (
                <ExpandableRows
                  date={reportingDate}
                  showPooledTips={true}
                  showDirectTips={false}
                  restaurantShiftTimesOption={restaurantShiftTimesOption}
                  revenueAndTips={reportingDigest.patPaymentMethod}
                  hideTips={hideTips}
                  eosConfiguration={eosConfiguration}
                />
              )}
              {isActiveServiceType(business, ServiceType.ORDER_AND_PAY) && (
                <ExpandableRows
                  date={reportingDate}
                  showPooledTips={true}
                  showDirectTips={false}
                  restaurantShiftTimesOption={restaurantShiftTimesOption}
                  revenueAndTips={reportingDigest.oapPaymentMethod}
                  hideTips={hideTips}
                  eosConfiguration={eosConfiguration}
                />
              )}
              {eosConfiguration.displayPaymentTerminal && (
                <ExpandableRows
                  date={reportingDate}
                  showPooledTips={true}
                  showDirectTips={!isPosPooledTipsConfiguration}
                  restaurantShiftTimesOption={restaurantShiftTimesOption}
                  revenueAndTips={reportingDigest.tpePaymentMethod}
                  hideTips={hideTips}
                  eosConfiguration={eosConfiguration}
                />
              )}
            </>
          )}
          {selected === 1 && (
            <>
              {reportingDigest.waitersProducts.map((waiterProducts) => {
                const canDisplay =
                  (business.oapEnrollment && waiterProducts.product === products.OAP) ||
                  (business.patEnrollment && waiterProducts.product === products.PAT) ||
                  waiterProducts.product === undefined ||
                  !isCnp(waiterProducts.product);
                return (
                  canDisplay && (
                    <ExpandableRows
                      key={waiterProducts.name}
                      date={reportingDate}
                      restaurantShiftTimesOption={restaurantShiftTimesOption}
                      revenueAndTips={waiterProducts}
                      hideTips={hideTips}
                      showPooledTips={canManageTips}
                      showDirectTips={false}
                      eosConfiguration={eosConfiguration}
                    />
                  )
                );
              })}
            </>
          )}
        </>
      )}

      <Stack direction="row" spacing={2}>
        {displayReconciliationDigests && reconciliationDigest.walkouts.operations.length > 0 && (
          <ReconciliationCard
            title={'Walkouts'}
            subtitle={'Visible only by OPS'}
            reporting={reconciliationDigest.walkouts}
            displayDetails={false}
          />
        )}
        {displayReconciliationDigests && reconciliationDigest.notNotifiedPayments.operations.length > 0 && (
          <ReconciliationCard
            title={'Payment not notified to POS'}
            subtitle={'Visible only by OPS'}
            reporting={reconciliationDigest.notNotifiedPayments}
            displayDetails={true}
          />
        )}
        {displayReconciliationDigests && reconciliationDigest.pdqFastPayments.operations.length > 0 && (
          <ReconciliationCard
            title={'PDQ Payments without associated table'}
            subtitle={'Visible only by OPS'}
            reporting={reconciliationDigest.pdqFastPayments}
            displayDetails={true}
          />
        )}
      </Stack>
    </Box>
  );
};
