import { restrictedToTipsManager } from 'src/tips/page/restrictedToTipsManager';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { useUpdateStaffRateMutation } from 'src/tips/mutations/useUpdateStaffRateMutation';
import React, { useState } from 'react';
import { useGetBusinessTippingConfiguration } from 'src/tips/queries/useGetBusinessTippingConfiguration';
import { useUpdateTipPoolDistributionFlowMutation } from 'src/tips/mutations/useUpdateTipPoolDistributionFlowMutation';
import {
  Box,
  CircularProgress,
  IconButton,
  Input,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  TipPoolDistributionFlow,
  tipPoolDistributionFlowTranslationKeys,
} from 'src/tips/domain/TipPoolDistributionFlows';
import { TipPoolDistributionConfigurationModal } from 'src/tips/tip-policies/TipPoolDistributionConfigurationModal';
import { palette } from 'src/organization-report/design/palette';
import PercentIcon from '@mui/icons-material/Percent';
import SundayTooltip from 'src/app/component/tooltip/SundayTooltip';
import { CalendarTodayOutlined, ChevronRightOutlined } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { NumericFormat } from 'react-number-format';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { BusinessTippingConfigurationCard } from 'src/tips/tip-policies/BusinessTippingConfigurationCard';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { TableEmptyState } from 'src/components/table/TableEmptyState';
import { useGetBusinessStaff } from 'src/tips/queries/getBusinessStaffQuery';

export const TipsPolicies = restrictedToTipsManager(() => {
  const intl = useIntl();
  const snackBar = useSnackbar();
  const business = useCurrentBusinessOrThrow();
  const { mutateAsync } = useUpdateStaffRateMutation(business.id);
  const [isEditing, setIsEditing] = useState(false);
  const [directTippingRate, setDirectTippingRate] = useState<number>();
  const [selectedStaff, setSelectedStaff] = useState<string | undefined>();
  const [showTipPoolDistributionModal, setShowTipPoolDistributionModal] = useState(false);
  const { data: tippingConfiguration, isError: tippingConfigError } = useGetBusinessTippingConfiguration(business.id);
  const { data: businessStaff, isError: businessStaffError } = useGetBusinessStaff(business.id);

  const updateTipPoolDistributionFlowMutation = useUpdateTipPoolDistributionFlowMutation(business.id);

  if (!businessStaff || !tippingConfiguration) {
    return <CircularProgress />;
  }

  if (tippingConfigError || businessStaffError) {
    return <TableEmptyState title={{
      id: 'sunday_tipping.staff_configuration.fetch_error',
      values: {},
    }} />;
  }

  if (tippingConfiguration && tippingConfiguration.type !== 'SundayTipping') {
    return (
      <TableEmptyState title={{
        id: 'sunday_tipping.staff_configuration.no_staff',
        values: {},
      }} />
    );
  }

  const resetStates = () => {
    setSelectedStaff(undefined);
    setDirectTippingRate(0);
    setIsEditing(false);
  };
  const submitTipPoolDistributionConfiguration = (selectedFlow: TipPoolDistributionFlow) => {
    updateTipPoolDistributionFlowMutation
      .mutateAsync({
        flow: selectedFlow,
      })
      .then(() => {
        snackBar.addNotification({
          variant: 'success',
          title: 'Succès',
          text: 'Distribution du pot commun modifiée avec succès',
        });
        setShowTipPoolDistributionModal(false);
      })
      .catch(() => {
        snackBar.addNotification({
          variant: 'error',
          title: 'Erreur',
          text: 'Une erreur est apparue, veuillez réessayer',
        });
      });
  };

  let displayTipPoolDistributionFlow = false;
  return (
    <>
      {showTipPoolDistributionModal && (
        <TipPoolDistributionConfigurationModal
          onSubmit={submitTipPoolDistributionConfiguration}
          onClose={() => setShowTipPoolDistributionModal(false)}
          value={tippingConfiguration.tipPoolDistributionFlow}
        />
      )}
      <Stack gap={'32px'}>
        <Stack gap={'16px'} direction="row">
          <BusinessTippingConfigurationCard
            icon={<PercentIcon fontSize={'large'} />}
            title={<FormattedMessage id={'sunday_tipping.staff_configuration.business_rate'} />}
            subtitle={<>{tippingConfiguration.waiterRate / 100}%</>}
            tooltip={<FormattedMessage id={'sunday_tipping.staff_configuration.business_rate.tooltip'} />}
          />

          {displayTipPoolDistributionFlow  &&
          <BusinessTippingConfigurationCard
            icon={<CalendarTodayOutlined fontSize={'large'} />}
            title={<FormattedMessage id={'sunday_tipping.tip_policies.tip_pool_distribution_flow.label'} />}
            subtitle={<FormattedMessage
              id={tipPoolDistributionFlowTranslationKeys[tippingConfiguration.tipPoolDistributionFlow]}
            />}
            tooltip={<FormattedMessage id="sunday_tipping.tip_policies.tip_pool_distribution_flow.tooltip" />}
            cta={<IconButton onClick={() => setShowTipPoolDistributionModal(true)} sx={{ maxHeight: '40px' }}>
              <ChevronRightOutlined fontSize="large" />
            </IconButton>}
          />
          }
        </Stack>

        <Typography variant="h5" fontWeight="700">
          <FormattedMessage id="sunday_tipping.tips_policies.staff_table.title" />
        </Typography>

        {businessStaff.length === 0
          ? (<TableEmptyState title={{ id: 'sunday_tipping.staff_configuration.no_staff', values: {} }} />)
          : (<Table>
            <TableHead>
              <TableRow>
                <TableCell size={'small'}>
                  <Typography variant="subtitle2" color={palette.neutral.grey500}>
                    {intl.formatMessage({ id: 'sunday_tipping.staff_configuration.name' })}
                  </Typography>
                </TableCell>
                <TableCell size={'small'}>
                  <Typography variant="subtitle2" color={palette.neutral.grey500}>
                    {intl.formatMessage({ id: 'sunday_tipping.staff_configuration.email' })}
                  </Typography>
                </TableCell>
                <TableCell size={'small'}>
                  <Stack direction={'row'} gap={'0.25rem'}>
                    <Typography variant="subtitle2" color={palette.neutral.grey500}>
                      {intl.formatMessage({ id: 'sunday_tipping.staff_configuration.rate' })}
                    </Typography>
                    <SundayTooltip
                      title={intl.formatMessage({ id: 'sunday_tipping.staff_configuration.rate.tooltip' })}>
                      <InfoOutlinedIcon />
                    </SundayTooltip>
                  </Stack>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {businessStaff.map((staff) => (
                <TableRow key={staff.id} sx={{ height: '67px' }}>
                  <TableCell size={'small'}>
                    <Typography variant="subtitle2">
                      {staff.firstName} {staff.lastName}
                    </Typography>
                  </TableCell>
                  <TableCell size={'small'}>
                    <Typography variant="subtitle2">{staff.email}</Typography>
                  </TableCell>
                  <TableCell>
                    {!(isEditing && selectedStaff === staff.id) && (
                      <Stack
                        direction={'row'}
                        alignItems={'center'}
                        gap={'4px'}
                        sx={{ cursor: 'pointer' }}
                        onMouseEnter={() => setSelectedStaff(staff.id)}
                        onClick={() => selectedStaff === staff.id && setIsEditing(true)}
                      >
                        {staff.directTippingRate ? (
                          <Typography variant="subtitle2">{staff.directTippingRate.value / 100}%</Typography>
                        ) : (
                          <Typography variant="subtitle2" color={'textDisabled'}>
                            {tippingConfiguration.waiterRate / 100}%
                          </Typography>
                        )}
                        {selectedStaff === staff.id && (<EditIcon />)}
                      </Stack>
                    )}

                    {isEditing && selectedStaff === staff.id && (
                      <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        gap={'8px'}
                      >
                        <NumericFormat
                          placeholder={'Custom %'}
                          isAllowed={({ floatValue }) => floatValue! <= 100}
                          customInput={Input}
                          required
                          autoFocus={isEditing}
                          value={
                            (businessStaff.find((b) => b.id === staff.id)?.directTippingRate?.value ??
                              tippingConfiguration.waiterRate) / 100
                          }
                          onChange={(event) => {
                            setDirectTippingRate(parseFloat(event.target.value));
                          }}
                          autoComplete="off"
                          sx={{
                            textAlignLast: 'end',
                            alignItems: 'center',
                            width: '6rem',
                            '& .MuiInput-input': {
                              padding: 0,
                            },
                          }}
                          slotProps={{
                            input: {
                              maxLength: 3,
                              step: 1,
                              min: 0,
                              max: 100,
                            },
                          }}
                        />
                        <IconButton
                          onClick={() => {
                            if (directTippingRate !== undefined) {
                              mutateAsync({
                                rate: directTippingRate * 100,
                                staffId: staff.id,
                              })
                                .then(() => {
                                  resetStates();
                                  snackBar.addNotification({
                                    variant: 'success',
                                    title: 'Succès',
                                    text: 'Le taux de pourboire en direct a été mis à jour avec succès',
                                  });
                                })
                                .catch(() => {
                                  snackBar.addNotification({
                                    variant: 'error',
                                    title: 'Erreur',
                                    text: 'Une erreur est apparue, veuillez réessayer',
                                  });
                                });
                            }
                          }}
                        >
                          <SaveIcon />
                        </IconButton>
                        <IconButton onClick={() => setIsEditing(false)}>
                          <CloseIcon />
                        </IconButton>
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>)}
      </Stack>
    </>
  );
});
