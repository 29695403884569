import React from 'react';
import { Typography } from '@mui/material';
import { Download } from '@mui/icons-material';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { BusinessId } from 'src/business/domain/Business';
import { MenuToolBox } from './MenuToolBox';
import { usePublishMenuMutation } from 'src/menu/dynamic-menu/mutations/menu/usePublishMenuMutation';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { BusinessMenusPublication } from 'src/menu/dynamic-menu/repositories/BusinessMenusPublication';

type PublishBusinessMenusButtonProps = {
  businessId: BusinessId;
};

export const PublishBusinessMenusButton = ({ businessId }: PublishBusinessMenusButtonProps) => {
  const intl = useIntl();
  const snackBar = useSnackbar();
  const publishMenus = usePublishMenuMutation(businessId);

  const showPublicationError = () => {
    snackBar.addNotification({
      variant: 'error',
      text: intl.formatMessage({ id: 'menus.publication.notification.error', defaultMessage: 'Unable to publish the menus' }),
    });
  };

  const showPublicationSuccess = (publication: BusinessMenusPublication) => {
    snackBar.addNotification({
      variant: 'success',
      text: intl.formatMessage({
        id: 'menus.publication.notification.success',
        defaultMessage: 'Menus for business {businessId} published with revision {revision}',
      }, {
        businessId: publication.businessId,
        revision: publication.revision,
      }),
    });
  };

  const onPublishMenus = () => {
    publishMenus
      .mutateAsync()
      .then((publication: BusinessMenusPublication) => {
        showPublicationSuccess(publication);
      })
      .catch(() => showPublicationError());
  };

  return (
    <MenuToolBox onClick={onPublishMenus}>
      <Download fontSize="large" sx={{ opacity: 0.6 }} />
      <Typography fontSize="medium" sx={{ opacity: 0.6 }}>
        <FormattedMessage id="menus.publication" defaultMessage="Publish menus" />
      </Typography>
    </MenuToolBox>
  );
};
