import { ValuesOf } from 'src/utils/typescript/valuesOf';

export const tipPoolDistributionFlows = {
  WHOLE_AT_ONCE: 'WHOLE_AT_ONCE',
  DAY_BY_DAY: 'DAY_BY_DAY',
} as const;

export const tipPoolDistributionFlowTranslationKeys = {
  [tipPoolDistributionFlows.WHOLE_AT_ONCE]: 'tipping_configuration.tip_pool_distribution_flow.whole_at_once',
  [tipPoolDistributionFlows.DAY_BY_DAY]: 'tipping_configuration.tip_pool_distribution_flow.day_by_day',
} as const;

export type TipPoolDistributionFlow = ValuesOf<typeof tipPoolDistributionFlows>;
