import { z } from 'zod';
import { MoneyZ } from 'src/operations/pos-reconciliation/domain/MoneyZ';
import { createPaginatedResponseSchema } from 'src/utils/ZodUtils';

export const clawbackStatuses = {
  READY_TO_BE_PAID: 'READY_TO_BE_PAID',
  PAYMENT_INITIATED: 'PAYMENT_INITIATED',
  PAID: 'PAID',
} as const;

const ClawbackSchema = z.object({
  id: z.string(),
  amount: MoneyZ,
  status: z.nativeEnum(clawbackStatuses),
  createdAt: z.number(),
  period: z.object({
    startDate: z.number(),
    endDate: z.number(),
  }),
})
  .transform((data) => ({
    ...data,
    createdAt: new Date(data.createdAt * 1000),
    period: {
      startDate: new Date(data.period.startDate * 1000),
      endDate: new Date(data.period.endDate * 1000),
    },
  }));

export const MerchantClawbacksSchema = z.object({
  totalCharged: MoneyZ.nullish(),
  clawbacks: createPaginatedResponseSchema(ClawbackSchema),
});

export type MerchantClawbacks = z.infer<typeof MerchantClawbacksSchema>;
export type Clawback = z.infer<typeof ClawbackSchema>;
