import { FormControl, MenuItem, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import React from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';

interface UserRoleFieldProps {
  row?: { id: string; role: string };
}

export const UserRoleField = ({ row }: UserRoleFieldProps) => {
  const { control } = useFormContext();
  const intl = useIntl();

  if (!row) return null;

  return (
    <Controller
      name={`users.${row.id}.role`}
      control={control}
      rules={{
        required: {
          value: true,
          message: `*${intl.formatMessage({ id: 'user_management.form.error.required' })}`,
        },
      }}
      defaultValue={row.role || ''}
      render={({ field, fieldState: { error } }) => (
        <FormControl sx={{ flex: 1 }}>
          <TextField
            {...field}
            select
            value={field.value || ''}
            label={`${intl.formatMessage({ id: 'user_management.roles.role' })}*`}
            helperText={error?.message}
            error={!!error}
          >
            <MenuItem value={'ADMIN'}>{intl.formatMessage({ id: 'user_management.role.admin' })}</MenuItem>
            <MenuItem value={'GENERAL_MANAGER'}>
              {intl.formatMessage({ id: 'user_management.role.general_manager' })}
            </MenuItem>
            <MenuItem value={'MANAGER'}>{intl.formatMessage({ id: 'user_management.role.manager' })}</MenuItem>
            <MenuItem value={'STAFF'}>{intl.formatMessage({ id: 'user_management.role.staff' })}</MenuItem>
            <MenuItem value={'WAITER'}>{intl.formatMessage({ id: 'user_management.role.waiter' })}</MenuItem>
          </TextField>
        </FormControl>
      )}
    />
  );
};
