import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDeleteUsers } from 'src/pages/Settings/UserPermissions/mutation/useBusinessUsersQuery';
import { useIntl } from 'src/app/i18n/TypedIntl';

interface DeleteUsersModalProps {
  showDeleteModal: boolean;
  setShowDeleteModal: (value: ((prevState: boolean) => boolean) | boolean) => void;
  selectedIds: string[];
}

export const DeleteUsersModal = ({ showDeleteModal, setShowDeleteModal, selectedIds }: DeleteUsersModalProps) => {
  const intl = useIntl();
  const { mutate: deleteUsers } = useDeleteUsers();

  const handleOnDeleteUsers = () => {
    deleteUsers(selectedIds);
    setShowDeleteModal(false);
  };

  return (
    <Dialog
      open={showDeleteModal}
      onClose={() => setShowDeleteModal(false)}
      maxWidth={'xs'}
      fullWidth
      sx={{
        padding: '1.5rem',
      }}
    >
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography sx={{ fontSize: '20px', fontWeight: 400, color: '#000' }}>
          {intl.formatMessage({ id: 'user_management.delete.modal.title' })}
        </Typography>
        <IconButton onClick={() => setShowDeleteModal(false)}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ color: '#70707B', fontSize: '16px' }}>
          {intl.formatMessage({ id: 'user_management.delete.modal.subTitle' })}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ padding: '1rem 1.5rem' }}>
        <Button onClick={() => setShowDeleteModal(false)}>
          <Typography sx={{ textDecoration: 'underline' }}>
            {intl.formatMessage({ id: 'user_management.delete.modal.button.cancel' })}
          </Typography>
        </Button>
        <Button onClick={handleOnDeleteUsers} variant={'contained'} color={'error'}>
          <Typography>{intl.formatMessage({ id: 'user_management.delete.modal.button.confirm' })}</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};
