import { styled, SxProps, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import React from 'react';

const fontSize = {
  title1: '48px',
  title2: '40px',
  title3: '32px',
  title4: '21px',
  xl: '21px',
  xxl: '24px',
  large: '18px',
  medium: '16px',
  small: '14px',
  xs: '12px',
  xxs: '10px',
};

const lineHeight = {
  title1: '48px',
  title2: '40px',
  title3: '32px',
  title4: '21px',
  xxl: '28px',
  xl: '22px',
  large: '26px',
  medium: '20px',
  small: '16px',
  xs: '14px',
  xxs: '12px',
};

interface TextWrapperProps {
  children: React.ReactNode;
  onClick?: () => void;
  sx?: SxProps<Theme>;
}

interface TextProps {
  size?: 'xxl' | 'xl' | 'large' | 'medium' | 'small' | 'xs' | 'xxs';
  textAlign?: 'left' | 'center' | 'right' | 'justify' | 'initial' | 'inherit';
  fontStyle?: 'normal' | 'italic';
  color?: string;
  isEllipsis?: boolean;
  isKeepCase?: boolean;
}

const SundayTypography = styled(Typography, {
  shouldForwardProp: (prop) =>
    prop !== 'size' &&
    prop !== 'textAlign' &&
    prop !== 'fontStyle' &&
    prop !== 'color' &&
    prop !== 'isEllipsis' &&
    prop !== 'isKeepCase',
})<TextProps>(({ size, textAlign, fontStyle, color, isEllipsis, isKeepCase }) => ({
  fontSize: fontSize[size!] || fontSize.medium,
  fontWeight: 400,
  lineHeight: lineHeight[size!] || lineHeight.medium,
  textAlign: textAlign ?? 'left',
  fontStyle: fontStyle,
  color: color ?? 'primary',
  textTransform: isKeepCase ? 'none' : 'lowercase',
  textOverflow: isEllipsis ? 'ellipsis' : '',
  whiteSpace: isEllipsis ? 'nowrap' : '',
  overflow: isEllipsis ? 'hidden' : '',
}));

export const SundayText = ({
  children,
  size,
  textAlign,
  fontStyle,
  color,
  isEllipsis,
  isKeepCase,
  sx,
  onClick,
}: TextProps & TextWrapperProps) => {
  const textProps = {
    size,
    textAlign: textAlign ?? 'left',
    fontStyle,
    color: color ?? 'primary',
    isEllipsis,
    isKeepCase,
  };

  return (
    // @ts-ignore
    <SundayTypography {...textProps} {...sx} onClick={onClick}>
      {children}
    </SundayTypography>
  );
};
