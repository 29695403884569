import React, { useState } from 'react';
import { FormControl, InputLabel, Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { PaytronixOnboardingForm } from 'src/loyalty/PaytronixOnboardingForm';
import { ComoOnboardingForm } from 'src/loyalty/ComoOnboardingForm';
import { PongoOnboardingForm } from 'src/loyalty/PongoOnboardingForm';
import { ZerosixOnboardingForm } from 'src/loyalty/ZerosixOnboardingForm';

export const LoyaltyOnboardingForms = ({ loyaltyReference }: {
  loyaltyReference: string | undefined
}) => {
  const [reference, setReference] = useState<string | undefined>(loyaltyReference);

  return (
    <>
      <FormControl>
        <InputLabel id="selectLoyaltyReference">Select loyalty reference</InputLabel>
        <Select
          labelId="selectLoyaltyReference"
          label="Select loyalty reference"
          defaultValue={reference}
          onChange={(e) => setReference(e.target.value)}
        >
          <MenuItem value="PAYTRONIX">Paytronix</MenuItem>
          <MenuItem value="COMO">Como</MenuItem>
          <MenuItem value="PONGO">Pongo</MenuItem>
          <MenuItem value="ZEROSIX">Zerosix</MenuItem>
        </Select>
      </FormControl>
      {reference === 'PAYTRONIX' && <PaytronixOnboardingForm />}
      {reference === 'COMO' && <ComoOnboardingForm />}
      {reference === 'PONGO' && <PongoOnboardingForm />}
      {reference === 'ZEROSIX' && <ZerosixOnboardingForm />}
    </>
  );
};
