import styled from 'styled-components';
import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { CurrencyCode } from '@sundayapp/web-money';
import GeneralForm from './GeneralForm';
import WaitersForm from './WaitersForm';
import {
  defaultConfigurationWithCountry,
  defaultNeptingConfiguration,
  PaymentTerminalConfiguration,
} from '../../domain/PaymentTerminalConfiguration';
import TipsForm from './TipsForm';
import { usePaymentTerminalConfiguration } from '../../queries/paymentTerminalConfigurationQuery';
import {
  useUpdatePaymentTerminalConfigurationMutation,
} from '../../mutations/useUpdatePaymentTerminalConfigurationMutation';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import PosConnectionForm from './PosConnectionForm';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { HttpPaymentTerminalRepository } from '../../infrastructure/HttpPaymentTerminalRepository';
import DeleteConfigurationForm from './DeleteConfigurationForm';
import { NeptingConfiguration } from '../../domain/PaymentTerminalRepository';
import FastPaymentForm from './FastPaymentForm';
import CrossSvg from '../../../app/component/icons/CrossSvg';
import { themeV5 } from 'src/app/theme/ThemeV5';
import { IconWrapper } from '@sundayapp/b2b-react-component-library';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import UsersForm from 'src/payment-terminal/components/settings/UsersForm';
import { ConcertConfigurationForm } from 'src/payment-terminal/components/settings/ConcertProtocolForm';
import { useAuthenticatedUserOrThrow } from 'src/auth/hooks/useAuthenticatedUserOrThrow';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  padding: 16px;
  width: 100%;
  background: #f4f4f7;
`;

interface Props {
  currency: CurrencyCode;
  enrollmentId: EnrollmentId;
  configurationId: string | undefined;
  numberOfTerminals: number;
  isDeletable: boolean;
  onCompleted: () => void;
  onClose: () => void;
}

function MainForm({
  currency,
  enrollmentId,
  configurationId,
  numberOfTerminals,
  isDeletable,
  onCompleted,
  onClose,
}: Props) {
  const intl = useIntl();
  const useSnackBar = useSnackbar();
  const user = useAuthenticatedUserOrThrow();
  const updateConfigurationMutation = useUpdatePaymentTerminalConfigurationMutation(currency, enrollmentId);
  const business = useCurrentBusinessOrThrow();

  const [ configuration, setConfiguration ] = useState<PaymentTerminalConfiguration>(
    defaultConfigurationWithCountry(business.address.countryCode.toString()),
  );
  const { isSuccess, isLoading, data } = usePaymentTerminalConfiguration(enrollmentId, configurationId);
  const [ neptingConfiguration, setNeptingConfiguration ] = useState<NeptingConfiguration>(defaultNeptingConfiguration);
  const [ isLoadingNeptingConfiguration, setIsLoadingNeptingConfiguration ] = useState(true);
  const repository = new HttpPaymentTerminalRepository(ConfigurationLoader.load().paymentTerminalBaseUrl);

  useEffect(() => {
    repository
      .getNeptingConfiguration(enrollmentId)
      .then((neptingConf) => setNeptingConfiguration(neptingConf))
      .finally(() => setIsLoadingNeptingConfiguration(false));
  }, []);

  useEffect(() => {
    if (data) {
      setConfiguration(data);
    }
  }, [ data ]);

  const deleteConfiguration = async () => {
    if (!configurationId) return;
    await repository.deleteConfiguration(enrollmentId, configurationId);
    onCompleted();
  };

  const updateConfiguration = async () => {
    await updateConfigurationMutation.mutateAsync(configuration);

    try {
      useSnackBar.addNotification({
        variant: 'success',
        text: intl.formatMessage({ id: 'payment.terminal.manage.configuration.updated' }),
      });
      onCompleted();
    } catch (e) {
      useSnackBar.addNotification({
        variant: 'error',
        text: intl.formatMessage({ id: 'payment.terminal.manage.configuration.not_updated' }),
      });
    }
  };

  if (isLoading || isLoadingNeptingConfiguration) return <CircularProgress />;
  return (
    <Container>
      {isLoading && isLoadingNeptingConfiguration && <CircularProgress />}
      {isSuccess && (
        <Stack gap="24px">
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h5">{configuration?.general.name ?? ''}</Typography>
            <IconWrapper onClick={onClose} cursor="pointer">
              <CrossSvg size={24} color={themeV5.palette.text.secondary} />
            </IconWrapper>
          </Stack>

          <GeneralForm
            generalConfig={configuration.general}
            neptingConfiguration={neptingConfiguration}
            onGeneralConfigurationUpdated={(generalConfig) =>
              setConfiguration({
                ...configuration,
                general: generalConfig,
              })
            }
          />
          {configuration.userSelectionMode === 'USER_LIST_FROM_MERCHANT_ACCOUNT' && (
            <UsersForm
              enrollmentId={enrollmentId}
              userConfig={configuration.users}
              onUserConfigUpdated={(userConfig) => setConfiguration({ ...configuration, users: userConfig })}
            />
          )}

          {configuration.userSelectionMode === 'LEGACY_WAITER_LIST' && (
            <WaitersForm
              waiterConfig={configuration.waiter}
              onWaiterConfigUpdated={(waiterConfig) => setConfiguration({ ...configuration, waiter: waiterConfig })}
            />
          )}

          <TipsForm
            tipsConfig={configuration.tips}
            onTipsUpdated={(tipsConfig) => setConfiguration({ ...configuration, tips: tipsConfig })}
          />
          <PosConnectionForm
            posConnectionConfig={configuration.psoConnection}
            onPosConnectionConfigUpdated={(posConnectionConfig) => setConfiguration({
              ...configuration,
              psoConnection: posConnectionConfig,
            })}
          />
          <FastPaymentForm
            fastPaymentConfiguration={configuration.fastPayment}
            onFastPaymentConfigUpdated={(fastPaymentConfig) =>
              setConfiguration({
                ...configuration,
                fastPayment: fastPaymentConfig,
              })
            }
          />
          {user.impersonated &&
            <ConcertConfigurationForm
              concertConfiguration={configuration.concertConfiguration}
              onConcertConfigUpdated={(concertConfig) =>
                setConfiguration({
                  ...configuration,
                  concertConfiguration: concertConfig,
                })
              }
            />
          }

          {!!configuration.id && isDeletable && (
            <DeleteConfigurationForm
              configuration={configuration}
              numberOfTerminals={numberOfTerminals}
              onClick={deleteConfiguration}
            />
          )}
          <Box paddingTop="32px" />
        </Stack>
      )}
      <Box position="fixed" bottom="1%" overflow="auto" paddingTop="16px">
        <Button variant="contained" onClick={updateConfiguration}>
          {!!configurationId ? (
            <FormattedMessage id="payment.terminal.settings.save_changes" />
          ) : (
            <FormattedMessage id="payment.terminal.settings.create" />
          )}
        </Button>
      </Box>
    </Container>
  );
}

export default MainForm;
