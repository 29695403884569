import React, { PropsWithChildren } from 'react';
import { Box } from '@mui/material';
import { VisaIcon } from './VisaIcon';
import { MasterCardIcon } from './MasterCardIcon';
import { AmexIcon } from './AmexIcon';
import { ApplePayIcon } from './ApplePayIcon';
import { GooglePayIcon } from './GooglePayIcon';
import { EdenredIcon } from './EdenredIcon';
import { SwileIcon } from './SwileIcon';
import { UpIcon } from './UpIcon';
import { BimpliIcon } from './BimpliIcon';
import { ValuesOf } from 'src/utils/typescript/valuesOf';
import { CarteBancairesIcon } from './CarteBancairesIcon';
import { GenericCardIcon } from './GenericCardIcon';
import { MaestroIcon } from './MaestroIcon';
import { ElectronIcon } from './ElectronIcon';
import { JcbIcon } from './JcbIcon';
import { UnionPayIcon } from './UnionPayIcon';
import { SodexoIcon } from './SodexoIcon';
import { SundayIcon } from 'src/accounting/common/Icons/SundayIcon';
import { CashIcon } from 'src/accounting/common/Icons/CashIcon';
import { DiscoverIcon } from 'src/accounting/common/Icons/DiscoverIcon';
import { RewardIcon } from 'src/accounting/common/Icons/RewardIcon';

const paymentMethodBrands = {
  sunday: 'sunday',
  visa: 'visa',
  sodexo: 'sodexo',
  pluxee: 'pluxee',
  mastercard: 'mastercard',
  amex: 'amex',
  applePay: 'applePay',
  googlePay: 'googlePay',
  edenred: 'edenred',
  swile: 'swile',
  up: 'up',
  bimpli: 'bimpli',
  carteBancaires: 'carteBancaires',
  maestro: 'maestro',
  electron: 'electron',
  jcb: 'jcb',
  discover: 'discover',
  reward: 'reward',
  unionpay: 'unionpay',
  cash: 'cash',
  generic: 'generic',
} as const;

export type PaymentMethodBrand = ValuesOf<typeof paymentMethodBrands>;

const isPaymentMethodBrand = (brand: string): brand is PaymentMethodBrand => brand in paymentMethodBrands;

type PaymentMethodSize = 'm' | 'l' | 'xl';

function getRatioFromSize(size: PaymentMethodSize) {
  // eslint-disable-next-line no-nested-ternary
  return size === 'm' ? 1 : size === 'l' ? 1.2 : 1.5;
}

interface WrapperPaymentMethodIconProps {
  brand: PaymentMethodBrand;
  size?: PaymentMethodSize;
}

type PaymentMethodComponent = React.FC<{ width: number; height: number }>;

const BrandIcons: Record<PaymentMethodBrand, { component: PaymentMethodComponent; width: number; height: number }> = {
  visa: {
    component: VisaIcon,
    width: 20,
    height: 8,
  },
  mastercard: {
    component: MasterCardIcon,
    width: 16,
    height: 13,
  },
  amex: {
    component: AmexIcon,
    width: 22,
    height: 8,
  },
  applePay: {
    component: ApplePayIcon,
    width: 21,
    height: 9,
  },
  googlePay: {
    component: GooglePayIcon,
    width: 20,
    height: 8,
  },
  edenred: {
    component: EdenredIcon,
    width: 20,
    height: 13,
  },
  sodexo: {
    component: SodexoIcon,
    width: 20,
    height: 20,
  },
  pluxee: {
    component: SodexoIcon,
    width: 20,
    height: 20,
  },
  swile: {
    component: SwileIcon,
    width: 20,
    height: 7,
  },
  up: {
    component: UpIcon,
    width: 20,
    height: 16,
  },
  bimpli: {
    component: BimpliIcon,
    width: 20,
    height: 16,
  },
  carteBancaires: {
    component: CarteBancairesIcon,
    width: 40,
    height: 24,
  },
  maestro: {
    component: MaestroIcon,
    width: 40,
    height: 24,
  },
  electron: {
    component: ElectronIcon,
    width: 40,
    height: 24,
  },
  generic: {
    component: GenericCardIcon,
    width: 40,
    height: 24,
  },
  jcb: {
    component: JcbIcon,
    width: 40,
    height: 24,
  },
  unionpay: {
    component: UnionPayIcon,
    width: 40,
    height: 24,
  },
  sunday: {
    component: SundayIcon,
    width: 40,
    height: 24,
  },
  cash: {
    component: CashIcon,
    width: 40,
    height: 24,
  },
  discover: {
    component: DiscoverIcon,
    width: 40,
    height: 24,
  },
  reward: {
    component: RewardIcon,
    width: 40,
    height: 24,
  },


};

const iconFromBrand = (brand: string): { component: PaymentMethodComponent, width: number, height: number } => {
  if (!isPaymentMethodBrand(brand)) {
    return BrandIcons.generic;
  }
  return BrandIcons[brand];
};

const Wrapper = ({ size = 'm', children }: PropsWithChildren<{ size: WrapperPaymentMethodIconProps['size'] }>) => (
  <Box sx={{
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 32 * (size === 'm' ? 1 : getRatioFromSize(size)),
    height: 20 * (size === 'm' ? 1 : getRatioFromSize(size)),
    borderRadius: '3px',
    border: '1px solid #eff0f4',
    backgroundColor: 'white',
  }}
  >
    {children}
  </Box>
);

export function PaymentMethodIcon({ brand, size = 'm' }: WrapperPaymentMethodIconProps) {
  const icon = iconFromBrand(brand);
  const Icon = icon.component;
  const ratio = getRatioFromSize(size);
  return <Wrapper size={size}><Icon width={icon.width * ratio} height={icon.height * ratio} /></Wrapper>;
}
