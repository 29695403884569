import { Box, Divider, styled, Typography } from '@mui/material';
import { ArrowForwardIos, NorthEast, SouthEast } from '@mui/icons-material';
import React, { ReactNode } from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';

type HomeCardSubItem = {
  label: string | ReactNode;
  value: string;
};

const CardWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  flexDirection: 'column',
  padding: '24px',
  alignItems: 'flex-start',
  gap: '24px',
  width: '100%',
  borderRadius: '16px',
  border: '1px solid rgba(14, 7, 29, 0.05)',

  '&[data-has-fixed-width="true"]': {
    width: '49%',

    ['@media (max-width: 1024px)']: {
      width: '100%',
    },
  },
}));

interface HomeCardProps {
  handleCardRedirect: () => void;
  subTitle?: string;
  title: string;
  titleIcon: ReactNode;
  trend?: number;
  value?: number;
  isPercentageValue?: boolean;
  subItems?: HomeCardSubItem[];
  hasDivider?: boolean;
  dataTable?: ReactNode;
  hasFixedWidth?: boolean;
}

export const NewHomeCard = ({
  handleCardRedirect,
  subTitle,
  title,
  titleIcon,
  trend,
  value,
  isPercentageValue,
  subItems,
  hasDivider = true,
  dataTable,
  hasFixedWidth = false,
}: HomeCardProps) => {
  const intl = useIntl();
  const signSymbol = trend && trend >= 0 ? '+' : '';
  const percentSymbol = isPercentageValue ? '%' : '';
  const trendValue = trend === Number.POSITIVE_INFINITY ? '' : `${signSymbol}${trend}%`;
  const displayedValue = `${value}${percentSymbol}`;
  return (
    <CardWrapper data-has-fixed-width={hasFixedWidth}>
      <Box
        component={'div'}
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'space-between',
        }}
        data-testId={'testNewHomeCard'}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            gap: '8px',
          }}
        >
          {titleIcon}
          <Typography sx={{ fontSize: '21px', fontWeight: 500 }}>{title}</Typography>
        </Box>
        <Box
          component={'div'}
          sx={{ display: 'flex', cursor: 'pointer' }}
          onClick={handleCardRedirect}
          data-testId="testClickOnCardArrowButton"
        >
          <ArrowForwardIos />
        </Box>
      </Box>
      {value !== undefined && (
        <Box>
          <Typography sx={{ fontSize: '18px', fontWeight: 500 }}>{subTitle}</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Typography
              sx={{ fontSize: '56px', fontWeight: 500, margin: '0' }}
              component="span"
              data-testId={'displayedValue'}
            >
              {displayedValue}
            </Typography>
            {trend !== 0 && trend !== undefined && (
              <Box component={'div'} sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {trend > 0 ? <NorthEast color="success" /> : <SouthEast color="error" />}
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 500,
                      ...(trend > 0 ? { color: 'success.main' } : { color: 'error.main' }),
                    }}
                    component="span"
                    data-testId={'trendValueTypo'}
                  >
                    {trendValue}
                  </Typography>
                </Box>
                <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                  {intl.formatMessage({ id: 'venue.home.compareLastMonday' })}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      )}
      {hasDivider && <Divider component="div" flexItem variant="fullWidth" />}
      {subItems && subItems.length > 0 && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px', width: '100%' }} data-testid={'subItemsBox'}>
          {subItems.map((item) => (
            <Box key={item.value} style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <Typography sx={{ color: 'rgba(14, 7, 29, 0.58)', fontSize: '18px', fontWeight: 500 }}>
                {item.label}
              </Typography>
              <Typography sx={{ fontSize: '18px', fontWeight: 500 }} data-testId={'subItemTypo'}>
                {item.value}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
      {dataTable}
    </CardWrapper>
  );
};
