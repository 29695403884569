import { Dialog, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import React from 'react';
import { CheckCircle } from '@mui/icons-material';
import { CrossIcon } from 'src/ordering/common/components/Icons';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { queryKeys } from 'src/app/queries/utils';
import { useQueryClient } from '@tanstack/react-query';
import { computeNextTipsPayoutDate, formatDispatchPayoutDate } from 'src/tips/dispatch/model/DoneTipsDispatch';

export const DispatchSuccessDialog = ({ opened, onClose }: { opened: boolean; onClose: () => void }) => {
  const queryClient = useQueryClient();
  const handleOnClose = () => {
    /*
       Invalidation is done here since invalidating queries from the mutation itself,
       generates a rerender across all the components, leading to the modal to not being displayed.
       It might not be the best option but since the mutation is only used once, it might be good enough atm.
     */
    queryClient
      .invalidateQueries({
        predicate: (query) =>
          [
            queryKeys.sundayTipsPooling.GET_DONE_TIPS_DISPATCH,
            queryKeys.sundayTipsPooling.GET_PENDING_TIPS_DISPATCH,
          ].filter((value) => value === query.queryKey[0]).length > 0,
      })
      .then(() => onClose());
  };

  const nextTipsPayoutDate = computeNextTipsPayoutDate();
  return (
    <Dialog open={opened} fullWidth maxWidth={'xs'} onClose={handleOnClose}>
      <DialogTitle>
        <Stack sx={{ cursor: 'pointer' }} onClick={handleOnClose} direction={'row'} justifyContent={'flex-end'}>
          <CrossIcon />
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack direction={'column'} alignItems={'center'} spacing={2} paddingBottom={2}>
          <CheckCircle style={{ fontSize: 64 }} color={'success'} />
          <Typography variant={'h4'}>
            <FormattedMessage id={'tips.sunday_pooling.dispatch.success'} />
          </Typography>
          <Typography variant={'body1'} align={'center'}>
            <FormattedMessage
              id={'tips.sunday_pooling.dispatch.explain'}
              values={{
                payoutDate: formatDispatchPayoutDate(nextTipsPayoutDate),
              }}
            />
          </Typography>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
