// eslint-disable-next-line @typescript-eslint/no-redeclare
import { colorUsage } from '@sundayapp/b2b-react-component-library';
import { spaceUsages } from 'src/app/theme/SpaceUsagesTheme';
import { SundayText } from 'src/app/component/Text/Text';

type MenuDailyScheduleProps = {
  active: boolean;
  messages: string[];
};

const MenuDailySchedule = ({ active, messages }: MenuDailyScheduleProps) => {
  const fontColor = active ? colorUsage.secondaryText : colorUsage.disabledText;

  return (
    <>
      {messages.map((message) => (
        <SundayText size="medium" color={fontColor} sx={{ paddingLeft: spaceUsages.small }} key={message}>
          {message}
        </SundayText>
      ))}
    </>
  );
};

export default MenuDailySchedule;
