import { colorUsage } from '@sundayapp/b2b-react-component-library';
import React from 'react';
import { Box } from '@mui/material';
import { SundayText } from 'src/app/component/Text/Text';

export type ConfigurationItemNameProps = {
  name: string;
  active: boolean;
};

export const ConfigurationItemName = ({ name, active }: ConfigurationItemNameProps) => {
  const fontColor = active ? colorUsage.primaryText : colorUsage.disabledText;

  return (
    <Box flexDirection="row" display="flex" textOverflow="ellipsis">
      <SundayText size="xl" color={fontColor} sx={{ textOverflow: 'ellipsis' }}>
        {name}
      </SundayText>
    </Box>
  );
};
