import { CurrencyCode } from '@sundayapp/web-money';
import { queryKeys } from 'src/app/queries/utils';
import { DashboardKpiRepository } from '../infrastructure/DashboardKpiRepository';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { VenueKpi } from '../domain/VenueKpi';
import { VenueKpiDto } from '../domain/VenueKpiRepository';

function round(val: number): number {
  return Math.round(val * 10) / 10;
}

export const venueKpiQuery = (businessId: string, currency: string, timezone: string) => ({
  queryKey: [queryKeys.VENUE_KPI, businessId, { currency, timezone }],
  queryFn: async (): Promise<VenueKpi> => {
    const configuration = ConfigurationLoader.load();
    const repository = new DashboardKpiRepository(configuration.merchantAnalyticsUrl);

    const {
      adoptionRate,
      adoptionRateLastWeek,
      averageRating,
      averageRatingLastWeek,
      billViewRate,
      billViewRateLastWeek,
      tipsPercentage,
      tipsPercentagePaymentTerminal,
      tipsPercentageLastWeek,
      totalLoyaltyCustomer,
      totalLoyaltyCustomerLastWeek,
      totalRevenue,
      totalRevenueLastWeek,
      totalReviews,
      totalReviewsLastWeek,
      totalTips,
      totalTipsLastWeek,
      totalAmountPaymentTerminal,
      totalAmountPaymentTerminalLastWeek,
      totalTipsPaymentTerminal,
      totalTipsPaymentTerminalLastWeek,
      nRatingsGoogleExternal,
      nRatingsGoogleFromSunday,
      n5StarsGoogleReviewsFromSunday,
      averageGoogleRatingWithSunday,
      nRatingsGoogleExternalLastWeek,
      nRatingsGoogleFromSundayLastWeek,
      n5StarsGoogleReviewsFromSundayLastWeek,
      averageGoogleRatingWithSundayLastWeek,
    }: VenueKpiDto = await repository.getVenueKpi(businessId, timezone);

    const venueCurrency = CurrencyCode[currency as keyof typeof CurrencyCode];

    return {
      adoptionRate: adoptionRate ? Math.round(adoptionRate * 100) : undefined,
      totalRevenue: { amount: totalRevenue * 100000, currency: venueCurrency },
      totalTips: { amount: totalTips * 100000, currency: venueCurrency },
      tipsPercentage: round(tipsPercentage),
      tipsPercentagePaymentTerminal: round(tipsPercentagePaymentTerminal),
      averageRating: round(averageRating),
      totalReviews,
      totalLoyaltyCustomer,
      adoptionRateLastWeek: adoptionRateLastWeek ? Math.round(adoptionRateLastWeek * 100) : undefined,
      totalRevenueLastWeek: { amount: (totalRevenueLastWeek ?? 0) * 100000, currency: venueCurrency },
      nRatingsGoogleExternal,
      nRatingsGoogleFromSunday: nRatingsGoogleFromSunday,
      n5StarsGoogleReviewsFromSunday,
      averageGoogleRatingWithSunday: averageGoogleRatingWithSunday,
      totalTipsLastWeek: { amount: (totalTipsLastWeek ?? 0) * 100000, currency: venueCurrency },
      tipsPercentageLastWeek: round(tipsPercentageLastWeek ?? 0),
      averageRatingLastWeek: round(averageRatingLastWeek ?? 0),
      totalReviewsLastWeek: totalReviewsLastWeek ?? 0,
      totalLoyaltyCustomerLastWeek: totalLoyaltyCustomerLastWeek ?? 0,
      totalAmountPaymentTerminal: { amount: (totalAmountPaymentTerminal ?? 0) * 100000, currency: venueCurrency },
      totalAmountPaymentTerminalLastWeek: {
        amount: (totalAmountPaymentTerminalLastWeek ?? 0) * 100000,
        currency: venueCurrency,
      },
      totalTipsPaymentTerminal: { amount: (totalTipsPaymentTerminal ?? 0) * 100000, currency: venueCurrency },
      totalTipsPaymentTerminalLastWeek: {
        amount: (totalTipsPaymentTerminalLastWeek ?? 0) * 100000,
        currency: venueCurrency,
      },
      billViewRate: Math.round((billViewRate ?? 0) * 100),
      billViewRateLastWeek: Math.round((billViewRateLastWeek ?? 0) * 100),
      nRatingsGoogleExternalLastWeek,
      nRatingsGoogleFromSundayLastWeek,
      n5StarsGoogleReviewsFromSundayLastWeek,
      averageGoogleRatingWithSundayLastWeek,
    };
  },
});
