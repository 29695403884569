import { IconButtonProps, Theme } from '@mui/material';

export const IconButtonTheme = {
  styleOverrides: {
    root: ({ ownerState, theme }: { ownerState: IconButtonProps; theme: Theme }) => ({
      ...(ownerState.size === 'small' &&
        ownerState.className === 'custom-small-iconButton' && {
          border: `1px solid ${theme.palette.background.paper}`,
          borderRadius: '50%',
          padding: '8px',
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.grey[600],
          cursor: 'pointer',
          width: '32px',
          height: '32px',

          '&:hover': {
            border: `solid 1px ${theme.palette.grey[300]}`,
          },

          '&:active': {
            border: `solid 1px ${theme.palette.grey[400]}`,
          },
        }),
      ...(ownerState.size === 'large' &&
        ownerState.className === 'custom-large-iconButton' && {
          border: `solid 1px ${theme.palette.background.paper}`,
          borderRadius: '50%',
          width: '48px',
          height: '48px',
          padding: '12px',
          color: theme.palette.grey[600],
          backgroundColor: '#FFFFFF',
          cursor: 'pointer',

          '&:hover': {
            border: `solid 1px ${theme.palette.grey[300]}`,
          },

          '&:active': {
            border: `solid 1px ${theme.palette.grey[400]}`,
          },
        }),
    }),
  },
};
