import { z } from 'zod';

const StaffZ = z.object({
  id: z.string(),
  externalId: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  phoneNumber: z.string(),
  email: z.string(),
  directTippingRate: z
    .object({
      value: z.number(),
    })
    .optional(),
});

export type Staff = z.infer<typeof StaffZ>;
export const StaffSchema = z.array(StaffZ);
